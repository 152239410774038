import React from 'react'
import '../App.css'
import {
    Container,
    Row,
    Col,
    Button,
    ListGroup, 
    ListGroupItem
} from "shards-react"
import { connect } from "react-redux";
import { withRouter } from "react-router"
import { API_URL } from '../configs/configs';
import Moment from 'react-moment';
import { ResponsiveEmbed } from 'react-bootstrap';
var moment = require('moment');

const axios = require('axios');

class CommissionsTables extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            loading: false,
            iconLoading: false,
            open: false,
			commissionMary: [],
			commissionEmployee: [],
			totalMary: null,
			totalEmployee: null,
			nameEmployee: null,
			wedding: [{}]
        }
    }

    changeFormValue = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    componentDidMount = () => {

		let id = this.props.match.params.id;

		//BRIDE/GROOM NAME
		axios.get( API_URL + '/wedding/getWedding/' + id , { headers: {'authorization': this.props.tokens} })
        .then((response) => {
            //console.log("RESPONSE DATA WEDDING BY ID", response.data.wedding);
            let weddingRes = response.data.wedding
    
            this.setState({
                groom: weddingRes.groom.nameGroom,
                bride: weddingRes.bride.nameBride
            })
        })
        .catch((error) => {
            console.log(error);
		});
		
        //COMMISSIONS
        axios.get( API_URL + '/wedding/comissionValues/' + id, { headers: {'authorization': this.props.tokens} })
        .then((response) => {

			console.log("RESULT: ", response.data.result)


			this.setState({
				commissionMary: response.data.result.comissionMary,
				commissionEmployee: response.data.result.comissionEmployee,
				totalMary: response.data.result.totalMary,
				totalEmployee: response.data.result.totalEmployee,
				nameEmployee: response.data.result.nameEmployee
			})

        })
        .catch((error) => {
        // notify("Erro na pesquisa de casamentos.")
        console.log(error);
        });
    }


    render() {
		console.log("STATE: ", this.state)
		// console.log("STATE FILTERED: ", this.state.filteredBy)

		let commissionsEmployee
		if(this.state.commissionEmployee !== null && this.state.commissionEmployee !== undefined){
			commissionsEmployee = this.state.commissionEmployee.map((value, index) => {
				return (
					<div>
						<ListGroupItem className="log-list" style={{fontSize:"14px"}} >
							<Row>
								<Col sm="5">
									{value.providerName}
								</Col>
								<Col sm="5">
									{value.packName}
								</Col>
								<Col sm="1" style={{textAlign:"center"}}>
									{value.comissionPerc}%
								</Col>
								<Col sm="1" style={{textAlign:"end"}}>
									{value.comissionValue}€
								</Col>
							</Row>
						</ListGroupItem>
					</div>
				)
			})

		}
		
		
        let commissionsMary = this.state.commissionMary.map((value, index) => {
            return (
                <div>
                    <ListGroupItem className="log-list" style={{fontSize:"14px"}} >
                        <Row>
                            <Col sm="5">
                                {value.providerName}
                            </Col>
                            <Col sm="4">
                                {value.packName}
                            </Col>
                            <Col sm="1" style={{textAlign:"center"}}>
                                {value.totalValue}€
                            </Col>
                            <Col sm="2" style={{textAlign:"end"}}>
                                {value.comissionValue}€
                            </Col>
                        </Row>
                    </ListGroupItem>
                </div>
            )
        })

    
        
        return (
            <div style={{ marginBottom: "7%" }}>
				<Row style={{justifyContent:"center", marginBottom:"3%"}}>
					<h5 >Comissões {this.state.bride} & {this.state.groom}</h5>
				</Row>
				{(this.state.commissionEmployee !== undefined && this.state.commissionEmployee !== null) ? (
					(this.state.commissionEmployee.length !== 0) ? (
						<Container style={{marginRight: "6%", marginBottom:"5%"}}>
							<ListGroup className="card" style={{ width:"95%", marginBottom:"5%"}}>
								<ListGroupItem className="log-list-header">
									<Row style={{justifyContent:"center", marginBottom:"2%", fontSize:"20px"}}>
										<b><u>{this.state.nameEmployee}</u></b>
									</Row>
									<Row style={{fontSize:"15px"}}>
										<Col sm="5">
											<b>Fornecedor</b>
										</Col>
										<Col sm="5">
											<b>Pack</b>
										</Col>
										<Col sm="1" style={{textAlign:"center"}}>
											<b>%</b>
										</Col>
										<Col sm="1" style={{textAlign:"end"}}>
											<b>Valor</b>
										</Col>
									</Row>
								</ListGroupItem >
									{commissionsEmployee}
								<ListGroupItem>
									<Row>
										<Col sm="12" style={{textAlign:"end", fontSize:"15px"}}>
											Total:  {this.state.totalEmployee} €
										</Col>
									</Row>
								</ListGroupItem>
							</ListGroup>
					</Container> 
					) : (
						<Container style={{marginRight: "6%"}}>
							<ListGroup className="card" style={{ width:"95%", marginBottom:"5%"}}>
								<ListGroupItem className="log-list-header">
									<Row style={{justifyContent:"center", marginBottom:"2%", fontSize:"20px"}}>
										<b><u>{this.state.nameEmployee}</u></b>
									</Row>
								</ListGroupItem >
								<p style={{marginLeft:"1%"}}>De momento não existem comissões.</p>
								
							</ListGroup>
						</Container> 
					)
				) : (
					null
				)}
                
				{(this.state.commissionMary.length !== 0) ? (
					<Container style={{marginRight: "6%"}}>
                        <ListGroup className="card" style={{ width:"95%", marginBottom:"5%"}}>
                            <ListGroupItem className="log-list-header">
								<Row style={{justifyContent:"center", marginBottom:"2%", fontSize:"20px"}}>
									<b><u>Mary Me</u></b>
								</Row>
								<Row style={{fontSize:"15px"}}>
									<Col sm="5">
										<b>Fornecedor</b>
									</Col>
									<Col sm="4">
										<b>Pack</b>
									</Col>
									<Col sm="1" style={{textAlign:"center"}}>
										<b>Valor</b>
									</Col>
									<Col sm="2" style={{textAlign:"end"}}>
										<b>Comissão</b>
									</Col>
								</Row>
                            </ListGroupItem >
                            	{commissionsMary}
							<ListGroupItem>
								<Row>
									<Col sm="12" style={{textAlign:"end", fontSize:"15px"}}>
										Total:  {this.state.totalMary} €
									</Col>
								</Row>
							</ListGroupItem>
                        </ListGroup>
                </Container> 
				) : (
					<Container style={{marginRight: "6%"}}>
                        <ListGroup className="card" style={{ width:"95%", marginBottom:"5%"}}>
                            <ListGroupItem className="log-list-header">
								<Row style={{justifyContent:"center", marginBottom:"2%", fontSize:"20px"}}>
									<b><u>Mary Me</u></b>
								</Row>
                            </ListGroupItem >
							<p style={{marginLeft:"1%"}}>De momento não existem comissões.</p>
							
                        </ListGroup>
                	</Container> 
				)}
            </div>
      );
    } 
}

let appContainer = withRouter(CommissionsTables);

  function mapStateToProps(state) {
    return { tokens: state.tokens, reduxStorageLoaded: state.storageLoaded, validTokens: state.validTokens, _id: state.user._id, user: state.user }
  }

  const AppRedux = connect(
    mapStateToProps,
  )(appContainer);
  
export default AppRedux;
