import React from 'react';
import { Spin } from 'antd';


class LoadingPage extends React.Component {

    render() {
        return (

            <div className="App">
            <header className="App-header">
                <Spin size="large"/>
                <h6 style={{marginTop: 25}}>A carregar</h6>
          </header>
        </div>
      );
    } 
}

export default LoadingPage;
