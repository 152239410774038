import React from 'react';
import './../App';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import { connect } from "react-redux";
import { setTokens } from './../redux/actions/index';
import DateRangeIcon from '@material-ui/icons/DateRange';
import MW from '@material-ui/icons/Wc';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import GroupIcon from '@material-ui/icons/Group';


class Sidebar extends React.Component {

	handleClick = e => {
		console.log('click ', e);
	};

	render() {
		//console.log("****** USER ****** ", this.props.user)
		return (
			<Menu
				onClick={this.handleClick}
				style={{ width: 80, position: 'fixed', height: "-webkit-fill-available" }}
				defaultSelectedKeys={['1']}
				// defaultOpenKeys={['sub1']}
				mode="inline">

				<Menu.Item className="sidebar-items" key="1">
					<i className="m_icon"><DateRangeIcon /></i>
					<Link to="/general_dashboard" />
				</Menu.Item>
				<Menu.Item className="sidebar-items" key="2">
					<i className="m_icon"><MW /></i>
					<Link to="/add_wedding" />
				</Menu.Item>
				{/* {(this.props.user.role && (this.props.user.role.indexOf("admin") !== -1)) ? ( */}
					<Menu.Item className="sidebar-items" key="3">
						<i className="m_icon"><PersonAddIcon /></i>
						<Link to="/add_provider" />
					</Menu.Item>
				{/* ) : null} */}
				<Menu.Item className="sidebar-items" key="4">
					<i className="m_icon"><GroupIcon /></i>
					<Link to="/view_providers" />
				</Menu.Item>
			</Menu>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		setTokens: payload => dispatch(setTokens(payload))
	};
}

function mapStateToProps(state) {
	return { tokens: state.tokens, user: state.user }
}

const SidebarRedux = connect(
	mapStateToProps,
	mapDispatchToProps
)(Sidebar);

export default SidebarRedux;