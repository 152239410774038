import React from 'react'
import '../App.css'
import {
    Col,
    Container,
    Row,
    Button 
} from "shards-react"
import { Radio, Input, Select } from 'antd'
import ControlPointIcon from '@material-ui/icons/ControlPoint'
import ProviderPack from './../components/ProviderPack'
import { connect } from "react-redux"
import { withRouter } from "react-router"
import { API_URL } from '../configs/configs'
import notify from '../helpers/notify'
import Tooltip from '@material-ui/core/Tooltip'
import Fade from '@material-ui/core/Fade'


const axios = require('axios')
const { Option } = Select

class AddProvider extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            loading: false,
            iconLoading: false,
            open: false,
            packs: [{
                name:"",
                desc:"",
                price: null,
                priceBy: null,
                comission:"",
                comissionType: null
            }],
            users: []
        }
    }

    componentDidMount = () => {

        //GET USERS FOR SELECT
        axios.get( API_URL + '/user/getUsers', { headers: {'authorization': this.props.tokens} })
        .then((response) => {
            let usersRes = response.data.users

            let usersData = usersRes.map((value, index) => (
                {
                    _id: value._id,
                    name: value.name
                }
            ))

            this.setState({
                users: usersData
            })
            
        })
        .catch((error) => {
        notify("Erro na pesquisa de utilizadores.")
        console.log(error);
        });

    }

    changeFormValue = (key, value) => {
        this.setState({
            [key]: value
        })
        //console.log("BBBBBB", this.state)
    }

    changeRadio = e => {
        this.setState({
          newProvider: e.target.value,
        });
        //console.log("EEEEEEEE, ", this.state)
    };

    changeSelectUsers = (value) => {
        this.setState({
            selectedUser: value
        })
        //console.log("YYYYYYYYY, ", this.state)
    }

    changeSelectCategory = (value) => {
        this.setState({
            selectedCategory: value
        })
        //console.log("XXXXX, ", this.state)
    }

    changePacksEvent = (value, key, index) => {
        let packsArr = this.state.packs;
        packsArr[index][key] = value;
        this.setState({
            packs: packsArr
        })
        //console.log("AAAAAAAAA", packsArr)
    }

    addPackRow = () => {
        let packArr = this.state.packs;
        packArr.push({
            name:"",
            desc:"",
            price: null,
            priceBy: null,
            comission:"",
            comissionType: null
        })
        this.setState({
            packs: packArr
        })
    }

    addProvider = (e) => {

        e.preventDefault();

        let providerObj = {
            name: this.state.name,
            role: this.state.selectedCategory,
            phone: this.state.phone,
            email: this.state.email,
            raisedBy: this.state.selectedUser,
            newProvider: (this.state.newProvider === "yes") ? true : false,
            packs: this.state.packs   
        }

        axios.post( API_URL + '/provider/addProvider/', providerObj, { headers: {Authorization: this.props.tokens}})
        .then((response) => {
            //console.log("ADDED PROV!", response.data);
            this.setState({ loading: false})
            notify("Fornecedor adicionado com sucesso!", "success")
            this.props.history.push('/general_dashboard')
        })
        .catch((err) => {
            notify("Erro ao adicionar fornecedor.")
            console.dir(err);
            this.setState({ loading: false });   
        });
    }


    render() {
        
        let users = this.state.users.map((value, index) => {
            return <Option value={value._id}>{value.name}</Option>
        })

        let providerPack = this.state.packs.map((value, index) => {
            return <ProviderPack 
                nameValue={value.name} 
                detailsValue={value.desc}
                price={value.price}
                priceBy={value.priceBy}
                commissionValue={value.comission}
                commissionType={value.comissionType}
                onNameChange={(el) => { this.changePacksEvent(el.target.value, "name", index) }} 
                onDetailsChange={(el) => { this.changePacksEvent(el.target.value, "desc", index) }} 
                onPriceChange={(el) => { this.changePacksEvent(el.target.value, "price", index) }} 
                onPriceByChange={(el) => { this.changePacksEvent(el.target.value, "priceBy", index) }} 
                onCommissionChange={(el) => { this.changePacksEvent(el.target.value, "comission", index) }} 
                onCommissionTypeChange={(el) => { this.changePacksEvent(el.target.value, "comissionType", index) }} 
            />
        })

        return (
            <form onSubmit={this.addProvider} >
                <div style={{ marginBottom: "4%" }}>
                    <Container style={{ paddingRight: 0, marginLeft: "1%", marginRight: "2%", maxWidth: "-webkit-fill-available" }}>
                        <h4 style={{ textAlign:"left", marginBottom: "50px", marginTop:"15px" }}>Adicionar Fornecedor</h4>
                        <Row style={{ marginBottom:"30px"}}>
                            <Col sm="4">
                                <div>
                                    <p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold"}}>O fornecedor a adicionar foi previamente contratado?</p>
                                    <Radio.Group name="Provider" onChange={this.changeRadio} value={this.state.newProvider}>
                                        <Radio value={"no"}>Sim</Radio>
                                        <Radio value={"yes"}>Não</Radio>
                                    </Radio.Group>
                                </div>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom:"30px"}}>
                            <Col sm="6">
                                <p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold"}}>Fornecedor angariado por:</p>
                                <Select placeholder="Selecione Colaborador/a." value={this.state.selectedUser} onChange={this.changeSelectUsers}>
                                    {/* <option selected disabled>Selecione Colaborador/a.</option> */}
                                    {users}
                                </Select>
                            </Col>
                            <Col sm="6">
                                <p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold"}}>Categoria:</p>
                                <Select placeholder="Selecione Categoria." value={this.state.selectedCategory} onChange={this.changeSelectCategory}>
                                    {/* <option selected disabled>Selecione Categoria.</option> */}
                                    <Option value="Alojamento">Alojamento</Option>
                                    <Option value="Aulas de dança">Aulas de dança</Option>
                                    <Option value="Babysitter">Babysitter</Option>
                                    <Option value="Banda">Banda</Option>
                                    <Option value="Decoração">Decoração</Option>
                                    <Option value="Design Gráfico">Design Gráfico</Option>
                                    <Option value="DJ">DJ</Option>
                                    <Option value="Espaço">Espaço</Option>
                                    <Option value="Florista">Florista</Option>
                                    <Option value="Fotografia e Video">Fotografia e Video</Option>
                                    <Option value="Grupo Musical">Grupo Musical</Option>
                                    {/* <Option value="Catering">Catering</Option>                                  
                                    <Option value="Espaço e Catering">Espaço e Catering</Option>                                   */}
                                    <Option value="Igreja">Igreja</Option> 
                                    <Option value="Lembranças">Lembranças</Option>
                                    <Option value="Makeup e Cabelo">Makeup e Cabelo</Option>
                                    <Option value="Outros">Outros</Option>
                                    <Option value="Padre">Padre</Option>
                                    <Option value="Performers">Performers</Option>
                                    <Option value="Pós Evento">Pós Evento</Option>
                                    <Option value="Pré Evento">Pré Evento</Option>
                                    <Option value="Processo Civil/Simbólico">Processo Civil/Simbólico</Option>                             
                                    <Option value="Roupa Noiva">Roupa Noiva</Option>                                  
                                    <Option value="Roupa Noivo">Roupa Noivo</Option>
                                    <Option value="Save the Date e Convite">Save the Date e Convite</Option>
                                    <Option value="Transporte Convidados">Transporte de Convidados</Option>
                                    <Option value="Website">Website</Option>
                                    {/* <Option value="Fotografia">Fotografia</Option>                                  
                                    <Option value="Video">Video</Option>                                   */}                                
                                </Select>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom:"30px"}}>
                            <Col sm="5">
                                <p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold"}}>Nome</p>
                                <Input onChange={(el) => { this.changeFormValue("name", el.target.value) }} /> 
                            </Col>
                            <Col sm="4">
                                <p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold"}}>Email</p>
                                <Input type="email" onChange={(el) => { this.changeFormValue("email", el.target.value) }}/>
                            </Col>
                            <Col sm="3">
                                <p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold"}}>Telemóvel</p>
                                <Input placeholder="Nº. Telemóvel" onChange={(el) => { this.changeFormValue("phone", el.target.value) }}/>
                            </Col>
                        </Row>
                        <Row style={{marginBottom:"8px", marginTop:"20px"}}>
                            <h6 style={{ textAlign:"left", marginRight:"15px" }}><b>Packs</b></h6>
                            <Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Adicionar Pack" placement="top">
                                <ControlPointIcon className="btn-inverted pointer" loading={this.state.loading} onClick={this.addPackRow}/>
                            </Tooltip>
                        </Row>
                        {providerPack}
                        <Row style={{marginBottom:"70px", marginTop:"40px", textAlign:"right"}}>
                            <Col>
                                <Button type="btn-user" loading={this.state.loading} htmltype="submit" style={{ width: 190, alignSelf: "right"}} >
                                    Adicionar
                                </Button>
                            </Col> 
                        </Row>
                    </Container>

                </div>
            </form>
        );
    } 
}

let appContainer = withRouter(AddProvider);

  function mapStateToProps(state) {
    return { tokens: state.tokens, reduxStorageLoaded: state.storageLoaded, validTokens: state.validTokens, _id: state.user._id }
  }

  const AppRedux = connect(
    mapStateToProps,
  )(appContainer);
  
export default AppRedux;
