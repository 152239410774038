import React from 'react';
import {Navbar} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Icon } from 'antd';
import HistoryIcon from '@material-ui/icons/History';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "shards-react";
import { connect } from "react-redux";
import { withRouter } from "react-router"
import { setTokens } from '../redux/actions';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';

class NavBar extends React.Component {
  
    state = {
      loading: false,
    };
    
    constructor(props) {
        super(props);
        this.state = {
          visible: false,
          dropdown1: false
        };
      }
    
      toggle = (nr) => {
        this.setState(prevState => {
          const newState = {};
          newState[`dropdown${nr}`] = !prevState[`dropdown${nr}`];
          return { ...prevState, ...newState };
        });
      }
      
      logoutUser = () => {
        this.props.setTokens(null)
        //notify("Sessão terminada com sucesso!", "success")
        this.props.history.push('/')
      }

      openLogs = () => {
        //notify("Sessão terminada com sucesso!", "success")
        this.props.history.push('/log_history')
      }

      // openCommissions = () => {
      //   //notify("Sessão terminada com sucesso!", "success")
      //   this.props.history.push('/commissions')
      // }
  
    render() {
      return (
            <Navbar bg="white" sticky="top" className="d-flex justify-content-between">
                <Navbar.Brand href="/general_dashboard">
                    <img src={require('../assets/img/mm-eventos-nude.svg')} alt="Mary Me" style={{width: "85%"}}/>
                </Navbar.Brand>
                <Dropdown 
                open={this.state.dropdown3}
                toggle={() => this.toggle(3)}
                size="sm"
                className="mr-2">
                <DropdownToggle className="btn-user" caret ><Icon type="user" style={{fontSize:"22px", marginRight:"5px"}}/></DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem onClick={this.logoutUser}><Icon type="logout" style={{ fontSize:"16px", marginRight:"10px"}} /> Logout</DropdownItem>
                    {(this.props.user.role && (this.props.user.role.indexOf("admin") !== -1) ) ? (
                      <div>
                        <DropdownItem style={{marginLeft:"-2px"}} onClick={this.openLogs}><HistoryIcon style={{ fontSize:"21px", marginRight:"10px"}}></HistoryIcon> Logs</DropdownItem>
                        {/* <DropdownItem style={{marginLeft:"-2px"}} onClick={this.openCommissions}><EuroSymbolIcon style={{ fontSize:"21px", marginRight:"10px"}}></EuroSymbolIcon> Comissões</DropdownItem> */}

                      </div>
                    ) : null}
                </DropdownMenu>
                </Dropdown>
            </Navbar>
      );
  
    }
    
  }


  let appContainer = withRouter(NavBar)

  function mapDispatchToProps(dispatch) {
    return {
      setTokens: payload => dispatch(setTokens(payload))
    };
  }
  
  function mapStateToProps(state) {
    return { tokens: state.tokens, user: state.user }
  }
  
  const TopbarRedux = connect(
    mapStateToProps,
    mapDispatchToProps
  )(appContainer);
  
  export default TopbarRedux;
  