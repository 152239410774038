import React from 'react'
import '../App.css'
import { Col, Container, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormTextarea } from "shards-react"
import { Switch, Input, Select, Collapse, Checkbox, Radio } from 'antd'
import { Card as CardAntd } from 'antd'
import { Card, CardContent } from '@material-ui/core'
import { connect } from "react-redux"
import { withRouter } from "react-router"
import { API_URL } from '../configs/configs'
import { after as _after } from 'lodash'
import { split as _split } from 'lodash'
import notify from '../helpers/notify'
import queryString from 'query-string'
import ArrowBack from '@material-ui/icons/ArrowBackIos'
import MeetingIcon from '@material-ui/icons/EventNote'
import MailIcon from '@material-ui/icons/Mail'
import ControlPointIcon from '@material-ui/icons/ControlPoint'
import Tooltip from '@material-ui/core/Tooltip'
import Fade from '@material-ui/core/Fade'
import moment from 'moment'
import 'moment/locale/pt'
import CheckIcon from '@material-ui/icons/Check'
import AddIcon from '@material-ui/icons/NoteAdd'
import PdfIcon from '@material-ui/icons/PictureAsPdf'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import WarningIcon from '@material-ui/icons/Warning'
import InfoIcon from '@material-ui/icons/Info'

moment.locale('pt')
const axios = require('axios')
const { Option } = Select
const { Panel } = Collapse

const genExtra = () => (
	<CheckIcon />
);

class CategoryProviders extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			iconLoading: false,
			open: false,
			users: [],
			quizRes: [],
			generalWanted: Boolean,
			espacoWanted: Boolean,
			cateringWanted: Boolean,
			espCatWanted: Boolean,
			grupoWanted: Boolean,
			providerWanted: Boolean,
			djWanted: Boolean,
			bandaWanted: Boolean,
			preWanted: Boolean,
			posWanted: Boolean,
			padreWanted: Boolean,
			babysitterWanted: Boolean,
			lembrancasWanted: Boolean,
			transporteWanted: Boolean,
			aulasWanted: Boolean,
			outrosWanted: Boolean,
			fotoWanted: Boolean,
			videoWanted: Boolean,
			fvWanted: Boolean,
			isClosed: Boolean,
			providers: [],
			packs: [],
			packDetails: [],
			espacoProviders: [],
			espacoPacks: [],
			packsEspaco: [],
			cateringProviders: [],
			cateringPacks: [],
			espCatProviders: [],
			espCatPacks: [],
			grupoProviders: [],
			packsGrupo: [],
			performersProviders: [],
			packsPerformers: [],
			djProviders: [],
			packsDJ: [],
			bandaProviders: [],
			packsBanda: [],
			preProviders: [],
			prePacks: [],
			posProviders: [],
			posPacks: [],
			padreProviders: [],
			padrePacks: [],
			babysitterProviders: [],
			babysitterPacks: [],
			lembrancasProviders: [],
			lembrancasPacks: [],
			transporteProviders: [],
			transportePacks: [],
			aulasProviders: [],
			aulasPacks: [],
			outrosProviders: [],
			outrosPacks: [],
			selectedProviderOutros: [],
			fvProviders: [],
			fvPacks: [],
			fotoProviders: [],
			fotoPacks: [],
			videoProviders: [],
			videoPacks: [],
			meetings: [],
			selectedProvider: undefined,
			emailSubj: "Disponibilidade para Evento",
			emailBody: "Envio-lhe este email para verificar a sua disponibilidade para prestar serviços num casamento. A data seria: ",
			possibleProviders: [],
			negotiatedPacks: [],
			possibleGeneral: [],
			possibleEspaco: [],
			possibleCatering: [],
			possibleEspCat: [],
			possibleGrupo: [],
			possiblePerformers: [],
			possibleDJ: [],
			possibleBanda: [],
			possiblePre: [],
			possiblePos: [],
			possiblePadre: [],
			possibleBaby: [],
			possibleLembrancas: [],
			possibleTransporte: [],
			possibleAulas: [],
			possibleOutros: [],
			possibleFoto: [],
			possibleVideo: [],
			possibleFV: [],
			arrayProviders: [{
				providerId: undefined,
				packId: undefined,
				providerInfo: null,
				providerPackOpts: [],
				providerPackInfo: null,
				hasContract: Boolean
			}],
			openDelete: false,
			checklistState: [],
			selectedPack: undefined
		}
	}

	onChange(e) {
		console.log(`checked = ${e.target.checked}`);
	}

	//COLLAPSE TOGGLE
	toggleCollapse = (collapse = false) => {
		this.setState({
			collapse: !this.state.collapse
		})
	}

	toggleDelete = (openDelete = false, role, selectedProv) => {
        this.setState({
		  openDelete: openDelete,
		  roleToDel: role,
		  selectedToDel: selectedProv
        })
    }

	//UPDATE FORNECEDORES EM NEGOCIAÇÃO
	updatePossible = (role) => {
		//GET URL PARAMS
		const url = queryString.parse(this.props.location.search)
		let roleUrl = url.cat
		let wedUrl = url.wed
		let arrayPossible = []
		let auxObj = {}

		switch (role) {
			case "Espaço":
				arrayPossible= this.state.possibleEspaco
				break;
			case "Catering":
				arrayPossible= this.state.possibleCatering
				break;
			case "Espaço e Catering":
				arrayPossible= this.state.possibleEspCat
				break;
			case "Grupo Musical":
				arrayPossible= this.state.possibleGrupo
				break;
			case "Performers":
				arrayPossible= this.state.possiblePerformers
				break;
			case "DJ":
				arrayPossible= this.state.possibleDJ
				break;
			case "Banda":
				arrayPossible= this.state.possibleBanda
				break;
			case "Pré Evento":
				arrayPossible= this.state.possiblePre
				break;
			case "Pós Evento":
				arrayPossible= this.state.possiblePos
			case "Padre":
				arrayPossible= this.state.possiblePadre
				break;
			case "Babysitter":
				arrayPossible= this.state.possibleBaby
				break;
			case "Lembranças":
				arrayPossible = this.state.possibleLembrancas
				break;
			case "Transporte Convidados":
				arrayPossible = this.state.possibleTransporte
				break;
			case "Aulas de dança":
				arrayPossible = this.state.possibleAulas
				break;
			case "Outros":
				arrayPossible = this.state.possibleOutros
				break;
			case "Fotografia":
				arrayPossible = this.state.possibleFoto
				break;
			case "Video":
				arrayPossible = this.state.possibleVideo
				break;
			case "Fotografia e Video":
				arrayPossible = this.state.possibleFV
				break;
			default:
				arrayPossible = this.state.possibleGeneral
				break;
		}
		//OBJ
		let obj = {
			category: roleUrl,
			role: role,
			possibleProviders: arrayPossible
		}
		//UPDATE FORNECEDORES EM NEGOCIAÇÃO - AXIOS
		axios.put(API_URL + '/wedding/updatePossibleProviders/' + wedUrl, obj, { headers: { Authorization: this.props.tokens } })
			.then((response) => {
				notify("Fornecedores em negociação alterados!", "success")
			})
			.catch((error) => {
				console.log(error);
				notify("Erro ao alterar fornecedores em negociação.")
			});
	}


	changePossible = (array, selectedValue) => {
		let negotiatedAux = array.slice(0)

		for (const item of selectedValue) {
			let negotiatedTemp = array.find((e) => {return e.providerId === item})
			if(negotiatedTemp === undefined){
				negotiatedAux.push({
					providerId: item,
					packId: undefined
				})
			}
		}

		for( let i=0; i < array.length; i++){
			let element = array[i]

			if(selectedValue.includes(element.providerId) === false){
				negotiatedAux.splice(i, 1)
			}
		}
		return negotiatedAux
	}

	//ALTERAÇÕES INPUTS NEGOCIAÇÕES
	//MULTISELECT FORNECEDORES
	handleChange = (value, role) => {
	
		switch (role) {
			case "Espaço":
				this.setState({
					possibleEspaco: this.changePossible(this.state.possibleEspaco, value)
				}, () => {
					this.updatePossible(role)
				})
				break;
			case "Catering":
				this.setState({
					possibleCatering: this.changePossible(this.state.possibleCatering, value)
				}, () => {
					this.updatePossible(role)
				})
				break;
			case "Espaço e Catering":
				this.setState({
					possibleEspCat: this.changePossible(this.state.possibleEspCat, value)
				}, () => {
					this.updatePossible(role)
				})
				break;
			case "Grupo Musical":
				this.setState({
					possibleGrupo: this.changePossible(this.state.possibleGrupo, value)
				}, () => {
					this.updatePossible(role)
				})
				break;
			case "Performers":
				this.setState({
					possiblePerformers: this.changePossible(this.state.possiblePerformers, value)
				}, () => {
					this.updatePossible(role)
				})
				break;
			case "DJ":
				this.setState({
					possibleDJ: this.changePossible(this.state.possibleDJ, value)
				}, () => {
					this.updatePossible(role)
				})
				break;
			case "Banda":
				this.setState({
					possibleBanda: this.changePossible(this.state.possibleBanda, value)
				}, () => {
					this.updatePossible(role)
				})
				break;
			case "Pré Evento":
				this.setState({
					possiblePre: this.changePossible(this.state.possiblePre, value)
				}, () => {
					this.updatePossible(role)
				})
				break;
			case "Pós Evento":
				this.setState({
					possiblePos: this.changePossible(this.state.possiblePos, value)
				}, () => {
					this.updatePossible(role)
				})
				break;
			case "Padre":
				this.setState({
					possiblePadre: this.changePossible(this.state.possiblePadre, value)
				}, () => {
					this.updatePossible(role)
				})
				break;
			case "Babysitter":
				this.setState({
					possibleBaby: this.changePossible(this.state.possibleBaby, value)
				}, () => {
					this.updatePossible(role)
				})
				break;
			case "Lembranças":
				this.setState({
					possibleLembrancas: this.changePossible(this.state.possibleLembrancas, value)
				}, () => {
					this.updatePossible(role)
				})
				break;
			case "Transporte Convidados":
				this.setState({
					possibleTransporte: this.changePossible(this.state.possibleTransporte, value)
				}, () => {
					this.updatePossible(role)
				})
				break;
			case "Aulas de dança":
				this.setState({
					possibleAulas: this.changePossible(this.state.possibleAulas, value)
				}, () => {
					this.updatePossible(role)
				})
				break;
			case "Outros":
				this.setState({
					possibleOutros: this.changePossible(this.state.possibleOutros, value)
				}, () => {
					this.updatePossible(role)
				})
				break;
			case "Fotografia":
				this.setState({
					possibleFoto: this.changePossible(this.state.possibleFoto, value)
				}, () => {
					this.updatePossible(role)
				})
				break;
			case "Video":
				this.setState({
					possibleVideo: this.changePossible(this.state.possibleVideo, value)
				}, () => {
					this.updatePossible(role)
				})
				break;
			case "Fotografia e Video":
				this.setState({
					possibleFV: this.changePossible(this.state.possibleFV, value)
				}, () => {
					this.updatePossible(role)
				})
				break;
			default:
				this.setState({
					possibleGeneral: this.changePossible(this.state.possibleGeneral, value)
				}, () => {
					this.updatePossible(role)
				})
				break;
		}


	}

	changePack = (array, provId, value) => {
		
		return array.map((item, index) => {
			if (item.providerId === provId) {
				return {
					providerId: provId,
					packId: value
				}
			} else {
				return {
					providerId: item.providerId,
					packId: item.packId
				}
			}
		})
	}

	//SELECT PACK DE FORNECEDOR
	handlePackSelect = (value, provId, role) => {

		//GET URL PARAMS
		const url = queryString.parse(this.props.location.search)
		let roleUrl = url.cat
		let wedUrl = url.wed
		//OBJ
		let packObj = {
			category: roleUrl,
			role: role,
			providerId: provId,
			packId: value
		}

		switch (role) {
			case "Espaço":
				this.setState({
					possibleEspaco: this.changePack(this.state.possibleEspaco, provId, value)
				})
				break;
			case "Catering":
				this.setState({
					possibleCatering: this.changePack(this.state.possibleCatering, provId, value)
				})
				break;
			case "Espaço e Catering":
				this.setState({
					possibleEspCat: this.changePack(this.state.possibleEspCat, provId, value)
				})
				break;
			case "Grupo Musical":
				this.setState({
					possibleGrupo: this.changePack(this.state.possibleGrupo, provId, value)
				})
				break;
			case "Performers":
				this.setState({
					possiblePerformers: this.changePack(this.state.possiblePerformers, provId, value)
				})
				break;
			case "DJ":
				this.setState({
					possibleDJ: this.changePack(this.state.possibleDJ, provId, value)
				})
				break;
			case "Banda":
				this.setState({
					possibleBanda: this.changePack(this.state.possibleBanda, provId, value)
				})
				break;
			case "Pré Evento":
				this.setState({
					possiblePre: this.changePack(this.state.possiblePre, provId, value)
				})
				break;
			case "Pós Evento":
				this.setState({
					possiblePos: this.changePack(this.state.possiblePos, provId, value)
				})
				break;
			case "Padre":
				this.setState({
					possiblePadre: this.changePack(this.state.possiblePadre, provId, value)
				})
				break;
			case "Babysitter":
				this.setState({
					possibleBaby: this.changePack(this.state.possibleBaby, provId, value)
				})
				break;
			case "Lembranças":
				this.setState({
					possibleLembrancas: this.changePack(this.state.possibleLembrancas, provId, value)
				})
				break;
			case "Transporte Convidados":
				this.setState({
					possibleTransporte: this.changePack(this.state.possibleTransporte, provId, value)

				})
				break;
			case "Aulas de dança":
				this.setState({
					possibleAulas: this.changePack(this.state.possibleAulas, provId, value)

				})
				break;
			case "Outros":
				this.setState({
					possibleOutros: this.changePack(this.state.possibleOutros, provId, value)
				})
				break;
			case "Fotografia":
				this.setState({
					possibleFoto: this.changePack(this.state.possibleFoto, provId, value)
				})
				break;
			case "Video":
				this.setState({
					possibleVideo: this.changePack(this.state.possibleVideo, provId, value)
				})
				break;
			case "Fotografia e Video":
				this.setState({
					possibleFV: this.changePack(this.state.possibleFV, provId, value)
				})
				break;
			default:
				this.setState({
					possibleGeneral: this.changePack(this.state.possibleGeneral, provId, value)
				})
				break;
		}

		//UPDATE POSSIBLE
		axios.put(API_URL + '/wedding/updatePossiblePack/' + wedUrl, packObj, { headers: { Authorization: this.props.tokens } })
		.then((response) => {
			notify("Pack de fornecedor em negociação alterado!", "success")
		})
		.catch((error) => {
			console.log(error);
			notify("Erro ao alterar pack de fornecedor em negociação.")
		});
	}

	//ON CHANGE - FECHAR SERVIÇO
	onChangeClosed = (checked) => {
		this.setState({
			isClosed: checked
		}, () => {

			//GET URL PARAMS
			const url = queryString.parse(this.props.location.search)
			let roleUrl = url.cat
			let wedUrl = url.wed

			let obj = {
				isClosed: this.state.isClosed,
				category: roleUrl
			}

			//UPDATE IS CLOSED
			axios.put(API_URL + '/wedding/updateCategoryIsClosed/' + wedUrl, obj, { headers: { Authorization: this.props.tokens } })
				.then((response) => {
					notify("Estado do serviço alterado com sucesso!", "success")
				})
				.catch((error) => {
					console.log(error);
					notify("Erro ao fechar o serviço.")
				});
		})
	}

	//ON CHANGE VALUE   
	changeFormValue = (key, value) => {
		this.setState({
			[key]: value
		})
		
	}

	//ON CHANGE CHECKLIST   
	changeChecklist = (key, checked) => {
		console.log("key", key)
		console.log("value", checked)
		// this.setState({
		// 	[key]: value
		// })

		//GET URL PARAMS
		const url = queryString.parse(this.props.location.search)
		let wedUrl = url.wed

		let obj = {
			toDo: key,
			isChecked: checked,
			category: this.state.roleUrl
		}

		//UPDATE IS CHECKED
		axios.put(API_URL + '/wedding/updateIsChecked/' + wedUrl, obj, { headers: { Authorization: this.props.tokens } })
			.then((response) => {
				notify("Checklist alterada com sucesso!", "success")
				this.componentDidMount()
			})
			.catch((error) => {
				console.log(error);
				notify("Erro ao alterar a checklist.")
			});
		
	}
	

	onChangeIsWanted = (key, cat, role, checked) => {
		this.setState({
			[key]: checked
		}, () => {
			//GET URL PARAMS
			const url = queryString.parse(this.props.location.search)
			let wedUrl = url.wed

			let obj = {
				category: cat,
				role: role,
				isWanted: checked
			}

			//UPDATE IS WANTED
			axios.put(API_URL + '/wedding/updateRoleWanted/' + wedUrl, obj, { headers: { Authorization: this.props.tokens } })
				.then((response) => {
					notify("Estado da sub-categoria alterado com sucesso!", "success")
				})
				.catch((error) => {
					console.log(error);
					notify("Erro ao alterar o estado da sub-categoria.")
				});
		})
	}

	//ALTERAÇÕES PROVIDERS SEM SUB CATEGORIAS
	changeSelectProvider = (value) => {
		this.setState({
			selectedProvider: value
		}, () => { this.getInfo() })
	}

	changeSelectPack = (value) => {
		this.setState({
			selectedPack: value
		})
	}

	//ALTERAÇÕES ESPAÇO
	changeSelectProviderEspaco = (value) => {
		this.setState({
			selectedProviderEspaco: value
		}, () => { this.getInfo() })
	}

	changeSelectPackEspaco = (value) => {
		this.setState({
			selectedPackEspaco: value
		})
	}
	//ALTERAÇÕES CATERING
	changeSelectProviderCatering = (value) => {
		this.setState({
			selectedProviderCatering: value
		}, () => { this.getInfo() })
	}

	changeSelectPackCatering = (value) => {
		this.setState({
			selectedPackCatering: value
		})
	}
	//ALTERAÇÕES ESPAÇO E CATERING
	changeSelectProviderEspCat = (value) => {
		this.setState({
			selectedProviderEspCat: value
		}, () => { this.getInfo() })
	}

	changeSelectPackEspCat = (value) => {
		this.setState({
			selectedPackEspCat: value
		})
	}

	//ALTERAÇÕES GRUPO MUSICAL
	changeSelectProviderGrupo = (value) => {
		this.setState({
			selectedProviderGrupo: value
		}, () => { this.getInfo() })
	}

	changeSelectPackGrupo = (value) => {
		this.setState({
			selectedPackGrupo: value
		})
	}

	//ALTERAÇÕES PERFORMERS
	changeSelectProviderPerformers = (value) => {
		this.setState({
			selectedProviderPerformers: value
		}, () => { this.getInfo() })
	}

	changeSelectPackPerformers = (value) => {
		this.setState({
			selectedPackPerformers: value
		})
	}

	//ALTERAÇÕES DJ
	changeSelectProviderDJ = (value) => {
		this.setState({
			selectedProviderDJ: value
		}, () => { this.getInfo() })
	}

	changeSelectPackDJ = (value) => {
		this.setState({
			selectedPackDJ: value
		})
	}

	//ALTERAÇÕES BANDA
	changeSelectProviderBanda = (value) => {
		this.setState({
			selectedProviderBanda: value
		}, () => { this.getInfo() })
	}

	changeSelectPackBanda = (value) => {
		this.setState({
			selectedPackBanda: value
		})
	}

	//ALTERAÇÕES PRÉ EVENTO
	changeSelectProviderPre = (value) => {
		this.setState({
			selectedProviderPre: value
		}, () => { this.getInfo() })
	}

	changeSelectPackPre = (value) => {
		this.setState({
			selectedPackPre: value
		})
	}

	//ALTERAÇÕES PÓS EVENTO
	changeSelectProviderPos = (value) => {
		this.setState({
			selectedProviderPos: value
		}, () => { this.getInfo() })
	}

	changeSelectPackPos = (value) => {
		this.setState({
			selectedPackPos: value
		})
	}

	//ALTERAÇÕES PADRE
	changeSelectProviderPadre = (value) => {
		this.setState({
			selectedProviderPadre: value
		}, () => { this.getInfo() })
	}

	changeSelectPackPadre = (value) => {
		this.setState({
			selectedPackPadre: value
		})
	}

	//ALTERAÇÕES BABYSITTER
	changeSelectProviderBabysitter = (value) => {
		this.setState({
			selectedProviderBabysitter: value
		}, () => { this.getInfo() })
	}

	changeSelectPackBabysitter = (value) => {
		this.setState({
			selectedPackBabysitter: value
		})
	}

	//ALTERAÇÕES LEMBRANCAS
	changeSelectProviderLembrancas = (value) => {
		this.setState({
			selectedProviderLembrancas: value
		}, () => { this.getInfo() })
	}

	changeSelectPackLembrancas = (value) => {
		this.setState({
			selectedPackLembrancas: value
		})
	}

	//ALTERAÇÕES TRANSPORTE
	changeSelectProviderTransporte = (value) => {
		this.setState({
			selectedProviderTransporte: value
		}, () => { this.getInfo() })
	}

	changeSelectPackTransporte = (value) => {
		this.setState({
			selectedPackTransporte: value
		})
	}

	//ALTERAÇÕES AULAS
	changeSelectProviderAulas = (value) => {
		this.setState({
			selectedProviderAulas: value
		}, () => { this.getInfo() })
	}

	changeSelectPackAulas = (value) => {
		this.setState({
			selectedPackAulas: value
		})
	}

	//ALTERAÇÕES FOTOGRAFIA E VIDEO
	changeSelectProviderFV = (value) => {
		this.setState({
			selectedProviderFV: value
		}, () => { this.getInfo() })
	}

	changeSelectPackFV = (value) => {
		this.setState({
			selectedPackFV: value
		})
	}

	//ALTERAÇÕES FOTOGRAFIA
	changeSelectProviderFoto = (value) => {
		this.setState({
			selectedProviderFoto: value
		}, () => { this.getInfo() })
	}

	changeSelectPackFoto = (value) => {
		this.setState({
			selectedPackFoto: value
		})
	}

	//ALTERAÇÕES VIDEO
	changeSelectProviderVideo = (value) => {
		this.setState({
			selectedProviderVideo: value
		}, () => { this.getInfo() })
	}

	changeSelectPackVideo = (value) => {
		this.setState({
			selectedPackVideo: value
		})
	}

	//ALTERAÇÔES OUTROS
	onChangeProviderSelect = (value, index) => {
		let indexSelect = index
		let providerId = value
		let providersSelects = this.state.arrayProviders.slice(0)

		if (value !== null && value !== undefined) {

			providersSelects[indexSelect].providerId = providerId;

			let providerInfo = this.state.outrosProviders.find((e) => e._id === providerId)
			providersSelects[indexSelect].providerInfo = providerInfo

			let providersPacks = providerInfo.packs
			providersSelects[indexSelect].providerPackOpts = providersPacks
		} else {
			providersSelects[indexSelect] = {
				providerId: undefined,
				packId: undefined,
				providerInfo: null,
				providerPackOpts: [],
				providerPackInfo: null
			}
		}

		this.setState({
			arrayProviders: providersSelects
		})
		console.log("State after changes: ", this.state)
	}

	onChangePackSelect = (value, index) => {
		let indexSelect = index
		let packId = value

		let providersSelects = this.state.arrayProviders.slice(0)

		let packInfo = providersSelects[indexSelect].providerPackOpts.find((e) => e._id === packId)
		providersSelects[indexSelect].providerPackInfo = packInfo

		providersSelects[indexSelect].packId = packId;

		this.setState({
			arrayProviders: providersSelects
		})
		console.log("State after changes pack: ", this.state)
	}

	onChangeFormV = (value, index) => {
		let indexSelect = index

		let providersSelects = this.state.arrayProviders.slice(0)

		providersSelects[indexSelect].providerPackInfo.value = value

		this.setState({
			arrayProviders: providersSelects
		})
		console.log("State after changes value: ", this.state)
	}

	addProviderRow = () => {
		let provArr = this.state.arrayProviders;
		provArr.push({
			providerId: undefined,
			packId: undefined,
			providerInfo: null,
			providerPackOpts: [],
			providerPackInfo: null,
			hasContract: Boolean
		})
		this.setState({
			arrayProviders: provArr
		})
	}

	//ALTERAÇÔES ESPAÇO - CONTRATAÇÕES
	onChangeProviderSelectEspaco = (value, index) => {
		let indexSelect = index
		let providerId = value
		let providersSelects = this.state.arrayProviders.slice(0)

		if (value !== null && value !== undefined && value !== "") {

			providersSelects[indexSelect].providerId = providerId;

			let providerInfo = this.state.espacoProviders.find((e) => e._id === providerId)
			providersSelects[indexSelect].providerInfo = providerInfo

			let providersPacks = providerInfo.packs
			providersSelects[indexSelect].providerPackOpts = providersPacks
		} else {
			providersSelects[indexSelect] = {
				providerId: undefined,
				packId: undefined,
				providerInfo: null,
				providerPackOpts: [],
				providerPackInfo: null
			}
		}

		this.setState({
			arrayProviders: providersSelects
		})
		console.log("State after changes: ", this.state)
	}

	onChangePackSelectEspaco = (value, index) => {
		let indexSelect = index
		let packId = value

		let providersSelects = this.state.arrayProviders.slice(0)

		let packInfo = providersSelects[indexSelect].providerPackOpts.find((e) => e._id === packId)
		providersSelects[indexSelect].providerPackInfo = packInfo

		providersSelects[indexSelect].packId = packId;

		this.setState({
			arrayProviders: providersSelects
		})
		console.log("State after changes pack: ", this.state)
	}

	onChangeFormV = (value, index, key) => {
		let indexSelect = index

		let providersSelects = this.state.arrayProviders.slice(0)

		providersSelects[indexSelect].providerPackInfo[key] = value

		this.setState({
			arrayProviders: providersSelects
		})
		console.log("State after changes value: ", this.state)
	}


	handleNotesText(key, value) {
		this.setState({ 
			[key]: value 
		});

		console.log("NOTES: ", this.state)
	}

	saveNotes(role){
		//GET URL PARAMS
		const url = queryString.parse(this.props.location.search)
		let roleUrl = url.cat
		let wedUrl = url.wed
		console.log("ROLE BTN: ", role)
		let obj

		switch (role) {
			case "Espaço":
				obj = {
					role: role,
					category: roleUrl,
					notes: this.state.notesEspacoRole
				}
				break;
			case "Catering":
				obj = {
					role: role,
					category: roleUrl,
					notes: this.state.notesCateringRole
				}
				break;
			case "Espaço e Catering":
				obj = {
					role: role,
					category: roleUrl,
					notes: this.state.notesEspCatRole
				}
				break;
			case "Grupo Musical":
				obj = {
					role: role,
					category: roleUrl,
					notes: this.state.notesGrupoRole
				}
				break;
			case "Performers":
				obj = {
					role: role,
					category: roleUrl,
					notes: this.state.notesPerformersRole
				}
				break;
			case "DJ":
				obj = {
					role: role,
					category: roleUrl,
					notes: this.state.notesDJRole
				}
				break;
			case "Banda":
				obj = {
					role: role,
					category: roleUrl,
					notes: this.state.notesBandaRole
				}
				break;
			case "Pré Evento":
				obj = {
					role: role,
					category: roleUrl,
					notes: this.state.notesPreRole
				}
				break;
			case "Pós Evento":
				obj = {
					role: role,
					category: roleUrl,
					notes: this.state.notesPosRole
				}
				break;
			case "Padre":
				obj = {
					role: role,
					category: roleUrl,
					notes: this.state.notesPadreRole
				}
				break;
			case "Babysitter":
				obj = {
					role: role,
					category: roleUrl,
					notes: this.state.notesBabysitterRole
				}
				break;
			case "Lembranças":
				obj = {
					role: role,
					category: roleUrl,
					notes: this.state.notesLembrancasRole
				}
				break;
			case "Transporte Convidados":
				obj = {
					role: role,
					category: roleUrl,
					notes: this.state.notesTransporteRole
				}
				break;
			case "Aulas de dança":
				obj = {
					role: role,
					category: roleUrl,
					notes: this.state.notesAulasRole
				}
				break;
			case "Fotografia":
				obj = {
					role: role,
					category: roleUrl,
					notes: this.state.notesFotografiaRole
				}
				break;
			case "Video":
				obj = {
					role: role,
					category: roleUrl,
					notes: this.state.notesVideoRole
				}
				break;
			case "Fotografia e Video":
				obj = {
					role: role,
					category: roleUrl,
					notes: this.state.notesFVRole
				}
				break;
			default:
				obj = {
					role: role,
					category: roleUrl,
					notes: this.state.notesGeneralRole
				}
				break;
		}
		
		//UPDATE FORNECEDORES EM NEGOCIAÇÃO - AXIOS
		axios.put(API_URL + '/wedding/updateRoleNotes/' + wedUrl, obj, { headers: { Authorization: this.props.tokens } })
		.then((response) => {
			notify("Alterações gravadas com sucesso!", "success")
		})
		.catch((error) => {
			console.log(error);
			notify("Erro ao gravar alterações.")
		});

	}

	//UPLOAD CONTRATO
	toggleUpload = (openUpload = false, role, provId) => {
        this.setState({
		  openUpload: openUpload,
		  roleContract: role,
		  provContract: provId
		})
		
		console.log("TOGGLE VALUES: ", role, " e ", provId)
	}	

	onChangeHandler=event=>{
        //console.log("EVENT TARGET FILES: ", event.target.files[0]);
        this.setState({
          selectedFile: event.target.files[0],
          loaded: 0,
        })
	}

	//GET TOTAL VALUES
	getTotalValues = () => {

		//INFOS URL
		const url = queryString.parse(this.props.location.search)
		let roleUrl = {
			cat: url.cat
		}
		let wedUrl = url.wed

		axios.get(API_URL + '/wedding/subCatValues/' + wedUrl, { headers: { Authorization: this.props.tokens }, params: roleUrl })
			.then((response) => {
				//notify("Alterações gravadas com sucesso na categoria " + roleUrl + '!', "success")
				//this.props.history.push('/wedding_details/' + wedUrl)
				console.log("GET TOTAL SUBCATS DONE! ", response.data)
				this.setState({
					totalValues: response.data.valuesArray
				})
			})
			.catch((error) => {
				console.log(error);
			});
	}
	
	upload = (role, provId) => {
		//GET URL PARAMS
		const url = queryString.parse(this.props.location.search)
		let roleUrl = url.cat
		let wedUrl = url.wed

		let params

		switch (role) {
			case "Espaço":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "Catering":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "Espaço e Catering":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "Grupo Musical":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "Performers":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "DJ":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "Banda":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "Pré Evento":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "Pós Evento":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "Padre":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "Babysitter":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "Lembranças":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "Transporte Convidados":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "Aulas de dança":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "Fotografia":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "Video":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "Fotografia e Video":
				params = {
					role: role,
					providerId: provId
				}
				break;
			default:
				params = {
					role: role,
					providerId: provId
				}
				break;
		}

        const form_data = new FormData();
        form_data.append('file', this.state.selectedFile);
        axios.post( API_URL + '/files/uploadContractProvider/' + wedUrl, form_data, { headers: {"authorization": this.props.tokens}, params: params })
        .then((response) => {
            //console.log(response.data);
            notify("Upload de contrato de fornecedor efetuado com sucesso!", "success")           
			window.location.reload()
			this.toggleUpload(false, null, null)
        })
        .catch((error) => {
			notify("Erro ao fazer upload do contrato de fornecedor.")
            console.log(error);
            this.setState({ loading: false });
        });
	};
	
	download = (role, provId) => {
		let params

		switch (role) {
			case "Espaço":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "Catering":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "Espaço e Catering":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "Grupo Musical":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "Performers":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "DJ":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "Banda":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "Pré Evento":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "Pós Evento":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "Padre":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "Babysitter":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "Lembranças":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "Transporte Convidados":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "Aulas de dança":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "Fotografia":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "Video":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "Fotografia e Video":
				params = {
					role: role,
					providerId: provId
				}
				break;
			default:
				params = {
					role: role,
					providerId: provId
				}
				break;
		}

		console.log("PARAMS: ", params)
		console.log("TokEN: ", this.props.tokens)

        axios.get( API_URL + '/files/downloadContractProvider', { headers: {"authorization": this.props.tokens}, responseType: 'blob', params: params })
        .then((res)=> {
            //const pdfBlob = new Blob([res.data], {type: 'application/pdf'});
            notify("Visualização do contrato de fornecedor efetuada com sucesso!", "success")
            //Build a URL from the file
            const fileURL = URL.createObjectURL(res.data);
            console.log("FILE URL: ", fileURL)
            //saveAs(pdfBlob, wedding_id + '.pdf');
            window.open(fileURL);
        })
        .catch((error) => {
            notify("Erro na visualização de contrato de fornecedor.")
            console.log(error);
        });
	}
	
	delete = (role, provId) => {
		//GET URL PARAMS
		const url = queryString.parse(this.props.location.search)
		let wedUrl = url.wed
		let params

		switch (role) {
			case "Espaço":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "Catering":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "Espaço e Catering":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "Grupo Musical":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "Performers":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "DJ":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "Banda":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "Pré Evento":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "Pós Evento":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "Padre":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "Babysitter":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "Lembranças":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "Transporte Convidados":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "Aulas de dança":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "Fotografia":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "Video":
				params = {
					role: role,
					providerId: provId
				}
				break;
			case "Fotografia e Video":
				params = {
					role: role,
					providerId: provId
				}
				break;
			default:
				params = {
					role: role,
					providerId: provId
				}
				break;
		}

		console.log("PARAMS: ", params)
		console.log("TokEN: ", this.props.tokens)

        axios.delete( API_URL + '/files/deleteContractProvider/' + wedUrl , { headers: {"authorization": this.props.tokens}, params: params })
        .then((res)=> {
            notify("Contrato de fornecedor eliminado com sucesso!", "success")
            window.location.reload()
        })
        .catch((error) => {
            notify("Erro ao eliminar contrato de fornecedor.")
            console.log(error);
        });
    }

	componentDidMount = async () => {

		const url = queryString.parse(this.props.location.search)

		//INFO URL
		let roleUrl = url.cat;
		let wedUrl = url.wed;

		if (url.cat === "Pré%20ou%20Pós%20Evento") {
			roleUrl = "Pré ou Pós Evento"
		}

		if (url.cat === "Processo%20Religioso") {
			roleUrl = "Processo Religioso"
		}

		let params = {
			wedId: wedUrl,
			cat: roleUrl
		}

		//GET FORNECEDORES
		let searchParams = {
			role: roleUrl
		}

		
		let step = _after(5, () => {
			this.getInfo()
		})
		
		let checklistCat

		switch (roleUrl) {
			case "Espaço":
				checklistCat = roleUrl
				break;

			case "Fotografia e Video":
				checklistCat = roleUrl
				break;
		
			default:
				checklistCat = "Geral"
				break;
		}

		//GET CHECKLIST
		axios.get(API_URL + '/checklist/getChecklist/' + checklistCat, { headers: { 'authorization': this.props.tokens }})
		.then((response) => {
			this.setState({
				checklistText: response.data.checklist[0].checklist
			}, step)
		})
		.catch((error) => {
			console.log(error);
			this.setState({ loading: false });
			step()
		});


		let getInfoWedding = () => {
			//GET INFO CASAMENTO
			axios.get(API_URL + '/wedding/byCategoryName/', { headers: { 'authorization': this.props.tokens }, params: params })
				.then((response) => {
					let category = response.data.category
					let providers = response.data.category.providers
					let possibleProvAxios = response.data.category.possibleProviders
					let roleNotes = response.data.category.roleNotes
					let checklist = response.data.category.checklist


					let auxGeneral
					let auxGeneral2
					let auxGeneral3
					let auxGeneral4
					let auxGeneralStart
					let auxGeneralEnd
					let auxGeneralNotes
					let aux1
					let aux2
					let aux3
					let aux4
					let aux5
					let aux6
					let aux7
					let aux8
					let aux9
					let aux10
					let aux11
					let aux12
					let auxA
					let auxB
					let auxC
					let auxD
					let auxE
					let auxF
					let auxG
					let auxH
					let auxI
					let auxJ
					let auxK
					let auxL
					let wanted1
					let wanted2
					let wanted3
					let wanted4
					let wanted5
					let wanted6
					let wanted7
					let wanted8
					let wanted9
					let wanted10
					let wanted11
					let wanted12
					let value1
					let value2
					let value3
					let value4
					let value5
					let value6
					let value7
					let value8
					let value9
					let value10
					let value11
					let value12
					let auxGrupoStart
					let auxGrupoEnd
					let auxGrupoNotes
					let auxPerformersStart
					let auxPerformersEnd
					let auxPerformersNotes
					let auxDJStart
					let auxDJEnd
					let auxDJNotes
					let auxBandaStart
					let auxBandaEnd
					let auxBandaNotes
					let auxPreStart
					let auxPreEnd
					let auxPreNotes
					let auxPosStart
					let auxPosEnd
					let auxPosNotes
					let auxPadreStart
					let auxPadreEnd
					let auxPadreNotes
					let auxBabyStart
					let auxBabyEnd
					let auxBabyNotes
					let auxLembStart
					let auxLembEnd
					let auxLembNotes
					let auxTranspStart
					let auxTranspEnd
					let auxTranspNotes
					let auxAulasStart
					let auxAulasEnd
					let auxAulasNotes
					let auxOutrosStart
					let auxOutrosEnd
					let auxOutrosNotes
					let wedDate = moment(response.data.category.wedDate).format("dddd, DD/MM/YYYY")
					let infoGeral
					let infoGrupo
					let infoPerformers
					let infoDJ
					let infoBanda
					let infoPre
					let infoPos
					let infoPadre
					let infoBaby
					let infoLembrancas
					let infoTransp
					let infoAulas
					let infoOutros
					let auxiliarProviders = []
					let espaco1
					let espaco2
					let espaco3
					let espaco4
					let espacoStart
					let espacoEnd
					let espacoNotes
					let infoEspaco
					let catering1
					let catering2
					let catering3
					let catering4
					let cateringStart
					let cateringEnd
					let cateringNotes
					let infoCatering
					let espCat1
					let espCat2
					let espCat3
					let espCat4
					let espCatStart
					let espCatEnd
					let espCatNotes
					let infoEspCat
					let packsEspAux = []
					let fv1
					let fv2
					let fv3
					let fv4
					let fvStart
					let fvEnd
					let fvNotes
					let infoFV
					let foto1
					let foto2
					let foto3
					let foto4
					let fotoStart
					let fotoEnd
					let fotoNotes
					let infoFoto
					let video1
					let video2
					let video3
					let video4
					let videoStart
					let videoEnd
					let videoNotes
					let infoVideo

					let contractGeneral
					let contractCatering
					let contractEspCat
					let contractGrupo
					let contractPerformers
					let contractDJ
					let contractBanda
					let contractPre
					let contractPos
					let contractPadre
					let contractBabysitter
					let contractLembrancas
					let contractTransporte
					let contractAulas
					let contractFoto
					let contractVideo
					let contractFV

					let auxValueBy
					let auxComissionValue
					let auxComissionBy
					let auxValueByCatering
					let auxComissionValueCatering
					let auxComissionByCatering
					let auxValueByEspCat
					let auxComissionValueEspCat
					let auxComissionByEspCat
					let auxValueByGrupo
					let auxComissionValueGrupo
					let auxComissionByGrupo
					let auxValueByPerformers
					let auxComissionValuePerformers
					let auxComissionByPerformers
					let auxValueByDJ
					let auxComissionValueDJ
					let auxComissionByDJ
					let auxValueByBanda
					let auxComissionValueBanda
					let auxComissionByBanda
					let auxValueByPre
					let auxComissionValuePre
					let auxComissionByPre
					let auxValueByPos
					let auxComissionValuePos
					let auxComissionByPos
					let auxValueByPadre
					let auxComissionValuePadre
					let auxComissionByPadre
					let auxValueByBaby
					let auxComissionValueBaby
					let auxComissionByBaby
					let auxValueByLembrancas
					let auxComissionValueLembrancas
					let auxComissionByLembrancas
					let auxValueByTransporte
					let auxComissionValueTransporte
					let auxComissionByTransporte
					let auxValueByAulas
					let auxComissionValueAulas
					let auxComissionByAulas
					let auxValueByFV
					let auxComissionValueFV
					let auxComissionByFV
					let auxValueByFoto
					let auxComissionValueFoto
					let auxComissionByFoto
					let auxValueByVideo
					let auxComissionValueVideo
					let auxComissionByVideo
					let auxPaidValue
					let auxPaidValueEspaco
					let auxPaidValueOutros
					let auxPaidValueCatering
					let auxPaidValueEspCat
					let auxPaidValueGrupo
					let auxPaidValuePerformers
					let auxPaidValueDJ
					let auxPaidValueBanda
					let auxPaidValuePre
					let auxPaidValuePos
					let auxPaidValuePadre
					let auxPaidValueBaby
					let auxPaidValueLembrancas
					let auxPaidValueTransporte
					let auxPaidValueAulas
					let auxPaidValueFoto
					let auxPaidValueVideo
					let auxPaidValueFV


					console.log("PROVIDERS AXIOS GET: ", providers)

					providers.map((item, index) => {
						if (item.role === "Outros") {
							let packs = []
							let packInfo = null
							let providerInfo = null
							let auxContracted = Boolean
							for (const element of this.state.outrosProviders) {
								if (element._id === item.providerId) {
									packs = element.packs
									packInfo = packs.find((e) => { return e._id === item.packId })
									if (packInfo !== null && packInfo !== undefined) {
										packInfo.value = item.value
										packInfo.valueByOutros = item.valueType
										packInfo.comissionValueOutros = item.comissionValue
										packInfo.comissionByOutros = item.comissionType
										packInfo.paidValueOutros = item.paidValue
									}
									providerInfo = element
									auxContracted = item.hasContract
									break
								}
							}
							wanted12 = item.isWanted
							auxiliarProviders.push({
								providerId: item.providerId,
								packId: item.packId,
								providerInfo: providerInfo,
								providerPackOpts: packs,
								providerPackInfo: packInfo,
								hasContract: auxContracted,
								auxOutrosStart: item.startTime,
								auxOutrosEnd: item.endTime,
								auxOutrosNotes: item.notes
							})
						} else if (item.role === "Espaço") {
							let packs = []
							let packInfo = null
							let providerInfo = null
							let auxContracted = Boolean
							for (const element of this.state.espacoProviders) {
								if (element._id === item.providerId) {
									packs = element.packs
									packInfo = packs.find((e) => { return e._id === item.packId })
									if (packInfo !== null && packInfo !== undefined) {
										packInfo.value = item.value
										packInfo.valueByEspaco = item.valueType
										packInfo.comissionValueEspaco = item.comissionValue
										packInfo.comissionByEspaco = item.comissionType
										packInfo.paidValueEspaco = item.paidValue
									}
									providerInfo = element
									packsEspAux = element.packs
									auxContracted = item.hasContract
									break
								}
							}
							espaco3 = item.isWanted
							auxiliarProviders.push({
								providerId: item.providerId,
								packId: item.packId,
								providerInfo: providerInfo,
								providerPackOpts: packs,
								providerPackInfo: packInfo,
								hasContract: auxContracted,
								espacoStart: item.startTime,
								espacoEnd: item.endTime,
								espacoNotes: item.notes
							})
						} else if (item.role === "Catering") {
							catering1 = item.providerId
							catering2 = item.packId
							catering3 = item.isWanted
							catering4 = item.value
							cateringStart = item.startTime
							cateringEnd = item.endTime
							cateringNotes = item.notes
							infoCatering = item.info
							contractCatering = item.hasContract
							auxValueByCatering = item.valueType
							auxComissionValueCatering = item.comissionValue
							auxComissionByCatering = item.comissionType
							auxPaidValueCatering = item.paidValue
						} else if (item.role === "Espaço e Catering") {
							espCat1 = item.providerId
							espCat2 = item.packId
							espCat3 = item.isWanted
							espCat4 = item.value
							espCatStart = item.startTime
							espCatEnd = item.endTime
							espCatNotes = item.notes
							infoEspCat = item.info
							contractEspCat = item.hasContract
							auxValueByEspCat = item.valueType
							auxComissionValueEspCat = item.comissionValue
							auxComissionByEspCat = item.comissionType
							auxPaidValueEspCat = item.paidValue
						} else if (item.role === "Grupo Musical") {
							aux1 = item.providerId
							auxA = item.packId
							wanted1 = item.isWanted
							value1 = item.value
							auxGrupoStart = item.startTime
							auxGrupoEnd = item.endTime
							infoGrupo = item.info
							contractGrupo = item.hasContract
							auxValueByGrupo = item.valueType
							auxComissionValueGrupo = item.comissionValue
							auxComissionByGrupo = item.comissionType
							auxPaidValueGrupo = item.paidValue
						} else if (item.role === "Performers") {
							aux2 = item.providerId
							auxB = item.packId
							wanted2 = item.isWanted
							value2 = item.value
							auxPerformersStart = item.startTime
							auxPerformersEnd = item.endTime
							auxPerformersNotes = item.notes
							infoPerformers = item.info
							contractPerformers = item.hasContract
							auxValueByPerformers = item.valueType
							auxComissionValuePerformers = item.comissionValue
							auxComissionByPerformers = item.comissionType
							auxPaidValuePerformers = item.paidValue
						} else if (item.role === "DJ") {
							aux3 = item.providerId
							auxC = item.packId
							wanted3 = item.isWanted
							value3 = item.value
							auxDJStart = item.startTime
							auxDJEnd = item.endTime
							auxDJNotes = item.notes
							infoDJ = item.info
							contractDJ = item.hasContract
							auxValueByDJ = item.valueType
							auxComissionValueDJ = item.comissionValue
							auxComissionByDJ = item.comissionType
							auxPaidValueDJ = item.paidValue
						} else if (item.role === "Banda") {
							aux4 = item.providerId
							auxD = item.packId
							wanted4 = item.isWanted
							value4 = item.value
							auxBandaStart = item.startTime
							auxBandaEnd = item.endTime
							auxBandaNotes = item.notes
							infoBanda = item.info
							contractBanda = item.hasContract
							auxValueByBanda = item.valueType
							auxComissionValueBanda = item.comissionValue
							auxComissionByBanda = item.comissionType
							auxPaidValueBanda = item.paidValue
						} else if (item.role === "Pré Evento") {
							aux5 = item.providerId
							auxE = item.packId
							wanted5 = item.isWanted
							value5 = item.value
							auxPreStart = item.startTime
							auxPreEnd = item.endTime
							auxPreNotes = item.notes
							infoPre = item.info
							contractPre = item.hasContract
							auxValueByPre = item.valueType
							auxComissionValuePre = item.comissionValue
							auxComissionByPre = item.comissionType
							auxPaidValuePre = item.paidValue
						} else if (item.role === "Pós Evento") {
							aux6 = item.providerId
							auxF = item.packId
							wanted6 = item.isWanted
							value6 = item.value
							auxPosStart = item.startTime
							auxPosEnd = item.endTime
							auxPosNotes = item.notes
							infoPos = item.info
							contractPos = item.hasContract
							auxValueByPos = item.valueType
							auxComissionValuePos = item.comissionValue
							auxComissionByPos = item.comissionType
							auxPaidValuePos = item.paidValue
						} else if (item.role === "Padre") {
							aux7 = item.providerId
							auxG = item.packId
							wanted7 = item.isWanted
							value7 = item.value
							auxPadreStart = item.startTime
							auxPadreEnd = item.endTime
							auxPadreNotes = item.notes
							infoPadre = item.info
							contractPadre= item.hasContract
							auxValueByPadre = item.valueType
							auxComissionValuePadre = item.comissionValue
							auxComissionByPadre = item.comissionType
							auxPaidValuePadre = item.paidValue
						} else if (item.role === "Babysitter") {
							aux8 = item.providerId
							auxH = item.packId
							wanted8 = item.isWanted
							value8 = item.value
							auxBabyStart = item.startTime
							auxBabyEnd = item.endTime
							auxBabyNotes = item.notes
							infoBaby = item.info
							contractBabysitter = item.hasContract
							auxValueByBaby = item.valueType
							auxComissionValueBaby = item.comissionValue
							auxComissionByBaby = item.comissionType
							auxPaidValueBaby = item.paidValue
						} else if (item.role === "Lembranças") {
							aux9 = item.providerId
							auxI = item.packId
							wanted9 = item.isWanted
							value9 = item.value
							auxLembStart = item.startTime
							auxLembEnd = item.endTime
							auxLembNotes = item.notes
							infoLembrancas = item.info
							contractLembrancas = item.hasContract
							auxValueByLembrancas = item.valueType
							auxComissionValueLembrancas = item.comissionValue
							auxComissionByLembrancas = item.comissionType
							auxPaidValueLembrancas = item.paidValue
						} else if (item.role === "Transporte Convidados") {
							aux10 = item.providerId
							auxJ = item.packId
							wanted10 = item.isWanted
							value10 = item.value
							auxTranspStart = item.startTime
							auxTranspEnd = item.endTime
							auxTranspNotes = item.notes
							infoTransp = item.info
							contractTransporte = item.hasContract
							auxValueByTransporte = item.valueType
							auxComissionValueTransporte = item.comissionValue
							auxComissionByTransporte = item.comissionType
							auxPaidValueTransporte = item.paidValue
						} else if (item.role === "Aulas de dança") {
							aux11 = item.providerId
							auxK = item.packId
							wanted11 = item.isWanted
							value11 = item.value
							auxAulasStart = item.startTime
							auxAulasEnd = item.endTime
							auxAulasNotes = item.notes
							infoAulas = item.info
							contractAulas = item.hasContract
							auxValueByAulas = item.valueType
							auxComissionValueAulas = item.comissionValue
							auxComissionByAulas = item.comissionType
							auxPaidValueAulas = item.paidValue
						} else if (item.role === "Fotografia e Video") {
							fv1 = item.providerId
							fv2 = item.packId
							fv3 = item.isWanted
							fv4 = item.value
							fvStart = item.startTime
							fvEnd = item.endTime
							fvNotes = item.notes
							infoFV = item.info
							contractFV = item.hasContract
							auxValueByFV = item.valueType
							auxComissionValueFV = item.comissionValue
							auxComissionByFV = item.comissionType
							auxPaidValueFV = item.paidValue
						} else if (item.role === "Fotografia") {
							foto1 = item.providerId
							foto2 = item.packId
							foto3 = item.isWanted
							foto4 = item.value
							fotoStart = item.startTime
							fotoEnd = item.endTime
							fotoNotes = item.notes
							infoFoto = item.info
							contractFoto = item.hasContract
							auxValueByFoto = item.valueType
							auxComissionValueFoto = item.comissionValue
							auxComissionByFoto = item.comissionType
							auxPaidValueFoto = item.paidValue
						} else if (item.role === "Video") {
							video1 = item.providerId
							video2 = item.packId
							video3 = item.isWanted
							video4 = item.value
							videoStart = item.startTime
							videoEnd = item.endTime
							videoNotes = item.notes
							infoVideo = item.info
							contractVideo = item.hasContract
							auxValueByVideo = item.valueType
							auxComissionValueVideo = item.comissionValue
							auxComissionByVideo = item.comissionType
							auxPaidValueVideo = item.paidValue
						}  else if (item.role === roleUrl) {
							auxGeneral = item.providerId
							auxGeneral2 = item.packId
							auxGeneral3 = item.isWanted
							auxGeneral4 = item.value
							auxGeneralStart = item.startTime
							auxGeneralEnd = item.endTime
							auxGeneralNotes = item.notes
							infoGeral = item.info
							contractGeneral = item.hasContract
							auxValueBy = item.valueType
							auxComissionValue = item.comissionValue
							auxComissionBy = item.comissionType
							auxPaidValue = item.paidValue
						}

					})

					console.log("AUX PROVIDERS: ", auxiliarProviders)

					let auxPossibleGeneral = []
					let auxProviderPack = []
					let auxPossibleGrupo = []
					let auxPossiblePerformers = []
					let auxPossibleDJ = []
					let auxPossibleBanda = []
					let auxPossiblePre = []
					let auxPossiblePos = []
					let auxPossiblePadre = []
					let auxPossibleBaby = []
					let auxPossibleLembrancas = []
					let auxPossibleTransporte = []
					let auxPossibleAulas = []
					let auxPossibleOutros = []
					let auxPossibleEspaco = []
					let auxPossibleCatering = []
					let auxPossibleEspCat = []
					let auxPossibleFV = []
					let auxPossibleFoto = []
					let auxPossibleVideo = []

					if (possibleProvAxios !== null && possibleProvAxios !== undefined) {
						if (possibleProvAxios.length !== 0) {
							possibleProvAxios.map((item, index) => {
								if (item.role === "Igreja" || item.role === "Save the Date e Convite"
									|| item.role === "Makeup e Cabelo" || item.role === "Roupa Noiva" || item.role === "Alojamento" || item.role === "Processo Civil/Simbólico" || item.role === "Roupa Noivo" 
									|| item.role === "Florista" || item.role === "Website" || item.role === "Decoração" || item.role === "Design Gráfico") {
									item.providers.map((el, index) => {
										if (typeof el.providerId !== "undefined") {
											auxPossibleGeneral.push({
												providerId: el.providerId,
												packId: el.packId
											})

											auxProviderPack.push({
												providerId: el.providerId,
												packId: el.packId
											})
										}
									})
								} else if (item.role === "Espaço") {
									item.providers.map((el, index) => {
										if (typeof el.providerId !== "undefined") {
											auxPossibleEspaco.push({
												providerId: el.providerId,
												packId: el.packId,
											})

											auxProviderPack.push({
												providerId: el.providerId,
												packId: el.packId
											})
										}
									})
								} else if (item.role === "Catering") {
									item.providers.map((el, index) => {
										if (typeof el.providerId !== "undefined") {
											auxPossibleCatering.push({
												providerId: el.providerId,
												packId: el.packId
											})

											auxProviderPack.push({
												providerId: el.providerId,
												packId: el.packId
											})
										}
									})
								} else if (item.role === "Espaço e Catering") {
									item.providers.map((el, index) => {
										if (typeof el.providerId !== "undefined") {
											auxPossibleEspCat.push({
												providerId: el.providerId,
												packId: el.packId
											})

											auxProviderPack.push({
												providerId: el.providerId,
												packId: el.packId
											})
										}
									})
								} else if (item.role === "Grupo Musical") {
									item.providers.map((el, index) => {
										if (typeof el.providerId !== "undefined") {
											auxPossibleGrupo.push({
												providerId: el.providerId,
												packId: el.packId
											})

											auxProviderPack.push({
												providerId: el.providerId,
												packId: el.packId
											})
										}
									})
								} else if (item.role === "Performers") {
									item.providers.map((el, index) => {
										if (typeof el.providerId !== "undefined") {
											auxPossiblePerformers.push({
												providerId: el.providerId,
												packId: el.packId
											})

											auxProviderPack.push({
												providerId: el.providerId,
												packId: el.packId
											})
										}
									})
								} else if (item.role === "DJ") {
									item.providers.map((el, index) => {
										if (typeof el.providerId !== "undefined") {
											auxPossibleDJ.push({
												providerId: el.providerId,
												packId: el.packId
											})

											auxProviderPack.push({
												providerId: el.providerId,
												packId: el.packId
											})
										}
									})
								} else if (item.role === "Banda") {
									item.providers.map((el, index) => {
										if (typeof el.providerId !== "undefined") {
											auxPossibleBanda.push({
												providerId: el.providerId,
												packId: el.packId
											})

											auxProviderPack.push({
												providerId: el.providerId,
												packId: el.packId
											})
										}
									})
								}
								else if (item.role === "Pré Evento") {
									item.providers.map((el, index) => {
										if (typeof el.providerId !== "undefined") {
											auxPossiblePre.push({
												providerId: el.providerId,
												packId: el.packId
											})

											auxProviderPack.push({
												providerId: el.providerId,
												packId: el.packId
											})
										}
									})
								} else if (item.role === "Pós Evento") {
									item.providers.map((el, index) => {
										if (typeof el.providerId !== "undefined") {
											auxPossiblePos.push({
												providerId: el.providerId,
												packId: el.packId
											})
											auxProviderPack.push({
												providerId: el.providerId,
												packId: el.packId
											})
										}
									})
								} else if (item.role === "Padre") {
									item.providers.map((el, index) => {
										if (typeof el.providerId !== "undefined") {
											auxPossiblePadre.push({
												providerId: el.providerId,
												packId: el.packId
											})

											auxProviderPack.push({
												providerId: el.providerId,
												packId: el.packId
											})
										}
									})
								} else if (item.role === "Babysitter") {
									item.providers.map((el, index) => {
										if (typeof el.providerId !== "undefined") {
											auxPossibleBaby.push({
												providerId: el.providerId,
												packId: el.packId
											})
											auxProviderPack.push({
												providerId: el.providerId,
												packId: el.packId
											})
										}
									})
								} else if (item.role === "Lembranças") {
									item.providers.map((el, index) => {
										if (typeof el.providerId !== "undefined") {
											auxPossibleLembrancas.push({
												providerId: el.providerId,
												packId: el.packId
											})

											auxProviderPack.push({
												providerId: el.providerId,
												packId: el.packId
											})
										}
									})
								} else if (item.role === "Transporte Convidados") {
									item.providers.map((el, index) => {
										if (typeof el.providerId !== "undefined") {
											auxPossibleTransporte.push({
												providerId: el.providerId,
												packId: el.packId
											})

											auxProviderPack.push({
												providerId: el.providerId,
												packId: el.packId
											})
										}
									})
								} else if (item.role === "Aulas de dança") {
									item.providers.map((el, index) => {
										if (typeof el.providerId !== "undefined") {
											auxPossibleAulas.push({
												providerId: el.providerId,
												packId: el.packId
											})

											auxProviderPack.push({
												providerId: el.providerId,
												packId: el.packId
											})
										}
									})
								} else if (item.role === "Fotografia e Video") {
									item.providers.map((el, index) => {
										if (typeof el.providerId !== "undefined") {
											auxPossibleFV.push({
												providerId: el.providerId,
												packId: el.packId
											})

											auxProviderPack.push({
												providerId: el.providerId,
												packId: el.packId
											})
										}
									})
								} else if (item.role === "Fotografia") {
									item.providers.map((el, index) => {
										if (typeof el.providerId !== "undefined") {
											auxPossibleFoto.push({
												providerId: el.providerId,
												packId: el.packId
											})

											auxProviderPack.push({
												providerId: el.providerId,
												packId: el.packId
											})
										}
									})
								} else if (item.role === "Video") {
									item.providers.map((el, index) => {
										if (typeof el.providerId !== "undefined") {
											auxPossibleVideo.push({
												providerId: el.providerId,
												packId: el.packId
											})

											auxProviderPack.push({
												providerId: el.providerId,
												packId: el.packId
											})
										}
									})
								} else if (item.role === "Outros") {
									item.providers.map((el, index) => {
										if (typeof el.providerId !== "undefined") {
											auxPossibleOutros.push({
												providerId: el.providerId,
												packId: el.packId
											})

											auxProviderPack.push({
												providerId: el.providerId,
												packId: el.packId
											})
										}
									})
								}
							})
						}
					}

					//SAVE ROLE NOTES IN STATE

					if(roleNotes !== null && roleNotes !== undefined && roleNotes.length > 0){
						roleNotes.map((item, index) => {
							switch (item.role) {
								case "Espaço":
									this.setState({
										notesEspacoRole: item.notes
									}) 
									break;
								case "Catering":
									this.setState({
										notesCateringRole: item.notes
									}) 
									break;
								case "Espaço e Catering":
									this.setState({
										notesEspCatRole: item.notes
									}) 
									break;
								case "Grupo Musical":
									this.setState({
										notesGrupoRole: item.notes
									}) 
									break;
								case "Performers":
									this.setState({
										notesPerformersRole: item.notes
									}) 
									break;
								case "DJ":
									this.setState({
										notesDJRole: item.notes
									}) 
									break;
								case "Banda":
									this.setState({
										notesBandaRole: item.notes
									}) 
									break;
								case "Pré Evento":
									this.setState({
										notesPreRole: item.notes
									}) 
									break;
								case "Pós Evento":
									this.setState({
										notesPosRole: item.notes
									}) 
									break;
								case "Padre":
									this.setState({
										notesPadreRole: item.notes
									}) 
									break;
								case "Babysitter":
									this.setState({
										notesBabysitterRole: item.notes
									}) 
									break;
								case "Lembranças":
									this.setState({
										notesLembrancasRole: item.notes
									}) 
									break;
								case "Transporte Convidados":
									this.setState({
										notesTransporteRole: item.notes
									}) 
									break;
								case "Aulas de dança":
									this.setState({
										notesAulasRole: item.notes
									}) 
									break;
								case "Fotografia":
									this.setState({
										notesFotografiaRole: item.notes
									}) 
									break;
								case "Video":
									this.setState({
										notesVideoRole: item.notes
									}) 
									break;
								case "Fotografia e Video":
									this.setState({
										notesFVRole: item.notes
									}) 
									break;
								default:
									this.setState({
										notesGeneralRole: item.notes
									}) 
									break;
							}
						})
					}

					console.log("AUXILIAR PROV LOG, ", auxiliarProviders)

					this.setState({
						wed_id: wedUrl,
						role_url: roleUrl,
						isClosed: category.isClosed,
						checklistState: response.data.category.checklist,
						possibleGeneral: auxPossibleGeneral,
						negotiatedPacks: auxProviderPack,
						possibleEspaco: auxPossibleEspaco,
						possibleCatering: auxPossibleCatering,
						possibleEspCat: auxPossibleEspCat,
						possibleGrupo: auxPossibleGrupo,
						possiblePerformers: auxPossiblePerformers,
						possibleDJ: auxPossibleDJ,
						possibleBanda: auxPossibleBanda,
						possiblePre: auxPossiblePre,
						possiblePos: auxPossiblePos,
						possiblePadre: auxPossiblePadre,
						possibleBaby: auxPossibleBaby,
						possibleLembrancas: auxPossibleLembrancas,
						possibleTransporte: auxPossibleTransporte,
						possibleAulas: auxPossibleAulas,
						possibleOutros: auxPossibleOutros,
						possibleFV: auxPossibleFV,
						possibleFoto: auxPossibleFoto,
						possibleVideo: auxPossibleVideo,
						wedDate: wedDate,
						selectedProvider: auxGeneral,
						selectedPack: auxGeneral2,
						generalWanted: auxGeneral3,
						generalValue: auxGeneral4,
						selectedProviderEspaco: espaco1,
						selectedPackEspaco: espaco2,
						espacoWanted: espaco3,
						espacoValue: espaco4,
						selectedProviderCatering: catering1,
						selectedPackCatering: catering2,
						cateringWanted: catering3,
						cateringValue: catering4,
						selectedProviderEspCat: espCat1,
						selectedPackEspCat: espCat2,
						espCatWanted: espCat3,
						espCatValue: espCat4,
						selectedProviderFV: fv1,
						selectedPackFV: fv2,
						fvWanted: fv3,
						fvValue: fv4,
						selectedProviderFoto: foto1,
						selectedPackFoto: foto2,
						fotoWanted: foto3,
						fotoValue: foto4,
						selectedProviderVideo: video1,
						selectedPackVideo: video2,
						videoWanted: video3,
						videoValue: video4,
						selectedProviderGrupo: aux1,
						selectedPackGrupo: auxA,
						grupoWanted: wanted1,
						grupoValue: value1,
						selectedProviderPerformers: aux2,
						selectedPackPerformers: auxB,
						performersWanted: wanted2,
						performersValue: value2,
						selectedProviderDJ: aux3,
						selectedPackDJ: auxC,
						djWanted: wanted3,
						djValue: value3,
						selectedProviderBanda: aux4,
						selectedPackBanda: auxD,
						bandaWanted: wanted4,
						bandaValue: value4,
						selectedProviderPre: aux5,
						selectedPackPre: auxE,
						preWanted: wanted5,
						preValue: value5,
						selectedProviderPos: aux6,
						selectedPackPos: auxF,
						posWanted: wanted6,
						posValue: value6,
						selectedProviderPadre: aux7,
						selectedPackPadre: auxG,
						padreWanted: wanted7,
						padreValue: value7,
						selectedProviderBabysitter: aux8,
						selectedPackBabysitter: auxH,
						babysitterWanted: wanted8,
						babysitterValue: value8,
						selectedProviderLembrancas: aux9,
						selectedPackLembrancas: auxI,
						lembrancasWanted: wanted9,
						lembrancasValue: value9,
						selectedProviderTransporte: aux10,
						selectedPackTransporte: auxJ,
						transporteWanted: wanted10,
						transporteValue: value10,
						selectedProviderAulas: aux11,
						selectedPackAulas: auxK,
						aulasWanted: wanted11,
						aulasValue: value11,
						arrayProviders: auxiliarProviders,
						selectedPackOutros: auxL,
						outrosWanted: wanted12,
						outrosValue: value12,
						generalStart: auxGeneralStart,
						generalEnd: auxGeneralEnd,
						generalNotes: auxGeneralNotes,
						espacoStart: espacoStart,
						espacoEnd: espacoEnd,
						espacoNotes: espacoNotes,
						cateringStart: cateringStart,
						cateringEnd: cateringEnd,
						cateringNotes: cateringNotes,
						espCatStart: espCatStart,
						espCatEnd: espCatEnd,
						espCatNotes: espCatNotes,
						grupoStart: auxGrupoStart,
						grupoEnd: auxGrupoEnd,
						grupoNotes: auxGrupoNotes,
						performersStart: auxPerformersStart,
						performersEnd: auxPerformersEnd,
						auxPerformersNotes: auxPerformersNotes,
						djStart: auxDJStart,
						djEnd: auxDJEnd,
						djNotes: auxDJNotes,
						bandaStart: auxBandaStart,
						bandaEnd: auxBandaEnd,
						bandaNotes: auxBandaNotes,
						preStart: auxPreStart,
						preEnd: auxPreEnd,
						preNotes: auxPreNotes,
						posStart: auxPosStart,
						posEnd: auxPosEnd,
						posNotes: auxPosNotes,
						padreStart: auxPadreStart,
						padreEnd: auxPadreEnd,
						padreNotes: auxPadreNotes,
						babyStart: auxBabyStart,
						babyEnd: auxBabyEnd,
						babyNotes: auxBabyNotes,
						lembStart: auxLembStart,
						lembEnd: auxLembEnd,
						lembNotes: auxLembNotes,
						transpStart: auxTranspStart,
						transpEnd: auxTranspEnd,
						transNotes: auxTranspNotes,
						aulasStart: auxAulasStart,
						aulasEnd: auxAulasEnd,
						aulasNotes: auxAulasNotes,
						fvStart: fvStart,
						fvEnd: fvEnd,
						fvNotes: fvNotes,
						fotoStart: fotoStart,
						fotoEnd: fotoEnd,
						fotoNotes: fotoNotes,
						videoStart: videoStart,
						videoEnd: videoEnd,
						videoNotes: videoNotes,
						infoGeral: infoGeral,
						infoEspaco: infoEspaco,
						infoCatering: infoCatering,
						infoEspCat: infoEspCat,
						infoGrupo: infoGrupo,
						infoPerformers: infoPerformers,
						infoDJ: infoDJ,
						infoBanda: infoBanda,
						infoPre: infoPre,
						infoPos: infoPos,
						infoPadre: infoPadre,
						infoBaby: infoBaby,
						infoLembrancas: infoLembrancas,
						infoTransp: infoTransp,
						infoAulas: infoAulas,
						infoFV: infoFV,
						infoFoto: infoFoto,
						infoVideo: infoVideo,
						infoOutros: infoOutros,
						packsEspaco: packsEspAux,
						hasContractGeneral: contractGeneral,
						hasContractCatering: contractCatering,
						hasContractEspCat: contractEspCat,
						hasContractGrupo: contractGrupo,
						hasContractPerformers: contractPerformers,
						hasContractDJ: contractDJ,
						hasContractBanda: contractBanda,
						hasContractPre: contractPre,
						hasContractPos: contractPos,
						hasContractPadre: contractPadre,
						hasContractBabysitter: contractBabysitter,
						hasContractLembrancas: contractLembrancas,
						hasContractTransporte: contractTransporte,
						hasContractAulas: contractAulas,
						hasContractFoto: contractFoto,
						hasContractVideo: contractVideo,
						hasContractFV: contractFV,
						valueBy: auxValueBy,
						comissionValue: auxComissionValue,
						comissionBy: auxComissionBy,
						valueByCatering: auxValueByCatering,
						comissionValueCatering: auxComissionValueCatering,
						comissionByCatering: auxComissionByCatering,
						valueByEspCat: auxValueByEspCat,
						comissionValueEspCat: auxComissionValueEspCat,
						comissionByEspCat: auxComissionByEspCat,
						valueByGrupo: auxValueByGrupo,
						comissionValueGrupo: auxComissionValueGrupo,
						comissionByGrupo: auxComissionByGrupo,
						valueByPerformers: auxValueByPerformers,
						comissionValuePerformers: auxComissionValuePerformers,
						comissionByPerformers: auxComissionByPerformers,
						valueByDJ: auxValueByDJ,
						comissionValueDJ: auxComissionValueDJ,
						comissionByDJ: auxComissionByDJ,
						valueByBanda: auxValueByBanda,
						comissionValueBanda: auxComissionValueBanda,
						comissionByBanda: auxComissionByBanda,
						valueByPre: auxValueByPre,
						comissionValuePre: auxComissionValuePre,
						comissionByPre: auxComissionByPre,
						valueByPos: auxValueByPos,
						comissionValuePos: auxComissionValuePos,
						comissionByPos: auxComissionByPos,
						valueByPadre: auxValueByPadre,
						comissionValuePadre: auxComissionValuePadre,
						comissionByPadre: auxComissionByPadre,
						valueByBaby: auxValueByBaby,
						comissionValueBaby: auxComissionValueBaby,
						comissionByBaby: auxComissionByBaby,
						valueByLembrancas: auxValueByLembrancas,
						comissionValueLembrancas: auxComissionValueLembrancas,
						comissionByLembrancas: auxComissionByLembrancas,
						valueByTransporte: auxValueByTransporte,
						comissionValueTransporte: auxComissionValueTransporte,
						comissionByTransporte: auxComissionByTransporte,
						valueByAulas: auxValueByAulas,
						comissionValueAulas: auxComissionValueAulas,
						comissionByAulas: auxComissionByAulas,
						valueByFV: auxValueByFV,
						comissionValueFV: auxComissionValueFV,
						comissionByFV: auxComissionByFV,
						valueByFoto: auxValueByFoto,
						comissionValueFoto: auxComissionValueFoto,
						comissionByFoto: auxComissionByFoto,
						valueByVideo: auxValueByVideo,
						comissionValueVideo: auxComissionValueVideo,
						comissionByVideo: auxComissionByVideo,
						paidValue: auxPaidValue,
						paidValueEspaco: auxPaidValueEspaco,
						paidValueOutros: auxPaidValueOutros,
						paidValueCatering: auxPaidValueCatering,
						paidValueEspCat: auxPaidValueEspCat,
						paidValueGrupo: auxPaidValueGrupo,
						paidValuePerformers: auxPaidValuePerformers,
						paidValueDJ: auxPaidValueDJ,
						paidValueBanda: auxPaidValueBanda,
						paidValuePre: auxPaidValuePre,
						paidValuePos: auxPaidValuePos,
						paidValuePadre: auxPaidValuePadre,
						paidValueBaby: auxPaidValueBaby,
						paidValueLembrancas: auxPaidValueLembrancas,
						paidValueTransporte: auxPaidValueTransporte,
						paidValueAulas: auxPaidValueAulas,
						paidValueFV: auxPaidValueFV,
						paidValueFoto: auxPaidValueFoto,
						paidValueVideo: auxPaidValueVideo
					}, step)

				})
				.catch((error) => {
					console.log(error);
					step()
				});

		}


		axios.get(API_URL + '/provider/byCategoryName', { headers: { Authorization: this.props.tokens }, params: searchParams })
			.then((response) => {
				let providersRes = response.data.providers;
				let grupoProviders = []
				let performersProviders = []
				let djProviders = []
				let bandaProviders = []
				let preProviders = []
				let posProviders = []
				let padreProviders = []
				let babysitterProviders = []
				let lembrancasProviders = []
				let transporteProviders = []
				let aulasProviders = []
				let outrosProviders = []
				let espacoProviders = []
				let cateringProviders = []
				let espCatProviders = []
				let fvProviders = []
				let fotoProviders = []
				let videoProviders = []

				console.log("PROV: ", providersRes)
				if (this.state.negotiatedPacks.length > 0) {
					let arrayAuxiliar = []
					providersRes.map((itm, idx) => {
						arrayAuxiliar.push({
							providerId: itm._id,
							packId: undefined
						})
					})
					arrayAuxiliar.map((vv, ii) => {
						this.state.negotiatedPacks.map((vl, ix) => {
							if (vv.providerId === vl.providerId) {
								arrayAuxiliar[ii].packId = vl.packId
							}
						})
					})

					this.setState({
						negotiatedPacks: arrayAuxiliar
					}, () => {
						console.log("")
					})
				} else {
					let arrayAuxiliar = []
					providersRes.map((itm, idx) => {
						arrayAuxiliar.push({
							providerId: itm._id,
							packId: undefined
						})
					})

					this.setState({
						negotiatedPacks: arrayAuxiliar
					}, () => {
						console.log("")
					})
				}

				providersRes.map((item, index) => {
					if (item.role === "Grupo Musical") {
						grupoProviders.push(item)
					} else if (item.role === "Performers") {
						performersProviders.push(item)
					} else if (item.role === "DJ") {
						djProviders.push(item)
					} else if (item.role === "Banda") {
						bandaProviders.push(item)
					} else if (item.role === "Pré Evento") {
						preProviders.push(item)
					} else if (item.role === "Pós Evento") {
						posProviders.push(item)
					} else if (item.role === "Padre") {
						padreProviders.push(item)
					} else if (item.role === "Babysitter") {
						babysitterProviders.push(item)
					} else if (item.role === "Lembranças") {
						lembrancasProviders.push(item)
					} else if (item.role === "Transporte Convidados") {
						transporteProviders.push(item)
					} else if (item.role === "Aulas de dança") {
						aulasProviders.push(item)
					} else if (item.role === "Outros") {
						outrosProviders.push(item)
					} else if (item.role === "Espaço") {
						espacoProviders.push(item)
					} else if (item.role === "Catering") {
						cateringProviders.push(item)
					} else if (item.role === "Espaço e Catering") {
						espCatProviders.push(item)
					} else if (item.role === "Fotografia e Video") {
						fvProviders.push(item)
					} else if (item.role === "Fotografia") {
						fotoProviders.push(item)
					} else if (item.role === "Video") {
						videoProviders.push(item)
					}
				})

				this.setState({
					roleUrl: roleUrl,
					providers: providersRes,
					espacoProviders: espacoProviders,
					cateringProviders: cateringProviders,
					espCatProviders: espCatProviders,
					grupoProviders: grupoProviders,
					performersProviders: performersProviders,
					djProviders: djProviders,
					bandaProviders: bandaProviders,
					preProviders: preProviders,
					posProviders: posProviders,
					padreProviders: padreProviders,
					babysitterProviders: babysitterProviders,
					lembrancasProviders: lembrancasProviders,
					transporteProviders: transporteProviders,
					aulasProviders: aulasProviders,
					outrosProviders: outrosProviders,
					fvProviders: fvProviders,
					fotoProviders: fotoProviders,
					videoProviders: videoProviders
				}, () => {
					getInfoWedding()
					step()
				})
				this.setState({ loading: false })
			})
			.catch((error) => {
				console.log(error);
				this.setState({ loading: false });
				step()
			});



		//GET REUNIÕES
		axios.get(API_URL + '/wedding/getMeetings/' + wedUrl, { headers: { 'authorization': this.props.tokens } })
			.then((response) => {
				this.setState({
					meetings: response.data.meetings
				}, step)
			})
			.catch((error) => {
				console.log(error);
				step()
			});



		//GET QUESTIONÁRIO
		//PARAMS PARA AXIOS
		let parameters = {
			wedId: wedUrl,
			category: roleUrl
		}

		axios.get(API_URL + '/wedding/getCategoryQuestions', { headers: { Authorization: this.props.tokens }, params: parameters })
			.then((response) => {
				this.setState({
					quizRes: response.data.categoryQuestions
				}, step)
			})
			.catch((error) => {
				console.log(error);
				this.setState({ loading: false });
				step()
			});

		this.getTotalValues()

	}

	//FILTRAR A INFORMAÇÃO POR SUB CATEGORIAS
	getInfo = () => {
		//Get info
		let id = this.state.selectedProvider
		let idGrupo = this.state.selectedProviderGrupo
		let idPerformers = this.state.selectedProviderPerformers
		let idDJ = this.state.selectedProviderDJ
		let idBanda = this.state.selectedProviderBanda
		let idPre = this.state.selectedProviderPre
		let idPos = this.state.selectedProviderPos
		let idPadre = this.state.selectedProviderPadre
		let idBabysitter = this.state.selectedProviderBabysitter
		let idLembrancas = this.state.selectedProviderLembrancas
		let idTransporte = this.state.selectedProviderTransporte
		let idAulas = this.state.selectedProviderAulas
		let idEspaco = this.state.selectedProviderEspaco
		let idCatering = this.state.selectedProviderCatering
		let idEspCat = this.state.selectedProviderEspCat
		let idFV = this.state.selectedProviderFV
		let idFoto = this.state.selectedProviderFoto
		let idVideo = this.state.selectedProviderVideo

		//NO SUB-CATEGORY
		this.state.providers.map((item, index) => {
			if (item._id === id) {
				this.setState({
					name: item.name,
					role: item.role,
					phone: item.phone,
					email: item.email,
					packs: item.packs
				})
			}
		})

		//ANIMAÇÃO
		if (this.state.grupoProviders.length > 0) {
			this.state.grupoProviders.map((i1, index) => {
				if (i1._id === idGrupo) {
					this.setState({
						nameGrupo: i1.name,
						roleGrupo: i1.role,
						phoneGrupo: i1.phone,
						emailGrupo: i1.email,
						packsGrupo: i1.packs
					})
				}
			})
		}

		//PERFORMERS
		if (this.state.performersProviders.length > 0) {
			this.state.performersProviders.map((i2, index) => {
				if (i2._id === idPerformers) {
					this.setState({
						namePerformers: i2.name,
						rolePerformers: i2.role,
						phonePerformers: i2.phone,
						emailPerformers: i2.email,
						packsPerformers: i2.packs
					})
				}
			})
		}

		//DJ
		if (this.state.djProviders.length > 0) {
			this.state.djProviders.map((i3, index) => {
				if (i3._id === idDJ) {
					this.setState({
						nameDJ: i3.name,
						roleDJ: i3.role,
						phoneDJ: i3.phone,
						emailDJ: i3.email,
						packsDJ: i3.packs
					})
				}
			})
		}

		//BANDA
		if (this.state.bandaProviders.length > 0) {
			this.state.bandaProviders.map((i4, index) => {
				if (i4._id === idBanda) {
					this.setState({
						nameBanda: i4.name,
						roleBanda: i4.role,
						phoneBanda: i4.phone,
						emailBanda: i4.email,
						packsBanda: i4.packs
					})
				}
			})
		}

		//PRE EVENTO
		if (this.state.preProviders.length > 0) {
			this.state.preProviders.map((i4, index) => {
				if (i4._id === idPre) {
					this.setState({
						namePre: i4.name,
						rolePre: i4.role,
						phonePre: i4.phone,
						emailPre: i4.email,
						prePacks: i4.packs
					})
				}
			})
		}

		//POS EVENTO
		if (this.state.posProviders.length > 0) {
			this.state.posProviders.map((i4, index) => {
				if (i4._id === idPos) {
					this.setState({
						namePos: i4.name,
						rolePos: i4.role,
						phonePos: i4.phone,
						emailPos: i4.email,
						posPacks: i4.packs
					})
				}
			})
		}

		//PADRE
		if (this.state.padreProviders.length > 0) {
			this.state.padreProviders.map((i4, index) => {
				if (i4._id === idPadre) {
					this.setState({
						namePadre: i4.name,
						rolePadre: i4.role,
						phonePadre: i4.phone,
						emailPadre: i4.email,
						padrePacks: i4.packs
					})
				}
			})
		}

		//BABYSITTER
		if (this.state.babysitterProviders.length > 0) {
			this.state.babysitterProviders.map((i4, index) => {
				if (i4._id === idBabysitter) {
					this.setState({
						nameBabysitter: i4.name,
						roleBabysitter: i4.role,
						phoneBabysitter: i4.phone,
						emailBabysitter: i4.email,
						babysitterPacks: i4.packs
					})
				}
			})
		}

		//LEMBRANCAS
		if (this.state.lembrancasProviders.length > 0) {
			this.state.lembrancasProviders.map((i4, index) => {
				if (i4._id === idLembrancas) {
					this.setState({
						nameLembrancas: i4.name,
						roleLembrancas: i4.role,
						phoneLembrancas: i4.phone,
						emailLembrancas: i4.email,
						lembrancasPacks: i4.packs
					})
				}
			})
		}

		//TRANSPORTE
		if (this.state.transporteProviders.length > 0) {
			this.state.transporteProviders.map((i4, index) => {
				if (i4._id === idTransporte) {
					this.setState({
						nameTransporte: i4.name,
						roleTransporte: i4.role,
						phoneTransporte: i4.phone,
						emailTransporte: i4.email,
						transportePacks: i4.packs
					})
				}
			})
		}

		//AULAS
		if (this.state.aulasProviders.length > 0) {
			this.state.aulasProviders.map((i4, index) => {
				if (i4._id === idAulas) {
					this.setState({
						nameAulas: i4.name,
						roleAulas: i4.role,
						phoneAulas: i4.phone,
						emailAulas: i4.email,
						aulasPacks: i4.packs
					})
				}
			})
		}

		//FOTOGRAFIA E VIDEO
		//FV
		if (this.state.fvProviders.length > 0) {
			this.state.fvProviders.map((i, index) => {
				if (i._id === idFV) {
					this.setState({
						nameFV: i.name,
						roleFV: i.role,
						phoneFV: i.phone,
						emailFV: i.email,
						fvPacks: i.packs
					})
				}
			})
		}

		//FOTOGRAFIA
		if (this.state.fvProviders.length > 0) {
			this.state.fvProviders.map((i, index) => {
				if (i._id === idFoto) {
					this.setState({
						nameFoto: i.name,
						roleFoto: i.role,
						phoneFoto: i.phone,
						emailFoto: i.email,
						fotoPacks: i.packs
					})
				}
			})
		}

		//VIDEO
		if (this.state.fvProviders.length > 0) {
			this.state.fvProviders.map((i, index) => {
				if (i._id === idVideo) {
					this.setState({
						nameVideo: i.name,
						roleVideo: i.role,
						phoneVideo: i.phone,
						emailVideo: i.email,
						videoPacks: i.packs
					})
				}
			})
		}


		//ESPAÇO E CATERING
		//ESPAÇO
		if (this.state.espacoProviders.length > 0) {
			this.state.espacoProviders.map((i5, index) => {
				if (i5._id === idEspaco) {
					this.setState({
						nameEspaco: i5.name,
						roleEspaco: i5.role,
						phoneEspaco: i5.phone,
						emailEspaco: i5.email,
						espacoPacks: i5.packs
					})
				}
			})
		}

		//CATERING
		if (this.state.espacoProviders.length > 0) {
			this.state.espacoProviders.map((i6, index) => {
				if (i6._id === idCatering) {
					this.setState({
						nameCatering: i6.name,
						roleCatering: i6.role,
						phoneCatering: i6.phone,
						emailCatering: i6.email,
						cateringPacks: i6.packs
					})
				}
			})
		}

		//ESPAÇO E CATERING
		if (this.state.espacoProviders.length > 0) {
			this.state.espacoProviders.map((i7, index) => {
				if (i7._id === idEspCat) {
					this.setState({
						nameEspCat: i7.name,
						roleEspCat: i7.role,
						phoneEspCat: i7.phone,
						emailEspCat: i7.email,
						espCatPacks: i7.packs
					})
				}
			})
		}

	}

	//UPDATE PROVIDERS AXIOS
	updateProviders = () => {

		//INFOS URL
		const url = queryString.parse(this.props.location.search)
		let roleUrl = url.cat
		let wedUrl = url.wed

		let obj
		//OBJ AXIOS - OUTROS
		let arrayProviders = this.state.arrayProviders

		

		//OBJ AXIOS - CATEGORIAS GERAIS
		if (roleUrl !== "Animação" && roleUrl !== "Serviços Extra" && roleUrl !== "Pré ou Pós Evento" && roleUrl !== "Processo Religioso" && roleUrl !== "Espaço" && roleUrl !== "Fotografia e Video") {
			obj = {
				category: roleUrl,
				providers: [{
					role: roleUrl,
					value: this.state.generalValue,
					valueType: this.state.valueBy,
					comissionValue: this.state.comissionValue,
					comissionType: this.state.comissionBy,
					paidValue: this.state.paidValue,
					providerId: this.state.selectedProvider,
					packId: this.state.selectedPack,
					isWanted: this.state.generalWanted,
					startTime: this.state.generalStart,
					endTime: this.state.generalEnd,
					notes: this.state.generalNotes,
					info: this.state.infoGeral
					
				}]
			}
		}

		//OBJ AXIOS - ESPAÇO
		let arrayProvidersEspaco = this.state.arrayProviders

		if (roleUrl === "Espaço") {
			let providersAxios = arrayProvidersEspaco.map((element, index) => {
				console.log("ELEMENT: ", element)
				console.log("START: ", this.state.espacoStart)
				console.log("END: ", this.state.espacoEnd)

				if (element.packId === null || element.packId === undefined || element.packId === "") {
					return {
						role: "Espaço",
						providerId: element.providerId,
						isWanted: this.state.espacoWanted,
						startTime: element.espacoStart,
						endTime: element.espacoEnd,
						notes: element.espacoNotes,
						info: this.state.infoEspaco
					}
				} else {
					return {
						role: "Espaço",
						value: element.providerPackInfo.value,
						valueType: element.providerPackInfo.valueByEspaco,
						comissionValue: element.providerPackInfo.comissionValueEspaco,
						comissionType: element.providerPackInfo.comissionByEspaco,
						providerId: element.providerId,
						packId: element.packId,
						//providerId: this.state.selectedProviderEspaco,
						isWanted: this.state.espacoWanted,
						startTime: element.espacoStart,
						endTime: element.espacoEnd,
						notes: element.espacoNotes,
						info: this.state.infoEspaco,
						paidValue: element.providerPackInfo.paidValueEspaco
					}
				}
			})

			providersAxios.push(
				{
					role: "Catering",
					value: this.state.cateringValue,
					valueType: this.state.valueByCatering,
					comissionValue: this.state.comissionValueCatering,
					comissionType: this.state.comissionByCatering,
					providerId: this.state.selectedProviderCatering,
					packId: this.state.selectedPackCatering,
					isWanted: this.state.cateringWanted,
					startTime: this.state.cateringStart,
					endTime: this.state.cateringEnd,
					notes: this.state.cateringNotes,
					info: this.state.infoCatering,
					paidValue: this.state.paidValueCatering
				},
				{
					role: "Espaço e Catering",
					value: this.state.espCatValue,
					valueType: this.state.valueByEspCat,
					comissionValue: this.state.comissionValueEspCat,
					comissionType: this.state.comissionByEspCat,
					providerId: this.state.selectedProviderEspCat,
					packId: this.state.selectedPackEspCat,
					isWanted: this.state.espCatWanted,
					startTime: this.state.espCatStart,
					endTime: this.state.espCatEnd,
					notes: this.state.espCatNotes,
					info: this.state.infoEspCat,
					paidValue: this.state.paidValueEspCat
				}
			)
			
			obj = {
				category: roleUrl,
				providers: providersAxios
			}
		}


		//OBJ AXIOS - ANIMAÇÃO
		if (roleUrl === "Animação") {
			obj = {
				category: roleUrl,
				providers: [{
					role: "Grupo Musical",
					value: this.state.grupoValue,
					valueType: this.state.valueByGrupo,
					comissionValue: this.state.comissionValueGrupo,
					comissionType: this.state.comissionByGrupo,
					providerId: this.state.selectedProviderGrupo,
					packId: this.state.selectedPackGrupo,
					isWanted: this.state.grupoWanted,
					startTime: this.state.grupoStart,
					endTime: this.state.grupoEnd,
					notes: this.state.grupoNotes,
					info: this.state.infoGrupo,
					paidValue: this.state.paidValueGrupo
				},
				{
					role: "Performers",
					value: this.state.performersValue,
					valueType: this.state.valueByPerformers,
					comissionValue: this.state.comissionValuePerformers,
					comissionType: this.state.comissionByPerformers,
					providerId: this.state.selectedProviderPerformers,
					packId: this.state.selectedPackPerformers,
					isWanted: this.state.performersWanted,
					startTime: this.state.performersStart,
					endTime: this.state.performersEnd,
					notes: this.state.auxPerformersNotes,
					info: this.state.infoPerformers,
					paidValue: this.state.paidValuePerformers
				},
				{
					role: "DJ",
					value: this.state.djValue,
					valueType: this.state.valueByDJ,
					comissionValue: this.state.comissionValueDJ,
					comissionType: this.state.comissionByDJ,
					providerId: this.state.selectedProviderDJ,
					packId: this.state.selectedPackDJ,
					isWanted: this.state.djWanted,
					startTime: this.state.djStart,
					endTime: this.state.djEnd,
					notes: this.state.djNotes,
					info: this.state.infoDJ,
					paidValue: this.state.paidValueDJ
				},
				{
					role: "Banda",
					value: this.state.bandaValue,
					valueType: this.state.valueByBanda,
					comissionValue: this.state.comissionValueBanda,
					comissionType: this.state.comissionByBanda,
					providerId: this.state.selectedProviderBanda,
					packId: this.state.selectedPackBanda,
					isWanted: this.state.bandaWanted,
					startTime: this.state.bandaStart,
					endTime: this.state.bandaEnd,
					notes: this.state.bandaNotes,
					info: this.state.infoBanda,
					paidValue: this.state.paidValueBanda
				}]
			}
		}

		//OBJ AXIOS - PRE OU POS EVENTO
		if (roleUrl === "Pré ou Pós Evento") {
			obj = {
				category: roleUrl,
				providers: [{
					role: "Pré Evento",
					value: this.state.preValue,
					valueType: this.state.valueByPre,
					comissionValue: this.state.comissionValuePre,
					comissionType: this.state.comissionByPre,
					providerId: this.state.selectedProviderPre,
					packId: this.state.selectedPackPre,
					isWanted: this.state.preWanted,
					startTime: this.state.preStart,
					endTime: this.state.preEnd,
					notes: this.state.preNotes,
					info: this.state.infoPre,
					paidValue: this.state.paidValuePre
				},
				{
					role: "Pós Evento",
					value: this.state.posValue,
					valueType: this.state.valueByPos,
					comissionValue: this.state.comissionValuePos,
					comissionType: this.state.comissionByPos,
					providerId: this.state.selectedProviderPos,
					packId: this.state.selectedPackPos,
					isWanted: this.state.posWanted,
					startTime: this.state.posStart,
					endTime: this.state.posEnd,
					notes: this.state.posNotes,
					info: this.state.infoPos,
					paidValue: this.state.paidValuePos
				}]
			}
		}

		//OBJ AXIOS - PROCESSO RELIGIOSO
		if (roleUrl === "Processo Religioso") {
			obj = {
				category: roleUrl,
				providers: [{
					role: "Padre",
					value: this.state.padreValue,
					valueType: this.state.valueByPadre,
					comissionValue: this.state.comissionValuePadre,
					comissionType: this.state.comissionByPadre,
					providerId: this.state.selectedProviderPadre,
					packId: this.state.selectedPackPadre,
					isWanted: this.state.padreWanted,
					startTime: this.state.padreStart,
					endTime: this.state.padreEnd,
					notes: this.state.padreNotes,
					info: this.state.infoPadre,
					paidValue: this.state.paidValuePadre
				}]
			}
		}

		//OBJ AXIOS - SERVIÇOS EXTRA
		if (roleUrl === "Serviços Extra") {
			obj = {
				category: roleUrl,
				providers: [{
					role: "Babysitter",
					value: this.state.babysitterValue,
					valueType: this.state.valueByBaby,
					comissionValue: this.state.comissionValueBaby,
					comissionType: this.state.comissionByBaby,
					providerId: this.state.selectedProviderBabysitter,
					packId: this.state.selectedPackBabysitter,
					isWanted: this.state.babysitterWanted,
					startTime: this.state.babyStart,
					endTime: this.state.babyEnd,
					notes: this.state.babyNotes,
					info: this.state.infoBaby,
					paidValue: this.state.paidValueBaby
				},
				{
					role: "Lembranças",
					value: this.state.lembrancasValue,
					valueType: this.state.valueByLembrancas,
					comissionValue: this.state.comissionValueLembrancas,
					comissionType: this.state.comissionByLembrancas,
					providerId: this.state.selectedProviderLembrancas,
					packId: this.state.selectedPackLembrancas,
					isWanted: this.state.lembrancasWanted,
					startTime: this.state.lembStart,
					endTime: this.state.lembEnd,
					notes: this.state.lembNotes,
					info: this.state.infoLembrancas,
					paidValue: this.state.paidValueLembrancas
				},
				{
					role: "Transporte Convidados",
					value: this.state.transporteValue,
					valueType: this.state.valueByTransporte,
					comissionValue: this.state.comissionValueTransporte,
					comissionType: this.state.comissionByTransporte,
					providerId: this.state.selectedProviderTransporte,
					packId: this.state.selectedPackTransporte,
					isWanted: this.state.transporteWanted,
					startTime: this.state.transpStart,
					endTime: this.state.transpEnd,
					notes: this.state.transpNotes,
					info: this.state.infoTransp,
					paidValue: this.state.paidValueTransporte
				},
				{
					role: "Aulas de dança",
					value: this.state.aulasValue,
					valueType: this.state.valueByAulas,
					comissionValue: this.state.comissionValueAulas,
					comissionType: this.state.comissionByAulas,
					providerId: this.state.selectedProviderAulas,
					packId: this.state.selectedPackAulas,
					isWanted: this.state.aulasWanted,
					startTime: this.state.aulasStart,
					endTime: this.state.aulasEnd,
					notes: this.state.aulasNotes,
					info: this.state.infoAulas,
					paidValue: this.state.paidValueAulas
				}]
			}

		}

		//OBJ AXIOS - FOTOGRAFIA E VIDEO
		if (roleUrl === "Fotografia e Video") {
			obj = {
				category: roleUrl,
				providers: [{
					role: "Fotografia",
					value: this.state.fotoValue,
					valueType: this.state.valueByFoto,
					comissionValue: this.state.comissionValueFoto,
					comissionType: this.state.comissionByFoto,
					providerId: this.state.selectedProviderFoto,
					packId: this.state.selectedPackFoto,
					isWanted: this.state.fotoWanted,
					startTime: this.state.fotoStart,
					endTime: this.state.fotoEnd,
					notes: this.state.fotoNotes,
					info: this.state.infoFoto,
					paidValue: this.state.paidValueFoto
				},
				{
					role: "Video",
					value: this.state.videoValue,
					valueType: this.state.valueByVideo,
					comissionValue: this.state.comissionValueVideo,
					comissionType: this.state.comissionByVideo,
					providerId: this.state.selectedProviderVideo,
					packId: this.state.selectedPackVideo,
					isWanted: this.state.videoWanted,
					startTime: this.state.videoStart,
					endTime: this.state.videoEnd,
					notes: this.state.videoNotes,
					info: this.state.infoVideo,
					paidValue: this.state.paidValueVideo
				},
				{
					role: "Fotografia e Video",
					value: this.state.fvValue,
					valueType: this.state.valueByFV,
					comissionValue: this.state.comissionValueFV,
					comissionType: this.state.comissionByFV,
					providerId: this.state.selectedProviderFV,
					packId: this.state.selectedPackFV,
					isWanted: this.state.fvWanted,
					startTime: this.state.fvStart,
					endTime: this.state.fvEnd,
					notes: this.state.fvNotes,
					info: this.state.infoFV,
					paidValue: this.state.paidValueFV
				}
			]
			}
		}

		if (roleUrl === "Outros") {
			let providersAxios = arrayProviders.map((element, index) => {
				console.log("ELEMENT: ", element)
				if (element.packId === null || element.packId === undefined || element.packId === "") {
					return {
						role: roleUrl,
						providerId: element.providerId,
						isWanted: this.state.outrosWanted,
						startTime: element.auxOutrosStart,
						endTime: element.auxOutrosEnd,
						notes: element.auxOutrosNotes,
						info: element.infoOutros
					}
				} else {
					return {
						role: roleUrl,
						value: element.providerPackInfo.value,
						valueType: element.providerPackInfo.valueByOutros,
						comissionValue: element.providerPackInfo.comissionValueOutros,
						comissionType: element.providerPackInfo.comissionByOutros,
						providerId: element.providerId,
						packId: element.packId,
						isWanted: this.state.outrosWanted,
						startTime: element.auxOutrosStart,
						endTime: element.auxOutrosEnd,
						notes: element.auxOutrosNotes,
						info: element.infoOutros,
						paidValue: element.providerPackInfo.paidValueOutros
					}
				}

				
			})
			obj = {
				category: roleUrl,
				providers: providersAxios
			}

			console.log("OBJ AFTER ARRPROVIDERS: ", obj)
		}

		console.log("OBJ AXIOS: ", obj)


		//UPDATE PROVIDERS INFO AXIOS
		axios.put(API_URL + '/wedding/updateProvider/' + wedUrl, obj, { headers: { Authorization: this.props.tokens } })
			.then((response) => {
				notify("Alterações gravadas com sucesso na categoria " + roleUrl + '!', "success")
				//this.props.history.push('/wedding_details/' + wedUrl)
				this.getTotalValues()
			})
			.catch((error) => {
				console.log(error);
				if (error.response && error.response.status === 403) {
					notify("Impossível contratar fornecedores com o mesmo pack!")
					this.setState({ loading: false });
				} else if(error.response && error.response.status === 401) {
					notify("Não é possível fazer alterações a catergorias fechadas!")
					this.setState({ loading: false });
				} else {
					notify("Erro ao gravar as alterações!")
					this.setState({ loading: false });
				}
			});
		}
		
	


	toggleMeetings = (openMeetings = false, meetings = null) => {
		this.setState({
			meetingToOpen: meetings,
			openMeetings: openMeetings
		})
	}


	toggleInfo = (openInfo = false) => {
		this.setState({
			openInfo: openInfo
		})
	}

	render() {

		console.log("STATE :  ", this.state)
		console.log("ESPACO PROVIDERS: ", this.state.espacoProviders)

		//INFORMAÇÃO DE REUNIÕES PARA MODAL
		let meetingsData
		if (this.state.meetings.length > 0) {
			meetingsData = this.state.meetings.map((item, index) => {
				let aux = moment(item.date).format('DD-MM-YYYY')
				let auxT = moment(item.time).format('HH:mm')
				return (
					<Row style={{ marginBottom: "3%" }}>
						<Col sm="3" style={{ textAlign: "left" }}>
							<p style={{ fontSize: "13px", color: "#000000" }}>{item.subject}</p>
						</Col>
						<Col sm="2" style={{ textAlign: "left" }}>
							<p style={{ fontSize: "13px", color: "#000000" }}>{aux}</p>
						</Col>
						<Col sm="2" style={{ textAlign: "left" }}>
							<p style={{ fontSize: "13px", color: "#000000" }}>{auxT}</p>
						</Col>
						<Col sm="2" style={{ textAlign: "left" }}>
							<p style={{ fontSize: "13px", color: "#000000" }}>{item.location}</p>
						</Col>
						<Col sm="3" style={{ textAlign: "left" }}>
							<p style={{ fontSize: "13px", color: "#000000" }}>{item.notes}</p>
						</Col>
					</Row>
				)
			})
		}

		//VARS PARA SELECTS E CARDS
		//PROVIDERS SEM SUB CATEGORIAS
		let providersData
		let providerInfo
		let providersPacks
		let detailsPack

		//ESPAÇO
		let providerEspaco
		let providerEspacoInfo
		let providerEspacoPacks
		let detailsPackEspaco

		//CATERING
		let providerCatering
		let providerCateringInfo
		let providerCateringPacks
		let detailsPackCatering

		//ESPAÇO E CATERING
		let providerEspCat
		let providerEspCatInfo
		let providerEspCatPacks
		let detailsPackEspCat

		//GRUPO MUSICAL
		let providerGrupo
		let providerGrupoInfo
		let providerGrupoPacks
		let detailsPackGrupo

		//PERFORMERS
		let providerPerformers
		let providerPerformersInfo
		let providerPerformersPacks
		let detailsPackPerformers

		//DJ
		let providerDJ
		let providerDJInfo
		let providerDJPacks
		let detailsPackDJ

		//BANDA
		let providerBanda
		let providerBandaInfo
		let providerBandaPacks
		let detailsPackBanda

		//PRE
		let providerPre
		let providerPreInfo
		let providerPrePacks
		let detailsPackPre

		//POS
		let providerPos
		let providerPosInfo
		let providerPosPacks
		let detailsPackPos

		//PADRE
		let providerPadre
		let providerPadreInfo
		let providerPadrePacks
		let detailsPackPadre

		//BABYSITTER
		let providerBabysitter
		let providerBabysitterInfo
		let providerBabysitterPacks
		let detailsPackBabysitter

		//LEMBRANÇAS
		let providerLembrancas
		let providerLembrancasInfo
		let providerLembrancasPacks
		let detailsPackLembrancas

		//TRANSPORTE CONVIDADOS
		let providerTransporte
		let providerTransporteInfo
		let providerTransportePacks
		let detailsPackTransporte

		//AULAS DE DANÇA
		let providerAulas
		let providerAulasInfo
		let providerAulasPacks
		let detailsPackAulas

		//OUTROS
		let providerOutros
		let providerOutrosPacks

		//FOTOGRAFIA E VIDEO
		let providerFV
		let providerFVInfo
		let providerFVPacks
		let detailsPackFV

		//FOTOGRAFIA 
		let providerFoto
		let providerFotoInfo
		let providerFotoPacks
		let detailsPackFoto

		//VIDEO
		let providerVideo
		let providerVideoInfo
		let providerVideoPacks
		let detailsPackVideo

		//DADOS PARA SELECTS E CARDS - CATEGORIAS GERAIS
		if (this.state.providers !== null || this.state.packs !== null) {
			providersData = this.state.providers.map((item, index) => {
				return (
					<Option value={item._id}>{item.name}</Option>
				)
			})

			for (const item of this.state.providers) {
				if (item._id === this.state.selectedProvider) {
					providerInfo = (
						//<Container style={{ paddingRight: 0, marginLeft: "1%", marginTop: "4%", marginRight: "2%", maxWidth: "-webkit-fill-available", maxHeight: "100%" }}>
							<Card style={{float:"left", width:"47%"}}>
								<CardContent>
									<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
										<h6 style={{ textAlign: "left" }}><b>Detalhes do Provider</b></h6>
									</Row>
									<Row className="inline-col" style={{ width: "-webkit-fill-available", marginBottom: "4%" }}>
										<Col sm="7">
											<p className="p-title-label">Nome</p>
											<p className="p-label">{item.name}</p>
										</Col>
										<Col sm="4">
											<p className="p-title-label">Contacto</p>
											<p className="p-label">{item.phone}</p>
										</Col>
									</Row>
									<Row className="inline-col" style={{ width: "-webkit-fill-available", marginBottom: "4%" }}>
										<Col>
											<p className="p-title-label">Email</p>
											<p className="p-label">{item.email}</p>
										</Col>
									</Row>
								</CardContent>
							</Card>
						//</Container>
					)
					break
				}
			}

			providersPacks = this.state.packs.map((itm, index) => {
				return (
					<Option value={itm._id}>{itm.name}</Option>
				)
			})

			for (const itm of this.state.packs) {
				if (itm._id === this.state.selectedPack) {
					detailsPack = (

						//<Container style={{ paddingRight: 0, marginLeft: "1%", marginRight: "2%", marginTop: "4%", maxWidth: "-webkit-fill-available" }}>
							<Card style={{float:"right", width:"47%"}}>
								<CardContent>
									<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
										<h6 style={{ textAlign: "left" }}><b>Detalhes do Pack</b></h6>
									</Row>
									<Row className="inline-col" style={{ width: "-webkit-fill-available" }}>
										<Col sm="3">
											<p className="p-title-label">Nome</p>
											<p className="p-label">{itm.name}</p>
										</Col>
										<Col sm="5">
											<p className="p-title-label">Descrição</p>
											<p className="p-label">{itm.desc}</p>
										</Col>
										<Col sm="2">
											{(itm.priceBy === "pessoa") ? (
												<div>
													<p className="p-title-label">Preço</p>
													<p className="p-label">{itm.price} €/PAX</p>
												</div>
											) : (
													<div>
														<p className="p-title-label">Preço</p>
														<p className="p-label">{itm.price} €</p>
													</div>
												)}
										</Col>
										<Col sm="2">
											{(itm.comissionType !== null && itm.comissionType !== undefined) ? (
												<div>
													<p className="p-title-label">Comissão</p>
													{(itm.comissionType === "euro") ? (
														<p className="p-label">{itm.comission} €</p>
													) : (
														<p className="p-label">{itm.comission} %</p>
													)}
												</div>
											) : (
												<div>
													<p className="p-title-label">Comissão</p>
													<p style={{textAlign: "center", fontSize: "14px"}}> - </p>
												</div>
											)}
										</Col>
									</Row>
									<Row style={{ marginTop: "4%" }}>
										<Col sm="3">
											<p className="p-title-label" style={{ textAlign: "right" }}>Valor acordado</p>
											{(this.state.generalValue === undefined || this.state.generalValue === null) ? (
												<Input value="" onChange={(el) => { this.changeFormValue("generalValue", el.target.value) }}></Input>
											) : (
												<Input value={this.state.generalValue} onChange={(el) => { this.changeFormValue("generalValue", el.target.value) }}></Input>
											)}
										</Col>
										<Col sm="4">
											<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold", color:"#FFFFFF"}}>.</p>
											<Radio.Group name="valueBy" onChange={(el) => { this.changeFormValue("valueBy", el.target.value) }} value={this.state.valueBy}>
												<Radio value={"pessoa"}>Pessoa</Radio>
												<Radio value={"geral"}>Geral</Radio>
											</Radio.Group>
										</Col>
										<Col sm="2">
											<p className="p-title-label" style={{ textAlign: "right" }}>Comissão</p>
											{(this.state.comissionValue === undefined || this.state.commissionValue === null) ? (
												<Input value="" onChange={(el) => { this.changeFormValue("comissionValue", el.target.value) }}></Input>
											) : (
												<Input value={this.state.comissionValue} onChange={(el) => { this.changeFormValue("comissionValue", el.target.value) }}></Input>
											)}
										</Col>
										<Col sm="3">
											<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold", color:"#FFFFFF"}}>.</p>
											<Radio.Group name="comissionBy" onChange={(el) => { this.changeFormValue("comissionBy", el.target.value) }} value={this.state.comissionBy}>
												<Radio value={"pcent"}>%</Radio>
												<Radio value={"euro"}>€</Radio>
											</Radio.Group>
										</Col>
									</Row>
									<Row style={{ marginTop: "4%"}}>
										<Col sm="3" style={{ justifyContent:"flex-start"}}>
											<p className="p-title-label" style={{ textAlign: "right" }}>Valor pago</p>
											{(this.state.paidValue === undefined || this.state.paidValue === null) ? (
												<Input value="" onChange={(el) => { this.changeFormValue("paidValue", el.target.value) }}></Input>
											) : (
												<Input value={this.state.paidValue} onChange={(el) => { this.changeFormValue("paidValue", el.target.value) }}></Input>
											)}
										</Col>
										<Col sm="9" style={{textAlign:"end", marginRight:"0%", marginTop:"4%"}}>
											{(this.state.totalValues.length !== 0) ? (
												<div>
													<span className="p-title-label">Total: </span>
													{this.state.totalValues[0].total} €
												</div>
											) : (
												<p></p>
											)}
										</Col>
									</Row>
								</CardContent>
							</Card>
						//</Container>
					)
					break
				}
			}
		}

		

		//DADOS PARA SELECTS E CARDS - CATEGORIA ESPAÇO
		if (this.state.espacoProviders !== null && this.state.espacoProviders !== undefined) {

			providerEspaco = this.state.espacoProviders.map((item, index) => {
				return (
					<Option value={item._id}>{item.name}</Option>
				)
			})

		}

		let providerListEspaco
		if (this.state.arrayProviders !== null && this.state.arrayProviders !== undefined) {
			providerListEspaco = this.state.arrayProviders.map((e, index) => {
				providerEspacoPacks = this.state.arrayProviders[index].providerPackOpts.map((itm, index) => {
					return (
						<Option value={itm._id}>{itm.name}</Option>
					)
				})
				return (
					<div key={index}>
						<Row style={{ marginBottom: "1%", marginTop: "2%", marginLeft: "0%" }}>
							<Col className="inline-col">
								<h6 style={{ textAlign: "left" }}><b>Fornecedor</b></h6>
							</Col>
							{(this.state.arrayProviders[index].providerId === null || this.state.arrayProviders[index].providerId === undefined) ? (
								<Col></Col>
							) : (
								<Col className="inline-col" style={{justifyContent:"flex-end"}}>
									{(this.state.arrayProviders[index].providerInfo !== null && this.state.arrayProviders[index].providerInfo !== undefined) ? (
										<div className="inline-col">
											<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
												<a href={"mailto:" + this.state.arrayProviders[index].providerInfo.email + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + this.state.arrayProviders[index].providerInfo.name + ", " + "%0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
											</Tooltip>
											<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Upload Contrato" placement="top">
												<AddIcon style={{marginLeft:"1%"}} className="btn-add pointer" onClick={() => this.toggleUpload(true, "Espaço", this.state.arrayProviders[index].providerId)}/>
											</Tooltip>
											{(this.state.arrayProviders[index].hasContract === true) ? (
												<div className="inline-col">
													<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Visualizar Contrato" placement="top">
														<PdfIcon style={{marginLeft:"1%"}} className="pointer" onClick={() => this.download("Espaço", this.state.arrayProviders[index].providerId)}/>
													</Tooltip>
													<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Eliminar Contrato" placement="top">
														<DeleteIcon style={{marginLeft:"1%"}} className="btn-delete pointer" onClick={() => this.toggleDelete(true, "Espaço", this.state.arrayProviders[index].providerId )}/>
													</Tooltip>
												</div>
											) : (
												<p></p>
											)}
											
										</div>
									) : (
										<p></p>
									)}
								</Col>
							)}
						</Row>
						<Row style={{ justifyContent:"space-evenly", marginBottom:"2%" }}>
							<Select style={{width:"47%"}} placeholder="Selecione Fornecedor." value={this.state.arrayProviders[index].providerId} onChange={(value) => { this.onChangeProviderSelectEspaco(value, index) }}>
								<Option value={undefined}>Selecione Fornecedor</Option>
								{providerEspaco}
							</Select>
							<Select style={{width:"47%"}} placeholder="Selecione Pack." value={this.state.arrayProviders[index].packId} onChange={(value) => { this.onChangePackSelectEspaco(value, index) }}>
								<Option value={undefined}>Selecione Pack</Option>
								{providerEspacoPacks}
							</Select>
						</Row>
						<Row style={{justifyContent: (this.state.arrayProviders[index].providerPackInfo !== null && this.state.arrayProviders[index].providerPackInfo !== undefined) ? "space-evenly" : "unset", marginLeft: (this.state.arrayProviders[index].providerPackInfo !== null && this.state.arrayProviders[index].providerPackInfo !== undefined) ? undefined : "1%", marginBottom:"2%"}}>
							{/* <Col style={{ width: "50%" }}> */}
								{(this.state.arrayProviders[index].providerInfo !== null && this.state.arrayProviders[index].providerInfo !== undefined) ? (
									//<Container style={{ paddingRight: 0, marginLeft: "1%", marginTop: "4%", marginRight: "2%", maxWidth: "-webkit-fill-available" }}>
										<Card style={{float:"left", width:"47%"}}>
											<CardContent>
												<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
													<h6 style={{ textAlign: "left" }}><b>Detalhes do Fornecedor</b></h6>
												</Row>
												<Row className="inline-col" style={{ width: "-webkit-fill-available", marginBottom: "4%" }}>
													<Col sm="7">
														<p className="p-title-label">Nome</p>
														<p className="p-label">{this.state.arrayProviders[index].providerInfo.name}</p>
													</Col>
													<Col sm="4">
														<p className="p-title-label">Contacto</p>
														<p className="p-label">{this.state.arrayProviders[index].providerInfo.phone}</p>
													</Col>
												</Row>
												<Row className="inline-col" style={{ width: "-webkit-fill-available", marginBottom: "4%" }}>
													<Col sm="5">
														<p className="p-title-label">Email</p>
														<p className="p-label">{this.state.arrayProviders[index].providerInfo.email}</p>
													</Col>
												</Row>
											</CardContent>
										</Card>
									//</Container>
								) : (
										<p></p>
									)}
							{/* </Col> */}
							{/* <Col> */}
								{(this.state.arrayProviders[index].providerPackInfo !== null && this.state.arrayProviders[index].providerPackInfo !== undefined) ? (
									//<Container style={{ paddingRight: 0, marginLeft: "1%", marginRight: "2%", marginTop: "4%", maxWidth: "-webkit-fill-available" }}>
										<Card style={{float:"right", width:"47%"}}>
											<CardContent>
												<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
													<h6 style={{ textAlign: "left" }}><b>Detalhes do Pack</b></h6>
												</Row>
												<Row className="inline-col" style={{ width: "-webkit-fill-available" }}>
													<Col sm="3">
														<p className="p-title-label">Nome</p>
														<p className="p-label">{this.state.arrayProviders[index].providerPackInfo.name}</p>
													</Col>
													<Col sm="5">
														<p className="p-title-label">Descrição</p>
														<p className="p-label">{this.state.arrayProviders[index].providerPackInfo.desc}</p>
													</Col>
													<Col sm="2">
														{(this.state.arrayProviders[index].providerPackInfo.priceBy === "pessoa") ? (
															<div>
																<p className="p-title-label">Preço</p>
																<p className="p-label">{this.state.arrayProviders[index].providerPackInfo.price} €/PAX</p>
															</div>
														) : (
															<div>
																<p className="p-title-label">Preço</p>
																<p className="p-label">{this.state.arrayProviders[index].providerPackInfo.price} €</p>
															</div>
														)}
													</Col>
													<Col sm="2">
														{(this.state.arrayProviders[index].providerPackInfo.comissionType !== null && this.state.arrayProviders[index].providerPackInfo.comissionType !== undefined) ? (
															<div>
																<p className="p-title-label">Comissão</p>
																{(this.state.arrayProviders[index].providerPackInfo.comissionType === "euro") ? (
																	<p className="p-label">{this.state.arrayProviders[index].providerPackInfo.comission} €</p>
																) : (
																	<p className="p-label">{this.state.arrayProviders[index].providerPackInfo.comission} %</p>
																)}
															</div>
														) : (
															<div>
																<p className="p-title-label">Comissão</p>
																<p style={{textAlign:"center", fontSize:"14px"}}> - </p>
															</div>
														)}
													</Col>
												</Row>
												<Row style={{ marginTop: "4%" }}>
													<Col sm="3">
														<p className="p-title-label" style={{ textAlign: "right" }}>Valor acordado</p>
														{(this.state.arrayProviders[index].providerPackInfo.value === undefined || this.state.arrayProviders[index].providerPackInfo.value === null) ? (
															<Input value="" onChange={(el) => { this.onChangeFormV(el.target.value, index, "value") }}></Input>
														) : (
															<Input value={this.state.arrayProviders[index].providerPackInfo.value} onChange={(el) => { this.onChangeFormV(el.target.value, index, "value") }}></Input>
														)}
													</Col>
													<Col sm="4">
														<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold", color:"#FFFFFF"}}>.</p>
														<Radio.Group name="valueByEspaco" onChange={(el) => { this.onChangeFormV(el.target.value, index, "valueByEspaco") }} value={this.state.arrayProviders[index].providerPackInfo.valueByEspaco}>
															<Radio value={"pessoa"}>Pessoa</Radio>
															<Radio value={"geral"}>Geral</Radio>
														</Radio.Group>
													</Col>
													<Col sm="2">
														<p className="p-title-label" style={{ textAlign: "right" }}>Comissão</p>
														{(this.state.arrayProviders[index].providerPackInfo.comissionValueEspaco === undefined || this.state.arrayProviders[index].providerPackInfo.comissionValueEspaco === null) ? (
															<Input value="" onChange={(el) => { this.onChangeFormV(el.target.value, index, "comissionValueEspaco") }}></Input>
														) : (
															<Input value={this.state.arrayProviders[index].providerPackInfo.comissionValueEspaco} onChange={(el) => { this.onChangeFormV(el.target.value, index, "comissionValueEspaco") }}></Input>
														)}
													</Col>
													<Col sm="3">
														<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold", color:"#FFFFFF"}}>.</p>
														<Radio.Group name="comissionByEspaco" onChange={(el) => { this.onChangeFormV(el.target.value, index, "comissionByEspaco") }} value={this.state.arrayProviders[index].providerPackInfo.comissionByEspaco}>
															<Radio value={"pcent"}>%</Radio>
															<Radio value={"euro"}>€</Radio>
														</Radio.Group>
													</Col>
												</Row>
												<Row style={{ marginTop: "4%", justifyContent:"flex-end", marginRight:"0%"}}>
													<Col sm="3" style={{ justifyContent:"flex-start"}}>
														<p className="p-title-label" style={{ textAlign: "right" }}>Valor pago</p>
														{(this.state.arrayProviders[index].providerPackInfo.paidValueEspaco === undefined || this.state.arrayProviders[index].providerPackInfo.paidValueEspaco === null) ? (
															<Input value="" onChange={(el) => { this.onChangeFormV(el.target.value, index, "paidValueEspaco") }}></Input>
														) : (
															<Input value={this.state.arrayProviders[index].providerPackInfo.paidValueEspaco} onChange={(el) => { this.onChangeFormV(el.target.value, index, "paidValueEspaco") }}></Input>
														)}
													</Col>
													<Col sm="9" style={{textAlign:"end", marginRight:"0%", marginTop:"4%"}}>
														{(this.state.totalValues.length !== 0) ? (
															this.state.totalValues.map((value, ix) => {
																if(value.role === "Espaço" && value.packId === this.state.arrayProviders[index].packId){
																	return(
																		<div>
																			<span className="p-title-label">Total: </span>
																			{this.state.totalValues[ix].total} €
																		</div>
																	)
																}
															})
														) : (
															null
														)}
													</Col>
												</Row>
											</CardContent>
										</Card>
									//</Container>
								) : (
										<p></p>
									)}
							{/* </Col> */}
						</Row>
					</div>
				)
			});

		}


		//CATERING
		if (this.state.espacoProviders !== null  && this.state.espacoProviders !== undefined) {

			providerCatering = this.state.espacoProviders.map((item, index) => {
				return (
					<Option value={item._id}>{item.name}</Option>
				)
			})

			for (const item of this.state.espacoProviders) {
				if (item._id === this.state.selectedProviderCatering) {
					providerCateringInfo = (
						//<Container style={{ paddingRight: 0, marginLeft: "1%", marginTop: "4%", marginRight: "2%", maxWidth: "-webkit-fill-available" }}>
							<Card style={{float:"left", width:"47%"}}>
								<CardContent>
									<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
										<h6 style={{ textAlign: "left" }}><b>Detalhes do Provider</b></h6>
									</Row>
									<Row className="inline-col" style={{ width: "-webkit-fill-available", marginBottom: "4%" }}>
										<Col sm="7">
											<p className="p-title-label">Nome</p>
											<p className="p-label">{item.name}</p>
										</Col>
										<Col sm="4">
											<p className="p-title-label">Contacto</p>
											<p className="p-label">{item.phone}</p>
										</Col>
									</Row>
									<Row className="inline-col" style={{ width: "-webkit-fill-available", marginBottom: "4%" }}>
										<Col>
											<p className="p-title-label">Email</p>
											<p className="p-label">{item.email}</p>
										</Col>
									</Row>
								</CardContent>
							</Card>
						//</Container>
					)
				}
			}

			if(this.state.cateringPacks !== null && this.state.cateringPacks !== undefined){
				providerCateringPacks = this.state.cateringPacks.map((itm, index) => {
					return (
						<Option value={itm._id}>{itm.name}</Option>
					)
				})
			}

			for (const result of this.state.cateringPacks) {
				if (result._id === this.state.selectedPackCatering) {
					detailsPackCatering = (
						//<Container style={{ paddingRight: 0, marginLeft: "1%", marginRight: "2%", marginTop: "4%", maxWidth: "-webkit-fill-available" }}>
							<Card style={{float:"right", width:"47%"}}>
								<CardContent>
									<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
										<h6 style={{ textAlign: "left" }}><b>Detalhes do Pack</b></h6>
									</Row>
									<Row className="inline-col" style={{ width: "-webkit-fill-available" }}>
										<Col sm="3">
											<p className="p-title-label">Nome</p>
											<p className="p-label">{result.name}</p>
										</Col>
										<Col sm="5">
											<p className="p-title-label">Descrição</p>
											<p className="p-label">{result.desc}</p>
										</Col>
										<Col sm="2">
											{(result.priceBy === "pessoa") ? (
												<div>
													<p className="p-title-label">Preço</p>
													<p className="p-label">{result.price} €/PAX</p>
												</div>
											) : (
													<div>
														<p className="p-title-label">Preço</p>
														<p className="p-label">{result.price} €</p>
													</div>
												)}
										</Col>
										<Col sm="2">
											{(result.comissionType !== null && result.comissionType !== undefined) ? (
												<div>
													<p className="p-title-label">Comissão</p>
													{(result.comissionType === "euro") ? (
														<p className="p-label">{result.comission} €</p>
													) : (
														<p className="p-label">{result.comission} %</p>
													)}
												</div>
											) : (
												<div>
													<p className="p-title-label">Comissão</p>
													<p style={{textAlign: "center", fontSize: "14px"}}> - </p>
												</div>
											)}
										</Col>
									</Row>
									<Row style={{ marginTop: "4%" }}>
										<Col sm="3">
											<p className="p-title-label" style={{ textAlign: "right" }}>Valor acordado</p>
											{(this.state.cateringValue === undefined || this.state.cateringValue === null) ? (
												<Input value="" onChange={(el) => { this.changeFormValue("cateringValue", el.target.value) }}></Input>
											) : (
												<Input value={this.state.cateringValue} onChange={(el) => { this.changeFormValue("cateringValue", el.target.value) }}></Input>
											)}
										</Col>
										<Col sm="4">
											<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold", color:"#FFFFFF"}}>.</p>
											<Radio.Group name="valueByCatering" onChange={(el) => { this.changeFormValue("valueByCatering", el.target.value) }} value={this.state.valueByCatering}>
												<Radio value={"pessoa"}>Pessoa</Radio>
												<Radio value={"geral"}>Geral</Radio>
											</Radio.Group>
										</Col>
										<Col sm="2">
											<p className="p-title-label" style={{ textAlign: "right" }}>Comissão</p>
											{(this.state.comissionValueCatering === undefined || this.state.commissionValueCatering === null) ? (
												<Input value="" onChange={(el) => { this.changeFormValue("comissionValueCatering", el.target.value) }}></Input>
											) : (
												<Input value={this.state.comissionValueCatering} onChange={(el) => { this.changeFormValue("comissionValueCatering", el.target.value) }}></Input>
											)}
										</Col>
										<Col sm="3">
											<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold", color:"#FFFFFF"}}>.</p>
											<Radio.Group name="comissionByCatering" onChange={(el) => { this.changeFormValue("comissionByCatering", el.target.value) }} value={this.state.comissionByCatering}>
												<Radio value={"pcent"}>%</Radio>
												<Radio value={"euro"}>€</Radio>
											</Radio.Group>
										</Col>
									</Row>
									<Row style={{ marginTop: "4%", justifyContent:"flex-end", marginRight:"0%"}}>
										<Col sm="3" style={{ justifyContent:"flex-start"}}>
												<p className="p-title-label" style={{ textAlign: "right" }}>Valor pago</p>
												{(this.state.paidValueCatering === undefined || this.state.paidValueCatering === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("paidValueCatering", el.target.value) }}></Input>
												) : (
													<Input value={this.state.paidValueCatering} onChange={(el) => { this.changeFormValue("paidValueCatering", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="9" style={{textAlign:"end", marginRight:"0%", marginTop:"4%"}}>
												{(this.state.totalValues.length !== 0) ? (
													this.state.totalValues.map((value, index) => {
														if(value.role === "Catering"){
															return(
																<div>
																	<span className="p-title-label">Total: </span>
																	{this.state.totalValues[index].total} €
																</div>
															)
														}
													})
												) : (
													null
												)}
											</Col>
										</Row>
								</CardContent>
							</Card>
						//</Container>
					)
				}
			}
		}
			//ESPAÇO E CATERING
			if (this.state.espCatProviders !== null && this.state.espCatProviders !== undefined) {

				providerEspCat = this.state.espacoProviders.map((item, index) => {
					return (
						<Option value={item._id}>{item.name}</Option>
					)
				})

				for (const item of this.state.espacoProviders) {
					if (item._id === this.state.selectedProviderEspCat) {
						providerEspCatInfo = (
							//<Container style={{ paddingRight: 0, marginLeft: "1%", marginTop: "4%", marginRight: "2%", maxWidth: "-webkit-fill-available" }}>
								<Card style={{float:"left", width:"47%"}}>
									<CardContent>
										<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
											<h6 style={{ textAlign: "left" }}><b>Detalhes do Provider</b></h6>
										</Row>
										<Row className="inline-col" style={{ width: "-webkit-fill-available", marginBottom: "4%" }}>
											<Col sm="7">
												<p className="p-title-label">Nome</p>
												<p className="p-label">{item.name}</p>
											</Col>
											<Col sm="4">
												<p className="p-title-label">Contacto</p>
												<p className="p-label">{item.phone}</p>
											</Col>
										</Row>
										<Row className="inline-col" style={{ width: "-webkit-fill-available", marginBottom: "4%" }}>
											<Col>
												<p className="p-title-label">Email</p>
												<p className="p-label">{item.email}</p>
											</Col>
										</Row>
									</CardContent>
								</Card>
							//</Container>
						)
					}
				}

				if(this.state.espCatPacks !== null && this.state.espCatPacks !== undefined){
					providerEspCatPacks = this.state.espCatPacks.map((itm, index) => {
						return (
							<Option value={itm._id}>{itm.name}</Option>
						)
					})
				}

				for (const result of this.state.espCatPacks) {
					if (result._id === this.state.selectedPackEspCat) {
						detailsPackEspCat = (
							//<Container style={{ paddingRight: 0, marginLeft: "1%", marginRight: "2%", marginTop: "4%", maxWidth: "-webkit-fill-available" }}>
								<Card style={{float:"right", width:"47%"}}>
									<CardContent>
										<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
											<h6 style={{ textAlign: "left" }}><b>Detalhes do Pack</b></h6>
										</Row>
										<Row className="inline-col" style={{ width: "-webkit-fill-available" }}>
											<Col sm="3">
												<p className="p-title-label">Nome</p>
												<p className="p-label">{result.name}</p>
											</Col>
											<Col sm="5">
												<p className="p-title-label">Descrição</p>
												<p className="p-label">{result.desc}</p>
											</Col>
											<Col sm="2">
												{(result.priceBy === "pessoa") ? (
													<div>
														<p className="p-title-label">Preço</p>
														<p className="p-label">{result.price} €/PAX</p>
													</div>
												) : (
														<div>
															<p className="p-title-label">Preço</p>
															<p className="p-label">{result.price} €</p>
														</div>
													)}
											</Col>
											<Col sm="2">
												{(result.comissionType !== null && result.comissionType !== undefined) ? (
													<div>
														<p className="p-title-label">Comissão</p>
														{(result.comissionType === "euro") ? (
															<p className="p-label">{result.comission} €</p>
														) : (
															<p className="p-label">{result.comission} %</p>
														)}
													</div>
												) : (
													<div>
														<p className="p-title-label">Comissão</p>
														<p style={{textAlign: "center", fontSize: "14px"}}> - </p>
													</div>
												)}
											</Col>
										</Row>
										<Row style={{ marginTop: "4%" }}>
											<Col sm="3">
												<p className="p-title-label" style={{ textAlign: "right" }}>Valor acordado</p>
												{(this.state.espCatValue === undefined || this.state.espCatValue === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("espCatValue", el.target.value) }}></Input>
												) : (
													<Input value={this.state.espCatValue} onChange={(el) => { this.changeFormValue("espCatValue", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="4">
												<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold", color:"#FFFFFF"}}>.</p>
												<Radio.Group name="valueByEspCat" onChange={(el) => { this.changeFormValue("valueByEspCat", el.target.value) }} value={this.state.valueByEspCat}>
													<Radio value={"pessoa"}>Pessoa</Radio>
													<Radio value={"geral"}>Geral</Radio>
												</Radio.Group>
											</Col>
											<Col sm="2">
												<p className="p-title-label" style={{ textAlign: "right" }}>Comissão</p>
												{(this.state.comissionValueEspCat === undefined || this.state.commissionValueEspCat === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("comissionValueEspCat", el.target.value) }}></Input>
												) : (
													<Input value={this.state.comissionValueEspCat} onChange={(el) => { this.changeFormValue("comissionValueEspCat", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="3">
												<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold", color:"#FFFFFF"}}>.</p>
												<Radio.Group name="comissionByEspCat" onChange={(el) => { this.changeFormValue("comissionByEspCat", el.target.value) }} value={this.state.comissionByEspCat}>
													<Radio value={"pcent"}>%</Radio>
													<Radio value={"euro"}>€</Radio>
												</Radio.Group>
											</Col>
										</Row>
										<Row style={{ marginTop: "4%", justifyContent:"flex-end", marginRight:"0%"}}>
											<Col sm="3" style={{ justifyContent:"flex-start"}}>
												<p className="p-title-label" style={{ textAlign: "right" }}>Valor pago</p>
												{(this.state.paidValueEspCat === undefined || this.state.paidValueEspCat === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("paidValueEspCat", el.target.value) }}></Input>
												) : (
													<Input value={this.state.paidValueEspCat} onChange={(el) => { this.changeFormValue("paidValueEspCat", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="9" style={{textAlign:"end", marginRight:"0%", marginTop:"4%"}}>
												{(this.state.totalValues.length !== 0) ? (
													this.state.totalValues.map((value, index) => {
														if(value.role === "Espaço e Catering"){
															return(
																<div>
																	<span className="p-title-label">Total: </span>
																	{this.state.totalValues[index].total} €
																</div>
															)
														}
													})
												) : (
													null
												)}
											</Col>
										</Row>
									</CardContent>
								</Card>
							//</Container>
						)
					}
				}
			}

			//DADOS PARA SELECTS E CARDS - CATEGORIA ANIMAÇÃO
			//GRUPO MUSICAL
			if (this.state.grupoProviders !== null || this.state.packsGrupo !== null) {
				providerGrupo = this.state.grupoProviders.map((item, index) => {
					return (
						<Option value={item._id}>{item.name}</Option>
					)
				})

				for (const item of this.state.grupoProviders) {
					if (item._id === this.state.selectedProviderGrupo) {
						providerGrupoInfo = (
							//<Container style={{ paddingRight: 0, marginLeft: "1%", marginTop: "4%", marginRight: "2%", maxWidth: "-webkit-fill-available", maxHeight: "100%" }}>
								<Card style={{float:"left", width:"47%"}}>
									<CardContent>
										<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
											<h6 style={{ textAlign: "left" }}><b>Detalhes do Provider</b></h6>
										</Row>
										<Row className="inline-col" style={{ width: "-webkit-fill-available", marginBottom: "4%" }}>
											<Col sm="7">
												<p className="p-title-label">Nome</p>
												<p className="p-label">{item.name}</p>
											</Col>
											<Col sm="4">
												<p className="p-title-label">Contacto</p>
												<p className="p-label">{item.phone}</p>
											</Col>
										</Row>
										<Row className="inline-col" style={{ width: "-webkit-fill-available", marginBottom: "4%" }}>
											<Col>
												<p className="p-title-label">Email</p>
												<p className="p-label">{item.email}</p>
											</Col>
										</Row>
									</CardContent>
								</Card>
							//</Container>
						)
					}

				}

				providerGrupoPacks = this.state.packsGrupo.map((itm, index) => {
					if (this.state.selectedProviderGrupo !== null && this.state.selectedProviderGrupo !== undefined) {
						return (
							<Option value={itm._id}>{itm.name}</Option>
						)
					}
				})

				for (const result of this.state.packsGrupo) {
					if (result._id === this.state.selectedPackGrupo) {
						detailsPackGrupo = (
							//<Container style={{ paddingRight: 0, marginLeft: "1%", marginRight: "2%", marginTop: "4%", maxWidth: "-webkit-fill-available" }}>
								<Card style={{float:"right", width:"47%"}}>
									<CardContent>
										<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
											<h6 style={{ textAlign: "left" }}><b>Detalhes do Pack</b></h6>
										</Row>
										<Row className="inline-col" style={{ width: "-webkit-fill-available" }}>
											<Col sm="3">
												<p className="p-title-label">Nome</p>
												<p className="p-label">{result.name}</p>
											</Col>
											<Col sm="5">
												<p className="p-title-label">Descrição</p>
												<p className="p-label">{result.desc}</p>
											</Col>
											<Col sm="2">
												{(result.priceBy === "pessoa") ? (
													<div>
														<p className="p-title-label">Preço</p>
														<p className="p-label">{result.price} €/PAX</p>
													</div>
												) : (
														<div>
															<p className="p-title-label">Preço</p>
															<p className="p-label">{result.price} €</p>
														</div>
													)}
											</Col>
											<Col sm="2">
												{(result.comissionType !== null && result.comissionType !== undefined) ? (
													<div>
														<p className="p-title-label">Comissão</p>
														{(result.comissionType === "euro") ? (
															<p className="p-label">{result.comission} €</p>
														) : (
															<p className="p-label">{result.comission} %</p>
														)}
													</div>
												) : (
													<div>
														<p className="p-title-label">Comissão</p>
														<p style={{textAlign: "center", fontSize: "14px"}}> - </p>
													</div>
												)}
											</Col>
										</Row>
										<Row style={{ marginTop: "4%" }}>
											<Col sm="3">
												<p className="p-title-label" style={{ textAlign: "right" }}>Valor acordado</p>
												{(this.state.grupoValue === undefined || this.state.grupoValue === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("grupoValue", el.target.value) }}></Input>
												) : (
													<Input value={this.state.grupoValue} onChange={(el) => { this.changeFormValue("grupoValue", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="4">
												<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold", color:"#FFFFFF"}}>.</p>
												<Radio.Group name="valueByGrupo" onChange={(el) => { this.changeFormValue("valueByGrupo", el.target.value) }} value={this.state.valueByGrupo}>
													<Radio value={"pessoa"}>Pessoa</Radio>
													<Radio value={"geral"}>Geral</Radio>
												</Radio.Group>
											</Col>
											<Col sm="2">
												<p className="p-title-label" style={{ textAlign: "right" }}>Comissão</p>
												{(this.state.comissionValueGrupo === undefined || this.state.commissionValueGrupo === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("comissionValueGrupo", el.target.value) }}></Input>
												) : (
													<Input value={this.state.comissionValueGrupo} onChange={(el) => { this.changeFormValue("comissionValueGrupo", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="3">
												<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold", color:"#FFFFFF"}}>.</p>
												<Radio.Group name="comissionByGrupo" onChange={(el) => { this.changeFormValue("comissionByGrupo", el.target.value) }} value={this.state.comissionByGrupo}>
													<Radio value={"pcent"}>%</Radio>
													<Radio value={"euro"}>€</Radio>
												</Radio.Group>
											</Col>
										</Row>
										<Row style={{ marginTop: "4%", justifyContent:"flex-end", marginRight:"0%"}}>
											<Col sm="3" style={{ justifyContent:"flex-start"}}>
												<p className="p-title-label" style={{ textAlign: "right" }}>Valor pago</p>
												{(this.state.paidValueGrupo === undefined || this.state.paidValueGrupo === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("paidValueGrupo", el.target.value) }}></Input>
												) : (
													<Input value={this.state.paidValueGrupo} onChange={(el) => { this.changeFormValue("paidValueGrupo", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="9" style={{textAlign:"end", marginRight:"0%", marginTop:"4%"}}>
												{(this.state.totalValues.length !== 0) ? (
													this.state.totalValues.map((value, ix) => {
														if(value.role === "Grupo Musical"){
															return(
																<div>
																	<span className="p-title-label">Total: </span>
																	{this.state.totalValues[ix].total} €
																</div>
															)
														}
													})
												) : (
													null
												)}
											</Col>
										</Row>
									</CardContent>
								</Card>
							//</Container>
						)
					}
				}
			}

			//PERFORMERS
			if (this.state.performersProviders !== null || this.state.packsPerformers !== null) {

				providerPerformers = this.state.performersProviders.map((item, index) => {
					return (
						<Option value={item._id}>{item.name}</Option>
					)
				})

				for (const item of this.state.performersProviders) {
					if (item._id === this.state.selectedProviderPerformers) {
						providerPerformersInfo = (
							//<Container style={{ paddingRight: 0, marginLeft: "1%", marginTop: "4%", marginRight: "2%", maxWidth: "-webkit-fill-available" }}>
								<Card style={{float:"left", width:"47%"}}>
									<CardContent>
										<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
											<h6 style={{ textAlign: "left" }}><b>Detalhes do Provider</b></h6>
										</Row>
										<Row className="inline-col" style={{ width: "-webkit-fill-available", marginBottom: "4%" }}>
											<Col sm="7">
												<p className="p-title-label">Nome</p>
												<p className="p-label">{item.name}</p>
											</Col>
											<Col sm="4">
												<p className="p-title-label">Contacto</p>
												<p className="p-label">{item.phone}</p>
											</Col>
										</Row>
										<Row className="inline-col" style={{ width: "-webkit-fill-available", marginBottom: "4%" }}>
											<Col>
												<p className="p-title-label">Email</p>
												<p className="p-label">{item.email}</p>
											</Col>
										</Row>
									</CardContent>
								</Card>
							//</Container>
						)
					}
				}

				providerPerformersPacks = this.state.packsPerformers.map((itm, index) => {
					return (
						<Option value={itm._id}>{itm.name}</Option>
					)
				})

				for (const result of this.state.packsPerformers) {
					if (result._id === this.state.selectedPackPerformers) {
						detailsPackPerformers = (
							//<Container style={{ paddingRight: 0, marginLeft: "1%", marginRight: "2%", marginTop: "4%", maxWidth: "-webkit-fill-available" }}>
								<Card style={{float:"right", width:"47%"}}>
									<CardContent>
										<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
											<h6 style={{ textAlign: "left" }}><b>Detalhes do Pack</b></h6>
										</Row>
										<Row className="inline-col" style={{ width: "-webkit-fill-available" }}>
											<Col sm="3">
												<p className="p-title-label">Nome</p>
												<p className="p-label">{result.name}</p>
											</Col>
											<Col sm="5">
												<p className="p-title-label">Descrição</p>
												<p className="p-label">{result.desc}</p>
											</Col>
											<Col sm="2">
												{(result.priceBy === "pessoa") ? (
													<div>
														<p className="p-title-label">Preço</p>
														<p className="p-label">{result.price} €/PAX</p>
													</div>
												) : (
														<div>
															<p className="p-title-label">Preço</p>
															<p className="p-label">{result.price} €</p>
														</div>
													)}
											</Col>
											<Col sm="2">
												{(result.comissionType !== null && result.comissionType !== undefined) ? (
													<div>
														<p className="p-title-label">Comissão</p>
														{(result.comissionType === "euro") ? (
															<p className="p-label">{result.comission} €</p>
														) : (
															<p className="p-label">{result.comission} %</p>
														)}
													</div>
												) : (
													<div>
														<p className="p-title-label">Comissão</p>
														<p style={{textAlign: "center", fontSize: "14px"}}> - </p>
													</div>
												)}
											</Col>
										</Row>
										<Row style={{ marginTop: "4%" }}>
											<Col sm="3">
												<p className="p-title-label" style={{ textAlign: "right" }}>Valor acordado</p>
												{(this.state.performersValue === undefined || this.state.performersValue === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("performersValue", el.target.value) }}></Input>
												) : (
													<Input value={this.state.performersValue} onChange={(el) => { this.changeFormValue("performersValue", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="4">
												<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold", color:"#FFFFFF"}}>.</p>
												<Radio.Group name="valueByPerformers" onChange={(el) => { this.changeFormValue("valueByPerformers", el.target.value) }} value={this.state.valueByPerformers}>
													<Radio value={"pessoa"}>Pessoa</Radio>
													<Radio value={"geral"}>Geral</Radio>
												</Radio.Group>
											</Col>
											<Col sm="2">
												<p className="p-title-label" style={{ textAlign: "right" }}>Comissão</p>
												{(this.state.comissionValuePerformers === undefined || this.state.commissionValuePerformers === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("comissionValuePerformers", el.target.value) }}></Input>
												) : (
													<Input value={this.state.comissionValuePerformers} onChange={(el) => { this.changeFormValue("comissionValuePerformers", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="3">
												<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold", color:"#FFFFFF"}}>.</p>
												<Radio.Group name="comissionByPerformers" onChange={(el) => { this.changeFormValue("comissionByPerformers", el.target.value) }} value={this.state.comissionByPerformers}>
													<Radio value={"pcent"}>%</Radio>
													<Radio value={"euro"}>€</Radio>
												</Radio.Group>
											</Col>
										</Row>
										<Row style={{ marginTop: "4%", justifyContent:"flex-end", marginRight:"0%"}}>
											<Col sm="3" style={{ justifyContent:"flex-start"}}>
												<p className="p-title-label" style={{ textAlign: "right" }}>Valor pago</p>
												{(this.state.paidValuePerformers === undefined || this.state.paidValuePerformers === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("paidValuePerformers", el.target.value) }}></Input>
												) : (
													<Input value={this.state.paidValuePerformers} onChange={(el) => { this.changeFormValue("paidValuePerformers", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="9" style={{textAlign:"end", marginRight:"0%", marginTop:"4%"}}>
												{(this.state.totalValues.length !== 0) ? (
													this.state.totalValues.map((value, ix) => {
														if(value.role === "Performers"){
															return(
																<div>
																	<span className="p-title-label">Total: </span>
																	{this.state.totalValues[ix].total} €
																</div>
															)
														}
													})
												) : (
													null
												)}
											</Col>
										</Row>
									</CardContent>
								</Card>
							//</Container>
						)
					}

				}
			}

			//DJ
			if (this.state.djProviders !== null || this.state.packsDJ !== null) {

				providerDJ = this.state.djProviders.map((item, index) => {
					return (
						<Option value={item._id}>{item.name}</Option>
					)
				})

				for (const item of this.state.djProviders) {
					if (item._id === this.state.selectedProviderDJ) {
						providerDJInfo = (
							//<Container style={{ paddingRight: 0, marginLeft: "1%", marginTop: "4%", marginRight: "2%", maxWidth: "-webkit-fill-available" }}>
							<Card style={{float:"left", width:"47%"}}>
								<CardContent>
									<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
										<h6 style={{ textAlign: "left" }}><b>Detalhes do Provider</b></h6>
									</Row>
									<Row className="inline-col" style={{ width: "-webkit-fill-available", marginBottom: "4%" }}>
										<Col sm="7">
											<p className="p-title-label">Nome</p>
											<p className="p-label">{item.name}</p>
										</Col>
										<Col sm="4">
											<p className="p-title-label">Contacto</p>
											<p className="p-label">{item.phone}</p>
										</Col>
									</Row>
									<Row className="inline-col" style={{ width: "-webkit-fill-available", marginBottom: "4%" }}>
										<Col>
											<p className="p-title-label">Email</p>
											<p className="p-label">{item.email}</p>
										</Col>
									</Row>
								</CardContent>
							</Card>
							//</Container>
						)
					}
				}

				providerDJPacks = this.state.packsDJ.map((itm, index) => {
					return (
						<Option value={itm._id}>{itm.name}</Option>
					)
				})

				for (const result of this.state.packsDJ) {
					if (result._id === this.state.selectedPackDJ) {
						detailsPackDJ = (
							//<Container style={{ paddingRight: 0, marginLeft: "1%", marginRight: "2%", marginTop: "4%", maxWidth: "-webkit-fill-available" }}>
								<Card style={{float:"right", width:"47%"}}>
									<CardContent>
										<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
											<h6 style={{ textAlign: "left" }}><b>Detalhes do Pack</b></h6>
										</Row>
										<Row className="inline-col" style={{ width: "-webkit-fill-available" }}>
											<Col sm="3">
												<p className="p-title-label">Nome</p>
												<p className="p-label">{result.name}</p>
											</Col>
											<Col sm="5">
												<p className="p-title-label">Descrição</p>
												<p className="p-label">{result.desc}</p>
											</Col>
											<Col sm="2">
												{(result.priceBy === "pessoa") ? (
													<div>
														<p className="p-title-label">Preço</p>
														<p className="p-label">{result.price} €/PAX</p>
													</div>
												) : (
														<div>
															<p className="p-title-label">Preço</p>
															<p className="p-label">{result.price} €</p>
														</div>
													)}
											</Col>
											<Col sm="2">
												{(result.comissionType !== null && result.comissionType !== undefined) ? (
													<div>
														<p className="p-title-label">Comissão</p>
														{(result.comissionType === "euro") ? (
															<p className="p-label">{result.comission} €</p>
														) : (
															<p className="p-label">{result.comission} %</p>
														)}
													</div>
												) : (
													<div>
														<p className="p-title-label">Comissão</p>
														<p style={{textAlign: "center", fontSize: "14px"}}> - </p>
													</div>
												)}
											</Col>
										</Row>
										<Row style={{ marginTop: "4%" }}>
											<Col sm="3">
												<p className="p-title-label" style={{ textAlign: "right" }}>Valor acordado</p>
												{(this.state.djValue === undefined || this.state.djValue === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("djValue", el.target.value) }}></Input>
												) : (
													<Input value={this.state.djValue} onChange={(el) => { this.changeFormValue("djValue", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="4">
												<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold", color:"#FFFFFF"}}>.</p>
												<Radio.Group name="valueByDJ" onChange={(el) => { this.changeFormValue("valueByDJ", el.target.value) }} value={this.state.valueByDJ}>
													<Radio value={"pessoa"}>Pessoa</Radio>
													<Radio value={"geral"}>Geral</Radio>
												</Radio.Group>
											</Col>
											<Col sm="2">
												<p className="p-title-label" style={{ textAlign: "right" }}>Comissão</p>
												{(this.state.comissionValueDJ === undefined || this.state.commissionValueDJ === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("comissionValueDJ", el.target.value) }}></Input>
												) : (
													<Input value={this.state.comissionValueDJ} onChange={(el) => { this.changeFormValue("comissionValueDJ", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="3">
												<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold", color:"#FFFFFF"}}>.</p>
												<Radio.Group name="comissionByDJ" onChange={(el) => { this.changeFormValue("comissionByDJ", el.target.value) }} value={this.state.comissionByDJ}>
													<Radio value={"pcent"}>%</Radio>
													<Radio value={"euro"}>€</Radio>
												</Radio.Group>
											</Col>
										</Row>
										<Row style={{ marginTop: "4%", justifyContent:"flex-end", marginRight:"0%"}}>
											<Col sm="3" style={{ justifyContent:"flex-start"}}>
												<p className="p-title-label" style={{ textAlign: "right" }}>Valor pago</p>
												{(this.state.paidValueDJ === undefined || this.state.paidValueDJ === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("paidValueDJ", el.target.value) }}></Input>
												) : (
													<Input value={this.state.paidValueDJ} onChange={(el) => { this.changeFormValue("paidValueDJ", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="9" style={{textAlign:"end", marginRight:"0%", marginTop:"4%"}}>
												{(this.state.totalValues.length !== 0) ? (
													this.state.totalValues.map((value, ix) => {
														if(value.role === "DJ"){
															return(
																<div>
																	<span className="p-title-label">Total: </span>
																	{this.state.totalValues[ix].total} €
																</div>
															)
														}
													})
												) : (
													null
												)}
											</Col>
										</Row>
									</CardContent>
								</Card>
							//</Container>
						)
					}
				}
			}

			//BANDA 
			if (this.state.bandaProviders !== null || this.state.packsBanda !== null) {

				providerBanda = this.state.bandaProviders.map((item, index) => {
					return (
						<Option value={item._id}>{item.name}</Option>
					)
				})

				for (const item of this.state.bandaProviders) {
					if (item._id === this.state.selectedProviderBanda) {
						providerBandaInfo = (
							//<Container style={{ paddingRight: 0, marginLeft: "1%", marginTop: "4%", marginRight: "2%", maxWidth: "-webkit-fill-available" }}>
							<Card style={{float:"left", width:"47%"}}>
								<CardContent>
									<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
										<h6 style={{ textAlign: "left" }}><b>Detalhes do Provider</b></h6>
									</Row>
									<Row className="inline-col" style={{ width: "-webkit-fill-available", marginBottom: "4%" }}>
										<Col sm="7">
											<p className="p-title-label">Nome</p>
											<p className="p-label">{item.name}</p>
										</Col>
										<Col sm="4">
											<p className="p-title-label">Contacto</p>
											<p className="p-label">{item.phone}</p>
										</Col>
									</Row>
									<Row className="inline-col" style={{ width: "-webkit-fill-available", marginBottom: "4%" }}>
										<Col>
											<p className="p-title-label">Email</p>
											<p className="p-label">{item.email}</p>
										</Col>
									</Row>
								</CardContent>
							</Card>
							//</Container>
						)
					}
				}

				providerBandaPacks = this.state.packsBanda.map((itm, index) => {
					return (
						<Option value={itm._id}>{itm.name}</Option>
					)
				})

				for (const result of this.state.packsBanda) {
					if (result._id === this.state.selectedPackBanda) {
						detailsPackBanda = (
							//<Container style={{ paddingRight: 0, marginLeft: "1%", marginRight: "2%", marginTop: "4%", maxWidth: "-webkit-fill-available" }}>
								<Card style={{float:"right", width:"47%"}}>
									<CardContent>
										<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
											<h6 style={{ textAlign: "left" }}><b>Detalhes do Pack</b></h6>
										</Row>
										<Row className="inline-col" style={{ width: "-webkit-fill-available" }}>
											<Col sm="3">
												<p className="p-title-label">Nome</p>
												<p className="p-label">{result.name}</p>
											</Col>
											<Col sm="5">
												<p className="p-title-label">Descrição</p>
												<p className="p-label">{result.desc}</p>
											</Col>
											<Col sm="2">
												{(result.priceBy === "pessoa") ? (
													<div>
														<p className="p-title-label">Preço</p>
														<p className="p-label">{result.price} €/PAX</p>
													</div>
												) : (
														<div>
															<p className="p-title-label">Preço</p>
															<p className="p-label">{result.price} €</p>
														</div>
													)}
											</Col>
											<Col sm="2">
												{(result.comissionType !== null && result.comissionType !== undefined) ? (
													<div>
														<p className="p-title-label">Comissão</p>
														{(result.comissionType === "euro") ? (
															<p className="p-label">{result.comission} €</p>
														) : (
															<p className="p-label">{result.comission} %</p>
														)}
													</div>
												) : (
													<div>
														<p className="p-title-label">Comissão</p>
														<p style={{textAlign: "center", fontSize: "14px"}}> - </p>
													</div>
												)}
											</Col>
										</Row>
										<Row style={{ marginTop: "4%" }}>
											<Col sm="3">
												<p className="p-title-label" style={{ textAlign: "right" }}>Valor acordado</p>
												{(this.state.bandaValue === undefined || this.state.bandaValue === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("bandaValue", el.target.value) }}></Input>
												) : (
													<Input value={this.state.bandaValue} onChange={(el) => { this.changeFormValue("bandaValue", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="4">
												<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold", color:"#FFFFFF"}}>.</p>
												<Radio.Group name="valueByBanda" onChange={(el) => { this.changeFormValue("valueByBanda", el.target.value) }} value={this.state.valueByBanda}>
													<Radio value={"pessoa"}>Pessoa</Radio>
													<Radio value={"geral"}>Geral</Radio>
												</Radio.Group>
											</Col>
											<Col sm="2">
												<p className="p-title-label" style={{ textAlign: "right" }}>Comissão</p>
												{(this.state.comissionValueBanda === undefined || this.state.commissionValueBanda === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("comissionValueBanda", el.target.value) }}></Input>
												) : (
													<Input value={this.state.comissionValueBanda} onChange={(el) => { this.changeFormValue("comissionValueBanda", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="3">
												<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold", color:"#FFFFFF"}}>.</p>
												<Radio.Group name="comissionByBanda" onChange={(el) => { this.changeFormValue("comissionByBanda", el.target.value) }} value={this.state.comissionByBanda}>
													<Radio value={"pcent"}>%</Radio>
													<Radio value={"euro"}>€</Radio>
												</Radio.Group>
											</Col>
										</Row>
										<Row style={{ marginTop: "4%", justifyContent:"flex-end", marginRight:"0%"}}>
											<Col sm="3" style={{ justifyContent:"flex-start"}}>
												<p className="p-title-label" style={{ textAlign: "right" }}>Valor pago</p>
												{(this.state.paidValueBanda === undefined || this.state.paidValueBanda === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("paidValueBanda", el.target.value) }}></Input>
												) : (
													<Input value={this.state.paidValueBanda} onChange={(el) => { this.changeFormValue("paidValueBanda", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="9" style={{textAlign:"end", marginRight:"0%", marginTop:"4%"}}>
												{(this.state.totalValues.length !== 0) ? (
													this.state.totalValues.map((value, ix) => {
														if(value.role === "Banda"){
															return(
																<div>
																	<span className="p-title-label">Total: </span>
																	{this.state.totalValues[ix].total} €
																</div>
															)
														}
													})
												) : (
													null
												)}
											</Col>
										</Row>
									</CardContent>
								</Card>
							//</Container>
						)
					}
				}
			}

			//DADOS PARA SELECTS E CARDS - CATEGORIA PRÉ E PÓS EVENTO
			//PRÉ EVENTO
			if (this.state.preProviders !== null || this.state.prePacks !== null) {

				providerPre = this.state.preProviders.map((item, index) => {
					return (
						<Option value={item._id}>{item.name}</Option>
					)
				})

				for (const item of this.state.preProviders) {
					if (item._id === this.state.selectedProviderPre) {
						providerPreInfo = (
							//<Container style={{ paddingRight: 0, marginLeft: "1%", marginTop: "4%", marginRight: "2%", maxWidth: "-webkit-fill-available" }}>
								<Card style={{float:"right", width:"47%"}}>
									<CardContent>
										<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
											<h6 style={{ textAlign: "left" }}><b>Detalhes do Provider</b></h6>
										</Row>
										<Row className="inline-col" style={{ width: "-webkit-fill-available", marginBottom: "4%" }}>
											<Col sm="7">
												<p className="p-title-label">Nome</p>
												<p className="p-label">{item.name}</p>
											</Col>
											<Col sm="4">
												<p className="p-title-label">Contacto</p>
												<p className="p-label">{item.phone}</p>
											</Col>
										</Row>
										<Row className="inline-col" style={{ width: "-webkit-fill-available", marginBottom: "4%" }}>
											<Col>
												<p className="p-title-label">Email</p>
												<p className="p-label">{item.email}</p>
											</Col>
										</Row>
									</CardContent>
								</Card>
							//</Container>
						)
					}
				}

				providerPrePacks = this.state.prePacks.map((itm, index) => {
					return (
						<Option value={itm._id}>{itm.name}</Option>
					)
				})

				for (const result of this.state.prePacks) {
					if (result._id === this.state.selectedPackPre) {
						detailsPackPre = (
							//<Container style={{ paddingRight: 0, marginLeft: "1%", marginRight: "2%", marginTop: "4%", maxWidth: "-webkit-fill-available" }}>
								<Card style={{float:"right", width:"47%"}}>
									<CardContent>
										<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
											<h6 style={{ textAlign: "left" }}><b>Detalhes do Pack</b></h6>
										</Row>
										<Row className="inline-col" style={{ width: "-webkit-fill-available" }}>
											<Col sm="3">
												<p className="p-title-label">Nome</p>
												<p className="p-label">{result.name}</p>
											</Col>
											<Col sm="5">
												<p className="p-title-label">Descrição</p>
												<p className="p-label">{result.desc}</p>
											</Col>
											<Col sm="2">
												{(result.priceBy === "pessoa") ? (
													<div>
														<p className="p-title-label">Preço</p>
														<p className="p-label">{result.price} €/PAX</p>
													</div>
												) : (
														<div>
															<p className="p-title-label">Preço</p>
															<p className="p-label">{result.price} €</p>
														</div>
													)}
											</Col>
											<Col sm="2">
												{(result.comissionType !== null && result.comissionType !== undefined) ? (
													<div>
														<p className="p-title-label">Comissão</p>
														{(result.comissionType === "euro") ? (
															<p className="p-label">{result.comission} €</p>
														) : (
															<p className="p-label">{result.comission} %</p>
														)}
													</div>
												) : (
													<div>
														<p className="p-title-label">Comissão</p>
														<p style={{textAlign: "center", fontSize: "14px"}}> - </p>
													</div>
												)}
											</Col>
										</Row>
										<Row style={{ marginTop: "4%" }}>
											<Col sm="3">
												<p className="p-title-label" style={{ textAlign: "right" }}>Valor acordado</p>
												{(this.state.preValue === undefined || this.state.preValue === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("preValue", el.target.value) }}></Input>
												) : (
													<Input value={this.state.preValue} onChange={(el) => { this.changeFormValue("preValue", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="4">
												<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold", color:"#FFFFFF"}}>.</p>
												<Radio.Group name="valueByPre" onChange={(el) => { this.changeFormValue("valueByPre", el.target.value) }} value={this.state.valueByPre}>
													<Radio value={"pessoa"}>Pessoa</Radio>
													<Radio value={"geral"}>Geral</Radio>
												</Radio.Group>
											</Col>
											<Col sm="2">
												<p className="p-title-label" style={{ textAlign: "right" }}>Comissão</p>
												{(this.state.comissionValuePre === undefined || this.state.commissionValuePre === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("comissionValuePre", el.target.value) }}></Input>
												) : (
													<Input value={this.state.comissionValuePre} onChange={(el) => { this.changeFormValue("comissionValuePre", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="3">
												<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold", color:"#FFFFFF"}}>.</p>
												<Radio.Group name="comissionByPre" onChange={(el) => { this.changeFormValue("comissionByPre", el.target.value) }} value={this.state.comissionByPre}>
													<Radio value={"pcent"}>%</Radio>
													<Radio value={"euro"}>€</Radio>
												</Radio.Group>
											</Col>
										</Row>
										<Row style={{ marginTop: "4%", justifyContent:"flex-end", marginRight:"0%"}}>
											<Col sm="3" style={{ justifyContent:"flex-start"}}>
												<p className="p-title-label" style={{ textAlign: "right" }}>Valor pago</p>
												{(this.state.paidValuePre === undefined || this.state.paidValuePre === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("paidValuePre", el.target.value) }}></Input>
												) : (
													<Input value={this.state.paidValuePre} onChange={(el) => { this.changeFormValue("paidValuePre", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="9" style={{textAlign:"end", marginRight:"0%", marginTop:"4%"}}>
												{(this.state.totalValues.length !== 0) ? (
													this.state.totalValues.map((value, ix) => {
														if(value.role === "Pré Evento"){
															return(
																<div>
																	<span className="p-title-label">Total: </span>
																	{this.state.totalValues[ix].total} €
																</div>
															)
														}
													})
												) : (
													null
												)}
											</Col>
										</Row>
									</CardContent>
								</Card>
							//</Container>
						)
					}
				}
			}

			//PÓS EVENTO
			if (this.state.posProviders !== null || this.state.posPacks !== null) {

				providerPos = this.state.posProviders.map((item, index) => {
					return (
						<Option value={item._id}>{item.name}</Option>
					)
				})

				for (const item of this.state.posProviders) {
					if (item._id === this.state.selectedProviderPos) {
						providerPosInfo = (
							//<Container style={{ paddingRight: 0, marginLeft: "1%", marginTop: "4%", marginRight: "2%", maxWidth: "-webkit-fill-available" }}>
								<Card style={{float:"right", width:"47%"}}>
									<CardContent>
										<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
											<h6 style={{ textAlign: "left" }}><b>Detalhes do Provider</b></h6>
										</Row>
										<Row className="inline-col" style={{ width: "-webkit-fill-available", marginBottom: "4%" }}>
											<Col sm="7">
												<p className="p-title-label">Nome</p>
												<p className="p-label">{item.name}</p>
											</Col>
											<Col sm="4">
												<p className="p-title-label">Contacto</p>
												<p className="p-label">{item.phone}</p>
											</Col>
										</Row>
										<Row className="inline-col" style={{ width: "-webkit-fill-available", marginBottom: "4%" }}>
											<Col>
												<p className="p-title-label">Email</p>
												<p className="p-label">{item.email}</p>
											</Col>
										</Row>
									</CardContent>
								</Card>
							//</Container>
						)
					}
				}

				providerPosPacks = this.state.posPacks.map((itm, index) => {
					return (
						<Option value={itm._id}>{itm.name}</Option>
					)
				})

				for (const result of this.state.posPacks) {
					if (result._id === this.state.selectedPackPos) {
						detailsPackPos = (
							//<Container style={{ paddingRight: 0, marginLeft: "1%", marginRight: "2%", marginTop: "4%", maxWidth: "-webkit-fill-available" }}>
								<Card style={{float:"right", width:"47%"}}>
									<CardContent>
										<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
											<h6 style={{ textAlign: "left" }}><b>Detalhes do Pack</b></h6>
										</Row>
										<Row className="inline-col" style={{ width: "-webkit-fill-available" }}>
											<Col sm="3">
												<p className="p-title-label">Nome</p>
												<p className="p-label">{result.name}</p>
											</Col>
											<Col sm="5">
												<p className="p-title-label">Descrição</p>
												<p className="p-label">{result.desc}</p>
											</Col>
											<Col sm="2">
												{(result.priceBy === "pessoa") ? (
													<div>
														<p className="p-title-label">Preço</p>
														<p className="p-label">{result.price} €/PAX</p>
													</div>
												) : (
														<div>
															<p className="p-title-label">Preço</p>
															<p className="p-label">{result.price} €</p>
														</div>
													)}
											</Col>
											<Col sm="2">
												{(result.comissionType !== null && result.comissionType !== undefined) ? (
													<div>
														<p className="p-title-label">Comissão</p>
														{(result.comissionType === "euro") ? (
															<p className="p-label">{result.comission} €</p>
														) : (
															<p className="p-label">{result.comission} %</p>
														)}
													</div>
												) : (
													<div>
														<p className="p-title-label">Comissão</p>
														<p style={{textAlign: "center", fontSize: "14px"}}> - </p>
													</div>
												)}
											</Col>
										</Row>
										<Row style={{ marginTop: "4%" }}>
											<Col sm="3">
												<p className="p-title-label" style={{ textAlign: "right" }}>Valor acordado</p>
												{(this.state.posValue === undefined || this.state.posValue === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("posValue", el.target.value) }}></Input>
												) : (
													<Input value={this.state.posValue} onChange={(el) => { this.changeFormValue("posValue", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="4">
												<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold", color:"#FFFFFF"}}>.</p>
												<Radio.Group name="valueByPos" onChange={(el) => { this.changeFormValue("valueByPos", el.target.value) }} value={this.state.valueByPos}>
													<Radio value={"pessoa"}>Pessoa</Radio>
													<Radio value={"geral"}>Geral</Radio>
												</Radio.Group>
											</Col>
											<Col sm="2">
												<p className="p-title-label" style={{ textAlign: "right" }}>Comissão</p>
												{(this.state.comissionValuePos === undefined || this.state.commissionValuePos === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("comissionValuePos", el.target.value) }}></Input>
												) : (
													<Input value={this.state.comissionValuePos} onChange={(el) => { this.changeFormValue("comissionValuePos", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="3">
												<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold", color:"#FFFFFF"}}>.</p>
												<Radio.Group name="comissionByPos" onChange={(el) => { this.changeFormValue("comissionByPos", el.target.value) }} value={this.state.comissionByPos}>
													<Radio value={"pcent"}>%</Radio>
													<Radio value={"euro"}>€</Radio>
												</Radio.Group>
											</Col>
										</Row>
										<Row style={{ marginTop: "4%", justifyContent:"flex-end", marginRight:"0%"}}>
											<Col sm="3" style={{ justifyContent:"flex-start"}}>
												<p className="p-title-label" style={{ textAlign: "right" }}>Valor pago</p>
												{(this.state.paidValuePos === undefined || this.state.paidValuePos === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("paidValuePos", el.target.value) }}></Input>
												) : (
													<Input value={this.state.paidValuePos} onChange={(el) => { this.changeFormValue("paidValuePos", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="9" style={{textAlign:"end", marginRight:"0%", marginTop:"4%"}}>
												{(this.state.totalValues.length !== 0) ? (
													this.state.totalValues.map((value, ix) => {
														if(value.role === "Pós Evento"){
															return(
																<div>
																	<span className="p-title-label">Total: </span>
																	{this.state.totalValues[ix].total} €
																</div>
															)
														}
													})
												) : (
													null
												)}
											</Col>
										</Row>
									</CardContent>
								</Card>
							//</Container>
						)
					}
				}
			}

			//DADOS PARA SELECTS E CARDS - CATEGORIA PROCESSO RELIGIOSO
			if (this.state.padreProviders !== null || this.state.padrePacks !== null) {

				providerPadre = this.state.padreProviders.map((item, index) => {
					return (
						<Option value={item._id}>{item.name}</Option>
					)
				})

				for (const item of this.state.padreProviders) {
					if (item._id === this.state.selectedProviderPadre) {
						providerPadreInfo = (
							<Card style={{float:"right", width:"47%"}}>
								<CardContent>
									<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
										<h6 style={{ textAlign: "left" }}><b>Detalhes do Provider</b></h6>
									</Row>
									<Row className="inline-col" style={{ width: "-webkit-fill-available", marginBottom: "4%" }}>
										<Col sm="7">
											<p className="p-title-label">Nome</p>
											<p className="p-label">{item.name}</p>
										</Col>
										<Col sm="4">
											<p className="p-title-label">Contacto</p>
											<p className="p-label">{item.phone}</p>
										</Col>
									</Row>
									<Row className="inline-col" style={{ width: "-webkit-fill-available", marginBottom: "4%" }}>
										<Col>
											<p className="p-title-label">Email</p>
											<p className="p-label">{item.email}</p>
										</Col>
									</Row>
								</CardContent>
							</Card>
						)
					}
				}

				providerPadrePacks = this.state.padrePacks.map((itm, index) => {
					return (
						<Option value={itm._id}>{itm.name}</Option>
					)
				})

				for (const result of this.state.padrePacks) {
					if (result._id === this.state.selectedPackPadre) {
						detailsPackPadre = (
								<Card style={{float:"right", width:"47%"}}>
									<CardContent>
										<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
											<h6 style={{ textAlign: "left" }}><b>Detalhes do Pack</b></h6>
										</Row>
										<Row className="inline-col" style={{ width: "-webkit-fill-available" }}>
											<Col sm="3">
												<p className="p-title-label">Nome</p>
												<p className="p-label">{result.name}</p>
											</Col>
											<Col sm="5">
												<p className="p-title-label">Descrição</p>
												<p className="p-label">{result.desc}</p>
											</Col>
											<Col sm="2">
												{(result.priceBy === "pessoa") ? (
													<div>
														<p className="p-title-label">Preço</p>
														<p className="p-label">{result.price} €/PAX</p>
													</div>
												) : (
														<div>
															<p className="p-title-label">Preço</p>
															<p className="p-label">{result.price} €</p>
														</div>
													)}
											</Col>
											<Col sm="2">
												{(result.comissionType !== null && result.comissionType !== undefined) ? (
													<div>
														<p className="p-title-label">Comissão</p>
														{(result.comissionType === "euro") ? (
															<p className="p-label">{result.comission} €</p>
														) : (
															<p className="p-label">{result.comission} %</p>
														)}
													</div>
												) : (
													<div>
														<p className="p-title-label">Comissão</p>
														<p style={{textAlign: "center", fontSize: "14px"}}> - </p>
													</div>
												)}
											</Col>
										</Row>
										<Row style={{ marginTop: "4%" }}>
											<Col sm="3">
												<p className="p-title-label" style={{ textAlign: "right" }}>Valor acordado</p>
												{(this.state.padreValue === undefined || this.state.padreValue === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("padreValue", el.target.value) }}></Input>
												) : (
													<Input value={this.state.padreValue} onChange={(el) => { this.changeFormValue("padreValue", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="4">
												<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold", color:"#FFFFFF"}}>.</p>
												<Radio.Group name="valueByPadre" onChange={(el) => { this.changeFormValue("valueByPadre", el.target.value) }} value={this.state.valueByPadre}>
													<Radio value={"pessoa"}>Pessoa</Radio>
													<Radio value={"geral"}>Geral</Radio>
												</Radio.Group>
											</Col>
											<Col sm="2">
												<p className="p-title-label" style={{ textAlign: "right" }}>Comissão</p>
												{(this.state.comissionValuePadre === undefined || this.state.commissionValuePadre === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("comissionValuePadre", el.target.value) }}></Input>
												) : (
													<Input value={this.state.comissionValuePadre} onChange={(el) => { this.changeFormValue("comissionValuePadre", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="3">
												<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold", color:"#FFFFFF"}}>.</p>
												<Radio.Group name="comissionByPadre" onChange={(el) => { this.changeFormValue("comissionByPadre", el.target.value) }} value={this.state.comissionByPadre}>
													<Radio value={"pcent"}>%</Radio>
													<Radio value={"euro"}>€</Radio>
												</Radio.Group>
											</Col>
										</Row>
										<Row style={{ marginTop: "4%", justifyContent:"flex-end", marginRight:"0%"}}>
											<Col sm="3" style={{ justifyContent:"flex-start"}}>
												<p className="p-title-label" style={{ textAlign: "right" }}>Valor pago</p>
												{(this.state.paidValuePadre === undefined || this.state.paidValuePadre === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("paidValuePadre", el.target.value) }}></Input>
												) : (
													<Input value={this.state.paidValuePadre} onChange={(el) => { this.changeFormValue("paidValuePadre", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="9" style={{textAlign:"end", marginRight:"0%", marginTop:"4%"}}>
												{(this.state.totalValues.length !== 0) ? (
													this.state.totalValues.map((value, ix) => {
														if(value.role === "Padre"){
															return(
																<div>
																	<span className="p-title-label">Total: </span>
																	{this.state.totalValues[ix].total} €
																</div>
															)
														}
													})
												) : (
													null
												)}
											</Col>
										</Row>
									</CardContent>
								</Card>
						)
					}
				}
			}

			//DADOS PARA SELECTS E CARDS - CATEGORIA SERVIÇOS EXTRA
			//BABYSITTER
			if (this.state.babysitterProviders !== null || this.state.babysitterPacks !== null) {

				providerBabysitter = this.state.babysitterProviders.map((item, index) => {
					return (
						<Option value={item._id}>{item.name}</Option>
					)
				})

				for (const item of this.state.babysitterProviders) {
					if (item._id === this.state.selectedProviderBabysitter) {
						providerBabysitterInfo = (
							<Card style={{float:"right", width:"47%"}}>
								<CardContent>
									<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
										<h6 style={{ textAlign: "left" }}><b>Detalhes do Provider</b></h6>
									</Row>
									<Row className="inline-col" style={{ width: "-webkit-fill-available", marginBottom: "4%" }}>
										<Col sm="7">
											<p className="p-title-label">Nome</p>
											<p className="p-label">{item.name}</p>
										</Col>
										<Col sm="4">
											<p className="p-title-label">Contacto</p>
											<p className="p-label">{item.phone}</p>
										</Col>
									</Row>
									<Row className="inline-col" style={{ width: "-webkit-fill-available", marginBottom: "4%" }}>
										<Col>
											<p className="p-title-label">Email</p>
											<p className="p-label">{item.email}</p>
										</Col>
									</Row>
								</CardContent>
							</Card>
						)
					}
				}

				providerBabysitterPacks = this.state.babysitterPacks.map((itm, index) => {
					return (
						<Option value={itm._id}>{itm.name}</Option>
					)
				})

				for (const result of this.state.babysitterPacks) {
					if (result._id === this.state.selectedPackBabysitter) {
						detailsPackBabysitter = (
							//<Container style={{ paddingRight: 0, marginLeft: "1%", marginRight: "2%", marginTop: "4%", maxWidth: "-webkit-fill-available" }}>
								<Card style={{float:"right", width:"47%"}}>
									<CardContent>
										<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
											<h6 style={{ textAlign: "left" }}><b>Detalhes do Pack</b></h6>
										</Row>
										<Row className="inline-col" style={{ width: "-webkit-fill-available" }}>
											<Col sm="3">
												<p className="p-title-label">Nome</p>
												<p className="p-label">{result.name}</p>
											</Col>
											<Col sm="5">
												<p className="p-title-label">Descrição</p>
												<p className="p-label">{result.desc}</p>
											</Col>
											<Col sm="2">
												{(result.priceBy === "pessoa") ? (
													<div>
														<p className="p-title-label">Preço</p>
														<p className="p-label">{result.price} €/PAX</p>
													</div>
												) : (
														<div>
															<p className="p-title-label">Preço</p>
															<p className="p-label">{result.price} €</p>
														</div>
													)}
											</Col>
											<Col sm="2">
												{(result.comissionType !== null && result.comissionType !== undefined) ? (
													<div>
														<p className="p-title-label">Comissão</p>
														{(result.comissionType === "euro") ? (
															<p className="p-label">{result.comission} €</p>
														) : (
															<p className="p-label">{result.comission} %</p>
														)}
													</div>
												) : (
													<div>
														<p className="p-title-label">Comissão</p>
														<p style={{textAlign: "center", fontSize: "14px"}}> - </p>
													</div>
												)}
											</Col>
										</Row>
										<Row style={{ marginTop: "4%" }}>
											<Col sm="3">
												<p className="p-title-label" style={{ textAlign: "right" }}>Valor acordado</p>
												{(this.state.babysitterValue === undefined || this.state.babysitterValue === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("babysitterValue", el.target.value) }}></Input>
												) : (
													<Input value={this.state.babysitterValue} onChange={(el) => { this.changeFormValue("babysitterValue", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="4">
												<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold", color:"#FFFFFF"}}>.</p>
												<Radio.Group name="valueByBaby" onChange={(el) => { this.changeFormValue("valueByBaby", el.target.value) }} value={this.state.valueByBaby}>
													<Radio value={"pessoa"}>Pessoa</Radio>
													<Radio value={"geral"}>Geral</Radio>
												</Radio.Group>
											</Col>
											<Col sm="2">
												<p className="p-title-label" style={{ textAlign: "right" }}>Comissão</p>
												{(this.state.comissionValueBaby === undefined || this.state.commissionValueBaby === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("comissionValueBaby", el.target.value) }}></Input>
												) : (
													<Input value={this.state.comissionValueBaby} onChange={(el) => { this.changeFormValue("comissionValueBaby", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="3">
												<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold", color:"#FFFFFF"}}>.</p>
												<Radio.Group name="comissionByBaby" onChange={(el) => { this.changeFormValue("comissionByBaby", el.target.value) }} value={this.state.comissionByBaby}>
													<Radio value={"pcent"}>%</Radio>
													<Radio value={"euro"}>€</Radio>
												</Radio.Group>
											</Col>
										</Row>
										<Row style={{ marginTop: "4%", justifyContent:"flex-end", marginRight:"0%"}}>
											<Col sm="3" style={{ justifyContent:"flex-start"}}>
												<p className="p-title-label" style={{ textAlign: "right" }}>Valor pago</p>
												{(this.state.paidValueBaby === undefined || this.state.paidValueBaby === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("paidValueBaby", el.target.value) }}></Input>
												) : (
													<Input value={this.state.paidValueBaby} onChange={(el) => { this.changeFormValue("paidValueBaby", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="9" style={{textAlign:"end", marginRight:"0%", marginTop:"4%"}}>
												{(this.state.totalValues.length !== 0) ? (
													this.state.totalValues.map((value, ix) => {
														if(value.role === "Babysitter"){
															return(
																<div>
																	<span className="p-title-label">Total: </span>
																	{this.state.totalValues[ix].total} €
																</div>
															)
														}
													})
												) : (
													null
												)}
											</Col>
										</Row>
									</CardContent>
								</Card>
							//</Container>
						)
					}
				}
			}

			//LEMBRANÇAS
			if (this.state.lembrancasProviders !== null || this.state.lembrancasPacks !== null) {

				providerLembrancas = this.state.lembrancasProviders.map((item, index) => {
					return (
						<Option value={item._id}>{item.name}</Option>
					)
				})

				for (const item of this.state.lembrancasProviders) {
					if (item._id === this.state.selectedProviderLembrancas) {
						providerLembrancasInfo = (
							<Card style={{float:"left", width:"47%"}}>
								<CardContent>
									<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
										<h6 style={{ textAlign: "left" }}><b>Detalhes do Provider</b></h6>
									</Row>
									<Row className="inline-col" style={{ width: "-webkit-fill-available", marginBottom: "4%" }}>
										<Col sm="7">
											<p className="p-title-label">Nome</p>
											<p className="p-label">{item.name}</p>
										</Col>
										<Col sm="4">
											<p className="p-title-label">Contacto</p>
											<p className="p-label">{item.phone}</p>
										</Col>
									</Row>
									<Row className="inline-col" style={{ width: "-webkit-fill-available", marginBottom: "4%" }}>
										<Col>
											<p className="p-title-label">Email</p>
											<p className="p-label">{item.email}</p>
										</Col>
									</Row>
								</CardContent>
							</Card>
						)
					}
				}

				providerLembrancasPacks = this.state.lembrancasPacks.map((itm, index) => {
					return (
						<Option value={itm._id}>{itm.name}</Option>
					)
				})

				for (const result of this.state.lembrancasPacks) {
					if (result._id === this.state.selectedPackLembrancas) {
						detailsPackLembrancas = (
							//<Container style={{ paddingRight: 0, marginLeft: "1%", marginRight: "2%", marginTop: "4%", maxWidth: "-webkit-fill-available" }}>
								<Card style={{float:"right", width:"47%"}}>
									<CardContent>
										<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
											<h6 style={{ textAlign: "left" }}><b>Detalhes do Pack</b></h6>
										</Row>
										<Row className="inline-col" style={{ width: "-webkit-fill-available" }}>
											<Col sm="3">
												<p className="p-title-label">Nome</p>
												<p className="p-label">{result.name}</p>
											</Col>
											<Col sm="5">
												<p className="p-title-label">Descrição</p>
												<p className="p-label">{result.desc}</p>
											</Col>
											<Col sm="2">
												{(result.priceBy === "pessoa") ? (
													<div>
														<p className="p-title-label">Preço</p>
														<p className="p-label">{result.price} €/PAX</p>
													</div>
												) : (
														<div>
															<p className="p-title-label">Preço</p>
															<p className="p-label">{result.price} €</p>
														</div>
													)}
											</Col>
											<Col sm="2">
												{(result.comissionType !== null && result.comissionType !== undefined) ? (
													<div>
														<p className="p-title-label">Comissão</p>
														{(result.comissionType === "euro") ? (
															<p className="p-label">{result.comission} €</p>
														) : (
															<p className="p-label">{result.comission} %</p>
														)}
													</div>
												) : (
													<div>
														<p className="p-title-label">Comissão</p>
														<p style={{textAlign: "center", fontSize: "14px"}}> - </p>
													</div>
												)}
											</Col>
										</Row>
										<Row style={{ marginTop: "4%" }}>
											<Col sm="3">
												<p className="p-title-label" style={{ textAlign: "right" }}>Valor acordado</p>
												{(this.state.lembrancasValue === undefined || this.state.lembrancasValue === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("lembrancasValue", el.target.value) }}></Input>
												) : (
													<Input value={this.state.lembrancasValue} onChange={(el) => { this.changeFormValue("lembrancasValue", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="4">
												<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold", color:"#FFFFFF"}}>.</p>
												<Radio.Group name="valueByLembrancas" onChange={(el) => { this.changeFormValue("valueByLembrancas", el.target.value) }} value={this.state.valueByLembrancas}>
													<Radio value={"pessoa"}>Pessoa</Radio>
													<Radio value={"geral"}>Geral</Radio>
												</Radio.Group>
											</Col>
											<Col sm="2">
												<p className="p-title-label" style={{ textAlign: "right" }}>Comissão</p>
												{(this.state.comissionValueLembrancas === undefined || this.state.commissionValueLembrancas === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("comissionValueLembrancas", el.target.value) }}></Input>
												) : (
													<Input value={this.state.comissionValueLembrancas} onChange={(el) => { this.changeFormValue("comissionValueLembrancas", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="3">
												<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold", color:"#FFFFFF"}}>.</p>
												<Radio.Group name="comissionByLembrancas" onChange={(el) => { this.changeFormValue("comissionByLembrancas", el.target.value) }} value={this.state.comissionByLembrancas}>
													<Radio value={"pcent"}>%</Radio>
													<Radio value={"euro"}>€</Radio>
												</Radio.Group>
											</Col>
										</Row>
										<Row style={{ marginTop: "4%", justifyContent:"flex-end", marginRight:"0%"}}>
											<Col sm="3" style={{ justifyContent:"flex-start"}}>
												<p className="p-title-label" style={{ textAlign: "right" }}>Valor pago</p>
												{(this.state.paidValueLembrancas === undefined || this.state.paidValueLembrancas === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("paidValueLembrancas", el.target.value) }}></Input>
												) : (
													<Input value={this.state.paidValueLembrancas} onChange={(el) => { this.changeFormValue("paidValueLembrancas", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="9" style={{textAlign:"end", marginRight:"0%", marginTop:"4%"}}>
												{(this.state.totalValues.length !== 0) ? (
													this.state.totalValues.map((value, ix) => {
														if(value.role === "Lembranças"){
															return(
																<div>
																	<span className="p-title-label">Total: </span>
																	{this.state.totalValues[ix].total} €
																</div>
															)
														}
													})
												) : (
													null
												)}
											</Col>
										</Row>
									</CardContent>
								</Card>
							//</Container>
						)
					}
				}
			}

			//TRANSPORTE DE CONVIDADOS
			if (this.state.transporteProviders !== null || this.state.transporteProviders !== null) {

				providerTransporte = this.state.transporteProviders.map((item, index) => {
					return (
						<Option value={item._id}>{item.name}</Option>
					)
				})

				for (const item of this.state.transporteProviders) {
					if (item._id === this.state.selectedProviderTransporte) {
						providerTransporteInfo = (
							<Card style={{float:"left", width:"47%"}}>
								<CardContent>
									<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
										<h6 style={{ textAlign: "left" }}><b>Detalhes do Provider</b></h6>
									</Row>
									<Row className="inline-col" style={{ width: "-webkit-fill-available", marginBottom: "4%" }}>
										<Col sm="7">
											<p className="p-title-label">Nome</p>
											<p className="p-label">{item.name}</p>
										</Col>
										<Col sm="4">
											<p className="p-title-label">Contacto</p>
											<p className="p-label">{item.phone}</p>
										</Col>
									</Row>
									<Row className="inline-col" style={{ width: "-webkit-fill-available", marginBottom: "4%" }}>
										<Col>
											<p className="p-title-label">Email</p>
											<p className="p-label">{item.email}</p>
										</Col>
									</Row>
								</CardContent>
							</Card>
						)
					}
				}

				providerTransportePacks = this.state.transportePacks.map((itm, index) => {
					return (
						<Option value={itm._id}>{itm.name}</Option>
					)
				})

				for (const result of this.state.transportePacks) {
					if (result._id === this.state.selectedPackTransporte) {
						detailsPackTransporte = (
							//<Container style={{ paddingRight: 0, marginLeft: "1%", marginRight: "2%", marginTop: "4%", maxWidth: "-webkit-fill-available" }}>
								<Card style={{float:"right", width:"47%"}}>
									<CardContent>
										<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
											<h6 style={{ textAlign: "left" }}><b>Detalhes do Pack</b></h6>
										</Row>
										<Row className="inline-col" style={{ width: "-webkit-fill-available" }}>
											<Col sm="3">
												<p className="p-title-label">Nome</p>
												<p className="p-label">{result.name}</p>
											</Col>
											<Col sm="5">
												<p className="p-title-label">Descrição</p>
												<p className="p-label">{result.desc}</p>
											</Col>
											<Col sm="2">
												{(result.priceBy === "pessoa") ? (
													<div>
														<p className="p-title-label">Preço</p>
														<p className="p-label">{result.price} €/PAX</p>
													</div>
												) : (
														<div>
															<p className="p-title-label">Preço</p>
															<p className="p-label">{result.price} €</p>
														</div>
													)}
											</Col>
											<Col sm="2">
												{(result.comissionType !== null && result.comissionType !== undefined) ? (
													<div>
														<p className="p-title-label">Comissão</p>
														{(result.comissionType === "euro") ? (
															<p className="p-label">{result.comission} €</p>
														) : (
															<p className="p-label">{result.comission} %</p>
														)}
													</div>
												) : (
													<div>
														<p className="p-title-label">Comissão</p>
														<p style={{textAlign: "center", fontSize: "14px"}}> - </p>
													</div>
												)}
											</Col>
										</Row>
										<Row style={{ marginTop: "4%" }}>
											<Col sm="3">
												<p className="p-title-label" style={{ textAlign: "right" }}>Valor acordado</p>
												{(this.state.transporteValue === undefined || this.state.transporteValue === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("transporteValue", el.target.value) }}></Input>
												) : (
													<Input value={this.state.transporteValue} onChange={(el) => { this.changeFormValue("transporteValue", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="4">
												<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold", color:"#FFFFFF"}}>.</p>
												<Radio.Group name="valueByTransporte" onChange={(el) => { this.changeFormValue("valueByTransporte", el.target.value) }} value={this.state.valueByTransporte}>
													<Radio value={"pessoa"}>Pessoa</Radio>
													<Radio value={"geral"}>Geral</Radio>
												</Radio.Group>
											</Col>
											<Col sm="2">
												<p className="p-title-label" style={{ textAlign: "right" }}>Comissão</p>
												{(this.state.comissionValueTransporte === undefined || this.state.commissionValueTransporte === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("comissionValueTransporte", el.target.value) }}></Input>
												) : (
													<Input value={this.state.comissionValueTransporte} onChange={(el) => { this.changeFormValue("comissionValueTransporte", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="3">
												<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold", color:"#FFFFFF"}}>.</p>
												<Radio.Group name="comissionByTransporte" onChange={(el) => { this.changeFormValue("comissionByTransporte", el.target.value) }} value={this.state.comissionByTransporte}>
													<Radio value={"pcent"}>%</Radio>
													<Radio value={"euro"}>€</Radio>
												</Radio.Group>
											</Col>
										</Row>
										<Row style={{ marginTop: "4%", justifyContent:"flex-end", marginRight:"0%"}}>
											<Col sm="3" style={{ justifyContent:"flex-start"}}>
												<p className="p-title-label" style={{ textAlign: "right" }}>Valor pago</p>
												{(this.state.paidValueTransporte === undefined || this.state.paidValueTransporte === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("paidValueTransporte", el.target.value) }}></Input>
												) : (
													<Input value={this.state.paidValueTransporte} onChange={(el) => { this.changeFormValue("paidValueTransporte", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="9" style={{textAlign:"end", marginRight:"0%", marginTop:"4%"}}>
												{(this.state.totalValues.length !== 0) ? (
													this.state.totalValues.map((value, ix) => {
														if(value.role === "Transporte Convidados"){
															return(
																<div>
																	<span className="p-title-label">Total: </span>
																	{this.state.totalValues[ix].total} €
																</div>
															)
														}
													})
												) : (
													null
												)}
											</Col>
										</Row>
									</CardContent>
								</Card>
							//</Container>
						)
					}
				}
			}

			//AULAS DE DANÇA
			if (this.state.aulasProviders !== null || this.state.aulasProviders !== null) {

				providerAulas = this.state.aulasProviders.map((item, index) => {
					return (
						<Option value={item._id}>{item.name}</Option>
					)
				})

				for (const item of this.state.aulasProviders) {
					if (item._id === this.state.selectedProviderAulas) {
						providerAulasInfo = (
							<Card style={{float:"left", width:"47%"}}>
								<CardContent>
									<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
										<h6 style={{ textAlign: "left" }}><b>Detalhes do Provider</b></h6>
									</Row>
									<Row className="inline-col" style={{ width: "-webkit-fill-available", marginBottom: "4%" }}>
										<Col sm="7">
											<p className="p-title-label">Nome</p>
											<p className="p-label">{item.name}</p>
										</Col>
										<Col sm="4">
											<p className="p-title-label">Contacto</p>
											<p className="p-label">{item.phone}</p>
										</Col>
									</Row>
									<Row className="inline-col" style={{ width: "-webkit-fill-available", marginBottom: "4%" }}>
										<Col>
											<p className="p-title-label">Email</p>
											<p className="p-label">{item.email}</p>
										</Col>
									</Row>
								</CardContent>
							</Card>
						)
					}
				}

				providerAulasPacks = this.state.aulasPacks.map((itm, index) => {
					return (
						<Option value={itm._id}>{itm.name}</Option>
					)
				})

				for (const result of this.state.aulasPacks) {
					if (result._id === this.state.selectedPackAulas) {
						detailsPackAulas = (
							//<Container style={{ paddingRight: 0, marginLeft: "1%", marginRight: "2%", marginTop: "4%", maxWidth: "-webkit-fill-available" }}>
								<Card style={{float:"right", width:"47%"}}>
									<CardContent>
										<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
											<h6 style={{ textAlign: "left" }}><b>Detalhes do Pack</b></h6>
										</Row>
										<Row className="inline-col" style={{ width: "-webkit-fill-available" }}>
											<Col sm="3">
												<p className="p-title-label">Nome</p>
												<p className="p-label">{result.name}</p>
											</Col>
											<Col sm="5">
												<p className="p-title-label">Descrição</p>
												<p className="p-label">{result.desc}</p>
											</Col>
											<Col sm="2">
												{(result.priceBy === "pessoa") ? (
													<div>
														<p className="p-title-label">Preço</p>
														<p className="p-label">{result.price} €/PAX</p>
													</div>
												) : (
														<div>
															<p className="p-title-label">Preço</p>
															<p className="p-label">{result.price} €</p>
														</div>
													)}
											</Col>
											<Col sm="2">
												{(result.comissionType !== null && result.comissionType !== undefined) ? (
													<div>
														<p className="p-title-label">Comissão</p>
														{(result.comissionType === "euro") ? (
															<p className="p-label">{result.comission} €</p>
														) : (
															<p className="p-label">{result.comission} %</p>
														)}
													</div>
												) : (
													<div>
														<p className="p-title-label">Comissão</p>
														<p style={{textAlign: "center", fontSize: "14px"}}> - </p>
													</div>
												)}
											</Col>
										</Row>
										<Row style={{ marginTop: "4%" }}>
											<Col sm="3">
												<p className="p-title-label" style={{ textAlign: "right" }}>Valor acordado</p>
												{(this.state.aulasValue === undefined || this.state.aulasValue === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("aulasValue", el.target.value) }}></Input>
												) : (
													<Input value={this.state.aulasValue} onChange={(el) => { this.changeFormValue("aulasValue", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="4">
												<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold", color:"#FFFFFF"}}>.</p>
												<Radio.Group name="valueByAulas" onChange={(el) => { this.changeFormValue("valueByAulas", el.target.value) }} value={this.state.valueByAulas}>
													<Radio value={"pessoa"}>Pessoa</Radio>
													<Radio value={"geral"}>Geral</Radio>
												</Radio.Group>
											</Col>
											<Col sm="2">
												<p className="p-title-label" style={{ textAlign: "right" }}>Comissão</p>
												{(this.state.comissionValueAulas === undefined || this.state.commissionValueAulas === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("comissionValueAulas", el.target.value) }}></Input>
												) : (
													<Input value={this.state.comissionValueAulas} onChange={(el) => { this.changeFormValue("comissionValueAulas", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="3">
												<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold", color:"#FFFFFF"}}>.</p>
												<Radio.Group name="comissionByAulas" onChange={(el) => { this.changeFormValue("comissionByAulas", el.target.value) }} value={this.state.comissionByAulas}>
													<Radio value={"pcent"}>%</Radio>
													<Radio value={"euro"}>€</Radio>
												</Radio.Group>
											</Col>
										</Row>
										<Row style={{ marginTop: "4%", justifyContent:"flex-end", marginRight:"0%"}}>
											<Col sm="3" style={{ justifyContent:"flex-start"}}>
												<p className="p-title-label" style={{ textAlign: "right" }}>Valor pago</p>
												{(this.state.paidValueAulas === undefined || this.state.paidValueAulas === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("paidValueAulas", el.target.value) }}></Input>
												) : (
													<Input value={this.state.paidValueAulas} onChange={(el) => { this.changeFormValue("paidValueAulas", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="9" style={{textAlign:"end", marginRight:"0%", marginTop:"4%"}}>
												{(this.state.totalValues.length !== 0) ? (
													this.state.totalValues.map((value, ix) => {
														if(value.role === "Aulas de Dança"){
															return(
																<div>
																	<span className="p-title-label">Total: </span>
																	{this.state.totalValues[ix].total} €
																</div>
															)
														}
													})
												) : (
													null
												)}
											</Col>
										</Row>
									</CardContent>
								</Card>
							//</Container>
						)
					}
				}
			}

			//DADOS PARA SELECTS E CARD - CATEGORIA FOTOGRAFIA E VIDEO
			//FOTOGRAFIA
			if (this.state.fvProviders !== null  && this.state.fvProviders !== undefined) {

				providerFoto = this.state.fvProviders.map((item, index) => {
					return (
						<Option value={item._id}>{item.name}</Option>
					)
				})

				for (const item of this.state.fvProviders) {
					if (item._id === this.state.selectedProviderFoto) {
						providerFotoInfo = (
							<Card style={{float:"left", width:"47%"}}>
								<CardContent>
									<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
										<h6 style={{ textAlign: "left" }}><b>Detalhes do Provider</b></h6>
									</Row>
									<Row className="inline-col" style={{ width: "-webkit-fill-available", marginBottom: "4%" }}>
										<Col sm="7">
											<p className="p-title-label">Nome</p>
											<p className="p-label">{item.name}</p>
										</Col>
										<Col sm="4">
											<p className="p-title-label">Contacto</p>
											<p className="p-label">{item.phone}</p>
										</Col>
									</Row>
									<Row className="inline-col" style={{ width: "-webkit-fill-available", marginBottom: "4%" }}>
										<Col>
											<p className="p-title-label">Email</p>
											<p className="p-label">{item.email}</p>
										</Col>
									</Row>
								</CardContent>
							</Card>
						)
					}
				}

				if(this.state.fotoPacks !== null && this.state.fotoPacks !== undefined){
					providerFotoPacks = this.state.fotoPacks.map((itm, index) => {
						return (
							<Option value={itm._id}>{itm.name}</Option>
						)
					})
				}

				for (const result of this.state.fotoPacks) {
					if (result._id === this.state.selectedPackFoto) {
						detailsPackFoto = (
							//<Container style={{ paddingRight: 0, marginLeft: "1%", marginRight: "2%", marginTop: "4%", maxWidth: "-webkit-fill-available" }}>
								<Card style={{float:"right", width:"47%"}}>
									<CardContent>
										<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
											<h6 style={{ textAlign: "left" }}><b>Detalhes do Pack</b></h6>
										</Row>
										<Row className="inline-col" style={{ width: "-webkit-fill-available" }}>
											<Col sm="3">
												<p className="p-title-label">Nome</p>
												<p className="p-label">{result.name}</p>
											</Col>
											<Col sm="5">
												<p className="p-title-label">Descrição</p>
												<p className="p-label">{result.desc}</p>
											</Col>
											<Col sm="2">
												{(result.priceBy === "pessoa") ? (
													<div>
														<p className="p-title-label">Preço</p>
														<p className="p-label">{result.price} €/PAX</p>
													</div>
												) : (
														<div>
															<p className="p-title-label">Preço</p>
															<p className="p-label">{result.price} €</p>
														</div>
													)}
											</Col>
											<Col sm="2">
												{(result.comissionType !== null && result.comissionType !== undefined) ? (
													<div>
														<p className="p-title-label">Comissão</p>
														{(result.comissionType === "euro") ? (
															<p className="p-label">{result.comission} €</p>
														) : (
															<p className="p-label">{result.comission} %</p>
														)}
													</div>
												) : (
													<div>
														<p className="p-title-label">Comissão</p>
														<p style={{textAlign: "center", fontSize: "14px"}}> - </p>
													</div>
												)}
											</Col>
										</Row>
										<Row style={{ marginTop: "4%" }}>
											<Col sm="3">
												<p className="p-title-label" style={{ textAlign: "right" }}>Valor acordado</p>
												{(this.state.fotoValue === undefined || this.state.fotoValue === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("fotoValue", el.target.value) }}></Input>
												) : (
													<Input value={this.state.fotoValue} onChange={(el) => { this.changeFormValue("fotoValue", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="4">
												<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold", color:"#FFFFFF"}}>.</p>
												<Radio.Group name="valueByFoto" onChange={(el) => { this.changeFormValue("valueByFoto", el.target.value) }} value={this.state.valueByFoto}>
													<Radio value={"pessoa"}>Pessoa</Radio>
													<Radio value={"geral"}>Geral</Radio>
												</Radio.Group>
											</Col>
											<Col sm="2">
												<p className="p-title-label" style={{ textAlign: "right" }}>Comissão</p>
												{(this.state.comissionValueFoto === undefined || this.state.commissionValueFoto === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("comissionValueFoto", el.target.value) }}></Input>
												) : (
													<Input value={this.state.comissionValueFoto} onChange={(el) => { this.changeFormValue("comissionValueFoto", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="3">
												<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold", color:"#FFFFFF"}}>.</p>
												<Radio.Group name="comissionByFoto" onChange={(el) => { this.changeFormValue("comissionByFoto", el.target.value) }} value={this.state.comissionByFoto}>
													<Radio value={"pcent"}>%</Radio>
													<Radio value={"euro"}>€</Radio>
												</Radio.Group>
											</Col>
										</Row>
										<Row style={{ marginTop: "4%", justifyContent:"flex-end", marginRight:"0%"}}>
											<Col sm="3" style={{ justifyContent:"flex-start"}}>
												<p className="p-title-label" style={{ textAlign: "right" }}>Valor pago</p>
												{(this.state.paidValueFoto === undefined || this.state.paidValueFoto === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("paidValueFoto", el.target.value) }}></Input>
												) : (
													<Input value={this.state.paidValueFoto} onChange={(el) => { this.changeFormValue("paidValueFoto", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="9" style={{textAlign:"end", marginRight:"0%", marginTop:"4%"}}>
												{(this.state.totalValues.length !== 0) ? (
													this.state.totalValues.map((value, ix) => {
														if(value.role === "Fotografia"){
															return(
																<div>
																	<span className="p-title-label">Total: </span>
																	{this.state.totalValues[ix].total} €
																</div>
															)
														}
													})
												) : (
													null
												)}
											</Col>
										</Row>
									</CardContent>
								</Card>
							//</Container>
						)
					}
				}
			}

			//VIDEO
			if (this.state.fvProviders !== null || this.state.fvPacks !== null) {

				providerVideo = this.state.fvProviders.map((item, index) => {
					return (
						<Option value={item._id}>{item.name}</Option>
					)
				})

				for (const item of this.state.fvProviders) {
					if (item._id === this.state.selectedProviderVideo) {
						providerVideoInfo = (
							<Card style={{float:"left", width:"47%"}}>
								<CardContent>
									<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
										<h6 style={{ textAlign: "left" }}><b>Detalhes do Provider</b></h6>
									</Row>
									<Row className="inline-col" style={{ width: "-webkit-fill-available", marginBottom: "4%" }}>
										<Col sm="7">
											<p className="p-title-label">Nome</p>
											<p className="p-label">{item.name}</p>
										</Col>
										<Col sm="4">
											<p className="p-title-label">Contacto</p>
											<p className="p-label">{item.phone}</p>
										</Col>
									</Row>
									<Row className="inline-col" style={{ width: "-webkit-fill-available", marginBottom: "4%" }}>
										<Col>
											<p className="p-title-label">Email</p>
											<p className="p-label">{item.email}</p>
										</Col>
									</Row>
								</CardContent>
							</Card>
						)
					}
				}

				if(this.state.videoPacks !== null && this.state.videoPacks !== undefined) {
					providerVideoPacks = this.state.videoPacks.map((itm, index) => {
						return (
							<Option value={itm._id}>{itm.name}</Option>
						)
					})
				}

				for (const result of this.state.videoPacks) {
					if (result._id === this.state.selectedPackVideo) {
						detailsPackVideo = (
							//<Container style={{ paddingRight: 0, marginLeft: "1%", marginRight: "2%", marginTop: "4%", maxWidth: "-webkit-fill-available" }}>
								<Card style={{float:"right", width:"47%"}}>
									<CardContent>
										<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
											<h6 style={{ textAlign: "left" }}><b>Detalhes do Pack</b></h6>
										</Row>
										<Row className="inline-col" style={{ width: "-webkit-fill-available" }}>
											<Col sm="3">
												<p className="p-title-label">Nome</p>
												<p className="p-label">{result.name}</p>
											</Col>
											<Col sm="5">
												<p className="p-title-label">Descrição</p>
												<p className="p-label">{result.desc}</p>
											</Col>
											<Col sm="2">
												{(result.priceBy === "pessoa") ? (
													<div>
														<p className="p-title-label">Preço</p>
														<p className="p-label">{result.price} €/PAX</p>
													</div>
												) : (
														<div>
															<p className="p-title-label">Preço</p>
															<p className="p-label">{result.price} €</p>
														</div>
													)}
											</Col>
											<Col sm="2">
												{(result.comissionType !== null && result.comissionType !== undefined) ? (
													<div>
														<p className="p-title-label">Comissão</p>
														{(result.comissionType === "euro") ? (
															<p className="p-label">{result.comission} €</p>
														) : (
															<p className="p-label">{result.comission} %</p>
														)}
													</div>
												) : (
													<div>
														<p className="p-title-label">Comissão</p>
														<p style={{textAlign: "center", fontSize: "14px"}}> - </p>
													</div>
												)}
											</Col>
										</Row>
										<Row style={{ marginTop: "4%" }}>
											<Col sm="3">
												<p className="p-title-label" style={{ textAlign: "right" }}>Valor acordado</p>
												{(this.state.videoValue === undefined || this.state.videoValue === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("videoValue", el.target.value) }}></Input>
												) : (
													<Input value={this.state.videoValue} onChange={(el) => { this.changeFormValue("videoValue", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="4">
												<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold", color:"#FFFFFF"}}>.</p>
												<Radio.Group name="valueByVideo" onChange={(el) => { this.changeFormValue("valueByVideo", el.target.value) }} value={this.state.valueByVideo}>
													<Radio value={"pessoa"}>Pessoa</Radio>
													<Radio value={"geral"}>Geral</Radio>
												</Radio.Group>
											</Col>
											<Col sm="2">
												<p className="p-title-label" style={{ textAlign: "right" }}>Comissão</p>
												{(this.state.comissionValueVideo === undefined || this.state.commissionValueVideo === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("comissionValueVideo", el.target.value) }}></Input>
												) : (
													<Input value={this.state.comissionValueVideo} onChange={(el) => { this.changeFormValue("comissionValueVideo", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="3">
												<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold", color:"#FFFFFF"}}>.</p>
												<Radio.Group name="comissionByVideo" onChange={(el) => { this.changeFormValue("comissionByVideo", el.target.value) }} value={this.state.comissionByVideo}>
													<Radio value={"pcent"}>%</Radio>
													<Radio value={"euro"}>€</Radio>
												</Radio.Group>
											</Col>
										</Row>
										<Row style={{ marginTop: "4%", justifyContent:"flex-end", marginRight:"0%"}}>
											<Col sm="3" style={{ justifyContent:"flex-start"}}>
												<p className="p-title-label" style={{ textAlign: "right" }}>Valor pago</p>
												{(this.state.paidValueVideo === undefined || this.state.paidValueVideo === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("paidValueVideo", el.target.value) }}></Input>
												) : (
													<Input value={this.state.paidValueVideo} onChange={(el) => { this.changeFormValue("paidValueVideo", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="9" style={{textAlign:"end", marginRight:"0%", marginTop:"4%"}}>
												{(this.state.totalValues.length !== 0) ? (
													this.state.totalValues.map((value, ix) => {
														if(value.role === "Video"){
															return(
																<div>
																	<span className="p-title-label">Total: </span>
																	{this.state.totalValues[ix].total} €
																</div>
															)
														}
													})
												) : (
													null
												)}
											</Col>
										</Row>
									</CardContent>
								</Card>
							//</Container>
						)
					}
				}
			}

			//FOTO E VIDEO
			if (this.state.fvProviders !== null || this.state.fvPacks !== null) {
				providerFV = this.state.fvProviders.map((item, index) => {
					return (
						<Option value={item._id}>{item.name}</Option>
					)
				})

				for (const item of this.state.fvProviders) {
					if (item._id === this.state.selectedProviderFV) {
						providerFVInfo = (
							<Card style={{float:"left", width:"47%"}}>
								<CardContent>
									<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
										<h6 style={{ textAlign: "left" }}><b>Detalhes do Provider</b></h6>
									</Row>
									<Row className="inline-col" style={{ width: "-webkit-fill-available", marginBottom: "4%" }}>
										<Col sm="7">
											<p className="p-title-label">Nome</p>
											<p className="p-label">{item.name}</p>
										</Col>
										<Col sm="4">
											<p className="p-title-label">Contacto</p>
											<p className="p-label">{item.phone}</p>
										</Col>
									</Row>
									<Row className="inline-col" style={{ width: "-webkit-fill-available", marginBottom: "4%" }}>
										<Col>
											<p className="p-title-label">Email</p>
											<p className="p-label">{item.email}</p>
										</Col>
									</Row>
								</CardContent>
							</Card>
						)
					}
				}

				providerFVPacks = this.state.fvPacks.map((itm, index) => {
					return (
						<Option value={itm._id}>{itm.name}</Option>
					)
				})

				for (const result of this.state.fvPacks) {
					if (result._id === this.state.selectedPackFV) {
						detailsPackFV = (
							//<Container style={{ paddingRight: 0, marginLeft: "1%", marginRight: "2%", marginTop: "4%", maxWidth: "-webkit-fill-available" }}>
								<Card style={{float:"right", width:"47%"}}>
									<CardContent>
										<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
											<h6 style={{ textAlign: "left" }}><b>Detalhes do Pack</b></h6>
										</Row>
										<Row className="inline-col" style={{ width: "-webkit-fill-available" }}>
											<Col sm="3">
												<p className="p-title-label">Nome</p>
												<p className="p-label">{result.name}</p>
											</Col>
											<Col sm="5">
												<p className="p-title-label">Descrição</p>
												<p className="p-label">{result.desc}</p>
											</Col>
											<Col sm="2">
												{(result.priceBy === "pessoa") ? (
													<div>
														<p className="p-title-label">Preço</p>
														<p className="p-label">{result.price} €/PAX</p>
													</div>
												) : (
														<div>
															<p className="p-title-label">Preço</p>
															<p className="p-label">{result.price} €</p>
														</div>
													)}
											</Col>
											<Col sm="2">
												{(result.comissionType !== null && result.comissionType !== undefined) ? (
													<div>
														<p className="p-title-label">Comissão</p>
														{(result.comissionType === "euro") ? (
															<p className="p-label">{result.comission} €</p>
														) : (
															<p className="p-label">{result.comission} %</p>
														)}
													</div>
												) : (
													<div>
														<p className="p-title-label">Comissão</p>
														<p style={{textAlign: "center", fontSize: "14px"}}> - </p>
													</div>
												)}
											</Col>
										</Row>
										<Row style={{ marginTop: "4%" }}>
											<Col sm="3">
												<p className="p-title-label" style={{ textAlign: "right" }}>Valor acordado</p>
												{(this.state.fvValue === undefined || this.state.fvValue === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("fvValue", el.target.value) }}></Input>
												) : (
													<Input value={this.state.fvValue} onChange={(el) => { this.changeFormValue("fvValue", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="4">
												<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold", color:"#FFFFFF"}}>.</p>
												<Radio.Group name="valueByFV" onChange={(el) => { this.changeFormValue("valueByFV", el.target.value) }} value={this.state.valueByFV}>
													<Radio value={"pessoa"}>Pessoa</Radio>
													<Radio value={"geral"}>Geral</Radio>
												</Radio.Group>
											</Col>
											<Col sm="2">
												<p className="p-title-label" style={{ textAlign: "right" }}>Comissão</p>
												{(this.state.comissionValueFV === undefined || this.state.commissionValueFV === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("comissionValueFV", el.target.value) }}></Input>
												) : (
													<Input value={this.state.comissionValueFV} onChange={(el) => { this.changeFormValue("comissionValueFV", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="3">
												<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold", color:"#FFFFFF"}}>.</p>
												<Radio.Group name="comissionByFV" onChange={(el) => { this.changeFormValue("comissionByFV", el.target.value) }} value={this.state.comissionByFV}>
													<Radio value={"pcent"}>%</Radio>
													<Radio value={"euro"}>€</Radio>
												</Radio.Group>
											</Col>
										</Row>
										<Row style={{ marginTop: "4%", justifyContent:"flex-end", marginRight:"0%"}}>
											<Col sm="3" style={{ justifyContent:"flex-start"}}>
												<p className="p-title-label" style={{ textAlign: "right" }}>Valor pago</p>
												{(this.state.paidValueFV === undefined || this.state.paidValueFV === null) ? (
													<Input value="" onChange={(el) => { this.changeFormValue("paidValueFV", el.target.value) }}></Input>
												) : (
													<Input value={this.state.paidValueFV} onChange={(el) => { this.changeFormValue("paidValueFV", el.target.value) }}></Input>
												)}
											</Col>
											<Col sm="9" style={{textAlign:"end", marginRight:"0%", marginTop:"4%"}}>
												{(this.state.totalValues.length !== 0) ? (
													this.state.totalValues.map((value, ix) => {
														if(value.role === "Fotografia e Video"){
															return(
																<div>
																	<span className="p-title-label">Total: </span>
																	{this.state.totalValues[ix].total} €
																</div>
															)
														}
													})
												) : (
													null
												)}
											</Col>
										</Row>
									</CardContent>
								</Card>
							//</Container>
						)
					}
				}
			}


			//DADOS PARA SELECTS E CARDS - CATEGORIA OUTROS
			if (this.state.outrosProviders !== null && this.state.outrosProviders !== undefined) {

				providerOutros = this.state.outrosProviders.map((item, index) => {
					return (
						<Option value={item._id}>{item.name}</Option>
					)
				})

			}

			let providerList
			if (this.state.arrayProviders !== null && this.state.arrayProviders !== undefined) {
				providerList = this.state.arrayProviders.map((e, index) => {
					providerOutrosPacks = this.state.arrayProviders[index].providerPackOpts.map((itm, index) => {
						return (
							<Option value={itm._id}>{itm.name}</Option>
						)
					})
					return (
						<div key={index}>
							<Row style={{ marginBottom: "1%", marginTop: "2%", marginLeft: "0%" }}>
								<Col className="inline-col">
									<h6 style={{ textAlign: "left" }}><b>Fornecedor</b></h6>
								</Col>
								{(this.state.arrayProviders[index].providerId === null || this.state.arrayProviders[index].providerId === undefined) ? (
									<Col></Col>
								) : (
									<Col className="inline-col" style={{justifyContent:"flex-end"}}>
										{(this.state.arrayProviders[index].providerInfo !== null && this.state.arrayProviders[index].providerInfo !== undefined) ? (
											<div className="inline-col">
												<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
													<a href={"mailto:" + this.state.arrayProviders[index].providerInfo.email + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + this.state.arrayProviders[index].providerInfo.name + ", " + "%0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
												</Tooltip>
												<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Upload Contrato" placement="top">
													<AddIcon style={{marginLeft:"1%"}} className="btn-add pointer" onClick={() => this.toggleUpload(true, "Outros", this.state.arrayProviders[index].providerId)}/>
												</Tooltip>
												{(this.state.arrayProviders[index].hasContract === true) ? (
													<div className="inline-col">
														<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Visualizar Contrato" placement="top">
															<PdfIcon style={{marginLeft:"1%"}} className="pointer" onClick={() => this.download("Outros", this.state.arrayProviders[index].providerId)}/>
														</Tooltip>
														<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Eliminar Contrato" placement="top">
															<DeleteIcon style={{marginLeft:"1%"}} className="btn-delete pointer" onClick={() => this.toggleDelete(true, "Outros", this.state.arrayProviders[index].providerId )}/>
														</Tooltip>
													</div>
												) : (
													<p></p>
												)}
											</div>
										) : (
											<p></p>
										)}
									</Col>
								)}
							</Row>
							<Row style={{ justifyContent:"space-evenly", marginBottom:"2%" }}>
								<Select style={{width:"47%"}} placeholder="Selecione Fornecedor." value={this.state.arrayProviders[index].providerId} onChange={(value) => { this.onChangeProviderSelect(value, index) }}>
									<Option value={undefined}>Selecione Fornecedor</Option>
									{providerOutros}
								</Select>
								<Select style={{width:"47%"}} placeholder="Selecione Pack." value={this.state.arrayProviders[index].packId} onChange={(value) => { this.onChangePackSelect(value, index) }}>
									<Option value={undefined}>Selecione Pack</Option>
									{providerOutrosPacks}
								</Select>
							</Row>
							<Row style={{justifyContent: (this.state.arrayProviders[index].providerPackInfo !== null && this.state.arrayProviders[index].providerPackInfo !== undefined) ? "space-evenly" : "unset", marginLeft: (this.state.arrayProviders[index].providerPackInfo !== null && this.state.arrayProviders[index].providerPackInfo !== undefined) ? undefined : "1%", marginBottom:"2%"}}>
									{(this.state.arrayProviders[index].providerInfo !== null && this.state.arrayProviders[index].providerInfo !== undefined) ? (
											<Card style={{float:"left", width:"47%"}}>
												<CardContent>
													<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
														<h6 style={{ textAlign: "left" }}><b>Detalhes do Fornecedor</b></h6>
													</Row>
													<Row className="inline-col" style={{ width: "-webkit-fill-available", marginBottom: "8%" }}>
														<Col sm="4">
															<p className="p-title-label">Nome</p>
															<p className="p-label">{this.state.arrayProviders[index].providerInfo.name}</p>
														</Col>
														<Col sm="3">
															<p className="p-title-label">Contacto</p>
															<p className="p-label">{this.state.arrayProviders[index].providerInfo.phone}</p>
														</Col>
														<Col sm="5">
															<p className="p-title-label">Email</p>
															<p className="p-label">{this.state.arrayProviders[index].providerInfo.email}</p>
														</Col>
													</Row>
												</CardContent>
											</Card>
									) : (
										<p></p>
									)}
								{/* <Col> */}
									{(this.state.arrayProviders[index].providerPackInfo !== null && this.state.arrayProviders[index].providerPackInfo !== undefined) ? (
										//<Container style={{ paddingRight: 0, marginLeft: "1%", marginRight: "2%", marginTop: "4%", maxWidth: "-webkit-fill-available" }}>
											<Card style={{float:"right", width:"47%"}}>
												<CardContent>
													<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
														<h6 style={{ textAlign: "left" }}><b>Detalhes do Pack</b></h6>
													</Row>
													<Row className="inline-col" style={{ width: "-webkit-fill-available" }}>
														<Col sm="3">
															<p className="p-title-label">Nome</p>
															<p className="p-label">{this.state.arrayProviders[index].providerPackInfo.name}</p>
														</Col>
														<Col sm="5">
															<p className="p-title-label">Descrição</p>
															<p className="p-label">{this.state.arrayProviders[index].providerPackInfo.desc}</p>
														</Col>
														<Col sm="2">
															{(this.state.arrayProviders[index].providerPackInfo.priceBy === "pessoa") ? (
																<div>
																	<p className="p-title-label">Preço</p>
																	<p className="p-label">{this.state.arrayProviders[index].providerPackInfo.price} €/PAX</p>
																</div>
															) : (
																	<div>
																		<p className="p-title-label">Preço</p>
																		<p className="p-label">{this.state.arrayProviders[index].providerPackInfo.price} €</p>
																	</div>
																)}
														</Col>
														<Col sm="2">
															{(this.state.arrayProviders[index].providerPackInfo.comissionType !== null && this.state.arrayProviders[index].providerPackInfo.comissionType !== undefined) ? (
																<div>
																	<p className="p-title-label">Comissão</p>
																	{(this.state.arrayProviders[index].providerPackInfo.comissionType === "euro") ? (
																		<p className="p-label">{this.state.arrayProviders[index].providerPackInfo.comission} €</p>
																	) : (
																		<p className="p-label">{this.state.arrayProviders[index].providerPackInfo.comission} %</p>
																	)}
																</div>
															) : (
																<div>
																	<p className="p-title-label">Comissão</p>
																	<p style={{textAlign:"center", fontSize:"14px"}}> - </p>
																</div>
															)}
														</Col>
													</Row>
													<Row style={{ marginTop: "4%" }}>
														<Col sm="3">
															<p className="p-title-label" style={{ textAlign: "right" }}>Valor acordado</p>
															{(this.state.arrayProviders[index].providerPackInfo.value === undefined || this.state.arrayProviders[index].providerPackInfo.value === null) ? (
																<Input value="" onChange={(el) => { this.onChangeFormV(el.target.value, index, "value") }}></Input>
															) : (
																<Input value={this.state.arrayProviders[index].providerPackInfo.value} onChange={(el) => { this.onChangeFormV(el.target.value, index, "value") }}></Input>
															)}
														</Col>
														<Col sm="4">
															<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold", color:"#FFFFFF"}}>.</p>
															<Radio.Group name="valueByOutros" onChange={(el) => { this.onChangeFormV(el.target.value, index, "valueByOutros") }} value={this.state.arrayProviders[index].providerPackInfo.valueByOutros}>
																<Radio value={"pessoa"}>Pessoa</Radio>
																<Radio value={"geral"}>Geral</Radio>
															</Radio.Group>
														</Col>
														<Col sm="2">
															<p className="p-title-label" style={{ textAlign: "right" }}>Comissão</p>
															{(this.state.arrayProviders[index].providerPackInfo.comissionValueOutros === undefined || this.state.arrayProviders[index].providerPackInfo.comissionValueOutros === null) ? (
																<Input value="" onChange={(el) => { this.onChangeFormV(el.target.value, index, "comissionValueOutros") }}></Input>
															) : (
																<Input value={this.state.arrayProviders[index].providerPackInfo.comissionValueOutros} onChange={(el) => { this.onChangeFormV(el.target.value, index, "comissionValueOutros") }}></Input>
															)}
														</Col>
														<Col sm="3">
															<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold", color:"#FFFFFF"}}>.</p>
															<Radio.Group name="comissionByOutros" onChange={(el) => { this.onChangeFormV(el.target.value, index, "comissionByOutros") }} value={this.state.arrayProviders[index].providerPackInfo.comissionByOutros}>
																<Radio value={"pcent"}>%</Radio>
																<Radio value={"euro"}>€</Radio>
															</Radio.Group>
														</Col>
													</Row>
													<Row style={{ marginTop: "4%", justifyContent:"flex-end", marginRight:"0%"}}>
														<Col sm="3" style={{ justifyContent:"flex-start"}}>
															<p className="p-title-label" style={{ textAlign: "right" }}>Valor pago</p>
															{(this.state.arrayProviders[index].providerPackInfo.paidValueOutros === undefined || this.state.arrayProviders[index].providerPackInfo.paidValueOutros === null) ? (
																<Input value="" onChange={(el) => { this.onChangeFormV(el.target.value, index, "paidValueOutros") }}></Input>
															) : (
																<Input value={this.state.arrayProviders[index].providerPackInfo.paidValueOutros} onChange={(el) => { this.onChangeFormV(el.target.value, index, "paidValueOutros") }}></Input>
															)}
														</Col>
														<Col sm="9" style={{textAlign:"end", marginRight:"0%", marginTop:"4%"}}>
															{(this.state.totalValues.length !== 0) ? (
																this.state.totalValues.map((value, ix) => {
																	if(value.role === "Outros" && value.packId === this.state.arrayProviders[index].packId){
																		return(
																			<div>
																				<span className="p-title-label">Total: </span>
																				{this.state.totalValues[ix].total} €
																			</div>
																		)
																	}
																})
															) : (
																null
															)}
														</Col>
													</Row>
												</CardContent>
											</Card>
										//</Container>
									) : (
											<p></p>
										)}
								{/* </Col> */}
							</Row>
						</div>
					)
				});

			}


			//NEGOCIAÇÕES - CATEGORIAS GERAIS

			let multiselectGeneralValue = this.state.possibleGeneral.map((e) => {return e.providerId})

			let multiselectProv
			if (this.state.providers !== null && this.state.providers !== undefined) {
				multiselectProv = this.state.providers.map((item, index) => {
					return (
						<Option value={item._id}>{item.name}</Option>
					)
				})
			}

			let selectedNegotiations = []
			if (multiselectGeneralValue !== undefined) {
				selectedNegotiations = this.state.providers.filter((element) => multiselectGeneralValue.includes(element._id))
			}

			let negotiationsList
			if (selectedNegotiations.length > 0) {
				negotiationsList = selectedNegotiations.map((item, index) => {
					return (
						<li class="list-group-item">
							<Row>
								<Col sm="4" style={{ textAlign: "left" }}>
									{item.name}
								</Col>
								<Col sm="3" style={{ textAlign: "left" }}>
									{item.phone}
								</Col>
								<Col sm="4" style={{ textAlign: "left" }}>
									{(this.state.possibleGeneral.length > 0) ? (
										this.state.possibleGeneral.map((val, index) => {
											if (item._id === val.providerId) {
												return (
													<Select value={this.state.possibleGeneral[index].packId} onChange={(value) => this.handlePackSelect(value, item._id, item.role)}>
														<Option value={undefined}>Selecione pack</Option>
														{(item.packs !== null && item.packs !== undefined) ? (
															item.packs.map((el, index) => {
																return (
																	(el.priceBy === "geral") ? (
																		<Option value={el._id}>{el.name} - {el.price}€</Option>
																	) : (
																			<Option value={el._id}>{el.name} - {el.price}€ pax. </Option>
																		)
																)
															})
														) : (
																<p>Não existem packs para este fornecedor.</p>
															)}
													</Select>
												)
											}
										})
									) : (
											<Select onChange={(value) => this.handlePackSelect(value, item._id, item.role)}>
												<Option value={undefined}>Selecione pack</Option>
												{(item.packs !== null && item.packs !== undefined) ? (
													item.packs.map((el, index) => {
														return (
															(el.priceBy === "geral") ? (
																<Option value={el._id}>{el.name} - {el.price}€</Option>
															) : (
																	<Option value={el._id}>{el.name} - {el.price}€ pax. </Option>
																)
														)
													})
												) : (
														<p>Não existem packs para este fornecedor.</p>
													)}
											</Select>
										)}
								</Col>
								<Col sm="1" style={{ textAlign: "center" }}>
									<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
										<a href={"mailto:" + item.email + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + item.name + ", %0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate + "."}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
									</Tooltip>
								</Col>
							</Row>
						</li>
					)
				})
			} else {
				negotiationsList = (
					<li class="list-group-item">
						<Row>
							<p style={{ fontSize: "14px", marginBottom: "0%", marginLeft: "1%" }}>Não existem fornecedores em negociação.</p>
						</Row>
					</li>
				)
			}


			//NEGOCIAÇÕES - CATEGORIA ESPAÇO
			//ESPAÇO

			let multiselectEspacoValue = this.state.possibleEspaco.map((e) => {return e.providerId})
			let multiselectCateringValue = this.state.possibleCatering.map((e) => {return e.providerId})
			let multiselectEspCatValue = this.state.possibleEspCat.map((e) => {return e.providerId})
			let multiselectGrupoValue = this.state.possibleGrupo.map((e) => {return e.providerId})
			let multiselectPerformersValue = this.state.possiblePerformers.map((e) => {return e.providerId})
			let multiselectDJValue = this.state.possibleDJ.map((e) => {return e.providerId})
			let multiselectBandaValue = this.state.possibleBanda.map((e) => {return e.providerId})
			let multiselectPreValue = this.state.possiblePre.map((e) => {return e.providerId})
			let multiselectPosValue = this.state.possiblePos.map((e) => {return e.providerId})
			let multiselectPadreValue = this.state.possiblePadre.map((e) => {return e.providerId})
			let multiselectBabyValue = this.state.possibleBaby.map((e) => {return e.providerId})
			let multiselectLembrancasValue = this.state.possibleLembrancas.map((e) => {return e.providerId})
			let multiselectTransporteValue = this.state.possibleTransporte.map((e) => {return e.providerId})
			let multiselectAulasValue = this.state.possibleAulas.map((e) => {return e.providerId})
			let multiselectOutrosValue = this.state.possibleOutros.map((e) => {return e.providerId})
			let multiselectFotoValue = this.state.possibleFoto.map((e) => {return e.providerId})
			let multiselectVideoValue = this.state.possibleVideo.map((e) => {return e.providerId})
			let multiselectFVValue = this.state.possibleFV.map((e) => {return e.providerId})

			let multiselectEspaco
			if (this.state.espacoProviders !== null && this.state.espacoProviders !== undefined) {
				multiselectEspaco = this.state.espacoProviders.map((item, index) => {
					return (
						<Option value={item._id}>{item.name}</Option>
					)
				})
			}

			let selectedNegotiationsEspaco = []
			if (multiselectEspacoValue !== undefined && multiselectEspacoValue !== null) {
				selectedNegotiationsEspaco = this.state.providers.filter((element) => multiselectEspacoValue.includes(element._id))
			}

			let negotiationsListEspaco
			if (selectedNegotiationsEspaco.length > 0) {
				negotiationsListEspaco = selectedNegotiationsEspaco.map((item, index) => {
					return (
						<li class="list-group-item">
							<Row>
								<Col sm="4" style={{ textAlign: "left" }}>
									{item.name}
								</Col>
								<Col sm="3" style={{ textAlign: "left" }}>
									{item.phone}
								</Col>
								<Col sm="4" style={{ textAlign: "left" }}>
									{(this.state.possibleEspaco.length > 0) ? (
										this.state.possibleEspaco.map((val, index) => {
											if (item._id === val.providerId) {
												return (
													<Select value={this.state.possibleEspaco[index].packId} onChange={(value) => this.handlePackSelect(value, item._id, item.role)}>
														<Option value={undefined}>Selecione pack</Option>
														{(item.packs !== null && item.packs !== undefined) ? (
															item.packs.map((el, index) => {
																return (
																	(el.priceBy === "geral") ? (
																		<Option value={el._id}>{el.name} - {el.price}€</Option>
																	) : (
																			<Option value={el._id}>{el.name} - {el.price}€ pax. </Option>
																		)
																)
															})
														) : (
																<p>Não existem packs para este fornecedor.</p>
															)}
													</Select>
												)
											}
										})
									) : (
											<Select onChange={(value) => this.handlePackSelect(value, item._id, item.role)}>
												<Option value={undefined}>Selecione pack</Option>
												{(item.packs !== null && item.packs !== undefined) ? (
													item.packs.map((el, index) => {
														return (
															(el.priceBy === "geral") ? (
																<Option value={el._id}>{el.name} - {el.price}€</Option>
															) : (
																	<Option value={el._id}>{el.name} - {el.price}€ pax. </Option>
																)
														)
													})
												) : (
														<p>Não existem packs para este fornecedor.</p>
													)}
											</Select>
										)}
								</Col>
								<Col sm="1" style={{ textAlign: "center" }}>
									<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
										<a href={"mailto:" + item.email + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + item.name + ", %0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate + "."}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
									</Tooltip>
								</Col>
							</Row>
						</li>
					)
				})
			} else {
				negotiationsListEspaco = (
					<li class="list-group-item">
						<Row>
							<p style={{ fontSize: "14px", marginBottom: "0%", marginLeft: "1%" }}>Não existem fornecedores em negociação.</p>
						</Row>
					</li>
				)
			}

			//CATERING
			let multiselectCatering
			if (this.state.espacoProviders !== null && this.state.espacoProviders !== undefined) {
				multiselectCatering = this.state.espacoProviders.map((item, index) => {
					return (
						<Option value={item._id}>{item.name}</Option>
					)
				})
			}

			let selectedNegotiationsCatering = []
			if (multiselectCateringValue !== undefined && multiselectCateringValue !== null) {
				selectedNegotiationsCatering = this.state.providers.filter((element) => multiselectCateringValue.includes(element._id))
			}

			let negotiationsListCatering
			if (selectedNegotiationsCatering.length > 0) {
				negotiationsListCatering = selectedNegotiationsCatering.map((item, index) => {
					return (
						<li class="list-group-item">
							<Row>
								<Col sm="4" style={{ textAlign: "left" }}>
									{item.name}
								</Col>
								<Col sm="3" style={{ textAlign: "left" }}>
									{item.phone}
								</Col>
								<Col sm="4" style={{ textAlign: "left" }}>
									{(this.state.possibleCatering.length > 0) ? (
										this.state.possibleCatering.map((val, index) => {
											if (item._id === val.providerId) {
												return (
													<Select value={this.state.possibleCatering[index].packId} onChange={(value) => this.handlePackSelect(value, item._id, "Catering")}>
														<Option value={undefined}>Selecione pack</Option>
														{(item.packs !== null && item.packs !== undefined) ? (
															item.packs.map((el, index) => {
																return (
																	(el.priceBy === "geral") ? (
																		<Option value={el._id}>{el.name} - {el.price}€</Option>
																	) : (
																			<Option value={el._id}>{el.name} - {el.price}€ pax. </Option>
																		)
																)
															})
														) : (
																<p>Não existem packs para este fornecedor.</p>
															)}
													</Select>
												)
											}
										})
									) : (
											<Select onChange={(value) => this.handlePackSelect(value, item._id, item.role)}>
												<Option value={undefined}>Selecione pack</Option>
												{(item.packs !== null && item.packs !== undefined) ? (
													item.packs.map((el, index) => {
														return (
															(el.priceBy === "geral") ? (
																<Option value={el._id}>{el.name} - {el.price}€</Option>
															) : (
																	<Option value={el._id}>{el.name} - {el.price}€ pax. </Option>
																)
														)
													})
												) : (
														<p>Não existem packs para este fornecedor.</p>
													)}
											</Select>
										)}
								</Col>
								<Col sm="1" style={{ textAlign: "center" }}>
									<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
										<a href={"mailto:" + item.email + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + item.name + ", %0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate + "."}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
									</Tooltip>
								</Col>
							</Row>
						</li>
					)
				})
			} else {
				negotiationsListCatering= (
					<li class="list-group-item">
						<Row>
							<p style={{ fontSize: "14px", marginBottom: "0%", marginLeft: "1%" }}>Não existem fornecedores em negociação.</p>
						</Row>
					</li>
				)
			}

			//ESPAÇO E CATERING
			let multiselectEspCat
			if (this.state.espacoProviders !== null && this.state.espacoProviders !== undefined) {
				multiselectEspCat = this.state.espacoProviders.map((item, index) => {
					return (
						<Option value={item._id}>{item.name}</Option>
					)
				})
			}

			let selectedNegotiationsEspCat = []
			if (multiselectEspCatValue !== undefined) {
				selectedNegotiationsEspCat = this.state.providers.filter((element) => multiselectEspCatValue.includes(element._id))
			}

			let negotiationsListEspCat
			if (selectedNegotiationsCatering.length > 0) {
				negotiationsListEspCat = selectedNegotiationsEspCat.map((item, index) => {
					return (
						<li class="list-group-item">
							<Row>
								<Col sm="4" style={{ textAlign: "left" }}>
									{item.name}
								</Col>
								<Col sm="3" style={{ textAlign: "left" }}>
									{item.phone}
								</Col>
								<Col sm="4" style={{ textAlign: "left" }}>
									{(this.state.possibleEspCat.length > 0) ? (
										this.state.possibleEspCat.map((val, index) => {
											if (item._id === val.providerId) {
												return (
													<Select value={this.state.possibleEspCat[index].packId} onChange={(value) => this.handlePackSelect(value, item._id, "Espaço e Catering")}>
														<Option value={undefined}>Selecione pack</Option>
														{(item.packs !== null && item.packs !== undefined) ? (
															item.packs.map((el, index) => {
																return (
																	(el.priceBy === "geral") ? (
																		<Option value={el._id}>{el.name} - {el.price}€</Option>
																	) : (
																			<Option value={el._id}>{el.name} - {el.price}€ pax. </Option>
																		)
																)
															})
														) : (
																<p>Não existem packs para este fornecedor.</p>
															)}
													</Select>
												)
											}
										})
									) : (
											<Select onChange={(value) => this.handlePackSelect(value, item._id, item.role)}>
												<Option value={undefined}>Selecione pack</Option>
												{(item.packs !== null && item.packs !== undefined) ? (
													item.packs.map((el, index) => {
														return (
															(el.priceBy === "geral") ? (
																<Option value={el._id}>{el.name} - {el.price}€</Option>
															) : (
																	<Option value={el._id}>{el.name} - {el.price}€ pax. </Option>
																)
														)
													})
												) : (
														<p>Não existem packs para este fornecedor.</p>
													)}
											</Select>
										)}
								</Col>
								<Col sm="1" style={{ textAlign: "center" }}>
									<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
										<a href={"mailto:" + item.email + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + item.name + ", %0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate + "."}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
									</Tooltip>
								</Col>
							</Row>
						</li>
					)
				})
			} else {
				negotiationsListEspCat = (
					<li class="list-group-item">
						<Row>
							<p style={{ fontSize: "14px", marginBottom: "0%", marginLeft: "1%" }}>Não existem fornecedores em negociação.</p>
						</Row>
					</li>
				)
			}


			//NEGOCIAÇÕES - CATEGORIA ANIMAÇÃO
			//GRUPO MUSICAL
			let multiselectGrupo
			if (this.state.grupoProviders !== null && this.state.grupoProviders !== undefined) {
				multiselectGrupo = this.state.grupoProviders.map((item, index) => {
					return (
						<Option value={item._id}>{item.name}</Option>
					)
				})
			}

			let selectedNegotiationsGrupo = []
			if (multiselectGrupoValue !== undefined) {
				selectedNegotiationsGrupo = this.state.providers.filter((element) => multiselectGrupoValue.includes(element._id))
			}

			let negotiationsListGrupo
			if (selectedNegotiationsGrupo.length > 0) {
				negotiationsListGrupo = selectedNegotiationsGrupo.map((item, index) => {
					return (
						<li class="list-group-item">
							<Row>
								<Col sm="4" style={{ textAlign: "left" }}>
									{item.name}
								</Col>
								<Col sm="3" style={{ textAlign: "left" }}>
									{item.phone}
								</Col>
								<Col sm="4" style={{ textAlign: "left" }}>
									{(this.state.possibleGrupo.length > 0) ? (
										this.state.possibleGrupo.map((val, index) => {
											if (item._id === val.providerId) {
												return (
													<Select value={this.state.possibleGrupo[index].packId} onChange={(value) => this.handlePackSelect(value, item._id, item.role)}>
														<Option value={undefined}>Selecione pack</Option>
														{(item.packs !== null && item.packs !== undefined) ? (
															item.packs.map((el, index) => {
																return (
																	(el.priceBy === "geral") ? (
																		<Option value={el._id}>{el.name} - {el.price}€</Option>
																	) : (
																			<Option value={el._id}>{el.name} - {el.price}€ pax. </Option>
																		)
																)
															})
														) : (
																<p>Não existem packs para este fornecedor.</p>
															)}
													</Select>
												)
											}
										})
									) : (
											<Select onChange={(value) => this.handlePackSelect(value, item._id, item.role)}>
												<Option value={undefined}>Selecione pack</Option>
												{(item.packs !== null && item.packs !== undefined) ? (
													item.packs.map((el, index) => {
														return (
															(el.priceBy === "geral") ? (
																<Option value={el._id}>{el.name} - {el.price}€</Option>
															) : (
																	<Option value={el._id}>{el.name} - {el.price}€ pax. </Option>
																)
														)
													})
												) : (
														<p>Não existem packs para este fornecedor.</p>
													)}
											</Select>
										)}
								</Col>
								<Col sm="1" style={{ textAlign: "center" }}>
									<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
										<a href={"mailto:" + item.email + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + item.name + ", %0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate + "."}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
									</Tooltip>
								</Col>
							</Row>
						</li>
					)
				})
			} else {
				negotiationsListGrupo = (
					<li class="list-group-item">
						<Row>
							<p style={{ fontSize: "14px", marginBottom: "0%", marginLeft: "1%" }}>Não existem fornecedores em negociação.</p>
						</Row>
					</li>
				)
			}

			//PERFORMERS
			let multiselectPerformers
			if (this.state.performersProviders !== null && this.state.performersProviders !== undefined) {
				multiselectPerformers = this.state.performersProviders.map((item, index) => {
					return (
						<Option value={item._id}>{item.name}</Option>
					)
				})
			}

			let selectedNegotiationsPerformers = []
			if (multiselectPerformersValue !== undefined) {
				selectedNegotiationsPerformers = this.state.providers.filter((element) => multiselectPerformersValue.includes(element._id))
			}

			let negotiationsListPerformers
			if (selectedNegotiationsPerformers.length > 0) {
				negotiationsListPerformers = selectedNegotiationsPerformers.map((item, index) => {
					return (
						<li class="list-group-item">
							<Row>
								<Col sm="4" style={{ textAlign: "left" }}>
									{item.name}
								</Col>
								<Col sm="3" style={{ textAlign: "left" }}>
									{item.phone}
								</Col>
								<Col sm="4" style={{ textAlign: "left" }}>
									{(this.state.possiblePerformers.length > 0) ? (
										this.state.possiblePerformers.map((val, index) => {
											if (item._id === val.providerId) {
												return (
													<Select value={this.state.possiblePerformers[index].packId} onChange={(value) => this.handlePackSelect(value, item._id, item.role)}>
														<Option value={undefined}>Selecione pack</Option>
														{(item.packs !== null && item.packs !== undefined) ? (
															item.packs.map((el, index) => {
																return (
																	(el.priceBy === "geral") ? (
																		<Option value={el._id}>{el.name} - {el.price}€</Option>
																	) : (
																			<Option value={el._id}>{el.name} - {el.price}€ pax. </Option>
																		)
																)
															})
														) : (
																<p>Não existem packs para este fornecedor.</p>
															)}
													</Select>
												)
											}
										})
									) : (
											<Select onChange={(value) => this.handlePackSelect(value, item._id, item.role)}>
												<Option value={undefined}>Selecione pack</Option>
												{(item.packs !== null && item.packs !== undefined) ? (
													item.packs.map((el, index) => {
														return (
															(el.priceBy === "geral") ? (
																<Option value={el._id}>{el.name} - {el.price}€</Option>
															) : (
																	<Option value={el._id}>{el.name} - {el.price}€ pax. </Option>
																)
														)
													})
												) : (
														<p>Não existem packs para este fornecedor.</p>
													)}
											</Select>
										)}
								</Col>
								<Col sm="1" style={{ textAlign: "center" }}>
									<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
										<a href={"mailto:" + item.email + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + item.name + ", %0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate + "."}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
									</Tooltip>
								</Col>
							</Row>
						</li>
					)
				})
			} else {
				negotiationsListPerformers = (
					<li class="list-group-item">
						<Row>
							<p style={{ fontSize: "14px", marginBottom: "0%", marginLeft: "1%" }}>Não existem fornecedores em negociação.</p>
						</Row>
					</li>
				)
			}

			//DJ
			let multiselectDJ
			if (this.state.djProviders !== null && this.state.djProviders !== undefined) {
				multiselectDJ = this.state.djProviders.map((item, index) => {
					return (
						<Option value={item._id}>{item.name}</Option>
					)
				})
			}

			let selectedNegotiationsDJ = []
			if (multiselectDJValue !== undefined) {
				selectedNegotiationsDJ = this.state.providers.filter((element) => multiselectDJValue.includes(element._id))
			}

			let negotiationsListDJ
			if (selectedNegotiationsDJ.length > 0) {
				negotiationsListDJ = selectedNegotiationsDJ.map((item, index) => {
					return (
						<li class="list-group-item">
							<Row>
								<Col sm="4" style={{ textAlign: "left" }}>
									{item.name}
								</Col>
								<Col sm="3" style={{ textAlign: "left" }}>
									{item.phone}
								</Col>
								<Col sm="4" style={{ textAlign: "left" }}>
									{(this.state.possibleDJ.length > 0) ? (
										this.state.possibleDJ.map((val, index) => {
											if (item._id === val.providerId) {
												return (
													<Select value={this.state.possibleDJ[index].packId} onChange={(value) => this.handlePackSelect(value, item._id, item.role)}>
														<Option value={undefined}>Selecione pack</Option>
														{(item.packs !== null && item.packs !== undefined) ? (
															item.packs.map((el, index) => {
																return (
																	(el.priceBy === "geral") ? (
																		<Option value={el._id}>{el.name} - {el.price}€</Option>
																	) : (
																			<Option value={el._id}>{el.name} - {el.price}€ pax. </Option>
																		)
																)
															})
														) : (
																<p>Não existem packs para este fornecedor.</p>
															)}
													</Select>
												)
											}
										})
									) : (
											<Select onChange={(value) => this.handlePackSelect(value, item._id, item.role)}>
												<Option value={undefined}>Selecione pack</Option>
												{(item.packs !== null && item.packs !== undefined) ? (
													item.packs.map((el, index) => {
														return (
															(el.priceBy === "geral") ? (
																<Option value={el._id}>{el.name} - {el.price}€</Option>
															) : (
																	<Option value={el._id}>{el.name} - {el.price}€ pax. </Option>
																)
														)
													})
												) : (
														<p>Não existem packs para este fornecedor.</p>
													)}
											</Select>
										)}
								</Col>
								<Col sm="1" style={{ textAlign: "center" }}>
									<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
										<a href={"mailto:" + item.email + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + item.name + ", %0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate + "."}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
									</Tooltip>
								</Col>
							</Row>
						</li>
					)
				})
			} else {
				negotiationsListDJ = (
					<li class="list-group-item">
						<Row>
							<p style={{ fontSize: "14px", marginBottom: "0%", marginLeft: "1%" }}>Não existem fornecedores em negociação.</p>
						</Row>
					</li>
				)
			}

			//BANDA
			let multiselectBanda
			if (this.state.bandaProviders !== null && this.state.bandaProviders !== undefined) {
				multiselectBanda = this.state.bandaProviders.map((item, index) => {
					return (
						<Option value={item._id}>{item.name}</Option>
					)
				})
			}

			let selectedNegotiationsBanda = []
			if (multiselectBandaValue !== undefined) {
				selectedNegotiationsBanda = this.state.providers.filter((element) => multiselectBandaValue.includes(element._id))
			}

			let negotiationsListBanda
			if (selectedNegotiationsBanda.length > 0) {
				negotiationsListBanda = selectedNegotiationsBanda.map((item, index) => {
					return (
						<li class="list-group-item">
							<Row>
								<Col sm="4" style={{ textAlign: "left" }}>
									{item.name}
								</Col>
								<Col sm="3" style={{ textAlign: "left" }}>
									{item.phone}
								</Col>
								<Col sm="4" style={{ textAlign: "left" }}>
									{(this.state.possibleBanda.length > 0) ? (
										this.state.possibleBanda.map((val, index) => {
											if (item._id === val.providerId) {
												return (
													<Select value={this.state.possibleBanda[index].packId} onChange={(value) => this.handlePackSelect(value, item._id, item.role)}>
														<Option value={undefined}>Selecione pack</Option>
														{(item.packs !== null && item.packs !== undefined) ? (
															item.packs.map((el, index) => {
																return (
																	(el.priceBy === "geral") ? (
																		<Option value={el._id}>{el.name} - {el.price}€</Option>
																	) : (
																			<Option value={el._id}>{el.name} - {el.price}€ pax. </Option>
																		)
																)
															})
														) : (
																<p>Não existem packs para este fornecedor.</p>
															)}
													</Select>
												)
											}
										})
									) : (
											<Select onChange={(value) => this.handlePackSelect(value, item._id, item.role)}>
												<Option value={undefined}>Selecione pack</Option>
												{(item.packs !== null && item.packs !== undefined) ? (
													item.packs.map((el, index) => {
														return (
															(el.priceBy === "geral") ? (
																<Option value={el._id}>{el.name} - {el.price}€</Option>
															) : (
																	<Option value={el._id}>{el.name} - {el.price}€ pax. </Option>
																)
														)
													})
												) : (
														<p>Não existem packs para este fornecedor.</p>
													)}
											</Select>
										)}
								</Col>
								<Col sm="1" style={{ textAlign: "center" }}>
									<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
										<a href={"mailto:" + item.email + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + item.name + ", %0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate + "."}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
									</Tooltip>
								</Col>
							</Row>
						</li>
					)
				})
			} else {
				negotiationsListBanda = (
					<li class="list-group-item">
						<Row>
							<p style={{ fontSize: "14px", marginBottom: "0%", marginLeft: "1%" }}>Não existem fornecedores em negociação.</p>
						</Row>
					</li>
				)
			}

			//NEGOCIAÇÕES - CATEGORIA PRÉ E PÓS EVENTO
			//PRE EVENTO
			let multiselectPre
			if (this.state.preProviders !== null && this.state.preProviders !== undefined) {
				multiselectPre = this.state.preProviders.map((item, index) => {
					return (
						<Option value={item._id}>{item.name}</Option>
					)
				})
			}

			let selectedNegotiationsPre = []
			if (multiselectPreValue !== undefined) {
				selectedNegotiationsPre = this.state.providers.filter((element) => multiselectPreValue.includes(element._id))
			}

			let negotiationsListPre
			if (selectedNegotiationsPre.length > 0) {
				negotiationsListPre = selectedNegotiationsPre.map((item, index) => {
					return (
						<li class="list-group-item">
							<Row>
								<Col sm="4" style={{ textAlign: "left" }}>
									{item.name}
								</Col>
								<Col sm="3" style={{ textAlign: "left" }}>
									{item.phone}
								</Col>
								<Col sm="4" style={{ textAlign: "left" }}>
									{(this.state.possiblePre.length > 0) ? (
										this.state.possiblePre.map((val, index) => {
											if (item._id === val.providerId) {
												return (
													<Select value={this.state.possiblePre[index].packId} onChange={(value) => this.handlePackSelect(value, item._id, item.role)}>
														<Option value={undefined}>Selecione pack</Option>
														{(item.packs !== null && item.packs !== undefined) ? (
															item.packs.map((el, index) => {
																return (
																	(el.priceBy === "geral") ? (
																		<Option value={el._id}>{el.name} - {el.price}€</Option>
																	) : (
																			<Option value={el._id}>{el.name} - {el.price}€ pax. </Option>
																		)
																)
															})
														) : (
																<p>Não existem packs para este fornecedor.</p>
															)}
													</Select>
												)
											}
										})
									) : (
											<Select onChange={(value) => this.handlePackSelect(value, item._id, item.role)}>
												<Option value={undefined}>Selecione pack</Option>
												{(item.packs !== null && item.packs !== undefined) ? (
													item.packs.map((el, index) => {
														return (
															(el.priceBy === "geral") ? (
																<Option value={el._id}>{el.name} - {el.price}€</Option>
															) : (
																	<Option value={el._id}>{el.name} - {el.price}€ pax. </Option>
																)
														)
													})
												) : (
														<p>Não existem packs para este fornecedor.</p>
													)}
											</Select>
										)}
								</Col>
								<Col sm="1" style={{ textAlign: "center" }}>
									<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
										<a href={"mailto:" + item.email + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + item.name + ", %0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate + "."}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
									</Tooltip>
								</Col>
							</Row>
						</li>
					)
				})
			} else {
				negotiationsListPre = (
					<li class="list-group-item">
						<Row>
							<p style={{ fontSize: "14px", marginBottom: "0%", marginLeft: "1%" }}>Não existem fornecedores em negociação.</p>
						</Row>
					</li>
				)
			}

			//POS EVENTO
			let multiselectPos
			if (this.state.posProviders !== null && this.state.posProviders !== undefined) {
				multiselectPos = this.state.posProviders.map((item, index) => {
					return (
						<Option value={item._id}>{item.name}</Option>
					)
				})
			}

			let selectedNegotiationsPos = []
			if (multiselectPosValue !== undefined) {
				selectedNegotiationsPos = this.state.providers.filter((element) => multiselectPosValue.includes(element._id))
			}

			let negotiationsListPos
			if (selectedNegotiationsPos.length > 0) {
				negotiationsListPos = selectedNegotiationsPos.map((item, index) => {
					return (
						<li class="list-group-item">
							<Row>
								<Col sm="4" style={{ textAlign: "left" }}>
									{item.name}
								</Col>
								<Col sm="3" style={{ textAlign: "left" }}>
									{item.phone}
								</Col>
								<Col sm="4" style={{ textAlign: "left" }}>
									{(this.state.possiblePos.length > 0) ? (
										this.state.possiblePos.map((val, index) => {
											if (item._id === val.providerId) {
												return (
													<Select value={this.state.possiblePos[index].packId} onChange={(value) => this.handlePackSelect(value, item._id, item.role)}>
														<Option value={undefined}>Selecione pack</Option>
														{(item.packs !== null && item.packs !== undefined) ? (
															item.packs.map((el, index) => {
																return (
																	(el.priceBy === "geral") ? (
																		<Option value={el._id}>{el.name} - {el.price}€</Option>
																	) : (
																			<Option value={el._id}>{el.name} - {el.price}€ pax. </Option>
																		)
																)
															})
														) : (
																<p>Não existem packs para este fornecedor.</p>
															)}
													</Select>
												)
											}
										})
									) : (
											<Select onChange={(value) => this.handlePackSelect(value, item._id, item.role)}>
												<Option value={undefined}>Selecione pack</Option>
												{(item.packs !== null && item.packs !== undefined) ? (
													item.packs.map((el, index) => {
														return (
															(el.priceBy === "geral") ? (
																<Option value={el._id}>{el.name} - {el.price}€</Option>
															) : (
																	<Option value={el._id}>{el.name} - {el.price}€ pax. </Option>
																)
														)
													})
												) : (
														<p>Não existem packs para este fornecedor.</p>
													)}
											</Select>
										)}
								</Col>
								<Col sm="1" style={{ textAlign: "center" }}>
									<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
										<a href={"mailto:" + item.email + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + item.name + ", %0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate + "."}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
									</Tooltip>
								</Col>
							</Row>
						</li>
					)
				})
			} else {
				negotiationsListPos = (
					<li class="list-group-item">
						<Row>
							<p style={{ fontSize: "14px", marginBottom: "0%", marginLeft: "1%" }}>Não existem fornecedores em negociação.</p>
						</Row>
					</li>
				)
			}

			//NEGOCIAÇÕES - CATEGORIA PROCESSO RELIGIOSO
			let multiselectPadre
			if (this.state.padreProviders !== null && this.state.padreProviders !== undefined) {
				multiselectPadre = this.state.padreProviders.map((item, index) => {
					return (
						<Option value={item._id}>{item.name}</Option>
					)
				})
			}

			let selectedNegotiationsPadre = []
			if (multiselectPadreValue !== undefined) {
				selectedNegotiationsPadre = this.state.providers.filter((element) => multiselectPadreValue.includes(element._id))
			}

			let negotiationsListPadre
			if (selectedNegotiationsPadre.length > 0) {
				negotiationsListPadre = selectedNegotiationsPadre.map((item, index) => {
					return (
						<li class="list-group-item">
							<Row>
								<Col sm="4" style={{ textAlign: "left" }}>
									{item.name}
								</Col>
								<Col sm="3" style={{ textAlign: "left" }}>
									{item.phone}
								</Col>
								<Col sm="4" style={{ textAlign: "left" }}>
									{(this.state.possiblePadre.length > 0) ? (
										this.state.possiblePadre.map((val, index) => {
											if (item._id === val.providerId) {
												return (
													<Select value={this.state.possiblePadre[index].packId} onChange={(value) => this.handlePackSelect(value, item._id, item.role)}>
														<Option value={undefined}>Selecione pack</Option>
														{(item.packs !== null && item.packs !== undefined) ? (
															item.packs.map((el, index) => {
																return (
																	(el.priceBy === "geral") ? (
																		<Option value={el._id}>{el.name} - {el.price}€</Option>
																	) : (
																			<Option value={el._id}>{el.name} - {el.price}€ pax. </Option>
																		)
																)
															})
														) : (
																<p>Não existem packs para este fornecedor.</p>
															)}
													</Select>
												)
											}
										})
									) : (
											<Select onChange={(value) => this.handlePackSelect(value, item._id, item.role)}>
												<Option value={undefined}>Selecione pack</Option>
												{(item.packs !== null && item.packs !== undefined) ? (
													item.packs.map((el, index) => {
														return (
															(el.priceBy === "geral") ? (
																<Option value={el._id}>{el.name} - {el.price}€</Option>
															) : (
																	<Option value={el._id}>{el.name} - {el.price}€ pax. </Option>
																)
														)
													})
												) : (
														<p>Não existem packs para este fornecedor.</p>
													)}
											</Select>
										)}
								</Col>
								<Col sm="1" style={{ textAlign: "center" }}>
									<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
										<a href={"mailto:" + item.email + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + item.name + ", %0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate + "."}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
									</Tooltip>
								</Col>
							</Row>
						</li>
					)
				})
			} else {
				negotiationsListPadre = (
					<li class="list-group-item">
						<Row>
							<p style={{ fontSize: "14px", marginBottom: "0%", marginLeft: "1%" }}>Não existem fornecedores em negociação.</p>
						</Row>
					</li>
				)
			}

			//NEGOCIAÇÕES - CATEGORIA SERVIÇOS EXTRA
			//BABYSITTER
			let multiselectBaby
			if (this.state.babysitterProviders !== null && this.state.babysitterProviders !== undefined) {
				multiselectBaby = this.state.babysitterProviders.map((item, index) => {
					return (
						<Option value={item._id}>{item.name}</Option>
					)
				})
			}

			let selectedNegotiationsBaby = []
			if (multiselectBabyValue !== undefined) {
				selectedNegotiationsBaby = this.state.providers.filter((element) => multiselectBabyValue.includes(element._id))
			}

			let negotiationsListBaby
			if (selectedNegotiationsBaby.length > 0) {
				negotiationsListBaby = selectedNegotiationsBaby.map((item, index) => {
					return (
						<li class="list-group-item">
							<Row>
								<Col sm="4" style={{ textAlign: "left" }}>
									{item.name}
								</Col>
								<Col sm="3" style={{ textAlign: "left" }}>
									{item.phone}
								</Col>
								<Col sm="4" style={{ textAlign: "left" }}>
									{(this.state.possibleBaby.length > 0) ? (
										this.state.possibleBaby.map((val, index) => {
											if (item._id === val.providerId) {
												return (
													<Select value={this.state.possibleBaby[index].packId} onChange={(value) => this.handlePackSelect(value, item._id, item.role)}>
														<Option value={undefined}>Selecione pack</Option>
														{(item.packs !== null && item.packs !== undefined) ? (
															item.packs.map((el, index) => {
																return (
																	(el.priceBy === "geral") ? (
																		<Option value={el._id}>{el.name} - {el.price}€</Option>
																	) : (
																			<Option value={el._id}>{el.name} - {el.price}€ pax. </Option>
																		)
																)
															})
														) : (
																<p>Não existem packs para este fornecedor.</p>
															)}
													</Select>
												)
											}
										})
									) : (
											<Select onChange={(value) => this.handlePackSelect(value, item._id, item.role)}>
												<Option value={undefined}>Selecione pack</Option>
												{(item.packs !== null && item.packs !== undefined) ? (
													item.packs.map((el, index) => {
														return (
															(el.priceBy === "geral") ? (
																<Option value={el._id}>{el.name} - {el.price}€</Option>
															) : (
																	<Option value={el._id}>{el.name} - {el.price}€ pax. </Option>
																)
														)
													})
												) : (
														<p>Não existem packs para este fornecedor.</p>
													)}
											</Select>
										)}
								</Col>
								<Col sm="1" style={{ textAlign: "center" }}>
									<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
										<a href={"mailto:" + item.email + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + item.name + ", %0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate + "."}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
									</Tooltip>
								</Col>
							</Row>
						</li>
					)
				})
			} else {
				negotiationsListBaby = (
					<li class="list-group-item">
						<Row>
							<p style={{ fontSize: "14px", marginBottom: "0%", marginLeft: "1%" }}>Não existem fornecedores em negociação.</p>
						</Row>
					</li>
				)
			}

			//LEMBRANCAS
			let multiselectLembrancas
			if (this.state.lembrancasProviders !== null && this.state.lembrancasProviders !== undefined) {
				multiselectLembrancas = this.state.lembrancasProviders.map((item, index) => {
					return (
						<Option value={item._id}>{item.name}</Option>
					)
				})
			}

			let selectedNegotiationsLembrancas = []
			if (multiselectLembrancasValue !== undefined) {
				selectedNegotiationsLembrancas = this.state.providers.filter((element) => multiselectLembrancasValue.includes(element._id))
			}

			let negotiationsListLembrancas
			if (selectedNegotiationsLembrancas.length > 0) {
				negotiationsListLembrancas = selectedNegotiationsLembrancas.map((item, index) => {
					return (
						<li class="list-group-item">
							<Row>
								<Col sm="4" style={{ textAlign: "left" }}>
									{item.name}
								</Col>
								<Col sm="3" style={{ textAlign: "left" }}>
									{item.phone}
								</Col>
								<Col sm="4" style={{ textAlign: "left" }}>
									{(this.state.possibleLembrancas.length > 0) ? (
										this.state.possibleLembrancas.map((val, index) => {
											if (item._id === val.providerId) {
												return (
													<Select value={this.state.possibleLembrancas[index].packId} onChange={(value) => this.handlePackSelect(value, item._id, item.role)}>
														<Option value={undefined}>Selecione pack</Option>
														{(item.packs !== null && item.packs !== undefined) ? (
															item.packs.map((el, index) => {
																return (
																	(el.priceBy === "geral") ? (
																		<Option value={el._id}>{el.name} - {el.price}€</Option>
																	) : (
																			<Option value={el._id}>{el.name} - {el.price}€ pax. </Option>
																		)
																)
															})
														) : (
																<p>Não existem packs para este fornecedor.</p>
															)}
													</Select>
												)
											}
										})
									) : (
											<Select onChange={(value) => this.handlePackSelect(value, item._id, item.role)}>
												<Option value={undefined}>Selecione pack</Option>
												{(item.packs !== null && item.packs !== undefined) ? (
													item.packs.map((el, index) => {
														return (
															(el.priceBy === "geral") ? (
																<Option value={el._id}>{el.name} - {el.price}€</Option>
															) : (
																	<Option value={el._id}>{el.name} - {el.price}€ pax. </Option>
																)
														)
													})
												) : (
														<p>Não existem packs para este fornecedor.</p>
													)}
											</Select>
										)}
								</Col>
								<Col sm="1" style={{ textAlign: "center" }}>
									<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
										<a href={"mailto:" + item.email + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + item.name + ", %0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate + "."}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
									</Tooltip>
								</Col>
							</Row>
						</li>
					)
				})
			} else {
				negotiationsListLembrancas = (
					<li class="list-group-item">
						<Row>
							<p style={{ fontSize: "14px", marginBottom: "0%", marginLeft: "1%" }}>Não existem fornecedores em negociação.</p>
						</Row>
					</li>
				)
			}

			//TRANSPORTE DE CONVIDADOS
			let multiselectTransporte
			if (this.state.transporteProviders !== null && this.state.transporteProviders !== undefined) {
				multiselectTransporte = this.state.transporteProviders.map((item, index) => {
					return (
						<Option value={item._id}>{item.name}</Option>
					)
				})
			}

			let selectedNegotiationsTransporte = []
			if (multiselectTransporteValue !== undefined) {
				selectedNegotiationsTransporte = this.state.providers.filter((element) => multiselectTransporteValue.includes(element._id))
			}

			let negotiationsListTransporte
			if (selectedNegotiationsTransporte.length > 0) {
				negotiationsListTransporte = selectedNegotiationsTransporte.map((item, index) => {
					return (
						<li class="list-group-item">
							<Row>
								<Col sm="4" style={{ textAlign: "left" }}>
									{item.name}
								</Col>
								<Col sm="3" style={{ textAlign: "left" }}>
									{item.phone}
								</Col>
								<Col sm="4" style={{ textAlign: "left" }}>
									{(this.state.possibleTransporte.length > 0) ? (
										this.state.possibleTransporte.map((val, index) => {
											if (item._id === val.providerId) {
												return (
													<Select value={this.state.possibleTransporte[index].packId} onChange={(value) => this.handlePackSelect(value, item._id, item.role)}>
														<Option value={undefined}>Selecione pack</Option>
														{(item.packs !== null && item.packs !== undefined) ? (
															item.packs.map((el, index) => {
																return (
																	(el.priceBy === "geral") ? (
																		<Option value={el._id}>{el.name} - {el.price}€</Option>
																	) : (
																			<Option value={el._id}>{el.name} - {el.price}€ pax. </Option>
																		)
																)
															})
														) : (
																<p>Não existem packs para este fornecedor.</p>
															)}
													</Select>
												)
											}
										})
									) : (
											<Select onChange={(value) => this.handlePackSelect(value, item._id, item.role)}>
												<Option value={undefined}>Selecione pack</Option>
												{(item.packs !== null && item.packs !== undefined) ? (
													item.packs.map((el, index) => {
														return (
															(el.priceBy === "geral") ? (
																<Option value={el._id}>{el.name} - {el.price}€</Option>
															) : (
																	<Option value={el._id}>{el.name} - {el.price}€ pax. </Option>
																)
														)
													})
												) : (
														<p>Não existem packs para este fornecedor.</p>
													)}
											</Select>
										)}
								</Col>
								<Col sm="1" style={{ textAlign: "center" }}>
									<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
										<a href={"mailto:" + item.email + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + item.name + ", %0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate + "."}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
									</Tooltip>
								</Col>
							</Row>
						</li>
					)
				})
			} else {
				negotiationsListTransporte = (
					<li class="list-group-item">
						<Row>
							<p style={{ fontSize: "14px", marginBottom: "0%", marginLeft: "1%" }}>Não existem fornecedores em negociação.</p>
						</Row>
					</li>
				)
			}

			//AULAS DE DANÇA
			let multiselectAulas
			if (this.state.aulasProviders !== null && this.state.aulasProviders !== undefined) {
				multiselectAulas = this.state.aulasProviders.map((item, index) => {
					return (
						<Option value={item._id}>{item.name}</Option>
					)
				})
			}

			let selectedNegotiationsAulas = []
			if (multiselectAulasValue !== undefined) {
				selectedNegotiationsAulas = this.state.providers.filter((element) => multiselectAulasValue.includes(element._id))
			}

			let negotiationsListAulas
			if (selectedNegotiationsAulas.length > 0) {
				negotiationsListAulas = selectedNegotiationsAulas.map((item, index) => {
					return (
						<li class="list-group-item">
							<Row>
								<Col sm="4" style={{ textAlign: "left" }}>
									{item.name}
								</Col>
								<Col sm="3" style={{ textAlign: "left" }}>
									{item.phone}
								</Col>
								<Col sm="4" style={{ textAlign: "left" }}>
									{(this.state.possibleAulas.length > 0) ? (
										this.state.possibleAulas.map((val, index) => {
											if (item._id === val.providerId) {
												return (
													<Select value={this.state.possibleAulas[index].packId} onChange={(value) => this.handlePackSelect(value, item._id, item.role)}>
														<Option value={undefined}>Selecione pack</Option>
														{(item.packs !== null && item.packs !== undefined) ? (
															item.packs.map((el, index) => {
																return (
																	(el.priceBy === "geral") ? (
																		<Option value={el._id}>{el.name} - {el.price}€</Option>
																	) : (
																			<Option value={el._id}>{el.name} - {el.price}€ pax. </Option>
																		)
																)
															})
														) : (
																<p>Não existem packs para este fornecedor.</p>
															)}
													</Select>
												)
											}
										})
									) : (
											<Select onChange={(value) => this.handlePackSelect(value, item._id, item.role)}>
												<Option value={undefined}>Selecione pack</Option>
												{(item.packs !== null && item.packs !== undefined) ? (
													item.packs.map((el, index) => {
														return (
															(el.priceBy === "geral") ? (
																<Option value={el._id}>{el.name} - {el.price}€</Option>
															) : (
																	<Option value={el._id}>{el.name} - {el.price}€ pax. </Option>
																)
														)
													})
												) : (
														<p>Não existem packs para este fornecedor.</p>
													)}
											</Select>
										)}
								</Col>
								<Col sm="1" style={{ textAlign: "center" }}>
									<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
										<a href={"mailto:" + item.email + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + item.name + ", %0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate + "."}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
									</Tooltip>
								</Col>
							</Row>
						</li>
					)
				})
			} else {
				negotiationsListAulas = (
					<li class="list-group-item">
						<Row>
							<p style={{ fontSize: "14px", marginBottom: "0%", marginLeft: "1%" }}>Não existem fornecedores em negociação.</p>
						</Row>
					</li>
				)
			}

			//NEGOCIAÇÕES - CATEGORIA "OUTROS"
			let multiselectOutros
			if (this.state.outrosProviders !== null && this.state.outrosProviders !== undefined) {
				multiselectOutros = this.state.outrosProviders.map((item, index) => {
					return (
						<Option value={item._id}>{item.name}</Option>
					)
				})
			}

			let selectedNegotiationsOutros = []
			if (multiselectAulasValue !== undefined) {
				selectedNegotiationsOutros = this.state.providers.filter((element) => multiselectAulasValue.includes(element._id))
			}

			let negotiationsListOutros
			if (selectedNegotiationsOutros.length > 0) {
				negotiationsListOutros = selectedNegotiationsOutros.map((item, index) => {
					return (
						<li class="list-group-item">
							<Row>
								<Col sm="4" style={{ textAlign: "left" }}>
									{item.name}
								</Col>
								<Col sm="3" style={{ textAlign: "left" }}>
									{item.phone}
								</Col>
								<Col sm="4" style={{ textAlign: "left" }}>
									{(this.state.possibleOutros.length > 0) ? (
										this.state.possibleOutros.map((val, index) => {
											if (item._id === val.providerId) {
												return (
													<Select value={this.state.possibleOutros[index].packId} onChange={(value) => this.handlePackSelect(value, item._id, item.role)}>
														<Option value={undefined}>Selecione pack</Option>
														{(item.packs !== null && item.packs !== undefined) ? (
															item.packs.map((el, index) => {
																return (
																	(el.priceBy === "geral") ? (
																		<Option value={el._id}>{el.name} - {el.price}€</Option>
																	) : (
																			<Option value={el._id}>{el.name} - {el.price}€ pax. </Option>
																		)
																)
															})
														) : (
																<p>Não existem packs para este fornecedor.</p>
															)}
													</Select>
												)
											}
										})
									) : (
											<Select onChange={(value) => this.handlePackSelect(value, item._id, item.role)}>
												<Option value={undefined}>Selecione pack</Option>
												{(item.packs !== null && item.packs !== undefined) ? (
													item.packs.map((el, index) => {
														return (
															(el.priceBy === "geral") ? (
																<Option value={el._id}>{el.name} - {el.price}€</Option>
															) : (
																	<Option value={el._id}>{el.name} - {el.price}€ pax. </Option>
																)
														)
													})
												) : (
														<p>Não existem packs para este fornecedor.</p>
													)}
											</Select>
										)}
								</Col>
								<Col sm="1" style={{ textAlign: "center" }}>
									<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
										<a href={"mailto:" + item.email + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + item.name + ", %0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate + "."}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
									</Tooltip>
								</Col>
							</Row>
						</li>
					)
				})
			} else {
				negotiationsListOutros = (
					<li class="list-group-item">
						<Row>
							<p style={{ fontSize: "14px", marginBottom: "0%", marginLeft: "1%" }}>Não existem fornecedores em negociação.</p>
						</Row>
					</li>
				)
			}

			//NEGOCIAÇÕES - CATEGORIA FOTOGRAFIA E VIDEO
			//FOTOGRAFIA
			let multiselectFoto
			if (this.state.fvProviders !== null && this.state.fvProviders !== undefined) {
				multiselectFoto = this.state.fvProviders.map((item, index) => {
					return (
						<Option value={item._id}>{item.name}</Option>
					)
				})
			}

			let selectedNegotiationsFoto = []
			if (multiselectFotoValue !== undefined) {
				selectedNegotiationsFoto = this.state.providers.filter((element) => multiselectFotoValue.includes(element._id))
			}

			let negotiationsListFoto
			if (selectedNegotiationsFoto.length > 0) {
				negotiationsListFoto = selectedNegotiationsFoto.map((item, index) => {
					return (
						<li class="list-group-item">
							<Row>
								<Col sm="4" style={{ textAlign: "left" }}>
									{item.name}
								</Col>
								<Col sm="3" style={{ textAlign: "left" }}>
									{item.phone}
								</Col>
								<Col sm="4" style={{ textAlign: "left" }}>
									{(this.state.possibleFoto.length > 0) ? (
										this.state.possibleFoto.map((val, index) => {
											if (item._id === val.providerId) {
												return (
													<Select value={this.state.possibleFoto[index].packId} onChange={(value) => this.handlePackSelect(value, item._id, "Fotografia")}>
														<Option value={undefined}>Selecione pack</Option>
														{(item.packs !== null && item.packs !== undefined) ? (
															item.packs.map((el, index) => {
																return (
																	(el.priceBy === "geral") ? (
																		<Option value={el._id}>{el.name} - {el.price}€</Option>
																	) : (
																			<Option value={el._id}>{el.name} - {el.price}€ pax. </Option>
																		)
																)
															})
														) : (
																<p>Não existem packs para este fornecedor.</p>
															)}
													</Select>
												)
											}
										})
									) : (
											<Select onChange={(value) => this.handlePackSelect(value, item._id, item.role)}>
												<Option value={undefined}>Selecione pack</Option>
												{(item.packs !== null && item.packs !== undefined) ? (
													item.packs.map((el, index) => {
														return (
															(el.priceBy === "geral") ? (
																<Option value={el._id}>{el.name} - {el.price}€</Option>
															) : (
																	<Option value={el._id}>{el.name} - {el.price}€ pax. </Option>
																)
														)
													})
												) : (
														<p>Não existem packs para este fornecedor.</p>
													)}
											</Select>
										)}
								</Col>
								<Col sm="1" style={{ textAlign: "center" }}>
									<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
										<a href={"mailto:" + item.email + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + item.name + ", %0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate + "."}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
									</Tooltip>
								</Col>
							</Row>
						</li>
					)
				})
			} else {
				negotiationsListFoto = (
					<li class="list-group-item">
						<Row>
							<p style={{ fontSize: "14px", marginBottom: "0%", marginLeft: "1%" }}>Não existem fornecedores em negociação.</p>
						</Row>
					</li>
				)
			}

			//VIDEO
			let multiselectVideo
			if (this.state.fvProviders !== null && this.state.fvProviders !== undefined) {
				multiselectVideo = this.state.fvProviders.map((item, index) => {
					return (
						<Option value={item._id}>{item.name}</Option>
					)
				})
			}

			let selectedNegotiationsVideo = []
			if (multiselectVideoValue !== undefined) {
				selectedNegotiationsVideo = this.state.providers.filter((element) => multiselectVideoValue.includes(element._id))
			}

			let negotiationsListVideo
			if (selectedNegotiationsVideo.length > 0) {
				negotiationsListVideo = selectedNegotiationsVideo.map((item, index) => {
					return (
						<li class="list-group-item">
							<Row>
								<Col sm="4" style={{ textAlign: "left" }}>
									{item.name}
								</Col>
								<Col sm="3" style={{ textAlign: "left" }}>
									{item.phone}
								</Col>
								<Col sm="4" style={{ textAlign: "left" }}>
									{(this.state.possibleVideo.length > 0) ? (
										this.state.possibleVideo.map((val, index) => {
											if (item._id === val.providerId) {
												return (
													<Select value={this.state.possibleVideo[index].packId} onChange={(value) => this.handlePackSelect(value, item._id, "Video")}>
														<Option value={undefined}>Selecione pack</Option>
														{(item.packs !== null && item.packs !== undefined) ? (
															item.packs.map((el, index) => {
																return (
																	(el.priceBy === "geral") ? (
																		<Option value={el._id}>{el.name} - {el.price}€</Option>
																	) : (
																			<Option value={el._id}>{el.name} - {el.price}€ pax. </Option>
																		)
																)
															})
														) : (
																<p>Não existem packs para este fornecedor.</p>
															)}
													</Select>
												)
											}
										})
									) : (
											<Select onChange={(value) => this.handlePackSelect(value, item._id, item.role)}>
												<Option value={undefined}>Selecione pack</Option>
												{(item.packs !== null && item.packs !== undefined) ? (
													item.packs.map((el, index) => {
														return (
															(el.priceBy === "geral") ? (
																<Option value={el._id}>{el.name} - {el.price}€</Option>
															) : (
																	<Option value={el._id}>{el.name} - {el.price}€ pax. </Option>
																)
														)
													})
												) : (
														<p>Não existem packs para este fornecedor.</p>
													)}
											</Select>
										)}
								</Col>
								<Col sm="1" style={{ textAlign: "center" }}>
									<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
										<a href={"mailto:" + item.email + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + item.name + ", %0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate + "."}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
									</Tooltip>
								</Col>
							</Row>
						</li>
					)
				})
			} else {
				negotiationsListVideo = (
					<li class="list-group-item">
						<Row>
							<p style={{ fontSize: "14px", marginBottom: "0%", marginLeft: "1%" }}>Não existem fornecedores em negociação.</p>
						</Row>
					</li>
				)
			}

			//FOTOGRAFIA E VIDEO
			let multiselectFV
			if (this.state.fvProviders !== null && this.state.fvProviders !== undefined) {
				multiselectFV = this.state.fvProviders.map((item, index) => {
					return (
						<Option value={item._id}>{item.name}</Option>
					)
				})
			}

			let selectedNegotiationsFV = []
			if (multiselectFVValue !== undefined) {
				selectedNegotiationsFV = this.state.providers.filter((element) => multiselectFVValue.includes(element._id))
			}

			let negotiationsListFV
			if (selectedNegotiationsFV.length > 0) {
				negotiationsListFV = selectedNegotiationsFV.map((item, index) => {
					return (
						<li class="list-group-item">
							<Row>
								<Col sm="4" style={{ textAlign: "left" }}>
									{item.name}
								</Col>
								<Col sm="3" style={{ textAlign: "left" }}>
									{item.phone}
								</Col>
								<Col sm="4" style={{ textAlign: "left" }}>
									{(this.state.possibleFV.length > 0) ? (
										this.state.possibleFV.map((val, index) => {
											if (item._id === val.providerId) {
												return (
													<Select value={this.state.possibleFV[index].packId} onChange={(value) => this.handlePackSelect(value, item._id, item.role)}>
														<Option value={undefined}>Selecione pack</Option>
														{(item.packs !== null && item.packs !== undefined) ? (
															item.packs.map((el, index) => {
																return (
																	(el.priceBy === "geral") ? (
																		<Option value={el._id}>{el.name} - {el.price}€</Option>
																	) : (
																			<Option value={el._id}>{el.name} - {el.price}€ pax. </Option>
																		)
																)
															})
														) : (
																<p>Não existem packs para este fornecedor.</p>
															)}
													</Select>
												)
											}
										})
									) : (
											<Select onChange={(value) => this.handlePackSelect(value, item._id, item.role)}>
												<Option value={undefined}>Selecione pack</Option>
												{(item.packs !== null && item.packs !== undefined) ? (
													item.packs.map((el, index) => {
														return (
															(el.priceBy === "geral") ? (
																<Option value={el._id}>{el.name} - {el.price}€</Option>
															) : (
																	<Option value={el._id}>{el.name} - {el.price}€ pax. </Option>
																)
														)
													})
												) : (
														<p>Não existem packs para este fornecedor.</p>
													)}
											</Select>
										)}
								</Col>
								<Col sm="1" style={{ textAlign: "center" }}>
									<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
										<a href={"mailto:" + item.email + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + item.name + ", %0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate + "."}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
									</Tooltip>
								</Col>
							</Row>
						</li>
					)
				})
			} else {
				negotiationsListFV = (
					<li class="list-group-item">
						<Row>
							<p style={{ fontSize: "14px", marginBottom: "0%", marginLeft: "1%" }}>Não existem fornecedores em negociação.</p>
						</Row>
					</li>
				)
			}

			

			return (
				<form >
					<div style={{ marginBottom: "5%" }}>
						<Container style={{ paddingRight: 0, marginLeft: "1%", marginRight: "2%", maxWidth: "-webkit-fill-available" }}>
							<Row>
								<Col className="inline-col">
									<h4 className="pointer" style={{ textAlign: "left", marginBottom: "2%", marginTop: "13px", marginRight: "1%" }}><ArrowBack style={{ fontSize: "18px" }} onClick={() => { this.props.history.push('/wedding_details/' + this.state.wed_id) }} /></h4>
									<h4 style={{ textAlign: "left", marginBottom: "2%", marginTop: "15px" }}>{this.state.roleUrl}</h4>
								</Col>
								<Col className="inline-col" sm="1" style={{ marginTop: "15px", justifyContent: "flex-end" }}>
									<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Fechar Serviço" placement="top">
										{(this.state.isClosed === true || this.state.isClosed === false) ? (
											<Switch size="medium" style={{ marginLeft: "2%", marginTop: "0.5%" }} checkedChildren={""} unCheckedChildren={""} defaultChecked={this.state.isClosed} onChange={this.onChangeClosed} />
										) : (
												<p></p>
											)}
									</Tooltip>
								</Col>
								<Col className="inline-col" sm="1" style={{ marginTop: "15px", marginRight:"-60px" }}>
									<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Reuniões" placement="top">
										<MeetingIcon className="pointer" style={{ fontSize: "24px" }} onClick={() => this.toggleMeetings(true, this.state.role_url)} />
									</Tooltip>
								</Col>
							{(this.state.roleUrl === "Processo Religioso" || this.state.roleUrl === "Processo Civil/Simbólico") ? (
								<Col className="inline-col" sm="1" style={{ marginTop: "15px", marginRight: "-40px"}}>
									<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Guidelines" placement="top">
										<InfoIcon className="pointer" style={{ fontSize: "24px" }} onClick={() => this.toggleInfo(true)} />
									</Tooltip>
								</Col>
							) : (
								null
							)}
							</Row>
							{(this.state.infoGeral !== null && this.state.infoGeral !== undefined) ? (
								<Row style={{ marginBottom: "1%", width: "95%", marginLeft: "2.5%" }}>
									<p style={{ fontSize: "13px" }}>{this.state.infoGeral}</p>
								</Row>
							) : (
									null
								)}
							<Row style={{ marginBottom: "1%", marginLeft: "0%", justifyContent: "center" }}>
								<Collapse accordion style={{ width: "95%" }}>
									<Panel header="Informações referentes ao questionário" key="1">
										{(this.state.quizRes !== null && this.state.quizRes !== undefined) ? (
											this.state.quizRes.map((item, index) => (
												<div>
													<p className="p-title-label">{item.q}</p>
													<p className="p-title">{item.a}</p>
												</div>
											))
										) : (
												<p style={{ marginTop: "2%" }}>Não existem perguntas no questionário associadas a esta categoria.</p>
											)}
									</Panel>
								</Collapse>
							</Row>
							{(this.state.roleUrl === "Igreja" || this.state.roleUrl === "Alojamento" || this.state.roleUrl === "Pré ou Pós Evento" || this.state.roleUrl === "Website" || this.state.roleUrl === "Processo Religioso"
							|| this.state.roleUrl === "Serviços Extra" || this.state.roleUrl === "Outros") ? (
								null
							) : (
								<Row style={{ marginBottom: "2%", marginTop:"2%", marginLeft: "0%", justifyContent: "center" }}>
									<CardAntd title={<span style={{fontSize:"14px"}}><WarningIcon style={{fontSize:"22px", marginRight:"1%", color:"#F4D666"}}/>  Não te esqueças!</span>} bordered={true} style={{ width:"95%" }}>
										{(this.state.roleUrl === "Espaço") ? (
											<div>
												<p className="p-label-reminders">- Confirma sempre se há limitações no espaço (horários, bar aberto etc) e confirma sempre muito bem tudo o que está incluído nos preços para não haver surpresas.</p>
												<p className="p-label-reminders">- Quando agendares a degustação, questiona ao fornecedor quais os pratos que devem selecionar e, se os acompanhares, toma nota de todas as críticas que forem dando e demonstra o teu papel ativo durante o processo! Da sugestões de melhoria, mas nunca critiques negativamente!</p>
												<p className="p-label-reminders">- Após degustação envia sempre e-mail a confirmar os pratos selecionados e a fazer um follow up para todos os intervenientes do que ficou registado ou foi conversado.</p>
											</div>
										) : (
											<span></span>
										)}
										{(this.state.roleUrl === "Save the Date e Convite") ? (
											<div>
												<p className="p-label-reminders">- Pede 3 ou 4 imagens de convites que os noivos gostam (slack).</p>
												<p className="p-label-reminders">- Cria Mood Board: plataforma (canva).</p>
												<p className="p-label-reminders">- Apresenta-o aos clientes para aprovação → Tens 1 semana para que aprovem ou modifiquem.</p>
												<p className="p-label-reminders">- Quando for aceite, pelos clientes, fixa-o no canal dos clientes slack!</p>
												<p className="p-label-reminders">- Pede 3 ou 4 imagens de convites que os noivos gostam (slack).</p>
											</div>
										) : (
											<span></span>
										)}
										{(this.state.roleUrl === "Makeup e Cabelo") ? (
											<div>
												<p className="p-label-reminders"><b>Antes de começar:</b></p>
												<p className="p-label-reminders">- Pede aos clientes para verem as redes sociais e nos enviarem fotos que gostam dentro desta categoria (slack).</p>
												<p className="p-label-reminders">- Não te esqueças que, depois de adjudicado o serviço, temos de agendar a prova! É importante as clientes definirem o que querem antes do casamento.</p>
											</div>
										) : (
											<span></span>
										)}
										{(this.state.roleUrl === "Roupa Noiva" || this.state.roleUrl === "Roupa Noivo") ? (
											<div>
												<p className="p-label-reminders"><b>Antes de começar:</b></p>
												<p className="p-label-reminders">- Ferramentas para começar: Solicitar aos clientes slack que vejam o nosso site e instagram e nos digam quais as galerias de fotografias que mais gostam.</p>
											</div>
										) : (
											<span></span>
										)}
										{(this.state.roleUrl === "Fotografia e Video") ? (
											<div>
												<p className="p-label-reminders"><b>Antes de começar:</b></p>
												<p className="p-label-reminders">- Pede aos clientes para verem o nosso website e nos dizerem quais as galerias de fotografias que mais gostam (slack).</p>
												<p className="p-label-reminders">- Se houver algumas fotografias ou videos online que gostem, para partilharem connosco também (slack)!</p>
											</div>
										) : (
											<span></span>
										)}
										{(this.state.roleUrl === "Animação") ? (
											<div>
												<p className="p-label-reminders"><b>Antes de começar:</b></p>
												<p className="p-label-reminders">- Cria o planeamento musical dos clientes, de acordo com os serviços que, dentro desta área, os clientes pretendem.</p>
												<p className="p-label-reminders">- Se te deixam ser criativa, é a tua oportunidade, surpreende-os!</p>
												<p className="p-label-reminders">- Apresenta-o aos clientes para aprovação. —> Tens 1 semana para que aprovem ou modifiquem. (Fingers crossed!)</p>
											</div>
										) : (
											<span></span>
										)}
										{(this.state.roleUrl === "Decoração" || this.state.roleUrl === "Design Gráfico" || this.state.roleUrl === "Florista") ? (
											<div>
												<p className="p-label-reminders"><b>Antes de começar:</b></p>
												<p className="p-label-reminders">- Pede aos clientes para verem as redes sociais e nos enviarem fotos que gostam dentro desta categoria (slack).</p>
											</div>
										) : (
											<span></span>
										)}
										{(this.state.roleUrl === "Processo Civil/Simbólico") ? (
											<div>
												<p className="p-label-reminders"><b>Processo Civil:</b></p>
												<p className="p-label-reminders">- Se falamos de casamento civil, as tarefas apresentadas não se aplicam.</p>
												<p className="p-label-reminders">- É necessário saber que este assunto só pode ser tratado 6 meses antes da data agendada do casamento e não antes. Tem atenção às guidelines para ver se não falta nada!</p>
												<p className="p-label-reminders">- Deverá estar concluída até 2 meses antes do casamento.</p>
												<p className="p-label-reminders"><b>Casamento Simbólico:</b></p>
												<p className="p-label-reminders">- Temos de encontrar o celebrante à altura do casal! Aqui, segue a lista de tarefas apresentada.</p>
											</div>
										) : (
											<span></span>
										)}
									</CardAntd>
								</Row>
							)}

							{(this.state.roleUrl === "Igreja" || this.state.roleUrl === "Save the Date e Convite" || this.state.roleUrl === "Makeup e Cabelo" || this.state.roleUrl === "Roupa Noiva"  || this.state.roleUrl === "Alojamento" 
							|| this.state.roleUrl === "Processo Civil/Simbólico" || this.state.roleUrl === "Roupa Noivo" || this.state.roleUrl === "Florista" || this.state.roleUrl === "Website" || this.state.roleUrl === "Decoração" || this.state.roleUrl === "Design Gráfico") ? (
									<div>
										<Row style={{marginTop: "4%", marginLeft: "2%" }}>
											<h6 style={{ textAlign: "left"}}><b>Checklist</b></h6>
										</Row>
										<Row style={{ marginBottom: "1%", marginTop:"1%", marginLeft: "0%", justifyContent: "center" }}>
											<Collapse accordion style={{ width: "95%" }}>
												<Panel header="Tarefas" key="1">
													{(this.state.ckecklistText !== null && this.state.checklistText !== undefined) ? (
														this.state.checklistText.map((item, index) => {
															let element = this.state.checklistState.find( (el) => el.toDo === item.toDo)
															let isChecked = (element) ? element.isChecked : false
															return(
																<div>
																	<Checkbox checked={isChecked} style={{marginBottom:"1%"}} onChange={(e) => { this.changeChecklist(item.toDo, e.target.checked)}}> {item.text}</Checkbox>
																</div>
															)	
														})
													) : (
														<p style={{ marginTop: "2%" }}>Não existe checklist associada a esta categoria.</p>
													)}
												</Panel>
											</Collapse>
										</Row>
										<Row style={{ marginTop: "5%", marginLeft: "1%" }}>
											<Col className="inline-col">
												<h6 style={{ textAlign: "left" }}><b>Fornecedores em Negociação</b></h6>
											</Col>
										</Row>
										<Row style={{ marginBottom: "2%", marginLeft: "0%", justifyContent: "center" }}>
											<Select value={multiselectGeneralValue} placeholder="Selecione Fornecedor." style={{ width: "95%" }} mode="multiple" placeholder="Selecione Fornecedor" onChange={(value) => this.handleChange(value, this.state.roleUrl)}>
												{multiselectProv}
											</Select>
										</Row>
										<Row style={{ marginBottom: "4%", marginLeft: "0%", justifyContent: "center" }}>
											<ul class="list-group" style={{ width: "95%" }}>
												{negotiationsList}
											</ul>
										</Row>
										<Row style={{ marginTop: "3%", marginLeft: "1%" }}>
											<Col className="inline-col">
												<h6 style={{ textAlign: "left" }}><b>Contratação final</b></h6>
											</Col>
										</Row>
										<Row style={{ marginBottom: "4%", marginLeft: "0%", justifyContent: "center" }}>
											{(this.state.selectedProvider !== null && this.state.selectedProvider !== undefined && this.state.selectedPack !== null && this.state.selectedPack !== undefined) ? (
												<Collapse accordion style={{ width: "95%", backgroundColor: "#bbe4ca2e" }}>
													<Panel header="Fornecedor Contratado" key="1" extra={genExtra()}>
														<Row style={{ marginBottom: "1%", marginTop: "2%", marginLeft: "0%" }}>
															<Col className="inline-col">
																<h6 style={{ textAlign: "left" }}><b>Fornecedor</b></h6>
															</Col>
															{(this.state.selectedProvider === null || this.state.selectedProvider === undefined) ? (
																<Col></Col>
															) : (
																<Col className="inline-col" style={{justifyContent:"flex-end"}}>
																	<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
																		<a href={"mailto:" + this.state.email + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + this.state.name + ", " + "%0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
																	</Tooltip>
																	<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Upload Contrato" placement="top">
																		<AddIcon style={{marginLeft:"1%"}} className="btn-add pointer" onClick={() => this.toggleUpload(true, this.state.roleUrl, this.state.selectedProvider)}/>
																	</Tooltip>
																	{(this.state.hasContractGeneral === true) ? (
																		<div className="inline-col">
																			<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Visualizar Contrato" placement="top">
																				<PdfIcon style={{marginLeft:"1%"}} className="pointer" onClick={() => this.download(this.state.roleUrl, this.state.selectedProvider)}/>
																			</Tooltip>
																			<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Eliminar Contrato" placement="top">
																				<DeleteIcon style={{marginLeft:"1%"}} className="btn-delete pointer"onClick={() => this.toggleDelete(true, this.state.roleUrl, this.state.selectedProvider )}/>
																			</Tooltip>
																		</div>
																	) : (
																		<p></p>
																	)}
																</Col>
															)}
														</Row>
														<Row style={{ justifyContent:"space-evenly", marginBottom:"2%" }}>
															{/* <Col sm="6"> */}
																<Select style={{width:"47%"}} placeholder="Selecione Fornecedor." value={this.state.selectedProvider} onChange={this.changeSelectProvider}>
																	<Option value={undefined}>Selecione Fornecedor</Option>
																	{providersData}
																</Select>
															{/* </Col>
															<Col sm="6"> */}
																<Select style={{width:"47%"}} placeholder="Selecione Pack." value={this.state.selectedPack} onChange={this.changeSelectPack}>
																	<Option value={undefined}>Selecione Pack</Option>
																	{providersPacks}
																</Select>
															{/* </Col> */}
														</Row>
														{/* <Row style={{ marginBottom: "2%" }}>
															<Col style={{ width: "50%" }}>
																{providerInfo}
															</Col>
															<Col>
																{detailsPack}
															</Col>
														</Row> */}
														<Row style={{justifyContent:"space-evenly", marginBottom:"2%"}}>
															{providerInfo}
															{detailsPack}
														</Row>
														<Row style={{ marginBottom: "2%", marginRight: "0%", textAlign: "right", float: "right" }}>
															<Button className="btn-user" onClick={this.updateProviders}>Contratar</Button>
														</Row>
													</Panel>
												</Collapse>
											) : (
													<Collapse accordion style={{ width: "95%" }}>
														<Panel header="Fornecedor Contratado" key="1">
															<Row style={{ marginBottom: "1%", marginTop: "2%", marginLeft: "0%" }}>
																<Col className="inline-col">
																	<h6 style={{ textAlign: "left" }}><b>Fornecedor</b></h6>
																</Col>
																{(this.state.selectedProvider === null || this.state.selectedProvider === undefined) ? (
																	<Col></Col>
																) : (
																		<Col className="inline-col" sm="1">
																			<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
																				<a href={"mailto:" + this.state.email + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + this.state.name + ", " + "%0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
																			</Tooltip>
																		</Col>
																	)}
															</Row>
															{/* <Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
																<Col sm="6">
																	<Select placeholder="Selecione Fornecedor." value={this.state.selectedProvider} onChange={this.changeSelectProvider}>
																		<Option value={undefined}>Selecione Fornecedor</Option>
																		{providersData}
																	</Select>
																</Col>
																<Col sm="6">
																	<Select placeholder="Selecione Pack." value={this.state.selectedPack} onChange={this.changeSelectPack}>
																		<Option value={undefined}>Selecione Pack</Option>
																		{providersPacks}
																	</Select>
																</Col>
															</Row> */}
															<Row style={{ justifyContent:"space-evenly", marginBottom:"2%" }}>
																{/* <Col sm="6"> */}
																	<Select style={{width:"47%"}} placeholder="Selecione Fornecedor." value={this.state.selectedProvider} onChange={this.changeSelectProvider}>
																		<Option value={undefined}>Selecione Fornecedor</Option>
																		{providersData}
																	</Select>
																{/* </Col>
																<Col sm="6"> */}
																	<Select style={{width:"47%"}} placeholder="Selecione Pack." value={this.state.selectedPack} onChange={this.changeSelectPack}>
																		<Option value={undefined}>Selecione Pack</Option>
																		{providersPacks}
																	</Select>
																{/* </Col> */}
															</Row>
															{/* <Row style={{ marginBottom: "2%" }}>
																<Col style={{ width: "50%" }}>
																	{providerInfo}
																</Col>
																<Col>
																	{detailsPack}
																</Col>
															</Row> */}
															<Row style={{marginLeft:"15px", marginBottom:"2%"}}>
																{providerInfo}
															</Row>
															<Row style={{ marginBottom: "2%", marginRight: "0%", textAlign: "right", float: "right" }}>
																<Button className="btn-user" onClick={this.updateProviders}>Contratar</Button>
															</Row>
														</Panel>
													</Collapse>
												)}
										</Row>
										<Row style={{ marginLeft: "1%" }}>
											<Col className="inline-col">
												<h6 style={{ textAlign: "left" }}><b>Notas</b></h6>
											</Col>
										</Row>
										<Row style={{ marginBottom: "2%", marginLeft: "0%", justifyContent: "center"}}>
											<FormTextarea id="text-area" value={this.state.notesGeneralRole} style={{ width: "95%" }} onChange={(el) => { this.handleNotesText("notesGeneralRole", el.target.value) }} />
										</Row>
										<Row style={{ marginBottom: "5%",  marginRight: "0%", textAlign: "right", float: "right" }}>
											<Button className="btn-user" onClick={() => {this.saveNotes(this.state.roleUrl)}}>Gravar Notas</Button>
										</Row>
									</div>
								) : (
									<Row></Row>
								)}

							{(this.state.roleUrl === "Espaço") ? (
								<div>
									<Row style={{marginTop: "2%", marginLeft: "0%" }}>
										<h6 style={{ textAlign: "left"}}><b>Checklist</b></h6>
									</Row>
									<Row style={{ marginBottom: "1%", marginTop:"1%", marginLeft: "0%", justifyContent: "center" }}>
										<Collapse accordion style={{ width: "95%" }}>
											<Panel header="Tarefas" key="1">
												{(this.state.ckecklistText !== null && this.state.checklistText !== undefined) ? (
													this.state.checklistText.map((item, index) => {
														let element = this.state.checklistState.find( (el) => el.toDo === item.toDo)
														let isChecked = (element) ? element.isChecked : false
														return(
															<div>
																<Checkbox checked={isChecked} style={{marginBottom:"1%"}} onChange={(e) => { this.changeChecklist(item.toDo, e.target.checked)}}> {item.text}</Checkbox>
															</div>
														)	
													})
												) : (
													<p style={{ marginTop: "2%" }}>Não existe checklist associada a esta categoria.</p>
												)}
											</Panel>
										</Collapse>
									</Row>
									<Row style={{ marginBottom: "1%", marginTop: "5%", marginLeft: "0%" }}>
										<h5 style={{ textAlign: "left", color: "#C09F92" }}><b>Espaço</b></h5>
										{(this.state.espacoWanted === true || this.state.espacoWanted === false) ? (
											<Switch size="small" style={{ marginLeft: "2%", marginTop: "0.5%" }} checkedChildren={""} unCheckedChildren={""} defaultChecked={this.state.espacoWanted} onChange={(checked) => this.onChangeIsWanted("espacoWanted", this.state.roleUrl, "Espaço", checked)} />
										) : (
											<p></p>
										)}
										
									</Row>
									{/* {(this.state.infoEspaco !== null && this.state.infoEspaco !== undefined) ? (
										<Row style={{ marginBottom: "1%", width: "95%", marginLeft: "0%" }}>
											<p style={{ fontSize: "13px" }}>{this.state.infoEspaco}</p>
										</Row>
									) : (
											null
									)} */}
									<Row style={{ marginTop: "2%", marginLeft: "1%" }}>
										<Col className="inline-col">
											<h6 style={{ textAlign: "left" }}><b>Fornecedores em Negociação</b></h6>
										</Col>
									</Row>
									<Row style={{ marginBottom: "2%", marginLeft: "0%", justifyContent: "center" }}>
										<Select value={multiselectEspacoValue} placeholder="Selecione Fornecedor." style={{ width: "95%" }} mode="multiple" placeholder="Selecione Fornecedor" onChange={(value) => this.handleChange(value, "Espaço")}>
											{multiselectEspaco}
										</Select>
									</Row>
									<Row style={{ marginBottom: "4%", marginLeft: "0%", justifyContent: "center" }}>
										<ul class="list-group" style={{ width: "95%" }}>
											{negotiationsListEspaco}
										</ul>
									</Row>
									<Row style={{ marginBottom: "3%", marginLeft: "0%", justifyContent: "center" }}>
										<Col className="inline-col">
											<h6 style={{ textAlign: "left", marginRight: "1%" }}><b>Contratações finais</b></h6>
											<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Adicionar Fornecedor" placement="top">
												<ControlPointIcon className="btn-inverted pointer" onClick={this.addProviderRow} />
											</Tooltip>
										</Col>
											<Collapse accordion defaultActiveKey={['1']} style={{ width: "95%" }}>
												<Panel header="Fornecedores Contratados" key="1">
													{(providerListEspaco.length > 0) ? (
														<div>
															{providerListEspaco}
															<Row style={{ marginBottom: "2%", marginRight: "0%", textAlign: "right", float: "right" }}>
																<Button className="btn-user" onClick={this.updateProviders}>Contratar</Button>
															</Row>
														</div>
													) : (
														<span>Utilize o <ControlPointIcon style={{ fontSize: "14px" }} /> para adicionar contratação.</span>
													)}
												</Panel>
											</Collapse>
									</Row>
									<Row style={{ marginLeft: "1%" }}>
										<Col className="inline-col">
											<h6 style={{ textAlign: "left" }}><b>Notas</b></h6>
										</Col>
									</Row>
									<Row style={{ marginBottom: "2%", marginLeft: "0%", justifyContent: "center"}}>
										<FormTextarea id="text-area" value={this.state.notesEspacoRole} style={{ width: "95%" }} onChange={(el) => { this.handleNotesText("notesEspacoRole", el.target.value) }} />
									</Row>
									<Row style={{ marginBottom: "5%",  marginRight: "0%", textAlign: "right", float: "right" }}>
										<Button className="btn-user" onClick={() => {this.saveNotes("Espaço")}}>Gravar Notas</Button>
									</Row>

									<Row style={{ marginBottom: "1%", marginTop: "8%", marginLeft: "0%" }}>
										<h5 style={{ textAlign: "left", color: "#C09F92" }}><b>Catering</b></h5>
										{(this.state.cateringWanted === true || this.state.cateringWanted === false) ? (
										<Switch size="small" style={{ marginLeft: "2%", marginTop: "0.5%" }} checkedChildren={""} unCheckedChildren={""} defaultChecked={this.state.cateringWanted} onChange={(checked) => this.onChangeIsWanted("cateringWanted", this.state.roleUrl, "Catering", checked)} />
										) : (
											<p></p>
										)}
										</Row>
									{/* {(this.state.infoCatering !== null && this.state.infoCatering !== undefined) ? (
										<Row style={{ marginBottom: "1%", width: "95%", marginLeft: "0%" }}>
											<p style={{ fontSize: "13px" }}>{this.state.infoCatering}</p>
										</Row>
									) : (
											null
										)} */}
									<Row style={{ marginTop: "2%", marginLeft: "1%" }}>
										<Col className="inline-col">
											<h6 style={{ textAlign: "left" }}><b>Fornecedores em Negociação</b></h6>
										</Col>
									</Row>
									<Row style={{ marginBottom: "2%", marginLeft: "0%", justifyContent: "center" }}>
										<Select value={multiselectCateringValue} placeholder="Selecione Fornecedor." style={{ width: "95%" }} mode="multiple" placeholder="Selecione Fornecedor" onChange={(value) => this.handleChange(value, "Catering")}>
											{multiselectCatering}
										</Select>
									</Row>
									<Row style={{ marginBottom: "4%", marginLeft: "0%", justifyContent: "center" }}>
										<ul class="list-group" style={{ width: "95%" }}>
											{negotiationsListCatering}
										</ul>
									</Row>
									<Row style={{ marginBottom: "3%", marginLeft: "0%", justifyContent: "center" }}>
										{(this.state.selectedProviderCatering !== null && this.state.selectedProviderCatering !== undefined && this.state.selectedPackCatering !== null && this.state.selectedPackCatering !== undefined) ? (
											<Collapse accordion style={{ width: "95%", backgroundColor: "#bbe4ca2e" }}>
												<Panel header="Fornecedor Contratado" key="1" extra={genExtra()}>
													<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
														<Col className="inline-col" style={{ marginTop: "15px" }}>
															<h6 style={{ textAlign: "left" }}><b>Fornecedor</b></h6>
														</Col>
														{(this.state.selectedProviderCatering === null || this.state.selectedProviderCatering === undefined) ? (
															<Col></Col>
														) : (
															<Col className="inline-col" style={{justifyContent:"flex-end"}}>
																<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
																<a href={"mailto:" + this.state.emailCatering + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + this.state.nameCatering + ", " + "%0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
																</Tooltip>
																<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Upload Contrato" placement="top">
																	<AddIcon style={{marginLeft:"1%"}} className="btn-add pointer" onClick={() => this.toggleUpload(true,"Catering", this.state.selectedProviderCatering)}/>
																</Tooltip>
																{(this.state.hasContractCatering === true) ? (
																	<div className="inline-col">
																		<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Visualizar Contrato" placement="top">
																			<PdfIcon style={{marginLeft:"1%"}} className="pointer"  onClick={() => this.download("Catering", this.state.selectedProviderCatering)}/>
																		</Tooltip>
																		<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Eliminar Contrato" placement="top">
																			<DeleteIcon style={{marginLeft:"1%"}} className="btn-delete pointer"onClick={() => this.toggleDelete(true, "Catering", this.state.selectedProviderCatering )}/>
																		</Tooltip>
																	</div>
																) : (
																	<p></p>
																)}
															</Col>
															)}
													</Row>
													<Row style={{ justifyContent:"space-evenly", marginBottom:"2%" }}>
														<Select style={{width:"47%"}} placeholder="Selecione Fornecedor." value={this.state.selectedProviderCatering} onChange={this.changeSelectProviderCatering}>
															<Option value={undefined}>Selecione Fornecedor</Option>
															{providerCatering}
														</Select>
														<Select style={{width:"47%"}} placeholder="Selecione Pack." value={this.state.selectedPackCatering} onChange={this.changeSelectPackCatering}>
															<Option value={undefined}>Selecione Pack</Option>
															{providerCateringPacks}
														</Select>
													</Row>
													<Row style={{justifyContent:"space-evenly", marginBottom:"2%"}}>
														{providerCateringInfo}
														{detailsPackCatering}
													</Row>
													<Row style={{ marginBottom: "2%", marginRight: "0%", textAlign: "right", float: "right" }}>
														<Button className="btn-user" onClick={this.updateProviders}>Contratar</Button>
													</Row>
												</Panel>
											</Collapse>
										) : (
												<Collapse accordion style={{ width: "95%" }}>
													<Panel header="Fornecedor Contratado" key="1">
														<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
															<Col className="inline-col" style={{ marginTop: "15px" }}>
																<h6 style={{ textAlign: "left" }}><b>Fornecedor</b></h6>
															</Col>
															{(this.state.selectedProviderCatering === null || this.state.selectedProviderCatering === undefined) ? (
																<Col></Col>
															) : (
																	<Col className="inline-col" sm="1" style={{ marginTop: "15px" }}>
																		<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
																			<a href={"mailto:" + this.state.emailCatering + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + this.state.nameCatering + ", " + "%0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
																		</Tooltip>
																	</Col>
																)}
														</Row>
														<Row style={{ justifyContent:"space-evenly", marginBottom:"2%" }}>
															<Select  style={{width:"47%"}} placeholder="Selecione Fornecedor." value={this.state.selectedProviderCatering} onChange={this.changeSelectProviderCatering}>
																<Option value={undefined}>Selecione Fornecedor</Option>
																{providerCatering}
															</Select>
															<Select style={{width:"47%"}} placeholder="Selecione Pack." value={this.state.selectedPackCatering} onChange={this.changeSelectPackCatering}>
																<Option value={undefined}>Selecione Pack</Option>
																{providerCateringPacks}
															</Select>
														</Row>
														<Row style={{marginLeft:"15px", marginBottom:"2%"}}>
															{providerCateringInfo}
														</Row>
														<Row style={{ marginBottom: "2%", marginRight: "0%", textAlign: "right", float: "right" }}>
															<Button className="btn-user" onClick={this.updateProviders}>Contratar</Button>
														</Row>
													</Panel>
												</Collapse>

											)}
									</Row>
									<Row style={{ marginLeft: "1%" }}>
										<Col className="inline-col">
											<h6 style={{ textAlign: "left" }}><b>Notas</b></h6>
										</Col>
									</Row>
									<Row style={{ marginBottom: "2%", marginLeft: "0%", justifyContent: "center"}}>
										<FormTextarea id="text-area" value={this.state.notesCateringRole} style={{ width: "95%" }} onChange={(el) => { this.handleNotesText("notesCateringRole", el.target.value) }} />
									</Row>
									<Row style={{ marginBottom: "5%",  marginRight: "0%", textAlign: "right", float: "right" }}>
										<Button className="btn-user" onClick={() => {this.saveNotes("Catering")}}>Gravar Notas</Button>
									</Row>

									<Row style={{ marginBottom: "1%", marginTop: "8%", marginLeft: "0%" }}>
										<h5 style={{ textAlign: "left", color: "#C09F92" }}><b>Espaço e Catering</b></h5>
										{(this.state.espCatWanted === true || this.state.espCatWanted === false) ? (
											<Switch size="small" style={{ marginLeft: "2%", marginTop: "0.5%" }} checkedChildren={""} unCheckedChildren={""} defaultChecked={this.state.espCatWanted} onChange={(checked) => this.onChangeIsWanted("espCatWanted", this.state.roleUrl, "Espaço e Catering", checked)} />
										) : (
											<p></p>
										)}
										</Row>
									{/* {(this.state.infoEspCat !== null && this.state.infoEspCat !== undefined) ? (
										<Row style={{ marginBottom: "1%", width: "95%", marginLeft: "0%" }}>
											<p style={{ fontSize: "13px" }}>{this.state.infoEspCat}</p>
										</Row>
									) : (
											null
										)} */}
									<Row style={{ marginTop: "2%", marginLeft: "1%" }}>
										<Col className="inline-col">
											<h6 style={{ textAlign: "left" }}><b>Fornecedores em Negociação</b></h6>
										</Col>
									</Row>
									<Row style={{ marginBottom: "2%", marginLeft: "0%", justifyContent: "center" }}>
										<Select value={multiselectEspCatValue} placeholder="Selecione Fornecedor." style={{ width: "95%" }} mode="multiple" placeholder="Selecione Fornecedor" onChange={(value) => this.handleChange(value, "Espaço e Catering")}>
											{multiselectEspCat}
										</Select>
									</Row>
									<Row style={{ marginBottom: "4%", marginLeft: "0%", justifyContent: "center" }}>
										<ul class="list-group" style={{ width: "95%" }}>
											{negotiationsListEspCat}
										</ul>
									</Row>
									<Row style={{ marginBottom: "3%", marginLeft: "0%", justifyContent: "center" }}>
										{(this.state.selectedProviderEspCat !== null && this.state.selectedProviderEspCat !== undefined && this.state.selectedPackEspCat !== null && this.state.selectedPackEspCat !== undefined) ? (
											<Collapse accordion style={{ width: "95%", backgroundColor: "#bbe4ca2e" }}>
												<Panel header="Fornecedor Contratado" key="1" extra={genExtra()}>
													<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
														<Col className="inline-col" style={{ marginTop: "15px" }}>
															<h6 style={{ textAlign: "left" }}><b>Fornecedor</b></h6>
														</Col>
														{(this.state.selectedProviderEspCat === null || this.state.selectedProviderEspCat === undefined) ? (
															<Col></Col>
														) : (
															<Col className="inline-col" style={{justifyContent:"flex-end"}}>
																<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
																	<a href={"mailto:" + this.state.emailEspCat + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + this.state.nameEspCat + ", " + "%0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
																</Tooltip>
																<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Upload Contrato" placement="top">
																	<AddIcon style={{marginLeft:"1%"}} className="btn-add pointer" onClick={() => this.toggleUpload(true, "Espaço e Catering", this.state.selectedProviderEspCat)}/>
																</Tooltip>
																{(this.state.hasContractEspCat === true) ? (
																	<div className="inline-col">
																		<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Visualizar Contrato" placement="top">
																			<PdfIcon style={{marginLeft:"1%"}} className="pointer" onClick={() => this.download("Espaço e Catering", this.state.selectedProviderCatering)}/>
																		</Tooltip>
																		<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Eliminar Contrato" placement="top">
																			<DeleteIcon style={{marginLeft:"1%"}} className="btn-delete pointer"onClick={() => this.toggleDelete(true, "Espaço e Catering", this.state.selectedProviderEspCat )}/>
																		</Tooltip>
																	</div>
																) : (
																	<p></p>
																)}
															</Col>
														)}
													</Row>
													<Row style={{ justifyContent:"space-evenly", marginBottom:"2%" }}>
														{/* <Col sm="6"> */}
															<Select style={{width:"47%"}} placeholder="Selecione Fornecedor." value={this.state.selectedProviderEspCat} onChange={this.changeSelectProviderEspCat}>
																<Option value={undefined}>Selecione Fornecedor</Option>
																{providerEspCat}
															</Select>
														{/* </Col>
														<Col sm="6"> */}
															<Select style={{width:"47%"}} placeholder="Selecione Pack." value={this.state.selectedPackEspCat} onChange={this.changeSelectPackEspCat}>
																<Option value={undefined}>Selecione Pack</Option>
																{providerEspCatPacks}
															</Select>
														{/* </Col> */}
													</Row>
													{/* <Row style={{ marginBottom: "2%", justifyContent: "space-evenly", minHeight: "100%" }}>
														<Col style={{ width: "50%", minHeight: "100%" }}>
															{providerEspCatInfo}
														</Col>
														<Col>
															{detailsPackEspCat}
														</Col>
													</Row> */}
													<Row style={{justifyContent:"space-evenly", marginBottom:"2%"}}>
														{providerEspCatInfo}
														{detailsPackEspCat}
													</Row>
													<Row style={{ marginBottom: "2%", marginRight: "0%", textAlign: "right", float: "right" }}>
														<Button className="btn-user" onClick={this.updateProviders}>Contratar</Button>
													</Row>
												</Panel>
											</Collapse>

										) : (
												<Collapse accordion style={{ width: "95%" }}>
													<Panel header="Fornecedor Contratado" key="1">
														<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
															<Col className="inline-col" style={{ marginTop: "15px" }}>
																<h6 style={{ textAlign: "left" }}><b>Fornecedor</b></h6>
															</Col>
															{(this.state.selectedProviderEspCat === null || this.state.selectedProviderEspCat === undefined) ? (
																<Col></Col>
															) : (
																	<Col className="inline-col" sm="1" style={{ marginTop: "15px" }}>
																		<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
																			<a href={"mailto:" + this.state.emailEspCat + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + this.state.nameEspCat + ", " + "%0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
																		</Tooltip>
																	</Col>
																)}
														</Row>
														<Row style={{ justifyContent:"space-evenly", marginBottom:"2%" }}>
															{/* <Col sm="6"> */}
																<Select style={{width:"47%"}} placeholder="Selecione Fornecedor." value={this.state.selectedProviderEspCat} onChange={this.changeSelectProviderEspCat}>
																	<Option value={undefined}>Selecione Fornecedor</Option>
																	{providerEspCat}
																</Select>
															{/* </Col>
															<Col sm="6"> */}
																<Select style={{width:"47%"}} placeholder="Selecione Pack." value={this.state.selectedPackEspCat} onChange={this.changeSelectPackEspCat}>
																	<Option value={undefined}>Selecione Pack</Option>
																	{providerEspCatPacks}
																</Select>
															{/* </Col> */}
														</Row>
														{/* <Row style={{ marginBottom: "2%", justifyContent: "space-evenly", minHeight: "100%" }}>
															<Col style={{ width: "50%", minHeight: "100%" }}>
																{providerEspCatInfo}
															</Col>
															<Col>
																{detailsPackEspCat}
															</Col>
														</Row> */}
														<Row style={{marginLeft:"15px", marginBottom:"2%"}}>
															{providerEspCatInfo}
														</Row>
														<Row style={{ marginBottom: "2%", marginRight: "0%", textAlign: "right", float: "right" }}>
															<Button className="btn-user" onClick={this.updateProviders}>Contratar</Button>
														</Row>
													</Panel>
												</Collapse>
											)}
									</Row>
									<Row style={{ marginLeft: "1%" }}>
										<Col className="inline-col">
											<h6 style={{ textAlign: "left" }}><b>Notas</b></h6>
										</Col>
									</Row>
									<Row style={{ marginBottom: "2%", marginLeft: "0%", justifyContent: "center"}}>
										<FormTextarea id="text-area" value={this.state.notesEspCatRole} style={{ width: "95%" }} onChange={(el) => { this.handleNotesText("notesEspCatRole", el.target.value) }} />
									</Row>
									<Row style={{ marginBottom: "5%",  marginRight: "0%", textAlign: "right", float: "right" }}>
										<Button className="btn-user" onClick={() => {this.saveNotes("Espaço e Catering")}}>Gravar Notas</Button>
									</Row>
								</div>
							) : (
								<Row></Row>
							)}

							{(this.state.roleUrl === "Animação") ? (
								<div>
									<Row style={{marginTop: "3%", marginLeft: "0%" }}>
										<h6 style={{ textAlign: "left"}}><b>Checklist</b></h6>
									</Row>
									<Row style={{ marginBottom: "1%", marginTop:"1%", marginLeft: "0%", justifyContent: "center" }}>
										<Collapse accordion style={{ width: "95%" }}>
											<Panel header="Tarefas" key="1">
												{(this.state.ckecklistText !== null && this.state.checklistText !== undefined) ? (
													this.state.checklistText.map((item, index) => {
														let element = this.state.checklistState.find( (el) => el.toDo === item.toDo)
														let isChecked = (element) ? element.isChecked : false
														return(
															<div>
																<Checkbox checked={isChecked} style={{marginBottom:"1%"}} onChange={(e) => { this.changeChecklist(item.toDo, e.target.checked)}}> {item.text}</Checkbox>
															</div>
														)	
													})
												) : (
													<p style={{ marginTop: "2%" }}>Não existe checklist associada a esta categoria.</p>
												)}
											</Panel>
										</Collapse>
									</Row>
									<Row style={{ marginBottom: "1%", marginTop: "6%", marginLeft: "0%" }}>
										<h5 style={{ textAlign: "left", color: "#C09F92" }}><b>Grupo Musical</b></h5>
										{(this.state.grupoWanted === true || this.state.grupoWanted === false) ? (
											<Switch size="small" style={{ marginLeft: "2%", marginTop: "0.5%" }} checkedChildren={""} unCheckedChildren={""} defaultChecked={this.state.grupoWanted} onChange={(checked) => this.onChangeIsWanted("grupoWanted", this.state.roleUrl, "Grupo Musical", checked)} />
										) : (
											<p></p>
										)}
									</Row>
									{/* {(this.state.infoGrupo !== null && this.state.infoGrupo !== undefined) ? (
										<Row style={{ marginBottom: "1%", width: "95%", marginLeft: "0%" }}>
											<p style={{ fontSize: "13px" }}>{this.state.infoGrupo}</p>
										</Row>
									) : (
											null
										)} */}
									<Row style={{ marginTop: "1%", marginLeft: "1%" }}>
										<Col className="inline-col">
											<h6 style={{ textAlign: "left" }}><b>Fornecedores em Negociação</b></h6>
										</Col>
									</Row>
									<Row style={{ marginBottom: "2%", marginLeft: "0%", justifyContent: "center" }}>
										<Select value={multiselectGrupoValue} placeholder="Selecione Fornecedor." style={{ width: "95%" }} mode="multiple" placeholder="Selecione Fornecedor" onChange={(value) => this.handleChange(value, "Grupo Musical")}>
											{multiselectGrupo}
										</Select>
									</Row>
									<Row style={{ marginBottom: "4%", marginLeft: "0%", justifyContent: "center" }}>
										<ul class="list-group" style={{ width: "95%" }}>
											{negotiationsListGrupo}
										</ul>
									</Row>
									<Row style={{ marginBottom: "3%", marginLeft: "0%", justifyContent: "center" }}>
										{(this.state.selectedProviderGrupo !== null && this.state.selectedProviderGrupo !== undefined && this.state.selectedPackGrupo !== null && this.state.selectedPackGrupo !== undefined) ? (
											<Collapse accordion style={{ width: "95%", backgroundColor: "#bbe4ca2e" }}>
												<Panel header="Fornecedor Contratado" key="1" extra={genExtra()}>
													<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
														<Col className="inline-col">
															<h6 style={{ textAlign: "left" }}><b>Fornecedor</b></h6>
														</Col>
														{(this.state.selectedProviderGrupo === null || this.state.selectedProviderGrupo === undefined) ? (
															<Col></Col>
														) : (
															<Col className="inline-col" style={{justifyContent:"flex-end"}}>
																<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
																	<a href={"mailto:" + this.state.emailGrupo + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + this.state.nameGrupo + ", " + "%0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
																</Tooltip>
																<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Upload Contrato" placement="top">
																	<AddIcon style={{marginLeft:"1%"}} className="btn-add pointer" onClick={() => this.toggleUpload(true, "Grupo Musical", this.state.selectedProviderGrupo)}/>
																</Tooltip>
																{(this.state.hasContractGrupo === true) ? (
																	<div className="inline-col">
																		<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Visualizar Contrato" placement="top">
																			<PdfIcon style={{marginLeft:"1%"}} className="pointer" onClick={() => this.download("Grupo Musical", this.state.selectedProviderGrupo)}/>
																		</Tooltip>
																		<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Eliminar Contrato" placement="top">
																			<DeleteIcon style={{marginLeft:"1%"}} className="btn-delete pointer"onClick={() => this.toggleDelete(true, "Grupo Musical", this.state.selectedProviderGrupo )}/>
																		</Tooltip>
																	</div>
																) : (
																	<p></p>
																)}
															</Col>
														)}
													</Row>
													<Row style={{ justifyContent:"space-evenly", marginBottom:"2%" }}>
														{/* <Col sm="6"> */}
															<Select style={{width:"47%"}}  placeholder="Selecione Fornecedor." value={this.state.selectedProviderGrupo} onChange={this.changeSelectProviderGrupo}>
																<Option value={undefined}>Selecione Fornecedor</Option>
																{providerGrupo}
															</Select>
														{/* </Col>
														<Col sm="6"> */}
															<Select style={{width:"47%"}} placeholder="Selecione Pack." value={this.state.selectedPackGrupo} onChange={this.changeSelectPackGrupo}>
																<Option value={undefined}>Selecione Pack</Option>
																{providerGrupoPacks}
															</Select>
														{/* </Col> */}
													</Row>
													{/* <Row style={{ marginBottom: "2%" }}>
														<Col style={{ width: "50%" }}>
															{providerGrupoInfo}
														</Col>
														<Col>
															{detailsPackGrupo}
														</Col>
													</Row> */}
													<Row style={{justifyContent:"space-evenly", marginBottom:"2%"}}>
														{providerGrupoInfo}
														{detailsPackGrupo}
													</Row>
													<Row style={{ marginBottom: "2%", marginRight: "0%", textAlign: "right", float: "right" }}>
														<Button className="btn-user" onClick={this.updateProviders}>Contratar</Button>
													</Row>
												</Panel>
											</Collapse>
										) : (
												<Collapse accordion style={{ width: "95%" }}>
													<Panel header="Fornecedor Contratado" key="1">
														<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
															<Col className="inline-col">
																<h6 style={{ textAlign: "left" }}><b>Fornecedor</b></h6>
															</Col>
															{(this.state.selectedProviderGrupo === null || this.state.selectedProviderGrupo === undefined) ? (
																<Col></Col>
															) : (
																	<Col className="inline-col" sm="1">
																		<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
																			<a href={"mailto:" + this.state.emailGrupo + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + this.state.nameGrupo + ", " + "%0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
																		</Tooltip>
																	</Col>
																)}
														</Row>
														<Row style={{ justifyContent:"space-evenly", marginBottom:"2%" }}>
															{/* <Col sm="6"> */}
																<Select style={{width:"47%"}} placeholder="Selecione Fornecedor." value={this.state.selectedProviderGrupo} onChange={this.changeSelectProviderGrupo}>
																	<Option value={undefined}>Selecione Fornecedor</Option>
																	{providerGrupo}
																</Select>
															{/* </Col>
															<Col sm="6"> */}
																<Select style={{width:"47%"}} placeholder="Selecione Pack." value={this.state.selectedPackGrupo} onChange={this.changeSelectPackGrupo}>
																	<Option value={undefined}>Selecione Pack</Option>
																	{providerGrupoPacks}
																</Select>
															{/* </Col> */}
														</Row>
														{/* <Row style={{ marginBottom: "2%" }}>
															<Col style={{ width: "50%" }}>
																{providerGrupoInfo}
															</Col>
															<Col>
																{detailsPackGrupo}
															</Col>
														</Row> */}
														<Row style={{marginLeft:"15px", marginBottom:"2%"}}>
															{providerGrupoInfo}
														</Row>
														<Row style={{ marginBottom: "2%", marginRight: "0%", textAlign: "right", float: "right" }}>
															<Button className="btn-user" onClick={this.updateProviders}>Contratar</Button>
														</Row>
													</Panel>
												</Collapse>
											)}
									</Row>
									<Row style={{ marginLeft: "1%" }}>
										<Col className="inline-col">
											<h6 style={{ textAlign: "left" }}><b>Notas</b></h6>
										</Col>
									</Row>
									<Row style={{ marginBottom: "2%", marginLeft: "0%", justifyContent: "center"}}>
										<FormTextarea id="text-area" value={this.state.notesGrupoRole} style={{ width: "95%" }} onChange={(el) => { this.handleNotesText("notesGrupoRole", el.target.value) }} />
									</Row>
									<Row style={{ marginBottom: "5%",  marginRight: "0%", textAlign: "right", float: "right" }}>
										<Button className="btn-user" onClick={() => {this.saveNotes("Grupo Musical")}}>Gravar Notas</Button>
									</Row>

									<Row style={{ marginBottom: "1%", marginTop: "8%", marginLeft: "0%" }}>
										<h5 style={{ textAlign: "left", color: "#C09F92" }}><b>Performers</b></h5>
										{(this.state.performersWanted === true || this.state.performersWanted === false) ? (
											<Switch size="small" style={{ marginLeft: "2%", marginTop: "0.5%" }} checkedChildren={""} unCheckedChildren={""} defaultChecked={this.state.performersWanted} onChange={(checked) => this.onChangeIsWanted("performersWanted", this.state.roleUrl, "Performers", checked)} />
										) : (
											<p></p>
										)}
											</Row>
									{/* {(this.state.infoPerformers !== null && this.state.infoPerformers !== undefined) ? (
										<Row style={{ marginBottom: "1%", width: "95%", marginLeft: "0%" }}>
											<p style={{ fontSize: "13px" }}>{this.state.infoPerformers}</p>
										</Row>
									) : (
											null
										)} */}
									<Row style={{ marginTop: "2%", marginLeft: "1%" }}>
										<Col className="inline-col">
											<h6 style={{ textAlign: "left" }}><b>Fornecedores em Negociação</b></h6>
										</Col>
									</Row>
									<Row style={{ marginBottom: "2%", marginLeft: "0%", justifyContent: "center" }}>
										<Select value={multiselectPerformersValue} placeholder="Selecione Fornecedor." style={{ width: "95%" }} mode="multiple" placeholder="Selecione Fornecedor" onChange={(value) => this.handleChange(value, "Performers")}>
											{multiselectPerformers}
										</Select>
									</Row>
									<Row style={{ marginBottom: "4%", marginLeft: "0%", justifyContent: "center" }}>
										<ul class="list-group" style={{ width: "95%" }}>
											{negotiationsListPerformers}
										</ul>
									</Row>
									<Row style={{ marginBottom: "3%", marginLeft: "0%", justifyContent: "center" }}>
										{(this.state.selectedProviderPerformers !== null && this.state.selectedProviderPerformers !== undefined && this.state.selectedPackPerformers !== null && this.state.selectedPackPerformers !== undefined) ? (
											<Collapse accordion style={{ width: "95%", backgroundColor: "#bbe4ca2e" }}>
												<Panel header="Fornecedor Contratado" key="1" extra={genExtra()}>
													<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
														<Col className="inline-col" style={{ marginTop: "15px" }}>
															<h6 style={{ textAlign: "left" }}><b>Fornecedor</b></h6>
														</Col>
														{(this.state.selectedProviderPerformers === null || this.state.selectedProviderPerformers === undefined || this.state.selectedProviderPerformers === "") ? (
															<Col></Col>
														) : (
															<Col className="inline-col" style={{justifyContent:"flex-end"}}>
																<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
																	<a href={"mailto:" + this.state.emailPerformers + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + this.state.namePerformers + ", " + "%0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
																</Tooltip>
																<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Upload Contrato" placement="top">
																	<AddIcon style={{marginLeft:"1%"}} className="btn-add pointer" onClick={() => this.toggleUpload(true, "Performers", this.state.selectedProviderPerformers)}/>
																</Tooltip>
																{(this.state.hasContractPerformers === true) ? (
																	<div className="inline-col">
																		<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Visualizar Contrato" placement="top">
																			<PdfIcon style={{marginLeft:"1%"}} className="pointer" onClick={() => this.download("Performers", this.state.selectedProviderPerformers)}/>
																		</Tooltip>
																		<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Eliminar Contrato" placement="top">
																			<DeleteIcon style={{marginLeft:"1%"}} className="btn-delete pointer"onClick={() => this.toggleDelete(true, "Performers", this.state.selectedProviderPerformers )}/>
																		</Tooltip>
																	</div>
																) : (
																	<p></p>
																)}
															</Col>
														)}
													</Row>
													<Row style={{ justifyContent:"space-evenly", marginBottom:"2%" }}>
														{/* <Col sm="6"> */}
															<Select style={{width:"47%"}} placeholder="Selecione Fornecedor." value={this.state.selectedProviderPerformers} onChange={this.changeSelectProviderPerformers}>
																<Option value={undefined}>Selecione Fornecedor</Option>
																{providerPerformers}
															</Select>
														{/* </Col>
														<Col sm="6"> */}
															<Select style={{width:"47%"}} placeholder="Selecione Pack." value={this.state.selectedPackPerformers} onChange={this.changeSelectPackPerformers}>
																<Option value={undefined}>Selecione Pack</Option>
																{providerPerformersPacks}
															</Select>
														{/* </Col> */}
													</Row>
													{/* <Row style={{ marginBottom: "2%", justifyContent: "space-evenly", minHeight: "100%" }}>
														<Col style={{ width: "50%", minHeight: "100%" }}>
															{providerPerformersInfo}
														</Col>
														<Col>
															{detailsPackPerformers}
														</Col>
													</Row> */}
													<Row style={{justifyContent:"space-evenly", marginBottom:"2%"}}>
														{providerPerformersInfo}
														{detailsPackPerformers}
													</Row>
													<Row style={{ marginBottom: "2%", marginRight: "0%", textAlign: "right", float: "right" }}>
														<Button className="btn-user" onClick={this.updateProviders}>Contratar</Button>
													</Row>
												</Panel>
											</Collapse>
										) : (
												<Collapse accordion style={{ width: "95%" }}>
													<Panel header="Fornecedor Contratado" key="1">
														<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
															<Col className="inline-col" style={{ marginTop: "15px" }}>
																<h6 style={{ textAlign: "left" }}><b>Fornecedor</b></h6>
															</Col>
															{(this.state.selectedProviderPerformers === null || this.state.selectedProviderPerformers === undefined) ? (
																<Col></Col>
															) : (
																	<Col className="inline-col" sm="1" style={{ marginTop: "15px" }}>
																		<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
																			<a href={"mailto:" + this.state.emailPerformers + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + this.state.namePerformers + ", " + "%0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
																		</Tooltip>
																	</Col>
																)}
														</Row>
														<Row style={{ justifyContent:"space-evenly", marginBottom:"2%" }}>
															{/* <Col sm="6"> */}
																<Select style={{width:"47%"}} placeholder="Selecione Fornecedor." value={this.state.selectedProviderPerformers} onChange={this.changeSelectProviderPerformers}>
																	<Option value={undefined}>Selecione Fornecedor</Option>
																	{providerPerformers}
																</Select>
															{/* </Col>
															<Col sm="6"> */}
																<Select style={{width:"47%"}}  placeholder="Selecione Pack." value={this.state.selectedPackPerformers} onChange={this.changeSelectPackPerformers}>
																	<Option value={undefined}>Selecione Pack</Option>
																	{providerPerformersPacks}
																</Select>
															{/* </Col> */}
														</Row>
														{/* <Row style={{ marginBottom: "2%", justifyContent: "space-evenly", minHeight: "100%" }}>
															<Col style={{ width: "50%", minHeight: "100%" }}>
																{providerPerformersInfo}
															</Col>
															<Col>
																{detailsPackPerformers}
															</Col>
														</Row> */}
														<Row style={{marginLeft:"15px", marginBottom:"2%"}}>
															{providerPerformersInfo}
														</Row>
														<Row style={{ marginBottom: "2%", marginRight: "0%", textAlign: "right", float: "right" }}>
															<Button className="btn-user" onClick={this.updateProviders}>Contratar</Button>
														</Row>
													</Panel>
												</Collapse>
											)}
									</Row>
									<Row style={{ marginLeft: "1%" }}>
										<Col className="inline-col">
											<h6 style={{ textAlign: "left" }}><b>Notas</b></h6>
										</Col>
									</Row>
									<Row style={{ marginBottom: "2%", marginLeft: "0%", justifyContent: "center"}}>
										<FormTextarea id="text-area" value={this.state.notesPerformersRole} style={{ width: "95%" }} onChange={(el) => { this.handleNotesText("notesPerformersRole", el.target.value) }} />
									</Row>
									<Row style={{ marginBottom: "5%",  marginRight: "0%", textAlign: "right", float: "right" }}>
										<Button className="btn-user" onClick={() => {this.saveNotes("Performers")}}>Gravar Notas</Button>
									</Row>

									<Row style={{ marginBottom: "1%", marginTop: "8%", marginLeft: "0%" }}>
										<h5 style={{ textAlign: "left", color: "#C09F92" }}><b>DJ</b></h5>
										{(this.state.djWanted === true || this.state.djWanted === false) ? (
											<Switch size="small" style={{ marginLeft: "2%", marginTop: "0.5%" }} checkedChildren={""} unCheckedChildren={""} defaultChecked={this.state.djWanted}  onChange={(checked) => this.onChangeIsWanted("djWanted", this.state.roleUrl, "DJ", checked)} />
										) : (
											<p></p>
										)}
									</Row>
									{/* {(this.state.infoDJ !== null && this.state.infoDJ !== undefined) ? (
										<Row style={{ marginBottom: "1%", width: "95%", marginLeft: "0%" }}>
											<p style={{ fontSize: "13px" }}>{this.state.infoDJ}</p>
										</Row>
									) : (
											null
										)} */}
									<Row style={{ marginTop: "2%", marginLeft: "1%" }}>
										<Col className="inline-col">
											<h6 style={{ textAlign: "left" }}><b>Fornecedores em Negociação</b></h6>
										</Col>
									</Row>
									<Row style={{ marginBottom: "2%", marginLeft: "0%", justifyContent: "center" }}>
										<Select value={multiselectDJValue} placeholder="Selecione Fornecedor." style={{ width: "95%" }} mode="multiple" placeholder="Selecione Fornecedor" onChange={(value) => this.handleChange(value, "DJ")}>
											{multiselectDJ}
										</Select>
									</Row>
									<Row style={{ marginBottom: "4%", marginLeft: "0%", justifyContent: "center" }}>
										<ul class="list-group" style={{ width: "95%" }}>
											{negotiationsListDJ}
										</ul>
									</Row>
									<Row style={{ marginBottom: "3%", marginLeft: "0%", justifyContent: "center" }}>
										{(this.state.selectedProviderDJ !== null && this.state.selectedProviderDJ !== undefined && this.state.selectedPackDJ !== null && this.state.selectedPackDJ !== undefined) ? (
											<Collapse accordion style={{ width: "95%", backgroundColor: "#bbe4ca2e" }}>
												<Panel header="Fornecedor Contratado" key="1" extra={genExtra()}>
													<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
														<Col className="inline-col" style={{ marginTop: "15px" }}>
															<h6 style={{ textAlign: "left" }}><b>Fornecedor</b></h6>
														</Col>
														{(this.state.selectedProviderDJ === null || this.state.selectedProviderDJ === undefined) ? (
															<Col></Col>
														) : (
															<Col className="inline-col" style={{justifyContent:"flex-end"}}>
																<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
																	<a href={"mailto:" + this.state.emailDJ + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + this.state.nameDJ + ", " + "%0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
																</Tooltip>
																<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Upload Contrato" placement="top">
																	<AddIcon style={{marginLeft:"1%"}} className="btn-add pointer" onClick={() => this.toggleUpload(true, "DJ", this.state.selectedProviderDJ)}/>
																</Tooltip>
																{(this.state.hasContractDJ === true) ? (
																	<div className="inline-col">
																		<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Visualizar Contrato" placement="top">
																			<PdfIcon style={{marginLeft:"1%"}} className="pointer" onClick={() => this.download("DJ", this.state.selectedProviderDJ)}/>
																		</Tooltip>
																		<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Eliminar Contrato" placement="top">
																			<DeleteIcon style={{marginLeft:"1%"}} className="btn-delete pointer"onClick={() => this.toggleDelete(true, "DJ", this.state.selectedProviderDJ )}/>
																		</Tooltip>
																	</div>
																) : (
																	<p></p>
																)}
															</Col>
														)}
													</Row>
													<Row style={{ justifyContent:"space-evenly", marginBottom:"2%" }}>
														{/* <Col sm="6"> */}
															<Select style={{width:"47%"}} placeholder="Selecione Fornecedor." value={this.state.selectedProviderDJ} onChange={this.changeSelectProviderDJ}>
																<Option value={undefined}>Selecione Fornecedor</Option>
																{providerDJ}
															</Select>
														{/* </Col>
														<Col sm="6"> */}
															<Select style={{width:"47%"}} placeholder="Selecione Pack." value={this.state.selectedPackDJ} onChange={this.changeSelectPackDJ}>
																<Option value={undefined}>Selecione Pack</Option>
																{providerDJPacks}
															</Select>
														{/* </Col> */}
													</Row>
													<Row style={{justifyContent:"space-evenly", marginBottom:"2%"}}>
														{providerDJInfo}
														{detailsPackDJ}
													</Row>
													<Row style={{ marginBottom: "2%", marginRight: "0%", textAlign: "right", float: "right" }}>
														<Button className="btn-user" onClick={this.updateProviders}>Contratar</Button>
													</Row>
												</Panel>
											</Collapse>
										) : (
												<Collapse accordion style={{ width: "95%" }}>
													<Panel header="Fornecedor Contratado" key="1">
														<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
															<Col className="inline-col" style={{ marginTop: "15px" }}>
																<h6 style={{ textAlign: "left" }}><b>Fornecedor</b></h6>
															</Col>
															{(this.state.selectedProviderDJ === null || this.state.selectedProviderDJ === undefined) ? (
																<Col></Col>
															) : (
																	<Col className="inline-col" sm="1" style={{ marginTop: "15px" }}>
																		<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
																			<a href={"mailto:" + this.state.emailDJ + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + this.state.nameDJ + ", " + "%0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
																		</Tooltip>
																	</Col>
																)}
														</Row>
														<Row style={{ justifyContent:"space-evenly", marginBottom:"2%" }}>
															{/* <Col sm="6"> */}
																<Select style={{width:"47%"}} placeholder="Selecione Fornecedor." value={this.state.selectedProviderDJ} onChange={this.changeSelectProviderDJ}>
																	<Option value={undefined}>Selecione Fornecedor</Option>
																	{providerDJ}
																</Select>
															{/* </Col>
															<Col sm="6"> */}
																<Select style={{width:"47%"}} placeholder="Selecione Pack." value={this.state.selectedPackDJ} onChange={this.changeSelectPackDJ}>
																	<Option value={undefined}>Selecione Pack</Option>
																	{providerDJPacks}
																</Select>
															{/* </Col> */}
														</Row>
														<Row style={{marginLeft:"15px", marginBottom:"2%"}}>
															{providerDJInfo}
														</Row>
														<Row style={{ marginBottom: "2%", marginRight: "0%", textAlign: "right", float: "right" }}>
															<Button className="btn-user" onClick={this.updateProviders}>Contratar</Button>
														</Row>
													</Panel>
												</Collapse>
											)}
									</Row>
									<Row style={{ marginLeft: "1%" }}>
										<Col className="inline-col">
											<h6 style={{ textAlign: "left" }}><b>Notas</b></h6>
										</Col>
									</Row>
									<Row style={{ marginBottom: "2%", marginLeft: "0%", justifyContent: "center"}}>
										<FormTextarea id="text-area" value={this.state.notesDJRole} style={{ width: "95%" }} onChange={(el) => { this.handleNotesText("notesDJRole", el.target.value) }} />
									</Row>
									<Row style={{ marginBottom: "5%",  marginRight: "0%", textAlign: "right", float: "right" }}>
										<Button className="btn-user" onClick={() => {this.saveNotes("DJ")}}>Gravar Notas</Button>
									</Row>

									<Row style={{ marginBottom: "1%", marginTop: "8%", marginLeft: "0%" }}>
										<h5 style={{ textAlign: "left", color: "#C09F92" }}><b>Banda</b></h5>
										{(this.state.bandaWanted === true || this.state.bandaWanted === false) ? (
											<Switch size="small" style={{ marginLeft: "2%", marginTop: "0.5%" }} checkedChildren={""} unCheckedChildren={""} defaultChecked={this.state.bandaWanted}  onChange={(checked) => this.onChangeIsWanted("bandaWanted", this.state.roleUrl, "Banda", checked)} />
										) : (
											<p></p>
										)}
											</Row>
									{/* {(this.state.infoBanda !== null && this.state.infoBanda !== undefined) ? (
										<Row style={{ marginBottom: "1%", width: "95%", marginLeft: "0%" }}>
											<p style={{ fontSize: "13px" }}>{this.state.infoBanda}</p>
										</Row>
									) : (
											null
										)} */}
									<Row style={{ marginTop: "2%", marginLeft: "1%" }}>
										<Col className="inline-col">
											<h6 style={{ textAlign: "left" }}><b>Fornecedores em Negociação</b></h6>
										</Col>
									</Row>
									<Row style={{ marginBottom: "2%", marginLeft: "0%", justifyContent: "center" }}>
										<Select value={multiselectBandaValue} placeholder="Selecione Fornecedor." style={{ width: "95%" }} mode="multiple" placeholder="Selecione Fornecedor" onChange={(value) => this.handleChange(value, "Banda")}>
											{multiselectBanda}
										</Select>
									</Row>
									<Row style={{ marginBottom: "4%", marginLeft: "0%", justifyContent: "center" }}>
										<ul class="list-group" style={{ width: "95%" }}>
											{negotiationsListBanda}
										</ul>
									</Row>
									<Row style={{ marginBottom: "3%", marginLeft: "0%", justifyContent: "center" }}>
										{(this.state.selectedProviderBanda !== null && this.state.selectedProviderBanda !== undefined && this.state.selectedPackBanda !== null && this.state.selectedPackBanda !== undefined) ? (
											<Collapse accordion style={{ width: "95%", backgroundColor: "#bbe4ca2e" }}>
												<Panel header="Fornecedor Contratado" key="1" extra={genExtra()}>
													<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
														<Col className="inline-col" style={{ marginTop: "15px" }}>
															<h6 style={{ textAlign: "left" }}><b>Fornecedor</b></h6>
														</Col>
														{(this.state.selectedProviderBanda === null || this.state.selectedProviderBanda === undefined) ? (
															<Col></Col>
														) : (
															<Col className="inline-col" style={{justifyContent:"flex-end"}}>
																<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
																	<a href={"mailto:" + this.state.emailBanda + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + this.state.nameBanda + ", " + "%0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
																</Tooltip>
																<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Upload Contrato" placement="top">
																	<AddIcon style={{marginLeft:"1%"}} className="btn-add pointer" onClick={() => this.toggleUpload(true, "Banda", this.state.selectedProviderBanda)}/>
																</Tooltip>
																{(this.state.hasContractBanda === true) ? (
																	<div className="inline-col">
																		<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Visualizar Contrato" placement="top">
																			<PdfIcon style={{marginLeft:"1%"}} className="pointer" onClick={() => this.download("Banda", this.state.selectedProviderBanda)}/>
																		</Tooltip>
																		<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Eliminar Contrato" placement="top">
																			<DeleteIcon style={{marginLeft:"1%"}} className="btn-delete pointer"onClick={() => this.toggleDelete(true, "Banda", this.state.selectedProviderBanda )}/>
																		</Tooltip>
																	</div>
																) : (
																	<p></p>
																)}
															</Col>
														)}
													</Row>
													<Row style={{ justifyContent:"space-evenly", marginBottom:"2%" }}>
														{/* <Col sm="6"> */}
															<Select style={{width:"47%"}} placeholder="Selecione Fornecedor." value={this.state.selectedProviderBanda} onChange={this.changeSelectProviderBanda}>
																<Option value={undefined}>Selecione Fornecedor</Option>
																{providerBanda}
															</Select>
														{/* </Col>
														<Col sm="6"> */}
															<Select style={{width:"47%"}} placeholder="Selecione Pack." value={this.state.selectedPackBanda} onChange={this.changeSelectPackBanda}>
																<Option value={undefined}>Selecione Pack</Option>
																{providerBandaPacks}
															</Select>
														{/* </Col> */}
													</Row>
													{/* <Row style={{ marginBottom: "2%" }}>
														<Col style={{ width: "50%" }}>
															{providerBandaInfo}
														</Col>
														<Col>
															{detailsPackBanda}
														</Col>
													</Row> */}
													<Row style={{justifyContent:"space-evenly", marginBottom:"2%"}}>
														{providerBandaInfo}
														{detailsPackBanda}
													</Row>
													<Row style={{ marginBottom: "2%", marginRight: "0%", textAlign: "right", float: "right" }}>
														<Button className="btn-user" onClick={this.updateProviders}>Contratar</Button>
													</Row>
												</Panel>
											</Collapse>

										) : (
												<Collapse accordion style={{ width: "95%" }}>
													<Panel header="Fornecedor Contratado" key="1">
														<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
															<Col className="inline-col" style={{ marginTop: "15px" }}>
																<h6 style={{ textAlign: "left" }}><b>Fornecedor</b></h6>
															</Col>
															{(this.state.selectedProviderBanda === null || this.state.selectedProviderBanda === undefined) ? (
																<Col></Col>
															) : (
																	<Col className="inline-col" sm="1" style={{ marginTop: "15px" }}>
																		<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
																			<a href={"mailto:" + this.state.emailBanda + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + this.state.nameBanda + ", " + "%0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
																		</Tooltip>
																	</Col>
																)}
														</Row>
														<Row style={{ justifyContent:"space-evenly", marginBottom:"2%" }}>
															{/* <Col sm="6"> */}
																<Select style={{width:"47%"}} placeholder="Selecione Fornecedor." value={this.state.selectedProviderBanda} onChange={this.changeSelectProviderBanda}>
																	<Option value={undefined}>Selecione Fornecedor</Option>
																	{providerBanda}
																</Select>
															{/* </Col>
															<Col sm="6"> */}
																<Select style={{width:"47%"}} placeholder="Selecione Pack." value={this.state.selectedPackBanda} onChange={this.changeSelectPackBanda}>
																	<Option value={undefined}>Selecione Pack</Option>
																	{providerBandaPacks}
																</Select>
															{/* </Col> */}
														</Row>
														{/* <Row style={{ marginBottom: "2%" }}>
															<Col style={{ width: "50%" }}>
																{providerBandaInfo}
															</Col>
															<Col>
																{detailsPackBanda}
															</Col>
														</Row> */}
														<Row style={{marginLeft:"15px", marginBottom:"2%"}}>
															{providerBandaInfo}
														</Row>
														<Row style={{ marginBottom: "2%", marginRight: "0%", textAlign: "right", float: "right" }}>
															<Button className="btn-user" onClick={this.updateProviders}>Contratar</Button>
														</Row>
													</Panel>
												</Collapse>
											)}
									</Row>
									<Row style={{ marginLeft: "1%" }}>
										<Col className="inline-col">
											<h6 style={{ textAlign: "left" }}><b>Notas</b></h6>
										</Col>
									</Row>
									<Row style={{ marginBottom: "2%", marginLeft: "0%", justifyContent: "center"}}>
										<FormTextarea id="text-area" value={this.state.notesBandaRole} style={{ width: "95%" }} onChange={(el) => { this.handleNotesText("notesBandaRole", el.target.value) }} />
									</Row>
									<Row style={{ marginBottom: "5%",  marginRight: "0%", textAlign: "right", float: "right" }}>
										<Button className="btn-user" onClick={() => {this.saveNotes("Banda")}}>Gravar Notas</Button>
									</Row>
								</div>
							) : (
								<Row></Row>
							)}

							{(this.state.roleUrl === "Pré ou Pós Evento") ? (
								<div>
									<Row style={{marginTop: "3%", marginLeft: "0%" }}>
										<h6 style={{ textAlign: "left"}}><b>Checklist</b></h6>
									</Row>
									<Row style={{ marginBottom: "1%", marginTop:"1%", marginLeft: "0%", justifyContent: "center" }}>
										<Collapse accordion style={{ width: "95%" }}>
											<Panel header="Tarefas" key="1">
												{(this.state.ckecklistText !== null && this.state.checklistText !== undefined) ? (
													this.state.checklistText.map((item, index) => {
														let element = this.state.checklistState.find( (el) => el.toDo === item.toDo)
														let isChecked = (element) ? element.isChecked : false
														return(
															<div>
																<Checkbox checked={isChecked} style={{marginBottom:"1%"}} onChange={(e) => { this.changeChecklist(item.toDo, e.target.checked)}}> {item.text}</Checkbox>
															</div>
														)	
													})
												) : (
													<p style={{ marginTop: "2%" }}>Não existe checklist associada a esta categoria.</p>
												)}
											</Panel>
										</Collapse>
									</Row>
									<Row style={{ marginBottom: "1%", marginTop: "5%", marginLeft: "0%" }}>
										<h5 style={{ textAlign: "left", color: "#C09F92" }}><b>Pré Evento</b></h5>
										{(this.state.preWanted === true || this.state.preWanted === false) ? (
											<Switch size="small" style={{ marginLeft: "2%", marginTop: "0.5%" }} checkedChildren={""} unCheckedChildren={""} defaultChecked={this.state.preWanted}  onChange={(checked) => this.onChangeIsWanted("preWanted", this.state.roleUrl, "Pré Evento", checked)} />
										) : (
											<p></p>
										)}
									</Row>
									{/* {(this.state.infoPre !== null && this.state.infoPre !== undefined) ? (
										<Row style={{ marginBottom: "1%", width: "95%", marginLeft: "0%" }}>
											<p style={{ fontSize: "13px" }}>{this.state.infoPre}</p>
										</Row>
									) : (
											null
										)} */}
									<Row style={{ marginTop: "2%", marginLeft: "1%" }}>
										<Col className="inline-col">
											<h6 style={{ textAlign: "left" }}><b>Fornecedores em Negociação</b></h6>
										</Col>
									</Row>
									<Row style={{ marginBottom: "2%", marginLeft: "0%", justifyContent: "center" }}>
										<Select value={multiselectPreValue} placeholder="Selecione Fornecedor." style={{ width: "95%" }} mode="multiple" placeholder="Selecione Fornecedor" onChange={(value) => this.handleChange(value, "Pré Evento")}>
											{multiselectPre}
										</Select>
									</Row>
									<Row style={{ marginBottom: "4%", marginLeft: "0%", justifyContent: "center" }}>
										<ul class="list-group" style={{ width: "95%" }}>
											{negotiationsListPre}
										</ul>
									</Row>
									<Row style={{ marginBottom: "4%", marginLeft: "0%", justifyContent: "center" }}>
										{(this.state.selectedProviderPre !== null && this.state.selectedProviderPre !== undefined && this.state.selectedPackPre !== null && this.state.selectedPackPre !== undefined) ? (
											<Collapse accordion style={{ width: "95%", backgroundColor: "#bbe4ca2e" }}>
												<Panel header="Fornecedor Contratado" key="1" extra={genExtra()}>
													<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
														<Col className="inline-col" style={{ marginTop: "15px" }}>
															<h6 style={{ textAlign: "left" }}><b>Fornecedor</b></h6>
														</Col>
														{(this.state.selectedProviderPre === null || this.state.selectedProviderPre === undefined) ? (
															<Col></Col>
														) : (
															<Col className="inline-col" style={{justifyContent:"flex-end"}}>
																<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
																	<a href={"mailto:" + this.state.emailPre + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + this.state.namePre + ", " + "%0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
																</Tooltip>
																<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Upload Contrato" placement="top">
																	<AddIcon style={{marginLeft:"1%"}} className="btn-add pointer" onClick={() => this.toggleUpload(true, "Pré Evento", this.state.selectedProviderPre)}/>
																</Tooltip>
																{(this.state.hasContractPre === true) ? (
																	<div className="inline-col">
																		<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Visualizar Contrato" placement="top">
																			<PdfIcon style={{marginLeft:"1%"}} className="pointer" onClick={() => this.download("Pré Evento", this.state.selectedProviderPre)}/>
																		</Tooltip>
																		<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Eliminar Contrato" placement="top">
																			<DeleteIcon style={{marginLeft:"1%"}} className="btn-delete pointer"onClick={() => this.toggleDelete(true, "Pré Evento", this.state.selectedProviderPre )}/>
																		</Tooltip>
																	</div>
																) : (
																	<p></p>
																)}
															</Col>
														)}
													</Row>
													<Row style={{ justifyContent:"space-evenly", marginBottom:"2%" }}>
														{/* <Col sm="6"> */}
															<Select style={{width:"47%"}} placeholder="Selecione Fornecedor." value={this.state.selectedProviderPre} onChange={this.changeSelectProviderPre}>
																<Option value={undefined}>Selecione Fornecedor</Option>
																{providerPre}
															</Select>
														{/* </Col>
														<Col sm="6"> */}
															<Select style={{width:"47%"}} placeholder="Selecione Pack." value={this.state.selectedPackPre} onChange={this.changeSelectPackPre}>
																<Option value={undefined}>Selecione Pack</Option>
																{providerPrePacks}
															</Select>
														{/* </Col> */}
													</Row>
													{/* <Row style={{ marginBottom: "2%" }}>
														<Col style={{ width: "50%" }}>
															{providerPreInfo}
														</Col>
														<Col>
															{detailsPackPre}
														</Col>
													</Row> */}
													<Row style={{justifyContent:"space-evenly", marginBottom:"2%"}}>
														{providerPreInfo}
														{detailsPackPre}
													</Row>
													<Row style={{ marginBottom: "2%", marginRight: "0%", textAlign: "right", float: "right" }}>
														<Button className="btn-user" onClick={this.updateProviders}>Contratar</Button>
													</Row>
												</Panel>
											</Collapse>
										) : (
												<Collapse accordion style={{ width: "95%" }}>
													<Panel header="Fornecedor Contratado" key="1">
														<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
															<Col className="inline-col" style={{ marginTop: "15px" }}>
																<h6 style={{ textAlign: "left" }}><b>Fornecedor</b></h6>
															</Col>
															{(this.state.selectedProviderPre === null || this.state.selectedProviderPre === undefined) ? (
																<Col></Col>
															) : (
																	<Col className="inline-col" sm="1" style={{ marginTop: "15px" }}>
																		<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
																			<a href={"mailto:" + this.state.emailPre + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + this.state.namePre + ", " + "%0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
																		</Tooltip>
																	</Col>
																)}
														</Row>
														<Row style={{ justifyContent:"space-evenly", marginBottom:"2%" }}>
															{/* <Col sm="6"> */}
																<Select style={{width:"47%"}} placeholder="Selecione Fornecedor." value={this.state.selectedProviderPre} onChange={this.changeSelectProviderPre}>
																	<Option value={undefined}>Selecione Fornecedor</Option>
																	{providerPre}
																</Select>
															{/* </Col>
															<Col sm="6"> */}
																<Select style={{width:"47%"}} placeholder="Selecione Pack." value={this.state.selectedPackPre} onChange={this.changeSelectPackPre}>
																	<Option value={undefined}>Selecione Pack</Option>
																	{providerPrePacks}
																</Select>
															{/* </Col> */}
														</Row>
														{/* <Row style={{ marginBottom: "2%" }}>
															<Col style={{ width: "50%" }}>
																{providerPreInfo}
															</Col>
															<Col>
																{detailsPackPre}
															</Col>
														</Row> */}
														<Row style={{marginLeft:"15px", marginBottom:"2%"}}>
															{providerPreInfo}
														</Row>
														<Row style={{ marginBottom: "2%", marginRight: "0%", textAlign: "right", float: "right" }}>
															<Button className="btn-user" onClick={this.updateProviders}>Contratar</Button>
														</Row>
													</Panel>
												</Collapse>
											)}
									</Row>
									<Row style={{ marginLeft: "1%" }}>
										<Col className="inline-col">
											<h6 style={{ textAlign: "left" }}><b>Notas</b></h6>
										</Col>
									</Row>
									<Row style={{ marginBottom: "2%", marginLeft: "0%", justifyContent: "center"}}>
										<FormTextarea id="text-area" value={this.state.notesPreRole} style={{ width: "95%" }} onChange={(el) => { this.handleNotesText("notesPreRole", el.target.value) }} />
									</Row>
									<Row style={{ marginBottom: "5%",  marginRight: "0%", textAlign: "right", float: "right" }}>
										<Button className="btn-user" onClick={() => {this.saveNotes("Pré Evento")}}>Gravar Notas</Button>
									</Row>

									<Row style={{ marginBottom: "1%", marginTop: "8%", marginLeft: "0%" }}>
										<h5 style={{ textAlign: "left", color: "#C09F92" }}><b>Pós Evento</b></h5>
										{(this.state.posWanted === true || this.state.posWanted === false) ? (
											<Switch size="small" style={{ marginLeft: "2%", marginTop: "0.5%" }} checkedChildren={""} unCheckedChildren={""} defaultChecked={this.state.posWanted}  onChange={(checked) => this.onChangeIsWanted("posWanted", this.state.roleUrl, "Pós Evento", checked)} />
										) : (
											<p></p>
										)}
									</Row>
									{/* {(this.state.infoPos !== null && this.state.infoPos !== undefined) ? (
										<Row style={{ marginBottom: "1%", width: "95%", marginLeft: "0%" }}>
											<p style={{ fontSize: "13px" }}>{this.state.infoPos}</p>
										</Row>
									) : (
											null
										)} */}
									<Row style={{ marginTop: "2%", marginLeft: "1%" }}>
										<Col className="inline-col">
											<h6 style={{ textAlign: "left" }}><b>Fornecedores em Negociação</b></h6>
										</Col>
									</Row>
									<Row style={{ marginBottom: "2%", marginLeft: "0%", justifyContent: "center" }}>
										<Select value={multiselectPosValue} placeholder="Selecione Fornecedor." style={{ width: "95%" }} mode="multiple" placeholder="Selecione Fornecedor" onChange={(value) => this.handleChange(value, "Pós Evento")}>
											{multiselectPos}
										</Select>
									</Row>
									<Row style={{ marginBottom: "4%", marginLeft: "0%", justifyContent: "center" }}>
										<ul class="list-group" style={{ width: "95%" }}>
											{negotiationsListPos}
										</ul>
									</Row>
									<Row style={{ marginBottom: "4%", marginLeft: "0%", justifyContent: "center" }}>
										{(this.state.selectedProviderPos !== null && this.state.selectedProviderPos !== undefined && this.state.selectedPackPos !== null && this.state.selectedPackPos !== undefined) ? (
											<Collapse accordion style={{ width: "95%", backgroundColor: "#bbe4ca2e" }}>
												<Panel header="Fornecedor Contratado" key="1" extra={genExtra()}>
													<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
														<Col className="inline-col" style={{ marginTop: "15px" }}>
															<h6 style={{ textAlign: "left" }}><b>Fornecedor</b></h6>
														</Col>
														{(this.state.selectedProviderPos === null || this.state.selectedProviderPos === undefined) ? (
															<Col></Col>
														) : (
															<Col className="inline-col" style={{justifyContent:"flex-end"}}>
																<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
																	<a href={"mailto:" + this.state.emailPos + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + this.state.namePos + ", " + "%0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
																</Tooltip>
																<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Upload Contrato" placement="top">
																	<AddIcon style={{marginLeft:"1%"}} className="btn-add pointer" onClick={() => this.toggleUpload(true, "Pós Evento", this.state.selectedProviderPos)}/>
																</Tooltip>
																{(this.state.hasContractPos === true) ? (
																	<div className="inline-col">
																		<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Visualizar Contrato" placement="top">
																			<PdfIcon style={{marginLeft:"1%"}} className="pointer" onClick={() => this.download("Pós Evento", this.state.selectedProviderPos)}/>
																		</Tooltip>
																		<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Eliminar Contrato" placement="top">
																			<DeleteIcon style={{marginLeft:"1%"}} className="btn-delete pointer"onClick={() => this.toggleDelete(true, "Pós Evento", this.state.selectedProviderPos )}/>
																		</Tooltip>
																	</div>
																) : (
																	<p></p>
																)}
															</Col>
														)}
													</Row>
													<Row style={{ justifyContent:"space-evenly", marginBottom:"2%" }}>
														{/* <Col sm="6"> */}
															<Select style={{width:"47%"}} placeholder="Selecione Fornecedor." value={this.state.selectedProviderPos} onChange={this.changeSelectProviderPos}>
																<Option value={undefined}>Selecione Fornecedor</Option>
																{providerPos}
															</Select>
														{/* </Col>
														<Col sm="6"> */}
															<Select style={{width:"47%"}} placeholder="Selecione Pack." value={this.state.selectedPackPos} onChange={this.changeSelectPackPos}>
																<Option value={undefined}>Selecione Pack</Option>
																{providerPosPacks}
															</Select>
														{/* </Col> */}
													</Row>
													{/* <Row style={{ marginBottom: "2%", justifyContent: "space-evenly", minHeight: "100%" }}>
														<Col style={{ width: "50%", minHeight: "100%" }}>
															{providerPosInfo}
														</Col>
														<Col>
															{detailsPackPos}
														</Col>
													</Row> */}
													<Row style={{justifyContent:"space-evenly", marginBottom:"2%"}}>
														{providerPosInfo}
														{detailsPackPos}
													</Row>
													<Row style={{ marginBottom: "2%", marginRight: "0%", textAlign: "right", float: "right" }}>
														<Button className="btn-user" onClick={this.updateProviders}>Contratar</Button>
													</Row>
												</Panel>
											</Collapse>

										) : (
												<Collapse accordion style={{ width: "95%" }}>
													<Panel header="Fornecedor Contratado" key="1">
														<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
															<Col className="inline-col" style={{ marginTop: "15px" }}>
																<h6 style={{ textAlign: "left" }}><b>Fornecedor</b></h6>
															</Col>
															{(this.state.selectedProviderPos === null || this.state.selectedProviderPos === undefined) ? (
																<Col></Col>
															) : (
																	<Col className="inline-col" sm="1" style={{ marginTop: "15px" }}>
																		<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
																			<a href={"mailto:" + this.state.emailPos + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + this.state.namePos + ", " + "%0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
																		</Tooltip>
																	</Col>
																)}
														</Row>
														<Row style={{ justifyContent:"space-evenly", marginBottom:"2%" }}>
															{/* <Col sm="6"> */}
																<Select style={{width:"47%"}} placeholder="Selecione Fornecedor." value={this.state.selectedProviderPos} onChange={this.changeSelectProviderPos}>
																	<Option value={undefined}>Selecione Fornecedor</Option>
																	{providerPos}
																</Select>
															{/* </Col>
															<Col sm="6"> */}
																<Select style={{width:"47%"}} placeholder="Selecione Pack." value={this.state.selectedPackPos} onChange={this.changeSelectPackPos}>
																	<Option value={undefined}>Selecione Pack</Option>
																	{providerPosPacks}
																</Select>
															{/* </Col> */}
														</Row>
														{/* <Row style={{ marginBottom: "2%", justifyContent: "space-evenly", minHeight: "100%" }}>
															<Col style={{ width: "50%", minHeight: "100%" }}>
																{providerPosInfo}
															</Col>
															<Col>
																{detailsPackPos}
															</Col>
														</Row> */}
														<Row style={{marginLeft:"15px", marginBottom:"2%"}}>
															{providerPosInfo}
														</Row>
														<Row style={{ marginBottom: "2%", marginRight: "0%", textAlign: "right", float: "right" }}>
															<Button className="btn-user" onClick={this.updateProviders}>Contratar</Button>
														</Row>
													</Panel>
												</Collapse>

											)}
									</Row>
									<Row style={{ marginLeft: "1%" }}>
										<Col className="inline-col">
											<h6 style={{ textAlign: "left" }}><b>Notas</b></h6>
										</Col>
									</Row>
									<Row style={{ marginBottom: "2%", marginLeft: "0%", justifyContent: "center"}}>
										<FormTextarea id="text-area" value={this.state.notesPosRole} style={{ width: "95%" }} onChange={(el) => { this.handleNotesText("notesPosRole", el.target.value) }} />
									</Row>
									<Row style={{ marginBottom: "5%",  marginRight: "0%", textAlign: "right", float: "right" }}>
										<Button className="btn-user" onClick={() => {this.saveNotes("Pós Evento")}}>Gravar Notas</Button>
									</Row>
								</div>
							) : (
									<Row></Row>
								)}

							{(this.state.roleUrl === "Processo Religioso") ? (
								<div>
									<Row style={{marginTop: "3%", marginLeft: "0%" }}>
										<h6 style={{ textAlign: "left"}}><b>Checklist</b></h6>
									</Row>
									<Row style={{ marginBottom: "1%", marginTop:"1%", marginLeft: "0%", justifyContent: "center" }}>
										<Collapse accordion style={{ width: "95%" }}>
											<Panel header="Tarefas" key="1">
												{(this.state.ckecklistText !== null && this.state.checklistText !== undefined) ? (
													this.state.checklistText.map((item, index) => {
														let element = this.state.checklistState.find( (el) => el.toDo === item.toDo)
														let isChecked = (element) ? element.isChecked : false
														return(
															<div>
																<Checkbox checked={isChecked} style={{marginBottom:"1%"}} onChange={(e) => { this.changeChecklist(item.toDo, e.target.checked)}}> {item.text}</Checkbox>
															</div>
														)	
													})
												) : (
													<p style={{ marginTop: "2%" }}>Não existe checklist associada a esta categoria.</p>
												)}
											</Panel>
										</Collapse>
									</Row>
									<Row style={{ marginBottom: "1%", marginTop: "5%", marginLeft: "0%" }}>
										<h5 style={{ textAlign: "left", color: "#C09F92" }}><b>Padre</b></h5>
										{(this.state.padreWanted === true || this.state.padreWanted === false) ? (
											<Switch size="small" style={{ marginLeft: "2%", marginTop: "0.5%" }} checkedChildren={""} unCheckedChildren={""} defaultChecked={this.state.padreWanted}  onChange={(checked) => this.onChangeIsWanted("padreWanted", this.state.roleUrl, "Padre", checked)} />
										) : (
											<p></p>
										)}
									</Row>
									{/* {(this.state.infoPadre !== null && this.state.infoPadre !== undefined) ? (
										<Row style={{ marginBottom: "1%", width: "95%", marginLeft: "0%" }}>
											<p style={{ fontSize: "13px" }}>{this.state.infoPadre}</p>
										</Row>
									) : (
											null
										)} */}
									<Row style={{ marginTop: "2%", marginLeft: "1%" }}>
										<Col className="inline-col">
											<h6 style={{ textAlign: "left" }}><b>Fornecedores em Negociação</b></h6>
										</Col>
									</Row>
									<Row style={{ marginBottom: "2%", marginLeft: "0%", justifyContent: "center" }}>
										<Select value={multiselectPadreValue} placeholder="Selecione Fornecedor." style={{ width: "95%" }} mode="multiple" placeholder="Selecione Fornecedor" onChange={(value) => this.handleChange(value, "Padre")}>
											{multiselectPadre}
										</Select>
									</Row>
									<Row style={{ marginBottom: "4%", marginLeft: "0%", justifyContent: "center" }}>
										<ul class="list-group" style={{ width: "95%" }}>
											{negotiationsListPadre}
										</ul>
									</Row>
									<Row style={{ marginBottom: "4%", marginLeft: "0%", justifyContent: "center" }}>
										{(this.state.selectedProviderPadre !== null && this.state.selectedProviderPadre !== undefined && this.state.selectedPackPadre !== null && this.state.selectedPackPadre !== undefined) ? (
											<Collapse accordion style={{ width: "95%", backgroundColor: "#bbe4ca2e" }}>
												<Panel header="Fornecedor Contratado" key="1" extra={genExtra()}>
													<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
														<Col className="inline-col" style={{ marginTop: "15px" }}>
															<h6 style={{ textAlign: "left" }}><b>Fornecedor</b></h6>
														</Col>
														{(this.state.selectedProviderPadre === null || this.state.selectedProviderPadre === undefined) ? (
															<Col></Col>
														) : (
															<Col className="inline-col" style={{justifyContent:"flex-end"}}>
																<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
																	<a href={"mailto:" + this.state.emailPadre + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + this.state.namePadre + ", " + "%0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
																</Tooltip>
																<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Upload Contrato" placement="top">
																	<AddIcon style={{marginLeft:"1%"}} className="btn-add pointer" onClick={() => this.toggleUpload(true, "Padre", this.state.selectedProviderPadre)}/>
																</Tooltip>
																{(this.state.hasContractPadre === true) ? (
																	<div className="inline-col">
																		<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Visualizar Contrato" placement="top">
																			<PdfIcon style={{marginLeft:"1%"}} className="pointer" onClick={() => this.download("Padre", this.state.selectedProviderPadre)}/>
																		</Tooltip>
																		<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Eliminar Contrato" placement="top">
																			<DeleteIcon style={{marginLeft:"1%"}} className="btn-delete pointer" onClick={() => this.toggleDelete(true, "Padre", this.state.selectedProviderPadre )}/>
																		</Tooltip>
																	</div>
																) : (
																	<p></p>
																)}
															</Col>
														)}
													</Row>
													<Row style={{ justifyContent:"space-evenly", marginBottom:"2%" }}>
														<Select style={{width:"47%"}} placeholder="Selecione Fornecedor." value={this.state.selectedProviderPadre} onChange={this.changeSelectProviderPadre}>
															<Option value={undefined}>Selecione Fornecedor</Option>
															{providerPadre}
														</Select>
														<Select style={{width:"47%"}} placeholder="Selecione Pack." value={this.state.selectedPackPadre} onChange={this.changeSelectPackPadre}>
															<Option value={undefined}>Selecione Pack</Option>
															{providerPadrePacks}
														</Select>
													</Row>
													{/* <Row style={{ marginBottom: "2%" }}>
														<Col style={{ width: "50%" }}>
															{providerPadreInfo}
														</Col>
														<Col>
															{detailsPackPadre}
														</Col>
													</Row> */}
													<Row style={{justifyContent:"space-evenly", marginBottom:"2%"}}>
														{providerPadreInfo}
														{detailsPackPadre}
													</Row>
													<Row style={{ marginBottom: "2%", marginRight: "0%", textAlign: "right", float: "right" }}>
														<Button className="btn-user" onClick={this.updateProviders}>Contratar</Button>
													</Row>
												</Panel>
											</Collapse>
										) : (
												<Collapse accordion style={{ width: "95%" }}>
													<Panel header="Fornecedor Contratado" key="1">
														<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
															<Col className="inline-col" style={{ marginTop: "15px" }}>
																<h6 style={{ textAlign: "left" }}><b>Fornecedor</b></h6>
															</Col>
															{(this.state.selectedProviderPadre === null || this.state.selectedProviderPadre === undefined) ? (
																<Col></Col>
															) : (
																	<Col className="inline-col" sm="1" style={{ marginTop: "15px" }}>
																		<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
																			<a href={"mailto:" + this.state.emailPadre + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + this.state.namePadre + ", " + "%0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
																		</Tooltip>
																	</Col>
																)}
														</Row>
														<Row style={{ justifyContent:"space-evenly", marginBottom:"2%" }}>
															<Select style={{width:"47%"}} placeholder="Selecione Fornecedor." value={this.state.selectedProviderPadre} onChange={this.changeSelectProviderPadre}>
																<Option value={undefined}>Selecione Fornecedor</Option>
																{providerPadre}
															</Select>
															<Select style={{width:"47%"}} placeholder="Selecione Pack." value={this.state.selectedPackPadre} onChange={this.changeSelectPackPadre}>
																<Option value={undefined}>Selecione Pack</Option>
																{providerPadrePacks}
															</Select>
														</Row>
														{/* <Row style={{ marginBottom: "2%" }}>
															<Col style={{ width: "50%" }}>
																{providerPadreInfo}
															</Col>
															<Col>
																{detailsPackPadre}
															</Col>
														</Row> */}
														<Row style={{marginLeft:"15px", marginBottom:"2%"}}>
															{providerPadreInfo}
														</Row>
														<Row style={{ marginBottom: "2%", marginRight: "0%", textAlign: "right", float: "right" }}>
															<Button className="btn-user" onClick={this.updateProviders}>Contratar</Button>
														</Row>
													</Panel>
												</Collapse>
											)}
									</Row>
									<Row style={{ marginLeft: "1%" }}>
										<Col className="inline-col">
											<h6 style={{ textAlign: "left" }}><b>Notas</b></h6>
										</Col>
									</Row>
									<Row style={{ marginBottom: "2%", marginLeft: "0%", justifyContent: "center"}}>
										<FormTextarea id="text-area" value={this.state.notesPadreRole} style={{ width: "95%" }} onChange={(el) => { this.handleNotesText("notesPadreRole", el.target.value) }} />
									</Row>
									<Row style={{ marginBottom: "5%",  marginRight: "0%", textAlign: "right", float: "right" }}>
										<Button className="btn-user" onClick={() => {this.saveNotes("Padre")}}>Gravar Notas</Button>
									</Row>
								</div>
							) : (
									<Row></Row>
								)}

							{(this.state.roleUrl === "Serviços Extra") ? (
								<div>
									<Row style={{marginTop: "2%", marginLeft: "0%" }}>
										<h6 style={{ textAlign: "left"}}><b>Checklist</b></h6>
									</Row>
									<Row style={{ marginBottom: "1%", marginTop:"1%", marginLeft: "0%", justifyContent: "center" }}>
										<Collapse accordion style={{ width: "95%" }}>
											<Panel header="Tarefas" key="1">
												{(this.state.ckecklistText !== null && this.state.checklistText !== undefined) ? (
													this.state.checklistText.map((item, index) => {
														let element = this.state.checklistState.find( (el) => el.toDo === item.toDo)
														let isChecked = (element) ? element.isChecked : false
														return(
															<div>
																<Checkbox checked={isChecked} style={{marginBottom:"1%"}} onChange={(e) => { this.changeChecklist(item.toDo, e.target.checked)}}> {item.text}</Checkbox>
															</div>
														)	
													})
												) : (
													<p style={{ marginTop: "2%" }}>Não existe checklist associada a esta categoria.</p>
												)}
											</Panel>
										</Collapse>
									</Row>
									<Row style={{ marginBottom: "1%", marginTop: "4%", marginLeft: "0%" }}>
										<h5 style={{ textAlign: "left", color: "#C09F92" }}><b>Babysitter</b></h5>
										{(this.state.babysitterWanted === true || this.state.babysitterWanted === false) ? (
											<Switch size="small" style={{ marginLeft: "2%", marginTop: "0.5%" }} checkedChildren={""} unCheckedChildren={""} defaultChecked={this.state.babysitterWanted}  onChange={(checked) => this.onChangeIsWanted("babysitterWanted", this.state.roleUrl, "Babysitter", checked)} />
										) : (
											<p></p>
										)}
									</Row>
									{/* {(this.state.infoBaby !== null && this.state.infoBaby !== undefined) ? (
										<Row style={{ marginBottom: "1%", width: "95%", marginLeft: "0%" }}>
											<p style={{ fontSize: "13px" }}>{this.state.infoBaby}</p>
										</Row>
									) : (
											null
										)} */}
									<Row style={{ marginTop: "2%", marginLeft: "1%" }}>
										<Col className="inline-col">
											<h6 style={{ textAlign: "left" }}><b>Fornecedores em Negociação</b></h6>
										</Col>
									</Row>
									<Row style={{ marginBottom: "2%", marginLeft: "0%", justifyContent: "center" }}>
										<Select value={multiselectBabyValue} placeholder="Selecione Fornecedor." style={{ width: "95%" }} mode="multiple" placeholder="Selecione Fornecedor" onChange={(value) => this.handleChange(value, "Babysitter")}>
											{multiselectBaby}
										</Select>
									</Row>
									<Row style={{ marginBottom: "4%", marginLeft: "0%", justifyContent: "center" }}>
										<ul class="list-group" style={{ width: "95%" }}>
											{negotiationsListBaby}
										</ul>
									</Row>
									<Row style={{ marginBottom: "4%", marginLeft: "0%", justifyContent: "center" }}>
										{(this.state.selectedProviderBabysitter !== null && this.state.selectedProviderBabysitter !== undefined && this.state.selectedPackBabysitter !== null && this.state.selectedPackBabysitter !== undefined) ? (
											<Collapse accordion style={{ width: "95%", backgroundColor: "#bbe4ca2e" }}>
												<Panel header="Fornecedor Contratado" key="1" extra={genExtra()}>
													<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
														<Col className="inline-col" style={{ marginTop: "15px" }}>
															<h6 style={{ textAlign: "left" }}><b>Fornecedor</b></h6>
														</Col>
														{(this.state.selectedProviderBabysitter === null || this.state.selectedProviderBabysitter === undefined) ? (
															<Col></Col>
														) : (
															<Col className="inline-col" style={{justifyContent:"flex-end"}}>
																<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
																	<a href={"mailto:" + this.state.emailBabysitter + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + this.state.nameBabysitter + ", " + "%0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
																</Tooltip>
																<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Upload Contrato" placement="top">
																	<AddIcon style={{marginLeft:"1%"}} className="btn-add pointer" onClick={() => this.toggleUpload(true, "Babysitter", this.state.selectedProviderBabysitter)}/>
																</Tooltip>
																{(this.state.hasContractBabysitter === true) ? (
																	<div className="inline-col">
																		<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Visualizar Contrato" placement="top">
																			<PdfIcon style={{marginLeft:"1%"}} className="pointer" onClick={() => this.download("Babysitter", this.state.selectedProviderBabysitter)}/>
																		</Tooltip>
																		<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Eliminar Contrato" placement="top">
																			<DeleteIcon style={{marginLeft:"1%"}} className="btn-delete pointer" onClick={() => this.toggleDelete(true, "Babysitter", this.state.selectedProviderBabysitter )}/>
																		</Tooltip>
																	</div>
																) : (
																	<p></p>
																)}
															</Col>
														)}
													</Row>
													<Row style={{ justifyContent:"space-evenly", marginBottom:"2%" }}>
														<Select style={{width:"47%"}} placeholder="Selecione Fornecedor." value={this.state.selectedProviderBabysitter} onChange={this.changeSelectProviderBabysitter}>
															<Option value={undefined}>Selecione Fornecedor</Option>
															{providerBabysitter}
														</Select>
														<Select style={{width:"47%"}} placeholder="Selecione Pack." value={this.state.selectedPackBabysitter} onChange={this.changeSelectPackBabysitter}>
															<Option value={undefined}>Selecione Pack</Option>
															{providerBabysitterPacks}
														</Select>
													</Row>
													{/* <Row style={{ marginBottom: "2%" }}>
														<Col style={{ width: "50%" }}>
															{providerBabysitterInfo}
														</Col>
														<Col>
															{detailsPackBabysitter}
														</Col>
													</Row> */}
													<Row style={{justifyContent:"space-evenly", marginBottom:"2%"}}>
														{providerBabysitterInfo}
														{detailsPackBabysitter}
													</Row>
													<Row style={{ marginBottom: "2%", marginRight: "0%", textAlign: "right", float: "right" }}>
														<Button className="btn-user" onClick={this.updateProviders}>Contratar</Button>
													</Row>
												</Panel>
											</Collapse>
										) : (
												<Collapse accordion style={{ width: "95%" }}>
													<Panel header="Fornecedor Contratado" key="1">
														<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
															<Col className="inline-col" style={{ marginTop: "15px" }}>
																<h6 style={{ textAlign: "left" }}><b>Fornecedor</b></h6>
															</Col>
															{(this.state.selectedProviderBabysitter === null || this.state.selectedProviderBabysitter === undefined) ? (
																<Col></Col>
															) : (
																<Col className="inline-col" sm="1" style={{ marginTop: "15px" }}>
																	<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
																		<a href={"mailto:" + this.state.emailBabysitter + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + this.state.nameBabysitter + ", " + "%0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
																	</Tooltip>
																</Col>
															)}
														</Row>
														<Row style={{ justifyContent:"space-evenly", marginBottom:"2%" }}>
															<Select style={{width:"47%"}} placeholder="Selecione Fornecedor." value={this.state.selectedProviderBabysitter} onChange={this.changeSelectProviderBabysitter}>
																<Option value={undefined}>Selecione Fornecedor</Option>
																{providerBabysitter}
															</Select>
															<Select style={{width:"47%"}} placeholder="Selecione Pack." value={this.state.selectedPackBabysitter} onChange={this.changeSelectPackBabysitter}>
																<Option value={undefined}>Selecione Pack</Option>
																{providerBabysitterPacks}
															</Select>
														</Row>
														{/* <Row style={{ marginBottom: "2%" }}>
															<Col style={{ width: "50%" }}>
																{providerBabysitterInfo}
															</Col>
															<Col>
																{detailsPackBabysitter}
															</Col>
														</Row> */}
														<Row style={{marginLeft:"15px", marginBottom:"2%"}}>
															{providerBabysitterInfo}
														</Row>
														<Row style={{ marginBottom: "2%", marginRight: "0%", textAlign: "right", float: "right" }}>
															<Button className="btn-user" onClick={this.updateProviders}>Contratar</Button>
														</Row>
													</Panel>
												</Collapse>
											)}
									</Row>
									<Row style={{ marginLeft: "1%" }}>
										<Col className="inline-col">
											<h6 style={{ textAlign: "left" }}><b>Notas</b></h6>
										</Col>
									</Row>
									<Row style={{ marginBottom: "2%", marginLeft: "0%", justifyContent: "center"}}>
										<FormTextarea id="text-area" value={this.state.notesBabysitterRole} style={{ width: "95%" }} onChange={(el) => { this.handleNotesText("notesBabysitterRole", el.target.value) }} />
									</Row>
									<Row style={{ marginBottom: "5%",  marginRight: "0%", textAlign: "right", float: "right" }}>
										<Button className="btn-user" onClick={() => {this.saveNotes("Babysitter")}}>Gravar Notas</Button>
									</Row>

									<Row style={{ marginBottom: "1%", marginTop: "8%", marginLeft: "0%" }}>
										<h5 style={{ textAlign: "left", color: "#C09F92" }}><b>Lembranças</b></h5>
										{(this.state.lembrancasWanted === true || this.state.lembrancasWanted === false) ? (
											<Switch size="small" style={{ marginLeft: "2%", marginTop: "0.5%" }} checkedChildren={""} unCheckedChildren={""} defaultChecked={this.state.lembrancasWanted}  onChange={(checked) => this.onChangeIsWanted("lembrancasWanted", this.state.roleUrl, "Lembranças", checked)} />
										) : (
											<p></p>
										)}
									</Row>
									{/* {(this.state.infoLembrancas !== null && this.state.infoLembrancas !== undefined) ? (
										<Row style={{ marginBottom: "1%", width: "95%", marginLeft: "0%" }}>
											<p style={{ fontSize: "13px" }}>{this.state.infoLembrancas}</p>
										</Row>
									) : (
											null
										)} */}
									<Row style={{ marginTop: "2%", marginLeft: "1%" }}>
										<Col className="inline-col">
											<h6 style={{ textAlign: "left" }}><b>Fornecedores em Negociação</b></h6>
										</Col>
									</Row>
									<Row style={{ marginBottom: "2%", marginLeft: "0%", justifyContent: "center" }}>
										<Select value={multiselectLembrancasValue} placeholder="Selecione Fornecedor." style={{ width: "95%" }} mode="multiple" placeholder="Selecione Fornecedor" onChange={(value) => this.handleChange(value, "Lembranças")}>
											{multiselectLembrancas}
										</Select>
									</Row>
									<Row style={{ marginBottom: "4%", marginLeft: "0%", justifyContent: "center" }}>
										<ul class="list-group" style={{ width: "95%" }}>
											{negotiationsListLembrancas}
										</ul>
									</Row>
									<Row style={{ marginBottom: "4%", marginLeft: "0%", justifyContent: "center" }}>
										{(this.state.selectedProviderLembrancas !== null && this.state.selectedProviderLembrancas !== undefined && this.state.selectedPackLembrancas !== null && this.state.selectedPackLembrancas !== undefined) ? (
											<Collapse accordion style={{ width: "95%", backgroundColor: "#bbe4ca2e" }}>
												<Panel header="Fornecedor Contratado" key="1" extra={genExtra()}>
													<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
														<Col className="inline-col" style={{ marginTop: "15px" }}>
															<h6 style={{ textAlign: "left" }}><b>Fornecedor</b></h6>
														</Col>
														{(this.state.selectedProviderLembrancas === null || this.state.selectedProviderLembrancas === undefined) ? (
															<Col></Col>
														) : (
															<Col className="inline-col" style={{justifyContent:"flex-end"}}>
																<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
																	<a href={"mailto:" + this.state.emailLembrancas + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + this.state.nameLembrancas + ", " + "%0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
																</Tooltip>
																<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Upload Contrato" placement="top">
																	<AddIcon style={{marginLeft:"1%"}} className="btn-add pointer" onClick={() => this.toggleUpload(true, "Lembranças", this.state.selectedProviderLembrancas)}/>
																</Tooltip>
																{(this.state.hasContractLembrancas === true) ? (
																	<div className="inline-col">
																		<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Visualizar Contrato" placement="top">
																			<PdfIcon style={{marginLeft:"1%"}} className="pointer" onClick={() => this.download("Lembranças", this.state.selectedProviderLembrancas)}/>
																		</Tooltip>
																		<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Eliminar Contrato" placement="top">
																			<DeleteIcon style={{marginLeft:"1%"}} className="btn-delete pointer" onClick={() => this.toggleDelete(true, "Lembranças", this.state.selectedProviderLembrancas )}/>
																		</Tooltip>
																	</div>
																) : (
																	<p></p>
																)}
															</Col>
														)}
													</Row>
													<Row style={{ justifyContent:"space-evenly", marginBottom:"2%" }}>
														<Select style={{width:"47%"}} placeholder="Selecione Fornecedor." value={this.state.selectedProviderLembrancas} onChange={this.changeSelectProviderLembrancas}>
															<Option value={undefined}>Selecione Fornecedor</Option>
															{providerLembrancas}
														</Select>
														<Select style={{width:"47%"}} placeholder="Selecione Pack." value={this.state.selectedPackLembrancas} onChange={this.changeSelectPackLembrancas}>
															<Option value={undefined}>Selecione Pack</Option>
															{providerLembrancasPacks}
														</Select>
													</Row>
													{/* <Row style={{ marginBottom: "2%" }}>
														<Col style={{ width: "50%" }}>
															{providerLembrancasInfo}
														</Col>
														<Col>
															{detailsPackLembrancas}
														</Col>
													</Row> */}
													<Row style={{justifyContent:"space-evenly", marginBottom:"2%"}}>
														{providerLembrancasInfo}
														{detailsPackLembrancas}
													</Row>
													<Row style={{ marginBottom: "2%", marginRight: "0%", textAlign: "right", float: "right" }}>
														<Button className="btn-user" onClick={this.updateProviders}>Contratar</Button>
													</Row>
												</Panel>
											</Collapse>
										) : (
												<Collapse accordion style={{ width: "95%" }}>
													<Panel header="Fornecedor Contratado" key="1">
														<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
															<Col className="inline-col" style={{ marginTop: "15px" }}>
																<h6 style={{ textAlign: "left" }}><b>Fornecedor</b></h6>
															</Col>
															{(this.state.selectedProviderLembrancas === null || this.state.selectedProviderLembrancas === undefined) ? (
																<Col></Col>
															) : (
																	<Col className="inline-col" sm="1" style={{ marginTop: "15px" }}>
																		<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
																			<a href={"mailto:" + this.state.emailLembrancas + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + this.state.nameLembrancas + ", " + "%0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
																		</Tooltip>
																	</Col>
																)}
														</Row>
														<Row style={{ justifyContent:"space-evenly", marginBottom:"2%" }}>
															<Select style={{width:"47%"}} placeholder="Selecione Fornecedor." value={this.state.selectedProviderLembrancas} onChange={this.changeSelectProviderLembrancas}>
																<Option value={undefined}>Selecione Fornecedor</Option>
																{providerLembrancas}
															</Select>
															<Select style={{width:"47%"}} placeholder="Selecione Pack." value={this.state.selectedPackLembrancas} onChange={this.changeSelectPackLembrancas}>
																<Option value={undefined}>Selecione Pack</Option>
																{providerLembrancasPacks}
															</Select>
														</Row>
														{/* <Row style={{ marginBottom: "2%" }}>
															<Col style={{ width: "50%" }}>
																{providerLembrancasInfo}
															</Col>
															<Col>
																{detailsPackLembrancas}
															</Col>
														</Row> */}
														<Row style={{marginLeft:"15px", marginBottom:"2%"}}>
															{providerLembrancasInfo}
														</Row>
														<Row style={{ marginBottom: "2%", marginRight: "0%", textAlign: "right", float: "right" }}>
															<Button className="btn-user" onClick={this.updateProviders}>Contratar</Button>
														</Row>
													</Panel>
												</Collapse>
											)}
									</Row>
									<Row style={{ marginLeft: "1%" }}>
										<Col className="inline-col">
											<h6 style={{ textAlign: "left" }}><b>Notas</b></h6>
										</Col>
									</Row>
									<Row style={{ marginBottom: "2%", marginLeft: "0%", justifyContent: "center"}}>
										<FormTextarea id="text-area" value={this.state.notesLembrancasRole} style={{ width: "95%" }} onChange={(el) => { this.handleNotesText("notesLembrancasRole", el.target.value) }} />
									</Row>
									<Row style={{ marginBottom: "5%",  marginRight: "0%", textAlign: "right", float: "right" }}>
										<Button className="btn-user" onClick={() => {this.saveNotes("Lembranças")}}>Gravar Notas</Button>
									</Row>

									<Row style={{ marginBottom: "1%", marginTop: "8%", marginLeft: "0%" }}>
										<h5 style={{ textAlign: "left", color: "#C09F92" }}><b>Transporte de Convidados</b></h5>
										{(this.state.transporteWanted === true || this.state.transporteWanted === false) ? (
											<Switch size="small" style={{ marginLeft: "2%", marginTop: "0.5%" }} checkedChildren={""} unCheckedChildren={""} defaultChecked={this.state.transporteWanted}  onChange={(checked) => this.onChangeIsWanted("transporteWanted", this.state.roleUrl, "Transporte Convidados", checked)} />
										) : (
											<p></p>
										)}
									</Row>
									{/* {(this.state.infoTransp !== null && this.state.infoTransp !== undefined) ? (
										<Row style={{ marginBottom: "1%", width: "95%", marginLeft: "0%" }}>
											<p style={{ fontSize: "13px" }}>{this.state.infoTransp}</p>
										</Row>
									) : (
											null
										)} */}
									<Row style={{ marginTop: "2%", marginLeft: "1%" }}>
										<Col className="inline-col">
											<h6 style={{ textAlign: "left" }}><b>Fornecedores em Negociação</b></h6>
										</Col>
									</Row>
									<Row style={{ marginBottom: "2%", marginLeft: "0%", justifyContent: "center" }}>
										<Select value={multiselectTransporteValue} placeholder="Selecione Fornecedor." style={{ width: "95%" }} mode="multiple" placeholder="Selecione Fornecedor" onChange={(value) => this.handleChange(value, "Transporte Convidados")}>
											{multiselectTransporte}
										</Select>
									</Row>
									<Row style={{ marginBottom: "4%", marginLeft: "0%", justifyContent: "center" }}>
										<ul class="list-group" style={{ width: "95%" }}>
											{negotiationsListTransporte}
										</ul>
									</Row>
									<Row style={{ marginBottom: "4%", marginLeft: "0%", justifyContent: "center" }}>
										{(this.state.selectedProviderTransporte !== null && this.state.selectedProviderTransporte !== undefined && this.state.selectedPackTransporte !== null && this.state.selectedPackTransporte !== undefined) ? (
											<Collapse accordion style={{ width: "95%", backgroundColor: "#bbe4ca2e" }}>
												<Panel header="Fornecedor Contratado" key="1" extra={genExtra()}>
													<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
														<Col className="inline-col" style={{ marginTop: "15px" }}>
															<h6 style={{ textAlign: "left" }}><b>Fornecedor</b></h6>
														</Col>
														{(this.state.selectedProviderTransporte === null || this.state.selectedProviderTransporte === undefined) ? (
															<Col></Col>
														) : (
															<Col className="inline-col" style={{justifyContent:"flex-end"}}>
																<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
																	<a href={"mailto:" + this.state.emailTransporte + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + this.state.nameTransporte + ", " + "%0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
																</Tooltip>
																<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Upload Contrato" placement="top">
																	<AddIcon style={{marginLeft:"1%"}} className="btn-add pointer" onClick={() => this.toggleUpload(true, "Transporte Convidados", this.state.selectedProviderTransporte)}/>
																</Tooltip>
																{(this.state.hasContractTransporte === true) ? (
																	<div className="inline-col">
																		<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Visualizar Contrato" placement="top">
																			<PdfIcon style={{marginLeft:"1%"}} className="pointer" onClick={() => this.download("Transporte Convidados", this.state.selectedProviderTransporte)}/>
																		</Tooltip>
																		<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Eliminar Contrato" placement="top">
																			<DeleteIcon style={{marginLeft:"1%"}} className="btn-delete pointer" onClick={() => this.toggleDelete(true, "Transporte Convidados", this.state.selectedProviderTransporte )}/>
																		</Tooltip>
																	</div>
																) : (
																	<p></p>
																)}
															</Col>
														)}
													</Row>
													<Row style={{ justifyContent:"space-evenly", marginBottom:"2%" }}>
														<Select style={{width:"47%"}} placeholder="Selecione Fornecedor." value={this.state.selectedProviderTransporte} onChange={this.changeSelectProviderTransporte}>
															<Option value={undefined}>Selecione Fornecedor</Option>
															{providerTransporte}
														</Select>
														<Select style={{width:"47%"}} placeholder="Selecione Pack." value={this.state.selectedPackTransporte} onChange={this.changeSelectPackTransporte}>
															<Option value={undefined}>Selecione Pack</Option>
															{providerTransportePacks}
														</Select>
													</Row>
													{/* <Row style={{ marginBottom: "2%" }}>
														<Col style={{ width: "50%" }}>
															{providerTransporteInfo}
														</Col>
														<Col>
															{detailsPackTransporte}
														</Col>
													</Row> */}
													<Row style={{justifyContent:"space-evenly", marginBottom:"2%"}}>
														{providerTransporteInfo}
														{detailsPackTransporte}
													</Row>
													<Row style={{ marginBottom: "2%", marginRight: "0%", textAlign: "right", float: "right" }}>
														<Button className="btn-user" onClick={this.updateProviders}>Contratar</Button>
													</Row>
												</Panel>
											</Collapse>
										) : (
												<Collapse accordion style={{ width: "95%" }}>
													<Panel header="Fornecedor Contratado" key="1">
														<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
															<Col className="inline-col" style={{ marginTop: "15px" }}>
																<h6 style={{ textAlign: "left" }}><b>Fornecedor</b></h6>
															</Col>
															{(this.state.selectedProviderTransporte === null || this.state.selectedProviderTransporte === undefined) ? (
																<Col></Col>
															) : (
																	<Col className="inline-col" sm="1" style={{ marginTop: "15px" }}>
																		<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
																			<a href={"mailto:" + this.state.emailTransporte + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + this.state.nameTransporte + ", " + "%0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
																		</Tooltip>
																	</Col>
																)}
														</Row>
														<Row style={{ justifyContent:"space-evenly", marginBottom:"2%" }}>
															<Select style={{width:"47%"}} placeholder="Selecione Fornecedor." value={this.state.selectedProviderTransporte} onChange={this.changeSelectProviderTransporte}>
																<Option value={undefined}>Selecione Fornecedor</Option>
																{providerTransporte}
															</Select>
															<Select style={{width:"47%"}} placeholder="Selecione Pack." value={this.state.selectedPackTransporte} onChange={this.changeSelectPackTransporte}>
																<Option value={undefined}>Selecione Pack</Option>
																{providerTransportePacks}
															</Select>
														</Row>
														{/* <Row style={{ marginBottom: "2%" }}>
															<Col style={{ width: "50%" }}>
																{providerTransporteInfo}
															</Col>
															<Col>
																{detailsPackTransporte}
															</Col>
														</Row> */}
														<Row style={{marginLeft:"15px", marginBottom:"2%"}}>
															{providerTransporteInfo}
														</Row>
														<Row style={{ marginBottom: "2%", marginRight: "0%", textAlign: "right", float: "right" }}>
															<Button className="btn-user" onClick={this.updateProviders}>Contratar</Button>
														</Row>
													</Panel>
												</Collapse>
											)}
									</Row>
									<Row style={{ marginLeft: "1%" }}>
										<Col className="inline-col">
											<h6 style={{ textAlign: "left" }}><b>Notas</b></h6>
										</Col>
									</Row>
									<Row style={{ marginBottom: "2%", marginLeft: "0%", justifyContent: "center"}}>
										<FormTextarea id="text-area" value={this.state.notesTransporteRole} style={{ width: "95%" }} onChange={(el) => { this.handleNotesText("notesTransporteRole", el.target.value) }} />
									</Row>
									<Row style={{marginBottom: "5%",  marginRight: "0%", textAlign: "right", float: "right" }}>
										<Button className="btn-user" onClick={() => {this.saveNotes("Transporte Convidados")}}>Gravar Notas</Button>
									</Row>

									<Row style={{ marginBottom: "1%", marginTop: "8%", marginLeft: "0%" }}>
										<h5 style={{ textAlign: "left", color: "#C09F92" }}><b>Aulas de Dança</b></h5>
										{(this.state.aulasWanted === true || this.state.aulasWanted === false) ? (
											<Switch size="small" style={{ marginLeft: "2%", marginTop: "0.5%" }} checkedChildren={""} unCheckedChildren={""} defaultChecked={this.state.aulasWanted}  onChange={(checked) => this.onChangeIsWanted("aulasWanted", this.state.roleUrl, "Aulas de dança", checked)} />
										) : (
											<p></p>
										)}
									</Row>
									{/* {(this.state.infoAulas !== null && this.state.infoAulas !== undefined) ? (
										<Row style={{ marginBottom: "1%", width: "95%", marginLeft: "0%" }}>
											<p style={{ fontSize: "13px" }}>{this.state.infoAulas}</p>
										</Row>
									) : (
											null
										)} */}
									<Row style={{ marginTop: "2%", marginLeft: "1%" }}>
										<Col className="inline-col">
											<h6 style={{ textAlign: "left" }}><b>Fornecedores em Negociação</b></h6>
										</Col>
									</Row>
									<Row style={{ marginBottom: "2%", marginLeft: "0%", justifyContent: "center" }}>
										<Select value={multiselectAulasValue} placeholder="Selecione Fornecedor." style={{ width: "95%" }} mode="multiple" placeholder="Selecione Fornecedor" onChange={(value) => this.handleChange(value, "Aulas de dança")}>
											{multiselectAulas}
										</Select>
									</Row>
									<Row style={{ marginBottom: "4%", marginLeft: "0%", justifyContent: "center" }}>
										<ul class="list-group" style={{ width: "95%" }}>
											{negotiationsListAulas}
										</ul>
									</Row>
									<Row style={{ marginBottom: "3%", marginLeft: "0%", justifyContent: "center" }}>
										{(this.state.selectedProviderAulas !== null && this.state.selectedProviderAulas !== undefined && this.state.selectedPackAulas !== null && this.state.selectedPackAulas !== undefined) ? (
											<Collapse accordion style={{ width: "95%", backgroundColor: "#bbe4ca2e" }}>
												<Panel header="Fornecedor Contratado" key="1" extra={genExtra()}>
													<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
														<Col className="inline-col" style={{ marginTop: "15px" }}>
															<h6 style={{ textAlign: "left" }}><b>Fornecedor</b></h6>
														</Col>
														{(this.state.selectedProviderAulas === null || this.state.selectedProviderAulas === undefined) ? (
															<Col></Col>
														) : (
															<Col className="inline-col" style={{justifyContent:"flex-end"}}>
																<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
																	<a href={"mailto:" + this.state.emailAulas + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + this.state.nameAulas + ", " + "%0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
																</Tooltip>
																<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Upload Contrato" placement="top">
																	<AddIcon style={{marginLeft:"1%"}} className="btn-add pointer" onClick={() => this.toggleUpload(true, "Aulas de dança", this.state.selectedProviderAulas)}/>
																</Tooltip>
																{(this.state.hasContractAulas === true) ? (
																	<div className="inline-col">
																		<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Visualizar Contrato" placement="top">
																			<PdfIcon style={{marginLeft:"1%"}} className="pointer" onClick={() => this.download("Aulas de dança", this.state.selectedProviderAulas)}/>
																		</Tooltip>
																		<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Eliminar Contrato" placement="top">
																			<DeleteIcon style={{marginLeft:"1%"}} className="btn-delete pointer" onClick={() => this.toggleDelete(true, "Aulas de dança", this.state.selectedProviderAulas )}/>
																		</Tooltip>
																	</div>
																) : (
																	<p></p>
																)}
															</Col>
														)}
													</Row>
													<Row style={{ justifyContent:"space-evenly", marginBottom:"2%" }}>
														<Select style={{width:"47%"}} placeholder="Selecione Fornecedor." value={this.state.selectedProviderAulas} onChange={this.changeSelectProviderAulas}>
															<Option value={undefined}>Selecione Fornecedor</Option>
															{providerAulas}
														</Select>
														<Select style={{width:"47%"}} placeholder="Selecione Pack." value={this.state.selectedPackAulas} onChange={this.changeSelectPackAulas}>
															<Option value={undefined}>Selecione Pack</Option>
															{providerAulasPacks}
														</Select>
													</Row>
													{/* <Row style={{ marginBottom: "2%" }}>
														<Col style={{ width: "50%" }}>
															{providerAulasInfo}
														</Col>
														<Col>
															{detailsPackAulas}
														</Col>
													</Row> */}
													<Row style={{justifyContent:"space-evenly", marginBottom:"2%"}}>
														{providerAulasInfo}
														{detailsPackAulas}
													</Row>
													<Row style={{ marginBottom: "2%", marginRight: "0%", textAlign: "right", float: "right" }}>
														<Button className="btn-user" onClick={this.updateProviders}>Contratar</Button>
													</Row>
												</Panel>
											</Collapse>
										) : (
												<Collapse accordion style={{ width: "95%" }}>
													<Panel header="Fornecedor Contratado" key="1">
														<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
															<Col className="inline-col" style={{ marginTop: "15px" }}>
																<h6 style={{ textAlign: "left" }}><b>Fornecedor</b></h6>
															</Col>
															{(this.state.selectedProviderAulas === null || this.state.selectedProviderAulas === undefined) ? (
																<Col></Col>
															) : (
																	<Col className="inline-col" sm="1" style={{ marginTop: "15px" }}>
																		<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
																			<a href={"mailto:" + this.state.emailAulas + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + this.state.nameAulas + ", " + "%0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
																		</Tooltip>
																	</Col>
																)}
														</Row>
														<Row style={{ justifyContent:"space-evenly", marginBottom:"2%" }}>
															<Select style={{width:"47%"}} placeholder="Selecione Fornecedor." value={this.state.selectedProviderAulas} onChange={this.changeSelectProviderAulas}>
																<Option value={undefined}>Selecione Fornecedor</Option>
																{providerAulas}
															</Select>
															<Select style={{width:"47%"}} placeholder="Selecione Pack." value={this.state.selectedPackAulas} onChange={this.changeSelectPackAulas}>
																<Option value={undefined}>Selecione Pack</Option>
																{providerAulasPacks}
															</Select>
														</Row>
														<Row style={{marginLeft:"15px", marginBottom:"2%"}}>
															{providerAulasInfo}
														</Row>
														<Row style={{ marginBottom: "2%", marginRight: "0%", textAlign: "right", float: "right" }}>
															<Button className="btn-user" onClick={this.updateProviders}>Contratar</Button>
														</Row>
													</Panel>
												</Collapse>
											)}
									</Row>
									<Row style={{ marginLeft: "1%" }}>
										<Col className="inline-col">
											<h6 style={{ textAlign: "left" }}><b>Notas</b></h6>
										</Col>
									</Row>
									<Row style={{ marginBottom: "2%", marginLeft: "0%", justifyContent: "center"}}>
										<FormTextarea id="text-area" value={this.state.notesAulasRole} style={{ width: "95%" }} onChange={(el) => { this.handleNotesText("notesAulasRole", el.target.value) }} />
									</Row>
									<Row style={{ marginBottom:"5%",  marginRight: "0%", textAlign: "right", float: "right" }}>
										<Button className="btn-user" onClick={() => {this.saveNotes("Aulas de dança")}}>Gravar Notas</Button>
									</Row>
								</div>
							) : (
									<Row></Row>
								)}

							{(this.state.roleUrl === "Fotografia e Video") ? (
								<div>
									<Row style={{marginTop: "2%", marginLeft: "0%" }}>
										<h6 style={{ textAlign: "left"}}><b>Checklist</b></h6>
									</Row>
									<Row style={{ marginBottom: "1%", marginTop:"1%", marginLeft: "0%", justifyContent: "center" }}>
										<Collapse accordion style={{ width: "95%" }}>
											<Panel header="Tarefas" key="1">
												{(this.state.ckecklistText !== null && this.state.checklistText !== undefined) ? (
													this.state.checklistText.map((item, index) => {
														let element = this.state.checklistState.find( (el) => el.toDo === item.toDo)
														let isChecked = (element) ? element.isChecked : false
														return(
															<div>
																<Checkbox checked={isChecked} style={{marginBottom:"1%"}} onChange={(e) => { this.changeChecklist(item.toDo, e.target.checked)}}> {item.text}</Checkbox>
															</div>
														)	
													})
												) : (
													<p style={{ marginTop: "2%" }}>Não existe checklist associada a esta categoria.</p>
												)}
											</Panel>
										</Collapse>
									</Row>
									<Row style={{ marginBottom: "1%", marginTop: "4%", marginLeft: "0%" }}>
										<h5 style={{ textAlign: "left", color: "#C09F92" }}><b>Fotografia</b></h5>
										{(this.state.fotoWanted === true || this.state.fotoWanted === false) ? (
											<Switch size="small" style={{ marginLeft: "2%", marginTop: "0.5%" }} checkedChildren={""} unCheckedChildren={""} defaultChecked={this.state.fotoWanted}  onChange={(checked) => this.onChangeIsWanted("fotoWanted", this.state.roleUrl, "Fotografia", checked)} />
										) : (
											<p></p>
										)}
									</Row>
									{/* {(this.state.infoFoto !== null && this.state.infoFoto !== undefined) ? (
										<Row style={{ marginBottom: "1%", width: "95%", marginLeft: "0%" }}>
											<p style={{ fontSize: "13px" }}>{this.state.infoFoto}</p>
										</Row>
									) : (
										null
									)} */}
									<Row style={{ marginTop: "2%", marginLeft: "1%" }}>
										<Col className="inline-col">
											<h6 style={{ textAlign: "left" }}><b>Fornecedores em Negociação</b></h6>
										</Col>
									</Row>
									<Row style={{ marginBottom: "2%", marginLeft: "0%", justifyContent: "center" }}>
										<Select value={multiselectFotoValue} placeholder="Selecione Fornecedor." style={{ width: "95%" }} mode="multiple" placeholder="Selecione Fornecedor" onChange={(value) => this.handleChange(value, "Fotografia")}>
											{multiselectFoto}
										</Select>
									</Row>
									<Row style={{ marginBottom: "4%", marginLeft: "0%", justifyContent: "center" }}>
										<ul class="list-group" style={{ width: "95%" }}>
											{negotiationsListFoto}
										</ul>
									</Row>
									<Row style={{ marginBottom: "4%", marginLeft: "0%", justifyContent: "center" }}>
										{(this.state.selectedProviderFoto !== null && this.state.selectedProviderFoto !== undefined && this.state.selectedPackFoto !== null && this.state.selectedPackFoto !== undefined) ? (
											<Collapse accordion style={{ width: "95%", backgroundColor: "#bbe4ca2e" }}>
												<Panel header="Fornecedor Contratado" key="1" extra={genExtra()}>
													<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
														<Col className="inline-col" style={{ marginTop: "15px" }}>
															<h6 style={{ textAlign: "left" }}><b>Fornecedor</b></h6>
														</Col>
														{(this.state.selectedProviderFoto === null || this.state.selectedProviderFoto === undefined) ? (
															<Col></Col>
														) : (
															<Col className="inline-col" style={{justifyContent:"flex-end"}}>
																<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
																	<a href={"mailto:" + this.state.emailFoto + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + this.state.nameFoto + ", " + "%0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
																</Tooltip>
																<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Upload Contrato" placement="top">
																	<AddIcon style={{marginLeft:"1%"}} className="btn-add pointer" onClick={() => this.toggleUpload(true, "Fotografia", this.state.selectedProviderFoto)}/>
																</Tooltip>
																{(this.state.hasContractFoto === true) ? (
																	<div className="inline-col">
																		<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Visualizar Contrato" placement="top">
																			<PdfIcon style={{marginLeft:"1%"}} className="pointer" onClick={() => this.download("Fotografia", this.state.selectedProviderFoto)}/>
																		</Tooltip>
																		<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Eliminar Contrato" placement="top">
																			<DeleteIcon style={{marginLeft:"1%"}} className="btn-delete pointer" onClick={() => this.toggleDelete(true, "Fotografia", this.state.selectedProviderFoto)}/>
																		</Tooltip>
																	</div>
																) : (
																	<p></p>
																)}
															</Col>
														)}
													</Row>
													<Row style={{ justifyContent:"space-evenly", marginBottom:"2%" }}>
														<Select style={{width:"47%"}} placeholder="Selecione Fornecedor." value={this.state.selectedProviderFoto} onChange={this.changeSelectProviderFoto}>
															<Option value={undefined}>Selecione Fornecedor</Option>
															{providerFoto}
														</Select>
														<Select style={{width:"47%"}} placeholder="Selecione Pack." value={this.state.selectedPackFoto} onChange={this.changeSelectPackFoto}>
															<Option value={undefined}>Selecione Pack</Option>
															{providerFotoPacks}
														</Select>
													</Row>
													{/* <Row style={{ marginBottom: "2%" }}>
														<Col style={{ width: "50%" }}>
															{providerFotoInfo}
														</Col>
														<Col>
															{detailsPackFoto}
														</Col>
													</Row> */}
													<Row style={{justifyContent:"space-evenly", marginBottom:"2%"}}>
														{providerFotoInfo}
														{detailsPackFoto}
													</Row>
													<Row style={{ marginBottom: "2%", marginRight: "0%", textAlign: "right", float: "right" }}>
														<Button className="btn-user" onClick={this.updateProviders}>Contratar</Button>
													</Row>
												</Panel>
											</Collapse>
										) : (
												<Collapse accordion style={{ width: "95%" }}>
													<Panel header="Fornecedor Contratado" key="1">
														<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
															<Col className="inline-col" style={{ marginTop: "15px" }}>
																<h6 style={{ textAlign: "left" }}><b>Fornecedor</b></h6>
															</Col>
															{(this.state.selectedProviderFoto === null || this.state.selectedProviderFoto === undefined) ? (
																<Col></Col>
															) : (
																	<Col className="inline-col" sm="1" style={{ marginTop: "15px" }}>
																		<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
																			<a href={"mailto:" + this.state.emailFoto + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + this.state.nameFoto + ", " + "%0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
																		</Tooltip>
																	</Col>
																)}
														</Row>
														<Row style={{ justifyContent:"space-evenly", marginBottom:"2%" }}>
															<Select style={{width:"47%"}} placeholder="Selecione Fornecedor." value={this.state.selectedProviderFoto} onChange={this.changeSelectProviderFoto}>
																<Option value={undefined}>Selecione Fornecedor</Option>
																{providerFoto}
															</Select>
															<Select style={{width:"47%"}} placeholder="Selecione Pack." value={this.state.selectedPackFoto} onChange={this.changeSelectPackFoto}>
																<Option value={undefined}>Selecione Pack</Option>
																{providerFotoPacks}
															</Select>
														</Row>
														{/* <Row style={{ marginBottom: "2%" }}>
															<Col style={{ width: "50%" }}>
																{providerFotoInfo}
															</Col>
															<Col>
																{detailsPackFoto}
															</Col>
														</Row> */}
														<Row style={{marginLeft:"15px", marginBottom:"2%"}}>
															{providerFotoInfo}
														</Row>
														<Row style={{ marginBottom: "2%", marginRight: "0%", textAlign: "right", float: "right" }}>
															<Button className="btn-user" onClick={this.updateProviders}>Contratar</Button>
														</Row>
													</Panel>
												</Collapse>
											)}
									</Row>
									<Row style={{ marginLeft: "1%" }}>
										<Col className="inline-col">
											<h6 style={{ textAlign: "left" }}><b>Notas</b></h6>
										</Col>
									</Row>
									<Row style={{ marginBottom: "2%", marginLeft: "0%", justifyContent: "center"}}>
										<FormTextarea id="text-area" value={this.state.notesFotografiaRole} style={{ width: "95%" }} onChange={(el) => { this.handleNotesText("notesFotografiaRole", el.target.value) }} />
									</Row>
									<Row style={{ marginBottom:"5%",  marginRight: "0%", textAlign: "right", float: "right" }}>
										<Button className="btn-user" onClick={() => {this.saveNotes("Fotografia")}}>Gravar Notas</Button>
									</Row>

									<Row style={{ marginBottom: "1%", marginTop: "8%", marginLeft: "0%" }}>
										<h5 style={{ textAlign: "left", color: "#C09F92" }}><b>Vídeo</b></h5>
										{(this.state.videoWanted === true || this.state.videoWanted === false) ? (
											<Switch size="small" style={{ marginLeft: "2%", marginTop: "0.5%" }} checkedChildren={""} unCheckedChildren={""} defaultChecked={this.state.videoWanted}  onChange={(checked) => this.onChangeIsWanted("videoWanted", this.state.roleUrl, "Video", checked)} />
										) : (
											<p></p>
										)}
									</Row>
									{/* {(this.state.infoVideo !== null && this.state.infoVideo !== undefined) ? (
										<Row style={{ marginBottom: "1%", width: "95%", marginLeft: "0%" }}>
											<p style={{ fontSize: "13px" }}>{this.state.infoVideo}</p>
										</Row>
									) : (
											null
										)} */}
									<Row style={{ marginTop: "2%", marginLeft: "1%" }}>
										<Col className="inline-col">
											<h6 style={{ textAlign: "left" }}><b>Fornecedores em Negociação</b></h6>
										</Col>
									</Row>
									<Row style={{ marginBottom: "2%", marginLeft: "0%", justifyContent: "center" }}>
										<Select value={multiselectVideoValue} placeholder="Selecione Fornecedor." style={{ width: "95%" }} mode="multiple" placeholder="Selecione Fornecedor" onChange={(value) => this.handleChange(value, "Video")}>
											{multiselectVideo}
										</Select>
									</Row>
									<Row style={{ marginBottom: "4%", marginLeft: "0%", justifyContent: "center" }}>
										<ul class="list-group" style={{ width: "95%" }}>
											{negotiationsListVideo}
										</ul>
									</Row>
									<Row style={{ marginBottom: "4%", marginLeft: "0%", justifyContent: "center" }}>
										{(this.state.selectedProviderVideo !== null && this.state.selectedProviderVideo !== undefined && this.state.selectedPackVideo !== null && this.state.selectedPackVideo !== undefined) ? (
											<Collapse accordion style={{ width: "95%", backgroundColor: "#bbe4ca2e" }}>
												<Panel header="Fornecedor Contratado" key="1" extra={genExtra()}>
													<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
														<Col className="inline-col" style={{ marginTop: "15px" }}>
															<h6 style={{ textAlign: "left" }}><b>Fornecedor</b></h6>
														</Col>
														{(this.state.selectedProviderVideo === null || this.state.selectedProviderVideo === undefined) ? (
															<Col></Col>
														) : (
															<Col className="inline-col" style={{justifyContent:"flex-end"}}>
																<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
																	<a href={"mailto:" + this.state.emailVideo + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + this.state.nameVideo + ", " + "%0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
																</Tooltip>
																<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Upload Contrato" placement="top">
																	<AddIcon style={{marginLeft:"1%"}} className="btn-add pointer" onClick={() => this.toggleUpload(true, "Video", this.state.selectedProviderVideo)}/>
																</Tooltip>
																{(this.state.hasContractVideo === true) ? (
																	<div className="inline-col">
																		<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Visualizar Contrato" placement="top">
																			<PdfIcon style={{marginLeft:"1%"}} className="pointer" onClick={() => this.download("Video", this.state.selectedProviderVideo)}/>
																		</Tooltip>
																		<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Eliminar Contrato" placement="top">
																			<DeleteIcon style={{marginLeft:"1%"}} className="btn-delete pointer" onClick={() => this.toggleDelete(true, "Video", this.state.selectedProviderVideo )}/>
																		</Tooltip>
																	</div>
																) : (
																	<p></p>
																)}
															</Col>
														)}
													</Row>
													<Row style={{ justifyContent:"space-evenly", marginBottom:"2%" }}>
														<Select style={{width:"47%"}} placeholder="Selecione Fornecedor." value={this.state.selectedProviderVideo} onChange={this.changeSelectProviderVideo}>
															<Option value={undefined}>Selecione Fornecedor</Option>
															{providerVideo}
														</Select>
														<Select style={{width:"47%"}} placeholder="Selecione Pack." value={this.state.selectedPackVideo} onChange={this.changeSelectPackVideo}>
															<Option value={undefined}>Selecione Pack</Option>
															{providerVideoPacks}
														</Select>
													</Row>
													{/* <Row style={{ marginBottom: "2%", justifyContent: "space-evenly", minHeight: "100%" }}>
														<Col style={{ width: "50%", minHeight: "100%" }}>
															{providerVideoInfo}
														</Col>
														<Col>
															{detailsPackVideo}
														</Col>
													</Row> */}
													<Row style={{justifyContent:"space-evenly", marginBottom:"2%"}}>
														{providerVideoInfo}
														{detailsPackVideo}
													</Row>
													<Row style={{ marginBottom: "2%", marginRight: "0%", textAlign: "right", float: "right" }}>
														<Button className="btn-user" onClick={this.updateProviders}>Contratar</Button>
													</Row>
												</Panel>
											</Collapse>

										) : (
												<Collapse accordion style={{ width: "95%" }}>
													<Panel header="Fornecedor Contratado" key="1">
														<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
															<Col className="inline-col" style={{ marginTop: "15px" }}>
																<h6 style={{ textAlign: "left" }}><b>Fornecedor</b></h6>
															</Col>
															{(this.state.selectedProviderVideo === null || this.state.selectedProviderVideo === undefined) ? (
																<Col></Col>
															) : (
																	<Col className="inline-col" sm="1" style={{ marginTop: "15px" }}>
																		<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
																			<a href={"mailto:" + this.state.emailVideo + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + this.state.nameVideo + ", " + "%0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
																		</Tooltip>
																	</Col>
																)}
														</Row>
														<Row style={{ justifyContent:"space-evenly", marginBottom:"2%" }}>
															<Select style={{width:"47%"}} placeholder="Selecione Fornecedor." value={this.state.selectedProviderVideo} onChange={this.changeSelectProviderVideo}>
																<Option value={undefined}>Selecione Fornecedor</Option>
																{providerVideo}
															</Select>
															<Select style={{width:"47%"}} placeholder="Selecione Pack." value={this.state.selectedPackVideo} onChange={this.changeSelectPackVideo}>
																<Option value={undefined}>Selecione Pack</Option>
																{providerVideoPacks}
															</Select>
														</Row>
														{/* <Row style={{ marginBottom: "2%", justifyContent: "space-evenly", minHeight: "100%" }}>
															<Col style={{ width: "50%", minHeight: "100%" }}>
																{providerVideoInfo}
															</Col>
															<Col>
																{detailsPackVideo}
															</Col>
														</Row> */}
														<Row style={{marginLeft:"15px", marginBottom:"2%"}}>
															{providerVideoInfo}
														</Row>
														<Row style={{ marginBottom: "2%", marginRight: "0%", textAlign: "right", float: "right" }}>
															<Button className="btn-user" onClick={this.updateProviders}>Contratar</Button>
														</Row>
													</Panel>
												</Collapse>
											)}
									</Row>
									<Row style={{ marginLeft: "1%" }}>
										<Col className="inline-col">
											<h6 style={{ textAlign: "left" }}><b>Notas</b></h6>
										</Col>
									</Row>
									<Row style={{ marginBottom: "2%", marginLeft: "0%", justifyContent: "center"}}>
										<FormTextarea id="text-area" value={this.state.notesVideoRole} style={{ width: "95%" }} onChange={(el) => { this.handleNotesText("notesVideoRole", el.target.value) }} />
									</Row>
									<Row style={{ marginBottom:"5%",  marginRight: "0%", textAlign: "right", float: "right" }}>
										<Button className="btn-user" onClick={() => {this.saveNotes("Video")}}>Gravar Notas</Button>
									</Row>

									<Row style={{ marginBottom: "1%", marginTop: "8%", marginLeft: "0%" }}>
										<h5 style={{ textAlign: "left", color: "#C09F92" }}><b>Fotografia e Video</b></h5>
										{(this.state.fvWanted === true || this.state.fvWanted === false) ? (
											<Switch size="small" style={{ marginLeft: "2%", marginTop: "0.5%" }} checkedChildren={""} unCheckedChildren={""} defaultChecked={this.state.fvWanted}  onChange={(checked) => this.onChangeIsWanted("fvWanted", this.state.roleUrl, "Fotografia e Video", checked)} />
										) : (
											<p></p>
										)}
									</Row>
									{/* {(this.state.infoFV !== null && this.state.infoFV !== undefined) ? (
										<Row style={{ marginBottom: "1%", width: "95%", marginLeft: "0%" }}>
											<p style={{ fontSize: "13px" }}>{this.state.infoFV}</p>
										</Row>
									) : (
											null
										)} */}
									<Row style={{ marginTop: "2%", marginLeft: "1%" }}>
										<Col className="inline-col">
											<h6 style={{ textAlign: "left" }}><b>Fornecedores em Negociação</b></h6>
										</Col>
									</Row>
									<Row style={{ marginBottom: "2%", marginLeft: "0%", justifyContent: "center" }}>
										<Select value={multiselectFVValue} placeholder="Selecione Fornecedor." style={{ width: "95%" }} mode="multiple" placeholder="Selecione Fornecedor" onChange={(value) => this.handleChange(value, "Fotografia e Video")}>
											{multiselectFV}
										</Select>
									</Row>
									<Row style={{ marginBottom: "4%", marginLeft: "0%", justifyContent: "center" }}>
										<ul class="list-group" style={{ width: "95%" }}>
											{negotiationsListFV}
										</ul>
									</Row>
									<Row style={{ marginBottom: "4%", marginLeft: "0%", justifyContent: "center" }}>
										{(this.state.selectedProviderFV !== null && this.state.selectedProviderFV !== undefined && this.state.selectedPackFV !== null && this.state.selectedPackFV !== undefined) ? (
											<Collapse accordion style={{ width: "95%", backgroundColor: "#bbe4ca2e" }}>
												<Panel header="Fornecedor Contratado" key="1" extra={genExtra()}>
													<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
														<Col className="inline-col" style={{ marginTop: "15px" }}>
															<h6 style={{ textAlign: "left" }}><b>Fornecedor</b></h6>
														</Col>
														{(this.state.selectedProviderFV === null || this.state.selectedProviderFV === undefined) ? (
															<Col></Col>
														) : (
															<Col className="inline-col" style={{justifyContent:"flex-end"}}>
																<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
																	<a href={"mailto:" + this.state.emailFV + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + this.state.nameFV + ", " + "%0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
																</Tooltip>
																<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Upload Contrato" placement="top">
																	<AddIcon style={{marginLeft:"1%"}} className="btn-add pointer" onClick={() => this.toggleUpload(true, "Fotografia e Video", this.state.selectedProviderFV)}/>
																</Tooltip>
																{(this.state.hasContractFV === true) ? (
																	<div className="inline-col">
																		<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Visualizar Contrato" placement="top">
																			<PdfIcon style={{marginLeft:"1%"}} className="pointer" onClick={() => this.download("Fotografia e Video", this.state.selectedProviderFV)}/>
																		</Tooltip>
																		<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Eliminar Contrato" placement="top">
																			<DeleteIcon style={{marginLeft:"1%"}} className="btn-delete pointer" onClick={() => this.toggleDelete(true, "Fotografia e Video", this.state.selectedProviderFV )}/>
																		</Tooltip>
																	</div>
																) : (
																	<p></p>
																)}
															</Col>
														)}
													</Row>
													<Row style={{ justifyContent:"space-evenly", marginBottom:"2%" }}>
														<Select style={{width:"47%"}} placeholder="Selecione Fornecedor." value={this.state.selectedProviderFV} onChange={this.changeSelectProviderFV}>
															<Option value={undefined}>Selecione Fornecedor</Option>
															{providerFV}
														</Select>
														<Select style={{width:"47%"}} placeholder="Selecione Pack." value={this.state.selectedPackFV} onChange={this.changeSelectPackFV}>
															<Option value={undefined}>Selecione Pack</Option>
															{providerFVPacks}
														</Select>
													</Row>
													{/* <Row style={{ marginBottom: "2%", justifyContent: "space-evenly", minHeight: "100%" }}>
														<Col style={{ width: "50%", minHeight: "100%" }}>
															{providerFVInfo}
														</Col>
														<Col>
															{detailsPackFV}
														</Col>
													</Row> */}
													<Row style={{justifyContent:"space-evenly", marginBottom:"2%"}}>
														{providerFVInfo}
														{detailsPackFV}
													</Row>
													<Row style={{ marginBottom: "2%", marginRight: "0%", textAlign: "right", float: "right" }}>
														<Button className="btn-user" onClick={this.updateProviders}>Contratar</Button>
													</Row>
												</Panel>
											</Collapse>

										) : (
												<Collapse accordion style={{ width: "95%" }}>
													<Panel header="Fornecedor Contratado" key="1">
														<Row style={{ marginBottom: "1%", marginLeft: "0%" }}>
															<Col className="inline-col" style={{ marginTop: "15px" }}>
																<h6 style={{ textAlign: "left" }}><b>Fornecedor</b></h6>
															</Col>
															{(this.state.selectedProviderFV === null || this.state.selectedProviderFV=== undefined) ? (
																<Col></Col>
															) : (
																	<Col className="inline-col" sm="1" style={{ marginTop: "15px" }}>
																		<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Enviar email" placement="top">
																			<a href={"mailto:" + this.state.emailFV + "?subject=" + this.state.emailSubj + "&body=" + "Olá " + this.state.nameFV + ", " + "%0D%0A%0D%0A" + this.state.emailBody + "%0D%0A" + this.state.wedDate}><MailIcon className="pointer" style={{ fontSize: "24px" }} /></a>
																		</Tooltip>
																	</Col>
																)}
														</Row>
														<Row style={{ justifyContent:"space-evenly", marginBottom:"2%" }}>
															<Select style={{width:"47%"}} placeholder="Selecione Fornecedor." value={this.state.selectedProviderFV} onChange={this.changeSelectProviderFV}>
																<Option value={undefined}>Selecione Fornecedor</Option>
																{providerFV}
															</Select>
															<Select style={{width:"47%"}} placeholder="Selecione Pack." value={this.state.selectedPackFV} onChange={this.changeSelectPackFV}>
																<Option value={undefined}>Selecione Pack</Option>
																{providerFVPacks}
															</Select>
														</Row>
														{/* <Row style={{ marginBottom: "2%", justifyContent: "space-evenly", minHeight: "100%" }}>
															<Col style={{ width: "50%", minHeight: "100%" }}>
																{providerFVInfo}
															</Col>
															<Col>
																{detailsPackFV}
															</Col>
														</Row> */}
														<Row style={{marginLeft:"15px", marginBottom:"2%"}}>
															{providerFVInfo}
														</Row>
														<Row style={{ marginBottom: "2%", marginRight: "0%", textAlign: "right", float: "right" }}>
															<Button className="btn-user" onClick={this.updateProviders}>Contratar</Button>
														</Row>
													</Panel>
												</Collapse>

											)}
									</Row>
									<Row style={{ marginLeft: "1%" }}>
										<Col className="inline-col">
											<h6 style={{ textAlign: "left" }}><b>Notas</b></h6>
										</Col>
									</Row>
									<Row style={{ marginBottom: "2%", marginLeft: "0%", justifyContent: "center"}}>
										<FormTextarea id="text-area" value={this.state.notesFVRole} style={{ width: "95%" }} onChange={(el) => { this.handleNotesText("notesFVRole", el.target.value) }} />
									</Row>
									<Row style={{ marginBottom:"5%",  marginRight: "0%", textAlign: "right", float: "right" }}>
										<Button className="btn-user" onClick={() => {this.saveNotes("Fotografia e Video")}}>Gravar Notas</Button>
									</Row>


								</div>
							) : (
								<Row></Row>
							)}


							{(this.state.roleUrl === "Outros") ? (
								<div>
									<Row style={{marginTop: "3%", marginLeft: "2%" }}>
										<h6 style={{ textAlign: "left"}}><b>Checklist</b></h6>
									</Row>
									<Row style={{ marginBottom: "1%", marginTop:"1%", marginLeft: "0%", justifyContent: "center" }}>
										<Collapse accordion style={{ width: "95%" }}>
											<Panel header="Tarefas" key="1">
												{(this.state.ckecklistText !== null && this.state.checklistText !== undefined) ? (
													this.state.checklistText.map((item, index) => {
														let element = this.state.checklistState.find( (el) => el.toDo === item.toDo)
														let isChecked = (element) ? element.isChecked : false
														return(
															<div>
																<Checkbox checked={isChecked} style={{marginBottom:"1%"}} onChange={(e) => { this.changeChecklist(item.toDo, e.target.checked)}}> {item.text}</Checkbox>
															</div>
														)	
													})
												) : (
													<p style={{ marginTop: "2%" }}>Não existe checklist associada a esta categoria.</p>
												)}
											</Panel>
										</Collapse>
									</Row>
									<Row style={{ marginTop: "6%", marginLeft: "1%" }}>
										<Col className="inline-col">
											<h6 style={{ textAlign: "left" }}><b>Fornecedores em Negociação</b></h6>
										</Col>
									</Row>
									<Row style={{ marginBottom: "2%", marginLeft: "0%", justifyContent: "center" }}>
										<Select value={multiselectOutrosValue} placeholder="Selecione Fornecedor." style={{ width: "95%" }} mode="multiple" placeholder="Selecione Fornecedor" onChange={(value) => this.handleChange(value, this.state.roleUrl)}>
											{multiselectOutros}
										</Select>
									</Row>
									<Row style={{ marginBottom: "4%", marginLeft: "0%", justifyContent: "center" }}>
										<ul class="list-group" style={{ width: "95%" }}>
											{negotiationsListOutros}
										</ul>
									</Row>
									<Row style={{ marginTop: "3%", marginLeft: "1%" }}>
										<Col className="inline-col">
											<h6 style={{ textAlign: "left", marginRight: "1%" }}><b>Contratações finais</b></h6>
											<Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title="Adicionar Fornecedor" placement="top">
												<ControlPointIcon className="btn-inverted pointer" onClick={this.addProviderRow} />
											</Tooltip>
										</Col>
									</Row>
									<Row style={{ marginBottom: "4%", marginLeft: "0%", justifyContent: "center" }}>
										<Collapse accordion defaultActiveKey={['1']} style={{ width: "95%" }}>
											<Panel header="Fornecedores Contratados" key="1">
												{(providerList.length > 0) ? (
													<div>
														{providerList}
														<Row style={{ marginBottom: "2%", marginRight: "0%", textAlign: "right", float: "right" }}>
															<Button className="btn-user" onClick={this.updateProviders}>Contratar</Button>
														</Row>
													</div>
												) : (
														<span>Utilize o <ControlPointIcon style={{ fontSize: "14px" }} /> para adicionar contratação.</span>
													)}
											</Panel>
										</Collapse>
									</Row>
									<Row style={{ marginLeft: "1%" }}>
										<Col className="inline-col">
											<h6 style={{ textAlign: "left" }}><b>Notas</b></h6>
										</Col>
									</Row>
									<Row style={{ marginBottom: "2%", marginLeft: "0%", justifyContent: "center"}}>
										<FormTextarea id="text-area" value={this.state.notesGeneralRole} style={{ width: "95%" }} onChange={(el) => { this.handleNotesText("notesGeneralRole", el.target.value) }} />
									</Row>
									<Row style={{ marginBottom:"5%",  marginRight: "0%", textAlign: "right", float: "right" }}>
										<Button className="btn-user" onClick={() => {this.saveNotes(this.state.roleUrl)}}>Gravar Notas</Button>
									</Row>
								</div>
							) : (
									<Row></Row>
								)}
						</Container>

						<Modal size="lg" open={this.state.openMeetings} toggle={() => { this.toggleMeetings(false) }}>
							<ModalHeader>Histórico de Reuniões</ModalHeader>
							<ModalBody>
								{(this.state.meetings.length > 0) ? (
									<div>
										<Row style={{ marginBottom: "2%" }}>
											<Col sm="3" style={{ textAlign: "left" }}>
												<b>Assunto</b>
											</Col>
											<Col sm="2" style={{ textAlign: "left" }}>
												<b>Data</b>
											</Col>
											<Col sm="2" style={{ textAlign: "left" }}>
												<b>Hora</b>
											</Col>
											<Col sm="2" style={{ textAlign: "left" }}>
												<b>Local</b>
											</Col>
											<Col sm="3" style={{ textAlign: "left" }}>
												<b>Notas</b>
											</Col>
										</Row>
										{meetingsData}
									</div>
								) : (
										<div>
											<Row>
												<p>Não existe histórico de reuniões.</p>
											</Row>
										</div>
									)}
							</ModalBody>
						</Modal>

						<Modal size="lg" open={this.state.openInfo} toggle={() => { this.toggleInfo(false) }}>
							<ModalHeader>Guidelines</ModalHeader>
							<ModalBody>
								<p style={{fontSize:"16px", fontWeight:"bold"}}><u>PROCESSO RELIGIOSO E CIVIL:</u></p>
								<p>Este passo só pode ser iniciado 6 meses antes da data do casamento, no entanto, há procedimentos que podem ser levados a cabo pelos clientes antes desta data, de forma a que, quando chegar a altura, esteja tudo pronto para “dar entrada”.</p>
								<p style={{marginLeft:"15px"}}><u>a. Processo Civil: </u></p>
								<p style={{marginLeft:"25px", marginBottom:"10px", fontWeight:"bold"}}>i. Residentes cá: Precisam de ir à Conservatória de Registo Civil (se Porto, aconselhamos 3.ª Conservatória do Registo Civil do Porto que fica na Rua de Álvares Cabral 432 4000, Porto.): </p>
								<p style={{marginLeft:"35px", marginBottom:"10px"}}>1. Data, local e horário de Casamento (Se Casamento Religioso, terão também de indicar essa situação e dizer qual a igreja)</p>
								<p style={{marginLeft:"35px", marginBottom:"10px"}}>2. Acordo Pré-Nupcial ou Comum.</p>
								<p style={{marginLeft:"35px", marginBottom:"10px"}}>3. Se existe ou não alteração de nome.</p>
								<p style={{marginLeft:"35px", marginBottom:"10px"}}>4. Precisam de levar os CC.</p>
								<p style={{marginLeft:"35px", marginBottom:"10px"}}>5. Nome, morada e estado civil das testemunhas.</p>
								<p style={{marginLeft:"25px", marginBottom:"10px", fontWeight:"bold"}}>ii. Residentes Fora:</p>
								<p style={{marginLeft:"35px", marginBottom:"10px"}}>1. Se um deles é portugês:</p>
								<p style={{marginLeft:"45px", marginBottom:"10px"}}>a. Podem iniciar Processo no Consulado ou Embaixada Portuguesa → PREFERENCIAL. (Aqui: o português precisa do seu CC apenas e o estrangeiro precisa dos documentos que refiro abaixo em 2).</p>
								<p style={{marginLeft:"45px", marginBottom:"10px"}}>b. Podem vir a PT e fazer i), considerando que o estrangeiro terá sempre de ter os documentos abaixo em 2.) traduzidos e autenticados por advogado → too much work por isso aconselhamos situaç\ao em a.</p>
								<p style={{marginLeft:"35px", marginBottom:"10px"}}>2. Se nenhum deles é português:</p>
								<p style={{marginLeft:"45px", marginBottom:"10px"}}>a. Conselho Mary Me: Casar civilmente no país deles e aqui simbolicamente ou na igreja.</p>
								<p style={{marginLeft:"45px", marginBottom:"10px"}}>b. Viagem prévia a Portugal com a seguinte documentação:</p>
								<p style={{marginLeft:"50px", marginBottom:"10px"}}>i. Certidão de Nascimento: Traduzida e Autenticada por Advogado português → precisamos de contratar um advogado + 1 tradutor.</p>
								<p style={{marginLeft:"50px", marginBottom:"10px"}}>ii. Certificado de Pessoa Livre para Casar: Traduzida e Autenticada por Advogado português → precisamos de contratar um advogado + 1 tradutor.</p>
								<p style={{marginLeft:"50px", marginBottom:"10px"}}>iii. Ida à Conservatória com tradutor ou Advogado que fale a língua dos clientes.</p>
								<p style={{marginLeft:"50px", marginBottom:"10px"}}>iv. Informar de todos os detalhes em 1.</p>
								<p style={{marginLeft:"45px", marginBottom:"10px"}}>c. Sem viagem prévia: contratar 2 advogados, fazer 2 procurações, … → not ideal nem nunca ocorreu.</p>
								<p style={{marginLeft:"25px", marginBottom:"10px", fontWeight:"bold"}}>iii. Depois de ter sido iniciado o processo:</p>
								<p style={{marginLeft:"35px", marginBottom:"10px"}}>1. Se há casamento religioso católico também: necessário levantar o documento para entregar na Paróquia (Opção A: Clientes fazem isso, Opção B: Nós podemos levantar.)</p>
								<p style={{marginLeft:"35px", marginBottom:"10px"}}>2. Se não há casamento católico: Eles terão de juntar as testemunhas e pagar o processo.</p>
								<p style={{marginLeft:"25px", marginBottom:"10px", fontWeight:"bold"}}>iv. No dia:</p>
								<p style={{marginLeft:"35px", marginBottom:"10px"}}>1. Se há casamento Religioso: nada a fazer.</p>
								<p style={{marginLeft:"35px", marginBottom:"10px"}}>2. Se não: necessário pagar o taxi (colocar no processo esta situação e alertar na altura do casamento) → 50€.</p>
								<p style={{marginLeft:"15px"}}>b. Processo Religioso:  Os processo religiosos dão sempre imenso trabalho e temos sempre de nos cingir aos horários das Paróquias. O processo tem de ser iniciado na Paróquia de Residência da Noiva. Por isso, </p>
								<p style={{marginLeft:"15px"}}>c. Se residem fora ou cá: disso informar os clientes (os estrangeiros terão de iniciar o processo na cidade deles).</p>
								<p style={{marginLeft:"15px"}}>d. Quando estão cá e precisam no nosso auxílio, é necessário pedir aos clientes no Slack:</p>
								<p style={{marginLeft:"35px", marginBottom:"10px"}}>1. Nome de Paróquia de Batismo de noivo e noiva.</p>
								<p style={{marginLeft:"35px", marginBottom:"10px"}}>2. Paróquia da Residência da Noiva. → informar que será aqui que será iniciado o processo, mesmo que diferente da de casamento ou igual. </p>
								<p style={{marginLeft:"15px"}}>e. Mediante o horário de atendimento já disponibilizado, informar os clientes para tratarem dessa situação dentros dos 6 meses:</p>
								<p style={{marginLeft:"25px", marginBottom:"10px"}}>i. Se estão fora: processo é tratado lá e enviado para a Diocese da Paróquia a que pertence a Igreja: podemos tratar deste assunto mas preferencialmente pedimos a um familiar para o fazer, se não for possível:</p>
								<p style={{marginLeft:"35px", marginBottom:"10px"}}>1. Levantamento do Processo na Diocese → pedir aos clientes pagamento de emolumentos através de revolut.</p>
								<p style={{marginLeft:"35px", marginBottom:"10px"}}>2. Entregar Processo na Paróquia a que respeita com os dados do Padre (cartão de cidadão de Padre se padre diferente da Paróquia) + Documento da Conservatória → pedir aos clientes pagamento de emolumentos através de revolut.</p>
								<p style={{marginLeft:"25px", marginBottom:"10px"}}>ii. Se estão cá: O processo vem da Paróquia da noiva e será enviado para a Diocese (ou levado → informar noivos dessa situação) e depois terá de ser levantado como na situação anterior.</p>
							</ModalBody>
						</Modal>
								
						<Modal open={this.state.openUpload} toggle={() => { this.toggleUpload(false) }}>
							<ModalHeader>Upload Contrato</ModalHeader>
							<ModalBody>
							{/* {(this.state.wedding.contract !== null) ? (
								<div>
									<Row style={{marginBottom:"20px", display:"inline-flex"}}>
										<h6 style={{color:"#b5291d"}}>Atenção!</h6>
									</Row>
									<Row style={{marginBottom:"30px"}}>
										<p className="p-label">
											Já existe um contrato para este fornecedor. Ao fazer upload de um novo, o atual será eliminado.
											Recorde-se que o formato terá de ser pdf.
										</p>         
									</Row>
									<Row style={{justifyContent:"flex-end"}}>
										<input id="file-upload" type="file" onChange={this.onChangeHandler}/>
									</Row>
								</div>
							) : ( */}
								<div>
									<Row style={{marginBottom:"30px"}}>
										<p className="p-label">Selecione o ficheiro do contrato. Recorde-se que o formato terá de ser pdf.</p>         
									</Row>
									<Row style={{justifyContent:"flex-end"}}>
										<input id="file-upload" type="file" onChange={this.onChangeHandler}/>
									</Row>
								</div>                
							{/* )} */}
							</ModalBody>
							<ModalFooter>
								<Button className="btn-inverted" onClick={() => this.toggleUpload(false, null, null)}>Cancelar</Button>
								<Button className="btn-inverted" onClick={() => this.upload(this.state.roleContract, this.state.provContract)}>Upload</Button> 
							</ModalFooter>
						</Modal>

						<Modal open={this.state.openDelete} toggle={() => { this.toggleDelete(false) }}>
							<ModalHeader>Atenção!</ModalHeader>
							<ModalBody>Este passo é irreversível. Deseja eliminar o contrato?</ModalBody>
							<ModalFooter>
								<Button className="btn-inverted" onClick={() => this.toggleDelete(false)}>Cancelar</Button>
								<Button className="btn-inverted" onClick={() => this.delete(this.state.roleToDel, this.state.selectedToDel)}>Apagar</Button> 
							</ModalFooter>
						</Modal>
					</div>
				</form>
			);
		}
	}
let appContainer = withRouter(CategoryProviders);

function mapStateToProps(state) {
	return { tokens: state.tokens, reduxStorageLoaded: state.storageLoaded, validTokens: state.validTokens, _id: state.user._id }
}

const AppRedux = connect(
	mapStateToProps,
)(appContainer);

export default AppRedux;
