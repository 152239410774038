import { SET_TOKENS } from "../constants/action-types";

const initialState = {
  tokens: {
    accessToken: null
  },
  user: null,
  storageLoaded: false,
  validTokens: null,
  logingOff: false
};

function rootReducer(state = initialState, action) {
  //console.log("root -> ", state, " - ", action);
  //console.log(action.payload);

  switch (action.type) {

    case SET_TOKENS:
      let tokens = (action.payload) ? action.payload.tokens : null;
      let user = null;
      if(action.payload && action.payload.user){
        user = {
          _id: action.payload.user._id,
        //   username: action.payload.user.username,
          role: action.payload.user.role,
        //   name: (action.payload.user.infoGeral) ? action.payload.user.infoGeral.name : null
        }
      }
      return { 
        ...state, 
        tokens: tokens, 
        validTokens: (tokens) ? true : false,
        user: user
      } 
      break;

    case "REDUX_STORAGE_LOAD":
      return { 
        ...state, 
        storageLoaded: true, 
        tokens: action.payload.tokens,
        user: action.payload.user
      };
      break;
  
    default:
      return state;
      break;
  }
}

export default rootReducer;