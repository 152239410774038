import React from 'react';
import {
  Switch,
  Route, 
  Redirect
} from "react-router-dom"
import './App.css';
import Login from './views/Login'
import GeneralDashboar from './views/GeneralDashboard'
import LoadingPage from './views/LoadingPage'
import Topbar from './components/NavBar'
import Sidebar from './components/SideBar'
import AddWedding from './views/AddWedding'
import EditWedding from './views/EditWedding'
import WeddingDetails from './views/WeddingDetails'
import InsertQuiz from './views/InsertQuiz'
import LogHistory from './views/LogHistory'
import CommissionsTables from './views/CommissionsTables'
import AddProvider from './views/AddProvider'
import ViewProviders from './views/ViewProviders'
import EditProvider from './views/EditProvider'
import CategoryProviders from './views/CategoryProviders'
import GenerateAlignment from './views/GenerateAlignment'
import Meetings from './views/Meetings'
import EditMeeting from './views/EditMeeting'
import MoreStatistics from './views/MoreStatistics'
import { withRouter } from "react-router"
import { connect } from "react-redux"
import axios from "axios"
import { setTokens } from './redux/actions'
import { API_URL } from './configs/configs'


const FULL_PAGES_PATHS = ["/"];

class App extends React.Component {

  state = {
    loading: true,
    authenticated: null
  }

  verifyTokens = () => {
    console.log("verifying tokens")
    if(this.props.tokens){

      axios.get(API_URL + '/login/testme', {
        headers: {'authorization': this.props.tokens},
      }) 
      .then((response) => {
        this.props.setTokens({ tokens: this.props.tokens, user: response.data.user });
        console.log("VERIFY STEP 1 - ", this.props.tokens, "USER - ", this.props.user)
        return this.setState({ loading: false, authenticated: true });
      })
      .catch((error) => {
        console.log("ERROR - ", error)
        return this.setState({ loading: false, authenticated: false });
      })
    } else {
      return this.setState({ loading: false, authenticated: false });
    }
  }

  render() {

    if(this.state.authenticated === true && this.props.validTokens === false){
      this.setState({ authenticated: false })
      return <Redirect to="/" />
    }

    if(this.props.validTokens === true && this.state.authenticated === false){
      this.setState({ authenticated: true });
      return <LoadingPage />;
    }

    if(this.state.loading === true){
      if(this.props.reduxStorageLoaded === true){
        this.verifyTokens();
      }
      return <LoadingPage />;
    }

    if(this.state.authenticated !== true && this.props.location.pathname !== "/"){
      // redirect to login page
      return <Redirect to="/" />
    }

    if(this.state.authenticated === true && this.props.location.pathname === "/"){
      return <Redirect to="/general_dashboard" />
    }


    if( FULL_PAGES_PATHS.indexOf(this.props.location.pathname) !== -1 ){
      return (
        <div>
          <Route path="/" exact component={Login} />
        </div>
      )
    } else {
      return (
        <div style={{ height: "100%" }}>
          <Topbar/>
          <Sidebar/>
          <div style={{ marginLeft: 85 }}>
          <Switch>
              <Route path="/general_dashboard" exact component={GeneralDashboar} />
              <Route path="/add_wedding" exact component={AddWedding} />
              <Route path="/edit_wedding/:id" exact component={EditWedding} />
              <Route path="/wedding_details" exact component={WeddingDetails} />
              <Route path="/wedding_details/:id" exact component={WeddingDetails} />
              <Route path="/insert_quiz/:id" exact component={InsertQuiz} />
              <Route path="/log_history" exact component={LogHistory} />
              <Route path="/add_provider" exact component={AddProvider} />
              <Route path="/view_providers" exact component={ViewProviders} />
              <Route path="/edit_provider/:id" exact component={EditProvider} />
              <Route path="/category_providers/" exact component={CategoryProviders} />
              <Route path="/generate_alignment/:id" exact component={GenerateAlignment} />
              <Route path="/statistics/:id" exact component={MoreStatistics} />
              <Route path="/meetings/:id" exact component={Meetings} />
              <Route path="/edit_meeting/" exact component={EditMeeting} />
              <Route path="/commissions/:id" exact component={CommissionsTables} />
            </Switch>
          </div>
        </div>
      );
    }

}
}


let appContainer = withRouter(App);

function mapStateToProps(state) {
  console.log("map state")
  return { tokens: state.tokens, reduxStorageLoaded: state.storageLoaded, validTokens: state.validTokens }
}

function mapDispatchToProps(dispatch) {
  return {
    setTokens: payload => dispatch(setTokens(payload))
  };
}

const AppRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(appContainer);

export default AppRedux;
