import React from 'react'
import '../App.css'
import {
	Col,
	Row,
	Button,
	FormTextarea,
	ListGroup, 
	ListGroupItem,
	Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "shards-react"
import { Input, TimePicker, DatePicker } from 'antd'
import { connect } from "react-redux"
import { withRouter } from "react-router"
import { API_URL } from '../configs/configs'
import notify from '../helpers/notify'
import moment from 'moment'
import SettingsIcon from '@material-ui/icons/Settings';
import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close'
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import { after as _after } from 'lodash'


const axios = require('axios')
const DateInput = Input.Group
const format = 'HH:mm'


class Meetings extends React.Component {

	constructor(props) {
		super(props);
		
		this.state = {
			loading: false,
			iconLoading: false,
			wedding: [],
			meetings: [],
			wed_id: ""
		}
	}

	componentDidMount = () => {

		let id = this.props.match.params.id;

		axios.get( API_URL + '/wedding/getWedding/' + id , { headers: {'authorization': this.props.tokens} })
		.then((response) => {
			//console.log("RESPONSE DATA WEDDING BY ID", response.data.wedding);
			let weddingRes = response.data.wedding
			let weddingData = 
				{
					wed_id: id,
					groom: weddingRes.groom.nameGroom,
					bride: weddingRes.bride.nameBride
				}

			this.setState({
				wedding: weddingData
			})
		})
		.catch((error) => {
			//console.log(error);
		});

		this.getInfo()
	}


	changeFormValue = (key, value) => {
		this.setState({
			[key]: value
		})
		//console.log("BBBBBB", this.state)
	}

    changeRowEvent = (value, key, index) => {
        let meetingsArr = this.state.meetings;
        meetingsArr[index][key] = value;
        this.setState({
            meetings: meetingsArr
        })
        //console.log("AAAAAAAAA", meetingsArr)
	}
	
	addMeeting = () => {
		let wedding_id = this.props.match.params.id;

		let step = _after(1, () => {
            this.getInfo()
        })

		let meetObj ={
			date: this.state.date,
			time: this.state.time,
			location: this.state.local,
			subject: this.state.subject,
			notes: this.state.notes
		}

        axios.post( API_URL + '/wedding/addMeeting/' + wedding_id, meetObj, { headers: {Authorization: this.props.tokens}})
        .then((response) => {
            console.log(response.data);
            this.setState({ 
				loading: false,
				date: "",
				time: "",
				local: "",
				subject: "",
				notes: ""
			}, step)
            notify("Reunião adicionada com sucesso!", "success")
        })
        .catch((err) => {
            notify("Erro ao adicionar reunião.")
            console.log(err);
            this.setState({ loading: false });
		});

		//this.getInfo()
	} 

	getInfo = () => {

		let id = this.props.match.params.id;

		axios.get( API_URL + '/wedding/getMeetings/' + id , { headers: {'authorization': this.props.tokens} })
		.then((response) => {
			//console.log("RESPONSE DATA MEETINGS BY ID", response.data.meetings);

			this.setState({
				meetings: response.data.meetings
			})
		})
		.catch((error) => {
			console.log(error);
		});

	}

	deleteMeeting = () => {
		let id = this.state.meetingToDelete
		let wed_id = this.props.match.params.id;
		//console.log("SELECTED MEET, ", id)
		
		let params = {
			wedId: wed_id,
			meetingId: id
		}

        axios.delete( API_URL + '/wedding/deleteMeeting', { headers: {'authorization': this.props.tokens}, params: params })
        .then((response) => {
            this.setState({ 
				loading: false,
				openDelete: false
            })
            notify("Reunião eliminada com sucesso!", "success")
			
			this.getInfo()
        })
        .catch((err) => {
            notify("Erro ao eliminar reunião.")
            console.dir(err);
            this.setState({ 
                loading: false
            })
        });
    }

	toggleDelete = (openDelete = false, meeting = null) => {
        this.setState({
            meetingToDelete: meeting, 
            openDelete: openDelete
        })
    }

	render() {

		let id = this.props.match.params.id;
		
		let meetingsData = this.state.meetings.map((item, index) => {
			let aux = moment(item.date).format('DD-MM-YYYY')
			let auxT = moment(item.time).format('HH:mm')
            return(
                <ListGroupItem style={{borderStyle:"solid", borderBottomWidth:"2px", borderTopWidth:"0px", borderBottomColor:"#cecece", fontSize:"14px"}}>
                    <Row>
                        <Col sm="3" style={{textAlign: "left"}}>
                            {item.subject}
                        </Col>
                        <Col sm="2" style={{textAlign: "left"}}>
                            {aux}
                        </Col>
                        <Col sm="1" style={{textAlign: "left"}}>
                            {auxT}
                        </Col>
                        <Col sm="2" style={{textAlign: "left"}}>
                            {item.location}
                        </Col>
                        <Col sm="3" style={{textAlign: "left"}}>
                            {item.notes}
                        </Col>
                        <Col sm="1" style={{textAlign:"center"}}>
							<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Editar" placement="top">
								<EditIcon className="pointer" style={{fontSize:"20px", marginRight:"4%"}} onClick={() => { this.props.history.push('/edit_meeting/?wed=' + id + '&meeting=' + item._id) }}/>
							</Tooltip>
							<Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Apagar" placement="top">
								<CloseIcon className="pointer" style={{fontSize:"20px", marginLeft:"4%"}} onClick={() => this.toggleDelete(true, item._id)}/>
							</Tooltip>
                        </Col>
                    </Row>
                </ListGroupItem>
            )
        })
		  

		return (
			<div className="general_div">
				<h5 style={{color:"#D1AF9F", marginBottom:"1%"}}><b>Reuniões</b></h5>
				<p style={{color:"#555555", marginBottom:"4%", textAlign:"center"}}><b>{this.state.wedding.bride} & {this.state.wedding.groom}</b></p>
				<Row style={{width:"95%", textAlign:"left", marginBottom:"2%", marginTop:"4%"}}>
					<Col sm="3">
						<Input value={this.state.subject} placeholder="Assunto" onChange={(el) => { this.changeFormValue("subject", el.target.value) }} />
					</Col>
					<Col sm="2">
						<DateInput>
							<DatePicker value={this.state.date} type="date" placeholder="Selecione data" style={{ width: '100%' }} onChange={(val) => { this.changeFormValue("date", val) }} />
						</DateInput>
					</Col>
					<Col sm="2">
						<TimePicker value={this.state.time} placeholder="Selecione hora" style={{ width: "75%" }} format={format} minuteStep={15} onChange={(val) => { this.changeFormValue("time", val) }} />
					</Col>
					<Col sm="2">
						<Input value={this.state.local} placeholder="Local" onChange={this.props.onLocalChange} onChange={(el) => { this.changeFormValue("local", el.target.value) }}/>
					</Col>
					<Col sm="3">
						<FormTextarea value={this.state.notes} size="sm" placeholder="Notas" style={{height:"32px", width: "100%"}} onChange={(el) => { this.changeFormValue("notes", el.target.value) }}/>
					</Col>
				</Row>
				<Row style={{width:"95%", textAlign:"right", marginBottom:"4%", justifyContent:"flex-end"}}>
					<Button className="btn-inverted" onClick={this.addMeeting}>Adicionar Reunião</Button>
				</Row>
				{(this.state.meetings.length > 0) ? (
					<div style={{width:"95%"}}>
						<ListGroup className="card" style={{ width:"95%", marginBottom:"5%", marginTop:"5%", textAlign:"left", justifyContent:"space-evenly"}}>
                        <ListGroupItem style={{backgroundColor:"#FAF5F2", borderStyle:"solid", borderBottomWidth:"2px", borderBottomColor:"#FAF5F2", borderTopColor:"#FAF5F2"}}>
                            <Row>
                                <Col style={{fontSize: "19px", color: "#212529"}}>
                                    <b>Histórico de Reuniões</b>
                                </Col> 
                            </Row>
                        </ListGroupItem>
                        <ListGroupItem style={{borderStyle:"solid", borderBottomWidth:"2px", borderTopWidth:"0px", borderBottomColor:"#cecece", fontSize:"15px"}}>
                            <Row>
                                <Col sm="3" style={{textAlign: "left"}}>
                                    <b>Assunto</b>
                                </Col>
                                <Col sm="2" style={{textAlign: "left"}}>
                                    <b>Data</b>
                                </Col>
                                <Col sm="1" style={{textAlign: "left"}}>
                                    <b>Hora</b>
                                </Col>
                                <Col sm="2" style={{textAlign: "left"}}>
                                    <b>Local</b>
                                </Col>
                                <Col sm="3" style={{textAlign: "left"}}>
                                    <b>Notas</b>
                                </Col>
                                <Col sm="1" style={{textAlign:"center"}}>
                                    <SettingsIcon style={{fontSize:"16px"}}/>
                                </Col>
                            </Row>
                        </ListGroupItem>
                        {meetingsData}
                    </ListGroup>
					</div>
				) : (
					<ListGroup className="card" style={{ width:"95%", marginBottom:"5%", marginTop:"5%", textAlign:"left", justifyContent:"space-evenly"}}>
						<ListGroupItem style={{backgroundColor:"#FAF5F2", borderStyle:"solid", borderBottomWidth:"2px", borderBottomColor:"#FAF5F2", borderTopColor:"#FAF5F2"}}>
							<Row>
								<Col style={{fontSize: "19px", color: "#212529"}}>
									<b>Histórico de Reuniões</b>
								</Col> 
							</Row>
						</ListGroupItem>
						<ListGroupItem>
							Não existe histórico de reuniões.
						</ListGroupItem>
					</ListGroup>
				)}

				<Modal open={this.state.openDelete} toggle={() => { this.toggleDelete(false) }}>
                    <ModalHeader>Atenção!</ModalHeader>
                    <ModalBody>Este passo é irreversível. Deseja eliminar a reunião?</ModalBody>
                        <ModalFooter>
                            <Button className="btn-inverted" onClick={() => this.toggleDelete(false)}>Cancelar</Button>
                            <Button className="btn-inverted" onClick={this.deleteMeeting}>Apagar</Button> 
                        </ModalFooter>
                </Modal>
			</div>
		);
	} 
}

let appContainer = withRouter(Meetings);

  function mapStateToProps(state) {
	return { tokens: state.tokens, reduxStorageLoaded: state.storageLoaded, validTokens: state.validTokens, _id: state.user._id }
  }

  const AppRedux = connect(
	mapStateToProps,
  )(appContainer);
  
export default AppRedux;
