import React from 'react'
import '../App.css'
import {
    Container,
    Row,
    Col,
    Button,
    ListGroup, 
    ListGroupItem
} from "shards-react"
import { connect } from "react-redux";
import { withRouter } from "react-router"
import { API_URL } from '../configs/configs';
import Moment from 'react-moment';
var moment = require('moment');

const axios = require('axios');

class LogHistory extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            loading: false,
            iconLoading: false,
            open: false,
            logs: [{}],
            date: moment(),
            filteredBy: 'daily'
        }
    }

    changeFormValue = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    filterDaily = () => {
        this.setState({
            filteredBy: 'daily'
        })
    }

    filterWeekly = () => {
        this.setState({
            filteredBy: 'weekly'
        })
    }
    noFilter = () => {
        this.setState({
            filteredBy: 'all'
        })
    }


    componentDidMount = () => {

        //console.log("DATE- ", this.state.date)
        //GET LOG INFO
        axios.get( API_URL + '/syslogs', { headers: {'authorization': this.props.tokens} })
        .then((response) => {
            let logRes = response.data.syslogs

            this.setState({
                logs: logRes
            })
        })
        .catch((error) => {
        // notify("Erro na pesquisa de casamentos.")
        console.log(error);
        });
    }


    render() {

        //console.log("STATE FILTERED: ", this.state.filteredBy)
        let logsInfo = this.state.logs.map((value, index) => {
            return (
                <div>
                    <ListGroupItem className="log-list" >
                        <Row>
                            <Col sm="3">
                                <Moment format="DD-MM-YYYY, HH:mm">{value.occurredAt}</Moment>
                            </Col>
                            <Col style={{textAlign: "left"}}>
                                {value.log}
                            </Col>
                        </Row>
                    </ListGroupItem>
                </div>
            )
        })

        let logsDaily = this.state.logs.map((value, index) => {
            if(moment(value.occurredAt).isSame(this.state.date, "day") && moment(value.occurredAt).isSame(this.state.date, "month") && moment(value.occurredAt).isSame(this.state.date, "year")){
                return(
                    <div>
                        <ListGroupItem className="log-list" >
                            <Row>
                                <Col sm="3">
                                    <Moment format="DD-MM-YYYY, HH:mm">{value.occurredAt}</Moment>
                                </Col>
                                <Col style={{textAlign: "left"}}>
                                    {value.log}
                                </Col>
                            </Row>
                        </ListGroupItem>
                    </div>
                )
            }  
        })

        let logsWeekly = this.state.logs.map((value, index) => {
            let lastWeek = moment().subtract(1,"week")

            if(moment(value.occurredAt).isBetween(lastWeek, moment())){
                return(
                    <div>
                        <ListGroupItem className="log-list" >
                            <Row>
                                <Col sm="3">
                                    <Moment format="DD-MM-YYYY, HH:mm">{value.occurredAt}</Moment>
                                </Col>
                                <Col style={{textAlign: "left"}}>
                                    {value.log}
                                </Col>
                            </Row>
                        </ListGroupItem>
                    </div>
                )
            }  
        })
        
        return (
            <div style={{ marginBottom: "4%" }}>
                <Container style={{ paddingRight: 0, marginLeft: "1%", marginRight: "2%", maxWidth: "-webkit-fill-available" }}>
                    <Row style={{marginBottom:"25px", textAlign:"right"}}>
                        <Col>
                            {(this.state.logs.length > 0 && this.state.filteredBy === "daily") ? (
                                <div>
                                    <Button className="btn-user" loading={this.state.loading} style={{alignSelf: "right", marginRight:"7px"}} onClick={() => this.filterDaily()} >
                                        Dia
                                    </Button>
                                    <Button className="btn-inverted" loading={this.state.loading} style={{alignSelf: "right", marginRight:"7px"}} onClick={() => this.filterWeekly()}>
                                        Semana
                                    </Button>
                                    <Button className="btn-inverted" loading={this.state.loading} style={{alignSelf: "right"}} onClick={() => this.noFilter()}>
                                        Todos
                                    </Button>
                                </div>
                            ) : (this.state.logs.length > 0 && this.state.filteredBy === "weekly") ? (
                                <div>
                                    <Button className="btn-inverted" loading={this.state.loading} style={{alignSelf: "right", marginRight:"7px"}} onClick={() => this.filterDaily()} >
                                        Dia
                                    </Button>
                                    <Button className="btn-user" loading={this.state.loading} style={{alignSelf: "right", marginRight:"7px"}} onClick={() => this.filterWeekly()}>
                                        Semana
                                    </Button>
                                    <Button className="btn-inverted" loading={this.state.loading} style={{alignSelf: "right"}} onClick={() => this.noFilter()}>
                                        Todos
                                    </Button>
                                </div>
                            ) : (this.state.logs.length > 0 && this.state.filteredBy === "all") ? ( 
                                <div>
                                    <Button className="btn-inverted" loading={this.state.loading} style={{alignSelf: "right", marginRight:"7px"}} onClick={() => this.filterDaily()} >
                                        Dia
                                    </Button>
                                    <Button className="btn-inverted" loading={this.state.loading} style={{alignSelf: "right", marginRight:"7px"}} onClick={() => this.filterWeekly()}>
                                        Semana
                                    </Button>
                                    <Button className="btn-user" loading={this.state.loading} style={{alignSelf: "right"}} onClick={() => this.noFilter()}>
                                        Todos
                                    </Button>
                                </div>
                            ) : (
                                <div>
                                    <Button className="btn-inverted" loading={this.state.loading} style={{alignSelf: "right", marginRight:"7px"}} onClick={() => this.filterDaily()} >
                                        Dia
                                    </Button>
                                    <Button className="btn-inverted" loading={this.state.loading} style={{alignSelf: "right", marginRight:"7px"}} onClick={() => this.filterWeekly()}>
                                        Semana
                                    </Button>
                                    <Button className="btn-inverted" loading={this.state.loading} style={{alignSelf: "right"}} onClick={() => this.noFilter()}>
                                        Todos
                                    </Button>
                                </div>
                            )}
                        </Col> 
                    </Row>
                    {(this.state.logs.length > 0 && this.state.filteredBy === "daily") ? (
                        <ListGroup className="card" style={{ width:"100%", marginBottom:"5%"}}>
                            <ListGroupItem className="log-list-header">
                                <Row>
                                    <Col style={{fontSize: "19px", color: "#212529"}}>
                                        <b>Histórico de Logs Diário</b>
                                    </Col> 
                                </Row>
                            </ListGroupItem>
                            {logsDaily}
                        </ListGroup>
                    ) : (this.state.logs.length > 0 && this.state.filteredBy === "weekly") ? (
                        <ListGroup className="card" style={{ width:"100%", marginBottom:"5%"}}>
                            <ListGroupItem className="log-list-header">
                                <Row>
                                    <Col style={{fontSize: "19px", color: "#212529"}}>
                                        <b>Histórico de Logs Semanal</b>
                                    </Col> 
                                </Row>
                            </ListGroupItem>
                            {logsWeekly}
                        </ListGroup>
                    ) : (this.state.logs.length > 0 && this.state.filteredBy === "all") ? (
                        <ListGroup className="card" style={{ width:"100%", marginBottom:"5%"}}>
                            <ListGroupItem className="log-list-header">
                                <Row>
                                    <Col style={{fontSize: "19px", color: "#212529"}}>
                                        <b>Histórico de Todos os Logs </b>
                                    </Col> 
                                </Row>
                            </ListGroupItem>
                            {logsInfo}
                        </ListGroup>
                    ) :
                        <ListGroup className="card" style={{ width:"95%", marginBottom:"5%"}}>
                            <ListGroupItem className="log-list-header">
                                <Row>
                                    <Col style={{fontSize: "19px", color: "#212529"}}>
                                        <b>Histórico de Logs</b>
                                    </Col> 
                                </Row>
                            </ListGroupItem>
                            Sem logs disponíveis.
                        </ListGroup>
                    }   
                </Container>
            </div>
      );
    } 
}

let appContainer = withRouter(LogHistory);

  function mapStateToProps(state) {
    return { tokens: state.tokens, reduxStorageLoaded: state.storageLoaded, validTokens: state.validTokens, _id: state.user._id }
  }

  const AppRedux = connect(
    mapStateToProps,
  )(appContainer);
  
export default AppRedux;
