import React from 'react'
import '../App.css'
import {
	Col,
	Container,
	Row,
	Button
} from "shards-react"
import { Input, Select, DatePicker } from 'antd'
import { connect } from "react-redux"
import { withRouter } from "react-router"
import { API_URL } from '../configs/configs'
import notify from '../helpers/notify'


const axios = require('axios')
const DateInput = Input.Group
const { Option } = Select

class AddWedding extends React.Component {

	constructor(props) {
		super(props);
		
		this.state = {
			loading: false,
			iconLoading: false,
			open: false,
			users: []
		}
	}

	componentDidMount = () => {

		//USERS PARA SELECT
		axios.get( API_URL + '/user/getUsers', { headers: {'authorization': this.props.tokens} })
		.then((response) => {
			let usersRes = response.data.users

			let usersData = usersRes.map((value, index) => (
				{
					_id: value._id,
					name: value.name
				}
			))

			this.setState({
				users: usersData
			})
			
		})
		.catch((error) => {
			console.log(error);
		});
	}

	changeSelectUsers = (value) => {
		this.setState({
			weddingPlanner: value
		})
	}

	changeFormValue = (key, value) => {
		this.setState({
			[key]: value
		})
	}

	changeSelectCeremony = (value) => {
		this.setState({
			ceremony: value
		})
	}

	addWedding = (e) => {

		e.preventDefault();

		let _id = this.state.weddingPlanner

		let weddingObj = {
			date: this.state.weddingDate,
			numGuests: this.state.guestNr,
			ceremony: this.state.ceremony,
			location: this.state.location,
			webSiteUrl: this.state.webSiteUrl,
			docsUrl: this.state.docsUrl,
			bride: {
				nameBride: this.state.nameBride,
				emailBride: this.state.emailBride,
				cellPhoneBride: this.state.cellPhoneBride
			},
			groom: {
				nameGroom: this.state.nameGroom,
				emailGroom: this.state.emailGroom,
				cellPhoneGroom: this.state.cellPhoneGroom,          
			},
			budget: this.state.budget
			// employee: _id
		}

		axios.post( API_URL + '/wedding/addWedding/' + _id, weddingObj, { headers: {Authorization: this.props.tokens}})
		.then((response) => {
			//(response.data);
			this.setState({ loading: false})
			notify("Casamento adicionado com sucesso!", "success")
			this.props.history.push('/general_dashboard')
		})
		.catch((err) => {
			notify("Erro ao adicionar casamento.")
			//console.log(err);
			this.setState({ loading: false });
		});

	}

	toggle = (open = false) => {
		this.setState({
		  open: open
		})
	  }


	render() {

		let users = this.state.users.map((value, index) => {
			return <Option value={value._id}>{value.name}</Option>
		})

		return (
			<form onSubmit={this.addWedding} >
				<div style={{ marginBottom: "4%" }}>
					<Container style={{ paddingRight: 0, marginLeft: "1%", marginRight: "2%", maxWidth: "-webkit-fill-available" }}>
						<h4 style={{ textAlign:"left", marginBottom: "55px", marginTop:"15px" }}>Adicionar Casamento</h4>
						<Row style={{marginBottom:"8px", marginTop:"20px"}}>
							<h6 style={{ textAlign:"left" }}><b>Dados da Noiva</b></h6>
						</Row>
						<Row style={{ marginBottom:"40px"}}>
							<Col sm="5">
								<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold"}}>Nome</p>
								<Input required value={this.state.nameBride} onChange={(el) => { this.changeFormValue("nameBride", el.target.value) }} placeholder="Nome" />
							</Col>
							<Col sm="4">
								<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold"}}>Email</p>
								<Input required value={this.state.emailBride} onChange={(el) => { this.changeFormValue("emailBride", el.target.value) }} type="email" placeholder="Email" />
							</Col>
							<Col sm="3">
								<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold"}}>Telemóvel</p>
								<Input required value={this.state.cellPhoneBride} onChange={(el) => { this.changeFormValue("cellPhoneBride", el.target.value) }} placeholder="Nº. Telemóvel" />
							</Col>
						</Row>
						<Row style={{marginBottom:"8px", marginTop:"20px"}}>
							<h6 style={{ textAlign:"left" }}><b>Dados do Noivo</b></h6>
						</Row>
						<Row style={{ marginBottom:"40px"}}>
							<Col sm="5">
								<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold"}}>Nome</p>
								<Input required value={this.state.nameGroom} onChange={(el) => { this.changeFormValue("nameGroom", el.target.value) }} placeholder="Nome" />
							</Col>
							<Col sm="4">
								<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold"}}>Email</p>
								<Input required value={this.state.emailGroom} onChange={(el) => { this.changeFormValue("emailGroom", el.target.value) }} type="email" placeholder="Email" />
							</Col>
							<Col sm="3">
								<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold"}}>Telemóvel</p>
								<Input required value={this.state.cellPhoneGroom} onChange={(el) => { this.changeFormValue("cellPhoneGroom", el.target.value) }} placeholder="Nº. Telemóvel" />
							</Col>
						</Row>
						<Row style={{marginBottom:"8px", marginTop:"20px"}}>
							<h6 style={{ textAlign:"left" }}><b>Detalhes do Casamento</b></h6>
						</Row>
						<Row style={{ marginBottom:"35px"}}>
							<Col sm="4">
								<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold"}}>Coordenadora</p>
								<Select placeholder="Selecione Colaborador/a." value={this.state.weddingPlanner} onChange={this.changeSelectUsers}>
									{/* <option selected disabled>Selecione Colaborador/a.</option> */}
									{users}
								</Select>
							</Col>
						</Row>
						<Row style={{ marginBottom:"35px"}}>
							<Col sm="2">
								<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold"}}>Data</p>
								<DateInput>
									<DatePicker value={this.state.weddingDate} onChange={(val) => { this.changeFormValue("weddingDate", val) }} type="date" placeholder="Selecione data" style={{ width: '100%' }} />
								</DateInput>
							</Col>
							<Col sm="2">
								<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold"}}>Nº. de Convidados</p>
								<Input required value={this.state.guestNr} onChange={(el) => { this.changeFormValue("guestNr", el.target.value) }} />
							</Col>
							<Col sm="3">
								<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold"}}>Cerimónia</p>
								<Select placeholder="Selecione um tipo de cerimónia." value={this.state.ceremony} onChange={this.changeSelectCeremony}>
									{/* <option selected disabled>Selecione um tipo de cerimónia.</option> <- FOR SHARDS SELECT*/}
									<Option value="Civil">Civil</Option>
									<Option value="Religiosa">Religiosa</Option>
									<Option value="Simbólica">Simbólica</Option>
									<Option value="Outra">Outra</Option>
								</Select>
							</Col> 
							<Col sm="5">
								<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold"}}>Localização</p>
								<Input value={this.state.location} onChange={(el) => { this.changeFormValue("location", el.target.value) }} />
							</Col>
						</Row>
						<Row style={{ marginBottom:"40px"}}>
							<Col sm="2">
								<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold"}}>Budget</p>
								<Input required value={this.state.budget} onChange={(el) => { this.changeFormValue("budget", el.target.value) }} placeholder="Budget(€)" />
							</Col>
							<Col>
								<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold"}}>Website</p>
								<Input value={this.state.webSiteUrl} onChange={(el) => { this.changeFormValue("webSiteUrl", el.target.value) }} placeholder="http://" />
							</Col>
							<Col>
								<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold"}}>Google Drive (Docs)</p>
								<Input value={this.state.docsUrl} onChange={(el) => { this.changeFormValue("docsUrl", el.target.value) }} placeholder="http://" />
							</Col>
						</Row>
						<Row style={{marginBottom:"70px", textAlign:"right"}}>
							<Col>
								<Button type="btn-user" loading={this.state.loading} htmltype="submit" style={{ width: 190, alignSelf: "right"}} >
									Criar
								</Button>
							</Col> 
						</Row>
					</Container>

				</div>
			</form>
		);
	} 
}

let appContainer = withRouter(AddWedding);

  function mapStateToProps(state) {
	return { tokens: state.tokens, reduxStorageLoaded: state.storageLoaded, validTokens: state.validTokens, _id: state.user._id }
  }

  const AppRedux = connect(
	mapStateToProps,
  )(appContainer);
  
export default AppRedux;
