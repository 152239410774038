import React from 'react'
import '../App.css'
import {
	Col,
	Row,
	Button
} from "shards-react"
import { Input } from 'antd'
import { connect } from "react-redux"
import { withRouter } from "react-router"
import { API_URL } from '../configs/configs'
import { get as _get } from 'lodash'
import notify from '../helpers/notify'
import ArrowBack from '@material-ui/icons/ArrowBackIos'

const axios = require('axios');

class InsertQuiz extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			iconLoading: false,
			open: false,
			wedding: [],
			quiz: [],
			value: '',
			ans: [],
			flag: false,
			key: null,
			indexs: []
		}
	}

	changeInputValue = (key, value) => {
		var index;
		this.state.indexs.map((v, i) => {
			if(Object.keys(v)[0] === key){
				index = Object.values(v)[0];
			}
		});
		let obj = this.state.ans
		obj[index] = {[key]: value}
		this.setState({
			ans: obj
		});
	}

	async componentWillMount() {

		//Get WeddingId from url
		let id = this.props.match.params.id;

		//GET WEDDING INFO
		await axios.get(API_URL + '/wedding/getWedding/' + id, { headers: { 'authorization': this.props.tokens } })
			.then(async (response) => {
				//console.log("RESPONSE DATA WEDDING BY ID", response.data.wedding);
				let weddingRes = response.data.wedding
				
				if(weddingRes.answers !== undefined && weddingRes.answers !== null){
					var array = [];
					weddingRes.answers.map((v, i) => {
						let key = Object.values(v)[1];
						let value = Object.values(v)[2];
						// let tmp = {[key]: value};
						// array.push(tmp);
						array[i] = {[key]: value}
					});
					this.setState({
						ans: array
					})
				}

				let weddingData = {
					budget: weddingRes.budget,
					groom: weddingRes.groom.nameGroom,
					bride: weddingRes.bride.nameBride,
					answers: weddingRes.answers
				}

				await this.setState({
					wedding: weddingData,
					wed_id: weddingRes._id
				})
				//console.log("WEDING IN STATE - ", this.state.wedding)
			})
			.catch((error) => {
				// notify("Erro na pesquisa de casamentos.")
				console.log(error);
			});

		//GET QUIZ FROM DB
		axios.get(API_URL + '/quiz/getQuiz', { headers: { 'authorization': this.props.tokens } })
			.then(async (response) => {
				//console.log("RESPONSE DATA QUIZ", response.data.quiz.quiz)

				let quizRes = response.data.quiz.quiz
				await this.setState({
					quiz: quizRes,
				})
				
				var count = 0
				var arr = this.state.indexs
				this.state.quiz.map((value, index) => {
					value.questions.map((val, ind) => {
						var key = value.cat + val.num
						arr[arr.length] = {[key]: count}
						count++
					})
				})
				let ans = this.state.ans

				for(let i = 0; i <this.state.indexs.length; i++){

					let indexsKey = Object.keys(this.state.indexs[i])[0]

					if(i < ans.length){
						let ansKey = Object.keys(ans[i])[0]

						if(indexsKey !== ansKey){
							ans.splice(i, 0, {[indexsKey]: ""})
						}
					} else {
						ans.push({[indexsKey]: ""})
					}
				}
				this.setState({
					indexs: arr,
					ans: ans
				})
				//console.log("QUIZ IN STATE - ", this.state.quiz)
			})
			.catch((error) => {
				// notify("Erro na pesquisa de casamentos.")
				console.log(error)
			})
	}


	addAnswers = (e) => {

		e.preventDefault()
		let _id = this.props.match.params.id

		let key
		let val
		let obj = []

		this.state.ans.map((value, index) => {
			key = Object.keys(value)[0]
			val = Object.values(value)[0]
			obj[obj.length] = { q: key, a: val }
		})
		//console.log("KKKKKK ", obj)

		axios.put(API_URL + '/wedding/updateAnswers/' + _id, obj, { headers: { 'authorization': this.props.tokens } })
			.then((response) => {
				console.log(response.data)
				this.setState({ loading: false })
				notify("Questionário alterado com sucesso!", "success")
				this.props.history.push('/wedding_details/' + _id)
			})
			.catch((err) => {
				if (_get(err, "response.data.err", false) === "invalid-dates") {
					// notify("Hora de Término deve ser maior do que Hora de Início. Verifique os campos.")
					console.log(err)
					this.setState({ loading: false })
				} else {
					// notify("Erro ao adicionar aula.")
					console.dir(err)
					this.setState({ loading: false })
				}
			});
	}
	

	render() {
		let f = false
		let k = null

		if ((this.state.wedding.answers) === undefined) {
			return null
		} else {
			return (
				<div style={{ marginLeft: "2%", marginRight: "2%" }}>
					<Row>
						<Col sm="1" style={{maxWidth:"3%"}} className="inline-col">
							<h4 className="pointer" style={{ marginBottom: "2%", marginTop:"200%" }}><ArrowBack style={{fontSize:"18px"}} onClick={() => { this.props.history.push('/wedding_details/' + this.state.wed_id)}}/></h4>
						</Col>
						<Col sm="11">
							<h4 style={{ textAlign: "left", marginBottom: "5px", marginTop: "10px" }}>Inserir Questionário</h4>
							<h6 style={{ textAlign: "left" }}><b>{this.state.wedding.bride}  & {this.state.wedding.groom}</b></h6>
						</Col>
					</Row>
					<form onSubmit={this.addAnswers} >
						{this.state.quiz.map((value, index) => (
							<div>
								<h6 style={{ textAlign: "left", marginBottom: "25px", marginTop: "65px" }}><b><u>{value.cat}</u></b></h6>
								{value.questions.map((val, ind) => (
									<div>
										<p style={{ marginLeft: 0, marginBottom: "5px", fontSize: "15px", fontWeight: "bold" }}>{val.num, " - ", val.q}</p>
										{f = false}
										{this.state.ans.map((vv, ii) => {
											if(Object.keys(vv)[0] === (value.cat + val.num)){
												{f = true}
												{k = Object.keys(vv)[0]}
												return(
													<Input style={{ marginBottom: "30px" }} value={this.state.ans[ii][k]} onChange={el =>  this.changeInputValue(value.cat + val.num, el.target.value) }/>
												)}
											}
										)}
										{(f === true) ? (console.log("TRUE")) : (
											<Input style={{ marginBottom: "30px" }} onChange={(el) => { this.changeInputValue(value.cat + val.num, el.target.value) }} />
										)}
									</div>
								))}
							</div>
						))}
						<Row style={{ marginBottom: "70px", textAlign: "right" }}>
							<Col>
								<Button type="btn-user" loading={this.state.loading} htmltype="submit" style={{ width: 190, alignSelf: "right" }}>
									Submeter
									</Button>
							</Col>
						</Row>
					</form>
				</div>
			)
		}
	}
}

let appContainer = withRouter(InsertQuiz)
function mapStateToProps(state) {
	return { tokens: state.tokens, reduxStorageLoaded: state.storageLoaded, validTokens: state.validTokens, _id: state.user._id }
}

const AppRedux = connect(
	mapStateToProps,
)(appContainer)
export default AppRedux