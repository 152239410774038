import React from 'react'
import '../App.css'
import {
    Col,
    Container,
    Row,
    Button
} from "shards-react"
import { Input, Select, DatePicker } from 'antd'
import { connect } from "react-redux"
import { withRouter } from "react-router"
import { API_URL } from '../configs/configs'
import moment from 'moment'
import notify from '../helpers/notify';


const axios = require('axios')
const DateInput = Input.Group
const { Option } = Select

class EditWedding extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            loading: false,
            iconLoading: false,
            date: undefined,
            numGuests: 0,
            location: "",
            budget: "",
            _id: "",
            ceremony: "",
            nameGroom: "",
            emailGroom: "",
            cellPhoneGroom: "",
            nameBride: "",
            emailBride: "",
            cellPhoneBride: "",
            webSiteUrl:"",
            docsUrl:"",
            users: []
        }
    }

    changeFormValue = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    changeSelectCeremony = (value) => {
        this.setState({
            ceremony: value
        })
        //console.log("YYYYYYYYY, ", this.state)
    }

    changeSelectUsers = (value) => {
		this.setState({
			weddingPlanner: value
		})
	}

    componentDidMount = () => {
        //USERS PARA SELECT
		axios.get( API_URL + '/user/getUsers', { headers: {'authorization': this.props.tokens} })
		.then((response) => {
			let usersRes = response.data.users

			let usersData = usersRes.map((value, index) => (
				{
					_id: value._id,
					name: value.name
				}
			))

			this.setState({
				users: usersData
			}, () => {this.getWedding()})
			
		})
		.catch((error) => {
			console.log(error);
        });  
    }

    getWedding(){
        let id = this.props.match.params.id;

        axios.get( API_URL + '/wedding/getWedding/' + id , { headers: {'authorization': this.props.tokens} })
        .then((response) => {
            console.log("RESPONSE DATA WEDDING BY ID", response.data.wedding);
            let weddingRes = response.data.wedding

            this.setState({
                date: moment(weddingRes.date),
                numGuests: weddingRes.numGuests,
                location: weddingRes.location,
                budget: weddingRes.budget,
                _id: weddingRes._id,
                ceremony: weddingRes.ceremony,
                nameGroom: weddingRes.groom.nameGroom,
                emailGroom: weddingRes.groom.emailGroom,
                cellPhoneGroom: weddingRes.groom.cellPhoneGroom,
                nameBride: weddingRes.bride.nameBride,
                emailBride: weddingRes.bride.emailBride,
                cellPhoneBride: weddingRes.bride.cellPhoneBride,
                webSiteUrl: weddingRes.webSiteUrl,
                docsUrl: weddingRes.docsUrl,
                weddingPlanner: weddingRes.employee._id
            })
            
        })
        .catch((error) => {
        // notify("Erro na pesquisa de casamentos.")
        console.log(error);
        });
    }

    updateWedding = (e) => {

        e.preventDefault();
        let id = this.props.match.params.id;
    
        let weddingDoc = {
            date: this.state.date,
            numGuests: this.state.numGuests,
            location: this.state.location,
            budget: this.state.budget,
            _id: this.state._id,
            ceremony: this.state.ceremony,
            nameGroom: this.state.nameGroom,
            emailGroom: this.state.emailGroom,
            cellPhoneGroom: this.state.cellPhoneGroom,
            nameBride: this.state.nameBride,
            emailBride: this.state.emailBride,
            cellPhoneBride: this.state.cellPhoneBride,
            webSiteUrl: this.state.webSiteUrl,
            docsUrl: this.state.docsUrl,
            employee: this.state.weddingPlanner
        }

        console.log("OBJ TO UPDATE: ", weddingDoc)
    
        axios.put( API_URL + '/wedding/updateWedding/' + id, weddingDoc, { headers: {authorization: this.props.tokens} })
        .then((response) => {
            console.log(response.data);
            notify("Casamento editado com sucesso!", "success")
            this.setState({ loading: false})
            this.props.history.push('/wedding_details/' + id)
        })
        .catch((error) => {
    
            notify("Erro ao editar ação.")
            console.log(error);
            this.setState({ loading: false });
        });
    }

    cancelEdit = () => {
        this.props.history.push('/general_dashboard')
    }


    toggle = (open = false) => {
        this.setState({
          open: open
        })
    }


    render() {

        let users = this.state.users.map((value, index) => {
			return <Option value={value._id}>{value.name}</Option>
		})

        return (
            <form onSubmit={this.updateWedding} >
                <div style={{ marginBottom: "4%", marginLeft:"2%" }}>
                    <Container style={{ paddingRight: 0, marginRight: "2%", maxWidth: "-webkit-fill-available" }}>
                        <h4 style={{ textAlign:"left", marginBottom: "50px", marginTop:"15px" }}>Editar Casamento</h4>
                        <Row style={{marginBottom:"8px", marginTop:"20px"}}>
                            <h6 style={{ textAlign:"left" }}><b>Dados da Noiva</b></h6>
                        </Row>
                        <Row style={{ marginBottom:"40px"}}>
                            <Col sm="5">
                                <p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold"}}>Nome</p>
                                <Input required value={this.state.nameBride} onChange={(el) => { this.changeFormValue("nameBride", el.target.value) }} placeholder="Nome" />
                            </Col>
                            <Col sm="4">
                                <p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold"}}>Email</p>
                                <Input required value={this.state.emailBride} type="email" onChange={(el) => { this.changeFormValue("emailBride", el.target.value) }}  placeholder="Email" />
                            </Col>
                            <Col sm="3">
                                <p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold"}}>Telemóvel</p>
                                <Input required value={this.state.cellPhoneBride} onChange={(el) => { this.changeFormValue("cellPhoneBride", el.target.value) }} placeholder="Nº. Telemóvel" />
                            </Col>
                        </Row>
                        <Row style={{marginBottom:"8px", marginTop:"20px"}}>
                            <h6 style={{ textAlign:"left" }}><b>Dados do Noivo</b></h6>
                        </Row>
                        <Row style={{ marginBottom:"40px"}}>
                            <Col sm="5">
                                <p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold"}}>Nome</p>
                                <Input required value={this.state.nameGroom} onChange={(el) => { this.changeFormValue("nameGroom", el.target.value) }} placeholder="Nome" />
                            </Col>
                            <Col sm="4">
                                <p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold"}}>Email</p>
                                <Input required value={this.state.emailGroom} type="email" onChange={(el) => { this.changeFormValue("emailGroom", el.target.value) }}  placeholder="Email" />
                            </Col>
                            <Col sm="3">
                                <p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold"}}>Telemóvel</p>
                                <Input required value={this.state.cellPhoneGroom} onChange={(el) => { this.changeFormValue("cellPhoneGroom", el.target.value) }} placeholder="Nº. Telemóvel" />
                            </Col>
                        </Row>
                        <Row style={{marginBottom:"8px", marginTop:"20px"}}>
                            <h6 style={{ textAlign:"left" }}><b>Detalhes do Casamento</b></h6>
                        </Row>
                        <Row style={{ marginBottom:"35px"}}>
							<Col sm="4">
								<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold"}}>Coordenadora</p>
								<Select placeholder="Selecione Colaborador/a." value={this.state.weddingPlanner} onChange={this.changeSelectUsers}>
									{users}
								</Select>
							</Col>
						</Row>
                        <Row style={{ marginBottom:"35px"}}>
                            <Col sm="2">
                                <p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold"}}>Data</p>
                                <DateInput>
                                    <DatePicker value={this.state.date} onChange={(val) => { this.changeFormValue("date", val) }} placeholder="Selecione data" style={{ width: '100%' }} />
                                </DateInput>
                            </Col>
                            <Col sm="2">
                                <p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold"}}>Nº. de Convidados</p>
                                <Input value={this.state.numGuests} onChange={(el) => { this.changeFormValue("numGuests", el.target.value) }} />
                            </Col>
                            <Col sm="3">
                                <p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold"}}>Cerimónia</p>
                                <Select placeholder="Selecione um tipo de cerimónia." value={this.state.ceremony} onChange={this.changeSelectCeremony}>
                                    {/* <option selected disabled>Selecione um tipo de cerimónia.</option> */}
                                    <Option value="Civil">Civil</Option>
                                    <Option value="Religiosa">Religiosa</Option>
                                    <Option value="Simbólica">Simbólica</Option>
                                    <Option value="Outra">Outra</Option>
                                </Select>
                            </Col> 
                            <Col sm="5">
                                <p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold"}}>Localização</p>
                                <Input value={this.state.location} onChange={(el) => { this.changeFormValue("location", el.target.value) }} />
                            </Col>
                        </Row>
                        <Row style={{ marginBottom:"40px"}}>
                            <Col sm="2">
                                <p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold"}}>Budget</p>
                                <Input value={this.state.budget} onChange={(el) => { this.changeFormValue("budget", el.target.value) }} placeholder="Budget(€)" />
                            </Col>
                            <Col>
                                <p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold"}}>Website</p>
                                <Input type="url" value={this.state.webSiteUrl} onChange={(el) => { this.changeFormValue("webSiteUrl", el.target.value) }} placeholder="http://" />
                            </Col>
                            <Col>
								<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold"}}>Google Drive (Docs)</p>
								<Input value={this.state.docsUrl} onChange={(el) => { this.changeFormValue("docsUrl", el.target.value) }} placeholder="http://" />
							</Col>
                        </Row>
                        <Row style={{marginBottom:"70px", textAlign:"right"}}>
                            <Col>
                                <Button type="btn-user" loading={this.state.loading} style={{ width: 160, marginRight:"20px", alignSelf: "right"}} onClick={this.cancelEdit}>
                                    Cancelar
                                </Button>
                                <Button type="btn-user" loading={this.state.loading} style={{ width: 160, alignSelf: "right"}} htmltype="submit">
                                    Editar
                                </Button>
                            </Col> 
                        </Row>
                    </Container>
                </div>
            </form>
        );
    } 
}

let appContainer = withRouter(EditWedding);

  function mapStateToProps(state) {
    return { tokens: state.tokens, reduxStorageLoaded: state.storageLoaded, validTokens: state.validTokens, _id: state.user._id }
  }

  const AppRedux = connect(
    mapStateToProps,
  )(appContainer);
  
export default AppRedux;
