import React from 'react'
import '../App.css'
import {
    InputGroup,
    FormInput,
    FormTextarea,
    Row,
    Col,
} from "shards-react"
import {Radio} from "antd"

class ProviderPack extends React.Component {
 
  render() {
      
        return (
            <Row style={{ marginBottom:"25px"}}>
                <Col sm="3">
                    <p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold"}}>Nome</p>
                    <InputGroup size="sm">
                        <FormInput value={this.props.nameValue} onChange={this.props.onNameChange} />
                    </InputGroup>
                </Col>
                <Col>
                    <p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold"}}>Detalhes</p>
                    <FormTextarea size="sm" value={this.props.detailsValue} style={{height:"30px"}} onChange={this.props.onDetailsChange}/>
                </Col>
                <Col sm="1">
                    <p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold"}}>Valor</p>
                    <InputGroup size="sm">
                        <FormInput value={this.props.price} onChange={this.props.onPriceChange} />
                    </InputGroup>
                </Col>
                <Col sm="1,5" style={{placeSelf: "auto"}}>
                    {/* Ao enviar no axios, necessário fazer no objeto - variavel: (this.state.commissionType === "pcent") ? true : false */}
                    <p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold", color:"#FFFFFF"}}>.</p>
                    <Radio.Group name="priceBy" onChange={this.props.onPriceByChange} value={this.props.priceBy}>
                        <Radio value={"pessoa"}>Pessoa</Radio>
                        <Radio value={"geral"}>Geral</Radio>
                    </Radio.Group>
                </Col>
                <Col sm="1">
                    <p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold"}}>Comissão</p>
                    <InputGroup size="sm">
                        <FormInput value={this.props.commissionValue} onChange={this.props.onCommissionChange} />
                    </InputGroup>
                </Col>
                <Col sm="1,5" style={{placeSelf: "auto"}}>
                    {/* Ao enviar no axios, necessário fazer no objeto - variavel: (this.state.commissionType === "pcent") ? true : false */}
                    <p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold", color:"#FFFFFF"}}>.</p>
                    <Radio.Group name="commissionType" onChange={this.props.onCommissionTypeChange} value={this.props.commissionType}>
                        <Radio value={"pcent"}>%</Radio>
                        <Radio value={"euro"}>€</Radio>
                    </Radio.Group>
                </Col>
            </Row>
        );
    } 
}
export default ProviderPack