import React from 'react'
import '../App.css'
import {
    Col,
    Row,
    Container,
    ListGroup, 
    ListGroupItem,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "shards-react"
import { Input, Select } from 'antd'
import { connect } from "react-redux"
import { withRouter } from "react-router"
import { API_URL } from '../configs/configs'
import notify from '../helpers/notify'
import SearchIcon from '@material-ui/icons/Search'
import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close'
import SettingsIcon from '@material-ui/icons/Settings';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';

const axios = require('axios');
const { Option } = Select

class ViewProviders extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            loading: false,
            iconLoading: false,
            open: false,
            providers: []
        }
    }

    componentDidMount = () => {

        axios.get( API_URL + '/provider/getProviders', { headers: {'authorization': this.props.tokens} })
        .then((response) => {
            let providersRes = response.data.providers
            //console.log("PROV, ", response.data.providers)
            let providersData = providersRes.map((value, index) => ( 
                {   
                    name: value.name,
                    email: value.email,
                    phone: value.phone,
                    role: value.role,
                    _id: value._id 
                }
            ))
   
            this.setState({
                providers: providersData
            })
            //console.log("AFTER STATE: ", this.state.providers)
        })
        .catch((error) => {
            console.log(error);
        });

    }


    changeFormValue = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    changeSelectRole = (value) => {
        this.setState({
            roleToSearch: value
        })
        //console.log("XXXXX, ", this.state)
    }

    //Search Providers
    searchProviders = () => {

        let searchParams = {
            name: this.state.nameToSearch,
            role: this.state.roleToSearch
        }

        axios.get(API_URL + '/provider/byCategoryNameRegex', { headers: { Authorization: this.props.tokens }, params: searchParams })
            .then((response) => {
                //console.log(response.data);
                notify("Pesquisa concluída!", "success")
                let providers = response.data.providers;

                this.setState({
                    providers
                })
                //console.log("PROVIDER AFTER SET STATE AXIOS", providers)
                this.setState({ loading: false })
            })
            .catch((error) => {
                notify("Erro na pesquisa.")
                //console.log(error);
                this.setState({ loading: false });
            });
    }

    deleteProvider = () => {
        let id = this.state.providerSelected
        //console.log("SELECTED PROV, ", id)

        axios.delete( API_URL + '/provider/deleteProvider/' + id, { headers: {'authorization': this.props.tokens} })
        .then((response) => {
            this.setState({ 
                loading: false,
            })
            notify("Fornecedor eliminado com sucesso!", "success")
            window.location.reload()
        })
        .catch((err) => {
            notify("Erro ao eliminar fornecedor.")
            console.dir(err);
            this.setState({ 
                loading: false
            })
        });
    }
    

    toggleDelete = (openDelete = false, provider = null) => {
        this.setState({
            providerSelected: provider, 
            openDelete: openDelete
        })
    }


    render() {
        console.log("PROVIDERS: ", this.state.providers)

        var providers = this.state.providers
        providers.sort((a, b) => (a.name > b.name) ? 1 : -1)

        let providersData = providers.map((item, index) => {
            return(
                <ListGroupItem style={{borderStyle:"solid", borderBottomWidth:"2px", borderTopWidth:"0px", borderBottomColor:"#cecece", fontSize:"14px"}}>
                    <Row>
                        <Col sm="3" style={{textAlign: "left"}}>
                            {item.name}
                        </Col>
                        <Col sm="3" style={{textAlign: "left"}}>
                            {item.role}
                        </Col>
                        <Col sm="2" style={{textAlign: "left"}}>
                            {item.phone}
                        </Col>
                        <Col sm="3" style={{textAlign: "left"}}>
                            {item.email}
                        </Col>
                        {/* {(this.props.user.role && (this.props.user.role.indexOf("admin") !== -1) ) ? ( */}
                            <Col sm="1" style={{textAlign:"center"}}>
                                <Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Editar" placement="top">
                                    <EditIcon className="pointer" style={{fontSize:"20px", marginRight:"2%"}} onClick={() => { this.props.history.push('/edit_provider/' + item._id) }}/>
                                </Tooltip>
                                <Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Apagar" placement="top">
                                    <CloseIcon className="pointer" style={{fontSize:"20px", marginLeft:"2%"}} onClick={() => this.toggleDelete(true, item._id)}/>
                                </Tooltip>
                            </Col>
                        {/* ) : null} */}
                    </Row>
                </ListGroupItem>
            )
        })

        return (
            <div style={{ marginBottom: "4%"}}>
                <Container style={{ paddingRight: 0, marginLeft: "1%", maxWidth: "100%", justifyContent:"space-evenly" }}>
                    <h4 style={{ textAlign:"left", marginBottom: "50px", marginTop:"15px" }}>Todos os Fornecedores</h4>
                    <Row style={{width:"100%", justifyContent:"space-evenly"}}>
                        <Col>
                            <Input style={{marginBottom:10}} placeholder="Nome" onChange={(e) => this.setState({ nameToSearch: e.target.value })} />
                        </Col>
                        <Col>
                            <Select style={{minWidth:"100%"}} placeholder="Selecione Categoria." value={this.state.roleToSearch} onChange={this.changeSelectRole}>
                                <Option value="">Todos</Option> 
                                <Option value="Espaço">Espaço</Option>                                  
                                <Option value="Catering">Catering</Option>                                  
                                <Option value="Espaço e Catering">Espaço e Catering</Option>                                  
                                <Option value="Save the Date e Convite">Save the Date e Convite</Option>                                  
                                <Option value="Makeup e Cabelo">Makeup e Cabelo</Option>                                  
                                <Option value="Roupa Noiva">Roupa Noiva</Option>                                  
                                <Option value="Roupa Noivo">Roupa Noivo</Option>                                  
                                <Option value="Fotografia">Fotografia</Option>                                  
                                <Option value="Video">Video</Option>                                  
                                <Option value="Fotografia e Video">Fotografia e Video</Option>                                  
                                <Option value="Grupo Musical">Grupo Musical</Option>                                  
                                <Option value="Performers">Performers</Option>                                  
                                <Option value="DJ">DJ</Option>                                  
                                <Option value="Banda">Banda</Option>                                  
                                <Option value="Alojamento">Alojamento</Option>                                  
                                <Option value="Pre Evento">Pre Evento</Option>                                  
                                <Option value="Pos Evento">Pos Evento</Option>                                  
                                <Option value="Website">Website</Option>                                  
                                <Option value="Padre">Padre</Option>                                  
                                <Option value="Processo Civil/Simbólico">Processo Civil/Simbólico</Option>                                  
                                <Option value="Decoração">Decoração</Option>                                  
                                <Option value="Babysitter">Babysitter</Option>                                  
                                <Option value="Lembranças">Lembranças</Option>                                  
                                <Option value="Transporte Convidados">Transporte de Convidados</Option>                                  
                                <Option value="Aulas de dança">Aulas de dança</Option>                                  
                                <Option value="Design Gráfico">Design Gráfico</Option>                                  
                                <Option value="Florista">Florista</Option>                                     
                            </Select>
                        </Col>
                        <Col sm="1">
                            <SearchIcon className="btn-inverted pointer" loading={this.state.loading} onClick={this.searchProviders}/>
                        </Col>
                    </Row>

                {(this.state.providers.length > 0) ? (
                    <ListGroup className="card" style={{ width:"95%", marginBottom:"5%", marginTop:"5%", textAlign:"left", justifyContent:"space-evenly"}}>
                        <ListGroupItem style={{backgroundColor:"#FAF5F2", borderStyle:"solid", borderBottomWidth:"2px", borderBottomColor:"#FAF5F2", borderTopColor:"#FAF5F2"}}>
                            <Row>
                                <Col style={{fontSize: "19px", color: "#212529"}}>
                                    <b>Lista de Fornecedores</b>
                                </Col> 
                            </Row>
                        </ListGroupItem>
                        <ListGroupItem style={{borderStyle:"solid", borderBottomWidth:"2px", borderTopWidth:"0px", borderBottomColor:"#cecece", fontSize:"15px"}}>
                            <Row>
                                <Col sm="3" style={{textAlign: "left"}}>
                                    <b>Nome</b>
                                </Col>
                                <Col sm="3" style={{textAlign: "left"}}>
                                    <b>Categoria</b>
                                </Col>
                                <Col sm="2" style={{textAlign: "left"}}>
                                    <b>Telemóvel</b>
                                </Col>
                                <Col sm="3" style={{textAlign: "left"}}>
                                    <b>Email</b>
                                </Col>
                                {/* {(this.props.user.role && (this.props.user.role.indexOf("admin") !== -1) ) ? ( */}
                                    <Col sm="1" style={{textAlign:"center"}}>
                                        <SettingsIcon style={{fontSize:"16px"}}/>
                                    </Col>
                                {/* ): null} */}
                            </Row>
                        </ListGroupItem>
                        {providersData}
                    </ListGroup>
                ) : (
                    <ListGroup className="card" style={{ width:"95%", marginBottom:"5%", marginTop:"5%", textAlign:"left", justifyContent:"space-evenly"}}>
                        <ListGroupItem style={{backgroundColor:"#FAF5F2", borderStyle:"solid", borderBottomWidth:"2px", borderBottomColor:"#FAF5F2", borderTopColor:"#FAF5F2"}}>
                            <Row>
                                <Col style={{fontSize: "19px", color: "#212529"}}>
                                    <b>Lista de Fornecedores</b>
                                </Col> 
                            </Row>
                        </ListGroupItem>
                        <ListGroupItem>
                            Não existem fornecedores adicionados.
                        </ListGroupItem>
                    </ListGroup>
                )}
                </Container>

                <Modal open={this.state.openDelete} toggle={() => { this.toggleDelete(false) }}>
                    <ModalHeader>Atenção!</ModalHeader>
                    <ModalBody>Este passo é irreversível. Deseja eliminar?</ModalBody>
                        <ModalFooter>
                            <Button className="btn-inverted" onClick={() => this.toggleDelete(false)}>Cancelar</Button>
                            <Button className="btn-inverted" onClick={this.deleteProvider}>Apagar</Button> 
                        </ModalFooter>
                </Modal>

            </div>
        );
    } 
}

let appContainer = withRouter(ViewProviders);

  function mapStateToProps(state) {
    return { tokens: state.tokens, reduxStorageLoaded: state.storageLoaded, validTokens: state.validTokens, _id: state.user._id, user: state.user }
  }

  const AppRedux = connect(
    mapStateToProps,
  )(appContainer);
  
export default AppRedux;
