/**
 * Gets the time necessary (ms) to read a specific message <message>
 * @param {string} message Message
 * @param {number} minTime - Minimum time(ms) 
 */
export default function getTimeToRead(message, minTime = 3000){

    if (typeof minTime !== 'number') {
      minTime = 3000;
    }
    
    if (typeof message !== 'string') {
      console.error("<message> is not a string! Return 3000ms");
      return minTime;
    }
    
    let timeToRead = Math.ceil(message.length / 18) * 1000;
  
    if (timeToRead < minTime) {
      timeToRead = minTime;
    }
    return timeToRead;
  }