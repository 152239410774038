import React from 'react';
import '../App.css';
import { Button, Collapse, Checkbox } from 'antd';
import { Button as ButtonShards } from 'shards-react';
import { Col, Row, Modal, ModalBody, ModalHeader, ModalFooter, FormTextarea } from 'shards-react';
import { Card, CardContent } from '@material-ui/core';
import { API_URL } from '../configs/configs';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Moment from 'react-moment';
import notify from '../helpers/notify';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import { after as _after } from 'lodash';
import CanvasJSReact from '../assets/canvasjs.react';
import ArrowBack from '@material-ui/icons/ArrowBackIos'
var CanvasJS = CanvasJSReact.CanvasJS;



const axios = require('axios');
const { Panel } = Collapse;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;


class MoreStatistics extends React.Component {
    state = {
        wedding:[{}],
        openDelete: false,
        collapse: false,
		valuesGraphCats: [],
		valuesGraphRoles: [],
		maxValues: [],
		debtValues: [],
		paidValues: [],
		totalValues: []
    }

    toggleCollapse = (collapse = false) => {
        this.setState({
          collapse: !this.state.collapse
        })
	}
	

    getWedingInfo = () => {
        let id = this.props.match.params.id;

        axios.get( API_URL + '/wedding/getWedding/' + id , { headers: {'authorization': this.props.tokens} })
        .then((response) => {
			
			console.log("RESPONSE DATA WEDDING BY ID", response.data.wedding);
            let weddingRes = response.data.wedding
            let wedding = response.data

            let weddingData = 
                {
                    date: weddingRes.date,
                    numGuests: weddingRes.numGuests,
                    location: weddingRes.location,
                    budget: weddingRes.budget,
                    _id: weddingRes._id,
                    ceremony: weddingRes.ceremony,
                    groom: weddingRes.groom.nameGroom,
                    bride: weddingRes.bride.nameBride,
                    categories: weddingRes.categories,
                    employee: weddingRes.employee.name,
                    emailIsSentState: weddingRes.emailIsSent.state,
                    emailIsSentDate: weddingRes.emailIsSent.date,
                    contract: weddingRes.contract,
                    daysUntil: weddingRes.daysUntil,
                    webSiteUrl: weddingRes.webSiteUrl,
                    docsUrl: weddingRes.docsUrl,
                    allClosed: wedding.areAllClosed,
                    finalTasks: weddingRes.finalTasks
                }
       

            this.setState({
                wedding: weddingData,
                weddingNotes: weddingRes.generalNotes,
                categoriesArr: weddingRes.categories
            })
            //console.log("State: ", this.state)
        })
        .catch((error) => {
            console.log(error);
        });

    }

    componentDidMount = () => {

        this.getWedingInfo()

        let id = this.props.match.params.id;

        axios.get( API_URL + '/wedding/negotiationValues/' + id , { headers: {'authorization': this.props.tokens} })
        .then((response) => {
            console.log("RESPONSE DATA VALS FROM API", response.data.valuesArray);
            
            this.setState({
				valuesGraphCats: response.data.valuesArray
            })
          
        })
        .catch((error) => {
            console.log(error);
		});
		
        axios.get( API_URL + '/wedding/roleValues/' + id , { headers: {'authorization': this.props.tokens} })
        .then((response) => {
            //console.log("RESPONSE DATA VALS ROLES FROM API", response.data);
            
            this.setState({
				valuesGraphRoles: response.data.valuesArray
            })
          
        })
        .catch((error) => {
            console.log(error);
        });


		axios.get( API_URL + '/wedding/maxBudget/' + id , { headers: {'authorization': this.props.tokens} })
        .then((response) => {
            //console.log("RESPONSE DATA MAX VALS FROM API", response.data);
            
            this.setState({
				maxValues: response.data.valuesArray
            })
          
        })
        .catch((error) => {
            console.log(error);
		});
		

		axios.get( API_URL + '/wedding/debtPayments/' + id , { headers: {'authorization': this.props.tokens} })
        .then((response) => {
            console.log("RESPONSE DATA PAYMENTS FROM API", response.data);
            
            this.setState({
				debtValues: response.data.valuesArray.debtValues,
				paidValues: response.data.valuesArray.paidValues,
				totalValues: response.data.valuesArray.totalValues
            })
          
        })
        .catch((error) => {
            console.log(error);
        });
		

    }


	addSymbols(e){
		var suffix = " €";
		return CanvasJS.formatNumber(e.value) + suffix;
	}
    


    render() {

		let wedNames = this.state.wedding.bride + "&" + this.state.wedding.groom
        let fileName = "BudgetServicos_" + wedNames
		let arrayVals = []
		let total
        this.state.valuesGraphCats.map((itm, index) => {
            if(itm.cat !== "Total Gasto" && itm.cat !== "Quota Extra") {
                arrayVals.push({ 
                    name: itm.cat,
                    y: itm.value
				})
			} else if ( itm.cat === "Total Gasto") {
				total = itm.value
			}
            console.log("PRINT ARRAY PUSHED, ", arrayVals)
            console.log("TOTAL, ", total)
        })
        console.log("PRINT ARRAY PUSHED, ", arrayVals)

        const options = {
			title:{
				text: "Valor gasto por categoria"
			},
			animationEnabled: true,
			exportFileName: fileName,
            exportEnabled: true,
            height: 350,
            subtitles: [{
				text: total + "€",
				verticalAlign: "center",
				fontSize: 22,
				dockInsidePlotArea: true
			}],
            legend: {
                horizontalAlign: "right",
                verticalAlign: "center",
                maxWidth: 550,
                itemWidth: 200,
                markerMargin: 10
            },
			data: [{
				type: "doughnut",
				showInLegend: true,
				legendText: "{name}: {y}€",
				toolTipContent: "{name}: {y}€",
				//indexLabel: "{name}",
				dataPoints: arrayVals
			}]
		}

		//TOTAL PAID/DEBT

		//let wedNames = this.state.wedding.bride + "&" + this.state.wedding.groom
        let fileNameTotals = "TotalPago/EmFalta_" + wedNames
		let arrayValsTotal = []
		let totalValue
        this.state.totalValues.map((itm, index) => {
            if(itm.name === "Total") {
				totalValue = itm.value
			} else {
				arrayValsTotal.push({ 
                    name: itm.name,
                    y: itm.value
				})
			}
            console.log("PRINT ARRAY PUSHED TOTALS, ", arrayValsTotal)
         
        })

        const optionsTotal = {
			title:{
				text: "Valor Pago/Em Falta"
			},
			animationEnabled: true,
			exportFileName: fileNameTotals,
            exportEnabled: true,
            height: 350,
            colorSet: "colorSet1",
            subtitles: [{
				text: totalValue + "€",
				verticalAlign: "center",
				fontSize: 22,
				dockInsidePlotArea: true
			}],
            legend: {
                horizontalAlign: "right",
                verticalAlign: "center",
                maxWidth: 550,
                itemWidth: 200,
                markerMargin: 10
            },
			data: [{
				type: "doughnut",
				showInLegend: true,
				legendText: "{name}: {y}€",
				toolTipContent: "{name}: {y}€",
				//indexLabel: "{name}",
				dataPoints: arrayValsTotal
			}]
		}
		

		//ROLES
		let arrayValsRoles = []
		let fileNameRoles = "BudgetDetalhado_" + wedNames
		this.state.valuesGraphRoles.map((itm, index) => {
			arrayValsRoles.push({ 
				name: itm.role,
				y: itm.value
			})
            console.log("PRINT ARRAY PUSHED ROLES, ", arrayValsRoles)
        })
        console.log("PRINT ARRAY PUSHED, ", arrayValsRoles)

        const optionsRoles = {
			title:{
				text: "Valor gasto detalhado"
			},
			animationEnabled: true,
			exportFileName: fileNameRoles,
            exportEnabled: true,
			height: 350,
            colorSet: "colorSet4",
            subtitles: [{
				text: total + "€",
				verticalAlign: "center",
				fontSize: 22,
				dockInsidePlotArea: true
			}],
            legend: {
                horizontalAlign: "right",
                verticalAlign: "center",
                maxWidth: 550,
                itemWidth: 250,
				markerMargin: 10,
				itemWrap: true
            },
			data: [{
				type: "doughnut",
				showInLegend: true,
				legendText: "{name}: {y}€",
				toolTipContent: "{name}: {y}€",
				//indexLabel: "{name}",
				dataPoints: arrayValsRoles
			}]
		}

		//MAX VALUES
		let arrayMaxVals = []
		let arrayMaxVals2 = []
		let fileNameMax = "BudgetMaxReal_" + wedNames
		this.state.maxValues.map((itm, index) => {
			arrayMaxVals.push({ 
				y: itm.value,
				label: itm.cat
			})
            console.log("PRINT ARRAY PUSHED MAX, ", arrayMaxVals)
        })
		this.state.maxValues.map((itm, index) => {
			arrayMaxVals2.push({ 
				y: itm.max,
				label: itm.cat
			})
            console.log("PRINT ARRAY PUSHED MAX, ", arrayMaxVals)
        })
        console.log("PRINT ARRAY PUSHED, ", arrayMaxVals)

		const optionsMax = {
			title:{
				text: "Máximo Previsto e Gasto Real"
			},
			animationEnabled: true,
			theme: "colorSet4",
			exportFileName: fileNameMax,
			exportEnabled: true,
			axisY: {
				labelFormatter: this.addSymbols
			},
			data: [{
				type: "bar",
				showInLegend: true,
				legendText: "Máximo Recomendado",
				color: "#7FB285 ",
				dataPoints: arrayMaxVals2
			},
			{
				type: "bar",
				showInLegend: true,
				legendText: "Gasto Real",
				color: "#E68F75",
				dataPoints: arrayMaxVals
			}]
		}

		//DEBT/PAYMENTS VALUES
		let arrayDebtVals = []
		let arrayPaidVals = []
		let fileNamePayments = "BudgetPagamentos_" + wedNames
		this.state.debtValues.map((itm, index) => {
			arrayDebtVals.push({ 
				label: itm.role,
				y: itm.debtValue
			})
            console.log("PRINT ARRAY PUSHED DEBT, ", arrayDebtVals)
		})
		
		this.state.paidValues.map((itm, index) => {
			arrayPaidVals.push({ 
				label: itm.role,
				y: itm.paidValue
			})
            console.log("PRINT ARRAY PUSHED PAID, ", arrayPaidVals)
        })

		const optionsPayments = {
			title: {
				text: "Valor Pago/Em falta detalhado"
			},
			exportFileName: fileNamePayments,
			exportEnabled: true,
			toolTip: {
				shared: true
			},
			legend: {
				verticalAlign: "top"
			},
			axisY: {
				suffix: "€"
			},
			axisX: {
				labelFontSize:"12",
				labelAutoFit: true,
				interval: 1
			},
			data: [{
				type: "stackedBar",
				color: "#A0CA92",
				name: "Pago",
				showInLegend: true,
				dataPoints: arrayPaidVals
			},{
				type: "stackedBar",
				color: "#DEBA2B",
				name: "Em falta",
				showInLegend: true,
				//indexLabel: "{y}€",
				//indexLabelFontColor: "white",
				//yValueFormatString: "#,###'%'",
				dataPoints: arrayDebtVals
			}]
		}


        return(
            <div>
				<Row style={{marginLeft:"15px"}}>
					<h4 className="pointer" style={{ textAlign: "left", marginBottom: "2%", marginTop: "13px", marginRight: "1%" }}><ArrowBack style={{ fontSize: "18px" }} onClick={() => { this.props.history.push('/wedding_details/' + this.state.wedding._id) }} /></h4>
					<h5 style={{color:"#000000", textAlign:"center", marginTop:"17px"}}><b>Estatísticas Budget</b></h5>
				</Row>
				<Row style={{justifyContent:"space-evenly", marginBottom:"6%", marginTop:"5%"}}>
					<Card style={{width:"47%"}}>
						<CardContent>
							<CanvasJSChart options = {optionsRoles}/>
						</CardContent>
					</Card>
					<Card style={{width:"47%"}}>
						<CardContent>
							<CanvasJSChart options = {optionsTotal}/>
						</CardContent>
					</Card>
					
					{/* <Card style={{width:"56%"}}>
						<CardContent>
							<CanvasJSChart options = {optionsRoles}/>
						</CardContent>
					</Card> */}
				</Row>
				<Row style={{justifyContent:"space-evenly", marginBottom:"5%"}}>
					<Card style={{width:"96%"}}>
						<CardContent>
							<CanvasJSChart options = {optionsMax}/>
						</CardContent>
					</Card>
				</Row>
				<Row style={{justifyContent:"space-evenly", marginBottom:"5%"}}>
					<Card style={{width:"96%"}}>
						<CardContent>
							<CanvasJSChart options = {optionsPayments}/>
						</CardContent>
					</Card>
				</Row>
				{/* <Row style={{justifyContent:"space-evenly", marginBottom:"5%"}}>
					<Card style={{width:"96%"}}>
						<CardContent>
							<CanvasJSChart options = {optionsPayments}/>
						</CardContent>
					</Card>
				</Row> */}

			</div>
        )
    }
}

let appContainer = withRouter(MoreStatistics);
  function mapStateToProps(state) {
    return { tokens: state.tokens, reduxStorageLoaded: state.storageLoaded, validTokens: state.validTokens, _id: state.user._id }
  }
  const AppRedux = connect(
    mapStateToProps,
  )(appContainer);
  
export default AppRedux;