import PNotify from 'pnotify/dist/es/PNotify';
import getTimeToRead from '../helpers/get_time_to_read';

PNotify.defaults.styling = 'bootstrap4'; // Bootstrap version 4
PNotify.defaults.icons = 'bootstrap3'; // glyphicons

/**
 * Activate PNotify notification
 * 
 * @param {string} text Notifification text
 * @param {string} pnotifyType Notification type. Default 'error'
 * @param {number} [delay] Time the notification is shown (ms)
 * @param {Void}
 */

 export default function notify(text, pnotifyType, delay) {
     delay = delay || getTimeToRead(text);
     pnotifyType = pnotifyType || "error";
     PNotify.alert({
         text,
         delay,
         type: pnotifyType,
     });
     return undefined;
 }